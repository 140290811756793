
.Funnel_list{background:#777;display:inline-flex;flex-direction:column}
.Funnel_tr{display:flex;flex-direction:row;width:170px;justify-content:space-between;padding:7px 15px;font-weight:600;font-size:16px}
.Funnel_common{width:320px;justify-content:flex-end}
.Funnel_skew1{width:290px;height:130px;position:relative;justify-content:flex-end;
    &::after{content:'';width:0;height:0;border-left:120px solid transparent;border-right:0 solid transparent;border-bottom:130px solid #fff;position:absolute;right:0}
}

.Funnel_skew2{width:170px;height:150px;position:relative;
    &::after{content:'';width:0;height:0;border-left:120px solid transparent;border-right:0 solid transparent;border-bottom:100px solid #fff;position:absolute;right:0}
    &::before{content:'';width:120px;height:50px;position:absolute;right:0;bottom:0;background:#fff}
    & .Funnel_tr{padding-right:40px}
}

.funnel_line_{position:relative}
.Funnel_td_1{color:red}
.Funnel_td_2{color:#fff}
.Funnel_helper{display:inline-flex;flex-wrap:wrap}
.Funnel_tl{display:inline-flex;align-items:center;padding:10px 15px; & i{width:16px;height:16px;display:inline-flex;background:#000;margin-right:6px}}
.Funnel_bottom{display:flex;justify-content:space-between;padding:0 25px;margin-top:-90px;position:relative;z-index:9;font-size:14px; & .Funnel_helper:first-child{width:250px;background:#d6bbf05c}}
.Funnel_lost i{background:red}
.Funnel_Sucess i{background:#fff}

.left_funnel{text-align:right;padding-left:40px;width:360px;
    & .Funnel_skew1::after{content:'';width:0;height:0;border-left:0 solid transparent;border-right:120px solid transparent;border-bottom:130px solid #fff;position:absolute;left:0}
    & .Funnel_skew2{width:170px;height:150px;position:relative}
    & .Funnel_skew2::after{content:'';width:0;height:0;border-left:0 solid transparent;border-right:120px solid transparent;border-bottom:100px solid #fff;position:absolute;left:0}
    & .Funnel_skew2 .Funnel_tr{padding-right:15px;padding-left:40px}
    & .Funnel_skew1{align-items:flex-end}
    & .Funnel_skew2::before{content:'';width:120px;height:50px;position:absolute;left:0;bottom:0;background:#fff}
    & .funnel_line_ i{width:190px;height:2px;background:#000;position:absolute;right:100%;top:0}
    & .Funnel_skew1.funnel_line_ i{right:100%;width:70px}
    & .funnel_line_ i .Funnle_data{position:absolute;left:-208px;top:-8px;color:#000;width:190px}
    & .Funnel_skew1.funnel_line_ i .Funnle_data,
    & .funnel_line_ i .Funnle_data{display:block}
}

.right_funnel{text-align:left;padding-right:40px;width:360px;
    & .Funnel_skew1::after{content:'';width:0;height:0;border-left:120px solid transparent;border-right:0 solid transparent;border-bottom:130px solid #fff;position:absolute;right:0;left:inherit}
    & .Funnel_skew2{width:170px;height:150px;position:relative}
    & .Funnel_skew2::after{content:'';width:0;height:0;border-left:120px solid transparent;border-right:0 solid transparent;border-bottom:100px solid #fff;position:absolute;right:0;left:inherit}
    & .Funnel_skew2 .Funnel_tr{padding-left:15px;padding-right:40px}
    & .Funnel_skew1{align-items:flex-start}
    & .Funnel_skew2::before{content:'';width:120px;height:50px;position:absolute;right:0;bottom:0;background:#fff;left:inherit}
    & .funnel_line_ i{width:190px;height:2px;background:#000;position:absolute;left:100%;top:0}
    & .Funnel_skew1.funnel_line_ i{left:100%;width:70px}
    & .funnel_line_ i .Funnle_data{position:absolute;right:-200px;top:-8px;color:#000;width:190px}
    & .Funnel_skew1.funnel_line_ i .Funnle_data,
    & .funnel_line_ i .Funnle_data{display:block}
}

.Funnel_main_div{display:flex;background:#fff;justify-content:center;padding:30px 15px}
.Funnle_data{display:none}

.Funnel_stage_1 i{background:#7e85d3;}
.Funnel_stage_2 i{background:#4ebf7a;}
.Funnel_stage_3 i{background:#6401c0;}