@font-face {
  font-family: "Helvetica LT Std-1";
  src: url("font/woff/HelveticaNeueLTStd-Bd.woff") format("woff"), url("font/ttf/HelveticaNeueLTStd-Bd.ttf") format("truetype"), url("font/otf/HelveticaNeueLTStd-Bd.otf") format("opentype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Helvetica LT Std-1";
  src: url("font/woff/HelveticaNeueLTStd-Roman.woff") format("woff"), url("font/ttf/HelveticaNeueLTStd-Roman.ttf") format("truetype"), url("font/otf/HelveticaNeueLTStd-Roman.otf") format("opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Helvetica LT Std-1";
  src: url("font/woff/HelveticaNeueLTStd-Lt.woff") format("woff"), url("font/ttf/HelveticaNeueLTStd-Lt.ttf") format("truetype"), url("font/otf/HelveticaNeueLTStd-Lt.otf") format("opentype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "gotham-rounded";
  src: url("font/gotham/GothamRnd-Bold.otf") format("opentype"), url("font/gotham/GothamRnd-Bold.woff") format("woff"), url("font/gotham/GothamRnd-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "font_set_";
  src: url("font/gotham/SofiaPro-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

:root {
  --b-radius: 4px;
  --border-radius15: 4px;
  --border-radius100: 100%; }

body {
  font-family: "Helvetica LT Std-1";
  font-size: 15px;
  font-style: normal;
  background: #f6f6f6;
  color: #1e1e1e;
  line-height: initial;
  font-weight: normal; }

img {
  max-width: 100%; }

a,
a > * {
  outline: 0;
  cursor: pointer;
  text-decoration: none; }

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
  outline: 0; }

:focus {
  outline: 0; }

h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  padding: 0;
  margin: 0; }

h1 {
  font-size: 36px;
  line-height: 36px;
  margin: 0; }

h2 {
  font-size: 30px;
  line-height: 30px; }

h3 {
  font-size: 18px;
  line-height: 18px; }

h4 {
  font-size: 18px;
  line-height: 18px; }

h5 {
  font-size: 15px;
  line-height: 15px; }

h6 {
  font-size: 13px;
  line-height: 13px; }

p {
  line-height: 14px;
  font-size: 14px;
  font-weight: 400; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e;
  font-weight: 600; }

p {
  color: #1e1e1e; }

footer h5,
footer h6 {
  line-height: 0; }

footer h5 {
  color: #1e1e1e;
  padding-bottom: 15px;
  color: var(--txt-color); }

footer h6 {
  color: #1e1e1e;
  color: var(--txt-color); }

label {
  margin-bottom: 8px;
  font-weight: 400; }

.block-ui.block_ui_custom {
  position: initial !important; }

.block-ui.block_ui_custom .block-ui-container {
  position: initial !important; }

.block-ui.block_ui_custom .block-ui-message-container {
  margin-top: 10px !important; }

.block-ui.block_ui_custom .loading-indicator {
  opacity: 0 !important; }

.block-ui-overlay {
  height: 100%; }

.block-ui {
  position: static !important; }

input {
  border: 1px solid #1e1e1e;
  background: #fff; }

.event_loca input {
  border: 1px solid #1e1e1e;
  background: #fff;
  border-color: var(--b-color);
  border-radius: var(--b-radius); }

.event_loca button {
  margin: 0px;
  padding: 0px !important;
  border-radius: 0px !important;
  background: transparent;
  box-shadow: 0px 0px 0 !important;
  border-width: 0;
  position: absolute;
  right: 7px;
  z-index: 2;
  top: 50%;
  transform: translate(0, -40%);
  outline: none; }

.event_loca {
  width: 100%;
  position: relative; }

.event_loca input {
  height: 40px;
  line-height: 40px;
  padding: 0px 50px 0px 15px;
  outline: none; }

.event_loca.search_i_right .icon-search {
  font-size: 22px; }

.event_loca button i:before {
  font-size: 30px;
  line-height: 26px; }

.event_loca i.icon.icon-search:before {
  font-size: 22px; }

textarea {
  border: 1px solid #1e1e1e;
  background: #fff;
  padding: 10px 15px;
  border-radius: var(--b-radius); }

input.but {
  background: #1e1e1e;
  border-radius: var(--b-radius); }

button.but {
  background: #1e1e1e;
  border-radius: var(--b-radius); }

a.but {
  background: #1e1e1e;
  color: #fff;
  display: table; }

.but_one {
  background: #e6e6e6;
  color: #fff;
  display: table;
  font-size: 18px;
  font-weight: 400;
  border-radius: var(--b-radius); }

.but {
  background: #1e1e1e;
  color: #fff;
  display: table; }

a.but_1 {
  background: #1e1e1e;
  color: #fff; }

.input_and_textarea {
  border: 1px solid #1e1e1e; }

.input_and_textarea {
  background: #fff; }

.input_and_textarea textarea {
  border: 0 !important;
  border-radius: var(--b-radius);
  font-weight: 300; }

.input_and_textarea {
  overflow: hidden;
  padding: 0 15px;
  border-color: var(--b-color); }

.input_and_textarea input {
  border-radius: 0 !important;
  padding-left: 0 !important; }

.input_and_textarea textarea {
  width: 100%;
  padding: 0;
  border-radius: 0 !important;
  height: 90px; }

a:focus {
  color: #777; }

a:hover {
  color: #777; }

a:focus,
a:hover {
  outline: 0;
  text-decoration: none; }

.back_icons {
  color: #1e1e1e;
  color: var(--txt-color); }

.bor_T,
.bt-1 {
  border-top: 1px solid #1e1e1e; }

.bb-1,
.bor_B {
  border-bottom: 1px solid #1e1e1e; }

.by-1 {
  border-top: 1px solid #1e1e1e;
  border-bottom: 1px solid #1e1e1e; }

a.but_1 {
  background: #e6e6e6;
  color: #1e1e1e; }

#sidebar-wrapper::-webkit-scrollbar {
  display: none; }

#wrapper.toggled_new #sidebar-wrapper_new {
  right: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

#wrapper.toggled_new #sidebar-wrapper_new_new {
  width: 245px; }

#sidebar-wrapper_new,
#sidebar-wrapper_new_new {
  z-index: 1000;
  left: auto;
  right: -245px;
  width: 245px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #545454;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 0 0 10px #00000057; }

#sidebar-wrapper {
  z-index: 1000;
  left: -245px;
  width: 245px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #545454;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 0 0 10px #00000057; }

#wrapper {
  padding-left: 0;
  padding-right: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

#wrapper.toggled {
  padding-left: 0; }

#wrapper.toggled #sidebar-wrapper {
  left: 0; }

#sidebar-wrapper::-webkit-scrollbar {
  display: none; }

#wrapper.toggled_new #sidebar-wrapper {
  right: 245px; }

.hamburger {
  top: 20px;
  z-index: 1;
  width: 32px;
  height: 32px;
  background: 0 0;
  border: none;
  display: inline-flex; }

.hamburger_new {
  top: 20px;
  z-index: 1;
  width: 32px;
  height: 32px;
  background: 0 0;
  border: none; }

#wrapper.toggled_new {
  padding-right: 0; }

.collapsed_me {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.mx-height {
  max-height: 100% !important; }

.active_user::first-letter {
  text-transform: capitalize; }

.active_user {
  color: #fff;
  padding: 14px;
  position: absolute;
  background: #545454;
  width: 100%;
  font-size: 16px;
  z-index: 1;
  border-bottom: 1px solid;
  border-color: #857e7e78;
  min-height: 52px; }

.active_user_new {
  color: #fff;
  bottom: 0;
  padding: 14px;
  position: absolute;
  background: #545454;
  font-size: 14px;
  width: 100%;
  z-index: 1; }

.active_user_new i:before {
  transform: rotate(180deg);
  font-size: 14px;
  border: 1px solid #fff;
  width: 28px;
  display: inline-block;
  height: 27px;
  line-height: 27px;
  vertical-align: middle;
  text-align: center;
  border-radius: 20px;
  margin-right: 7px; }

.active_user a {
  color: inherit; }

.active_user {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.active_user_new,
.footer_right .bt-1 {
  border-top: 1px solid #857e7e78; }

.footer_right {
  color: #fff;
  bottom: 0;
  padding: 0 7px;
  position: fixed;
  background: #545454;
  font-size: 16px;
  width: auto;
  z-index: 3; }

.footer_right i:before {
  transform: rotate(0);
  font-size: 25px;
  border: #f0f8ff;
  width: 28px;
  display: inline-block;
  height: 30px;
  line-height: 27px;
  vertical-align: middle;
  text-align: center;
  border-radius: 20px;
  margin-left: 7px; }

.footer_right a {
  font-size: 14px;
  line-height: 16px; }

.footer_right .br-1 {
  border-color: #857e7e78; }

ul.your_setting_list li a {
  color: #fff; }

ul.your_setting_list li {
  font-size: 14px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 15px;
  margin-bottom: 0;
  list-style-type: none;
  border-bottom: solid 1px #4c4c4c;
  box-shadow: 0 3px 5px #525151;
  display: flex;
  align-items: center; }

ul.your_setting_list li a {
  color: #d4d4d4 !important;
  font-size: 14px;
  display: block;
  padding: 10px 15px;
  width: 100%; }

ul.your_setting_list li a:hover {
  color: #fff !important; }

ul.your_setting_list li:before {
  font-family: icons_me;
  content: "\71";
  margin-left: -20px;
  color: #fff;
  font-size: 8px; }

ul.your_setting_list li a {
  padding-left: 10px; }

ul.your_setting_list {
  list-style: none;
  padding: 0;
  padding-left: 10px; }

a.left-i {
  color: #fff;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle; }

a.right-i {
  color: #fff;
  font-size: 22px;
  display: inline-block;
  vertical-align: middle; }

.footer_right a.right-i {
  font-size: 15px; }

.right-i span {
  position: relative; }

.right-i span i {
  background: #ff2d55;
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  font-size: 12px;
  font-style: inherit;
  text-align: center;
  display: flex;
  top: -7px;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius100);
  right: -6px;
  line-height: 20px; }

.toggled_new .right_icons a,
.toggled_new .right_icons a:focus,
.toggled_new .right_icons a:hover,
.toggled_new .right_icons button,
.toggled_new .right_icons button:focus,
.toggled_new .right_icons button:hover {
  color: #777; }

.right_icons a.right-i,
.right_icons a.right-i:focus,
.right_icons a.right-i:hover,
.right_icons button.right-i,
.right_icons button.right-i:focus,
.right_icons button.right-i:hover {
  color: #fff; }

.right_icons button {
  background: 0 0;
  border: 0; }

.right_icons a {
  font-size: 22px;
  display: inline-block;
  height: 30px;
  align-items: center;
  line-height: 30px; }

.right_icons button {
  font-size: 22px;
  display: inline-block;
  height: 30px;
  align-items: center;
  line-height: 15px; }

.right_icons a,
.right_icons button {
  padding-left: 10px; }

.right_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.right_icons a,
.right_icons button {
  display: inline-flex;
  align-items: center;
  line-height: 25px;
  height: 100%;
  margin-top: 0px; }

.toggled .left-i .menu_bar_icon::before {
  content: "\e03b";
  font-family: icons_me; }

.default_but_remove {
  background: 0 0;
  padding: 0;
  border: 0; }

.mess_V_1 .nav_apps div {
  margin-left: 0;
  margin-bottom: 0; }

.nav_apps span.add_access {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  vertical-align: middle;
  font-size: 32px;
  line-height: 50px;
  border-radius: var(--border-radius100);
  font-weight: 600;
  color: #fff;
  opacity: 1;
  font-family: sans-serif; }

.left_men_content_2v .nav_apps {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 9px; }

.left_men_content_2v .nav_apps div {
  display: inline-block;
  /* margin-left:15px; */
  margin-bottom: 15px; }

.left_men_content_2v .nav_apps div a {
  text-align: center; }

.mess_V_1 .nav_apps span.add_access {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  font-size: 25px;
  justify-content: center;
  border: 0; }

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.8);
  z-index: 1; }

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  content: "";
  z-index: 50;
  display: none; }

.toggled .overlay_bg,
.toggled_new .overlay_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  margin-top: 53px; }

.overlay_text_p0 {
  position: absolute;
  bottom: -5px;
  z-index: 99;
  text-align: center;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  left: 0;
  bottom: -1px; }

.overlay_text_p01 {
  position: absolute;
  left: 50%;
  text-align: center;
  display: flex;
  top: 3px;
  transform: translate(-50%, 0);
  color: #fff; }

.bb-0 {
  border-bottom: 0 solid #fff !important; }

footer.text-center div {
  width: 100%; }

.footer_side_bar {
  position: fixed;
  bottom: 0;
  width: 245px; }

li.dropdown.open .caret {
  transform: rotate(180deg); }

.scroll_left {
  unicode-bidi: bidi-override;
  min-height: 100%;
  direction: rtl;
  overflow-y: auto;
  max-height: 100%; }

header.slds-global-header_container {
  width: 100%;
  z-index: 2;
  position: static; }
  header.slds-global-header_container div.slds-global-header {
    background-color: #f6f6f6; }
  header.slds-global-header_container div#header-profile-popover-dialog-body {
    padding: 0; }

header #header-profile-popover-dialog-body a {
  color: #006dcc;
  text-decoration: none;
  -webkit-transition: color 0.1s linear;
  transition: color 0.1s linear; }
  header #header-profile-popover-dialog-body a:hover, header #header-profile-popover-dialog-body a:focus {
    text-decoration: underline;
    color: #005fb2; }
  header #header-profile-popover-dialog-body a:focus {
    color: #005fb2; }

header li.slds-global-header__notification {
  background-color: white; }

div.header-notifications-popover-content {
  background-color: white; }

.left_menu_content .Imail_MSG_div:first-child {
  border-top: 1px solid;
  border-color: #857e7e78; }

.left_menu_content::-webkit-scrollbar {
  width: 5px; }

.left_menu_content::-webkit-scrollbar-track {
  background-color: none;
  border-left: 0px solid;
  border-radius: 10px; }

.left_menu_content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px; }

.left_menu_content::-webkit-scrollbar-thumb:hover {
  background-color: #777; }

.left_menu_content {
  position: absolute;
  width: 100%;
  height: 85%;
  background: #545454;
  padding-top: 0;
  overflow: auto;
  padding-bottom: 70px;
  margin-top: 20%; }

.left_menu_content .collapsible {
  background-color: #545454;
  color: #fff;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: none;
  text-align: left;
  outline: 0;
  font-size: 15px;
  font-weight: 400; }

.left_menu_content .collapsible:after {
  font-family: icons_me;
  content: "\69";
  color: #fff;
  font-weight: 700;
  float: left;
  margin-right: 5px;
  font-size: 12px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  margin-top: 4px; }

.left_menu_content .active_side_toggle:after {
  font-family: icons_me;
  content: "\66"; }

.left_menu_content .side_dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #545454; }

.left_men_content_2v {
  overflow: hidden; }

.but_search {
  margin: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  background: 0 0;
  box-shadow: 0 0 0 !important;
  border-width: 0;
  position: absolute;
  left: 10px;
  font-size: 20px;
  z-index: 1;
  top: 50%;
  transform: translate(0, -40%);
  outline: 0; }

.show_div_1 {
  display: flex;
  width: 100%; }

.show_div_1 .img_p img {
  width: 100%; }

.show_div_1 .img_p img {
  position: absolute;
  width: 75%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.show_div_1 .img_p {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius100);
  padding: 0;
  display: inline-block;
  background: #fff;
  align-items: center;
  vertical-align: middle;
  border: 1px solid #fff;
  position: relative; }

.show_div_2 {
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 13px; }

.show_div_2 {
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 15px;
  padding: 8px 15px;
  border-radius: var(--b-radius);
  font-weight: 300;
  margin-top: 5px; }

.show_div_3 {
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 13px;
  padding: 8px 15px;
  border-radius: var(--b-radius);
  font-weight: 300;
  margin-top: 5px; }

.show_div_4 {
  display: flex;
  width: 100%;
  color: #fff;
  justify-content: space-between; }

.show_div_4 span {
  font-size: 13px;
  font-weight: 300; }

.show_div_4 i {
  font-size: 22px;
  margin-top: 5px; }

.show_notification {
  position: relative; }

.show_notification span {
  position: absolute;
  right: -8px;
  top: -7px;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  background: #ff2d55;
  height: 18px;
  width: 18px;
  border-radius: var(--border-radius100);
  text-align: center;
  line-height: 20px;
  font-family: sans-serif;
  font-style: initial;
  display: flex;
  align-items: center;
  justify-content: center; }

.show_notification {
  position: relative;
  font-size: 25px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-left: 9px;
  margin-right: 0; }

.img_p_name {
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: var(--b-radius);
  margin-left: 5px; }

.menu_bar_icon {
  display: flex; }

.Not_msg_div {
  white-space: normal; }

.Not_msg_div {
  background: #fff;
  border-radius: var(--border-radius15);
  padding: 10px;
  margin-top: 5px; }

.Not_msg_div .Not_m_d1 {
  font-size: 15px;
  font-weight: 400; }

.Not_msg_div div span {
  font-weight: 600; }

.Not_msg_div div span {
  font-weight: 600;
  color: #f8741c; }

.Not_m_d3 {
  font-size: 13px;
  font-weight: 400; }

.Not_m_d2 {
  font-size: 10px;
  font-weight: 400;
  margin: 0 0 9px; }

.input_search.change_b input {
  font-size: 18px; }

.input_search.change_b span.icon.icon-search {
  color: var(--txt-color); }

.gradient_color .input_3 {
  font-weight: 300 !important;
  font-family: Helvetica LT Std-1;
  font-size: 16px;
  line-height: 1.5;
  border: 0 solid transparent;
  display: block;
  width: 100%;
  text-align: center;
  height: 45px;
  padding: 0; }

.gradient_color .but_login {
  font-weight: 400 !important; }

.gradient_color .but_login {
  border-radius: var(--b-radius); }

.gradient_color .but_login {
  border-radius: var(--b-radius);
  border: 0;
  font-family: Helvetica LT Std-1;
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
  width: 100%;
  min-height: 50px;
  background: #57b846;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  background: linear-gradient(-135deg, #d77f0b, #09a275); }

.gradient_color .login_but {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.gradient_color .logo {
  font-weight: 600;
  margin-top: 15px; }

.gradient_color .login_1 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 50px 0; }

.gradient_color .Smiley h1 {
  font-family: "gotham-rounded" !important;
  font-weight: 600;
  color: #1e1e1e; }

.gradient_color .Smiley h1 {
  font-size: 36px; }

.gradient_color .User {
  text-align: center;
  margin: 30px auto 20px; }

.gradient_color .User span {
  width: 200px;
  height: 200px;
  border: double 2px transparent;
  background-image: linear-gradient(white, #fff), radial-gradient(circle at top left, #38266e, #38266e);
  display: table;
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 0 auto;
  display: flex;
  border-radius: var(--border-radius100);
  overflow: hidden;
  position: relative; }

.gradient_color .User span img {
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px; }

.gradient_color .input_2 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 15px;
  background: #38266e;
  border-radius: var(--b-radius) var(--b-radius) var(--b-radius) var(--b-radius);
  -moz-border-radius: var(--b-radius) var(--b-radius) var(--b-radius) var(--b-radius);
  -webkit-border-radius: var(--b-radius) var(--b-radius) var(--b-radius) var(--b-radius);
  padding: 1px;
  display: inline-table; }

.gradient_color .for_text a {
  color: #1e1e1e;
  font-family: Helvetica; }

.gradient_color footer h5,
.gradient_color footer h6 {
  color: #1e1e1e; }

.gradient_color .b_check_2 label {
  margin-bottom: 0;
  color: #1e1e1e;
  font-family: Helvetica; }

.gradient_color .b_check_2 input.checkbox2[type="checkbox"] + label span {
  border: 0 solid #1ca278;
  background: linear-gradient(-135deg, #d77f0b, #09a275);
  float: left; }

.gradient_color .b_check_2 label {
  margin-bottom: 0;
  color: #1e1e1e;
  font-family: Helvetica; }

.gradient_color input.checkbox2[type="checkbox"]:checked + label span:after {
  background: linear-gradient(-135deg, #d77f0b, #09a275);
  width: 19px;
  height: 19px;
  border-radius: 5px; }

.gradient_color .login100-form {
  width: 100%; }

footer.text-center div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; }

footer h5 {
  font-size: 10px;
  font-weight: 600; }

footer h5 a {
  color: var(--txt-color); }

footer h6 {
  font-size: 10px;
  font-weight: 600; }

footer h6 span {
  position: relative; }

footer h6 span:after {
  position: absolute;
  bottom: -1px;
  right: 0;
  font-size: 6px; }

.access-error {
  color: #c00; }

.error_login {
  text-align: center;
  font-size: 15px;
  color: #c00;
  font-weight: 300; }

.success_login {
  text-align: center;
  font-size: 15px;
  color: #57b846;
  font-weight: 300; }

.e_new span {
  display: flex;
  height: 40px;
  background: #c00;
  color: #fff;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  border-radius: var(--b-radius);
  border-radius: var(--b-radius); }

.e_new span i {
  font-size: 23px;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 0; }

.e_new span div {
  width: 95%; }

.s_new span {
  display: flex;
  height: 40px;
  background: #249e67;
  color: #fff;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  border-radius: var(--b-radius);
  border-radius: var(--b-radius); }

.s_new span i {
  font-size: 23px;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 0;
  border-radius: var(--b-radius); }

.s_new span div {
  width: 85%; }

form {
  display: inline; }

.gradient_color .but_login {
  background: #38266e; }

html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  position: relative;
  padding-bottom: 65px; }

.button_small a {
  padding: 7px 24px;
  text-align: center;
  background: #fff;
  border: 1px solid #1e1e1e;
  font-weight: 600;
  border-radius: var(--b-radius); }

.Close_SB {
  font-size: 24px;
  opacity: 0.5;
  cursor: pointer; }

.logo img {
  width: 200px; }

.Smiley i {
  display: none; }

.main_header {
  height: 55px;
  background-color: #565656;
  box-shadow: 0 5px 15px #cecece;
  padding: 0 15px;
  height: 70px;
  background-color: #565656;
  box-shadow: 0 5px 15px #cecece;
  padding: 0 15px; }

.sidebar {
  width: 0;
  background: #fff;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0; }

.sidebar.left {
  left: 0;
  width: 300px;
  transition: all ease 0.5s; }

.sidebar.right {
  right: 0;
  width: 300px;
  transition: all ease 0.5s; }

.asideSect__ {
  background: #f7f7f7;
  min-height: calc(100vh - 55px);
  position: relative;
  padding: 15px 15px;
  padding-left: 245px; }

.main_sidebar__ {
  width: 245px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 107%; }

.main_sidebar__::-webkit-scrollbar {
  width: 4px; }

.main_sidebar__::-webkit-scrollbar-track {
  background: #f1f1f1; }

.main_sidebar__::-webkit-scrollbar-thumb {
  background: #888; }

.main_sidebar__::-webkit-scrollbar-thumb:hover {
  background: #555; }

.bodyNormal {
  transition: all ease 0.2s; }

.bodyNormal.right {
  margin-right: 300px;
  transition: all ease 0.2s; }

.bodyNormal.left {
  margin-left: 300px;
  transition: all ease 0.2s; }

.sideCnts {
  display: none;
  padding: 15px; }

.notifyBar .sideCnts.notify_cnt {
  display: block; }

.mailBar .sideCnts.mail_cnt {
  display: block; }

.sideNavies__ {
  list-style-type: none;
  padding-left: 0;
  font-size: 14px; }

.sideNavies__ li a {
  font-size: 13px;
  font-weight: 400;
  padding: 7px 15px; }

.sideNavies__ .Sub_in_sub li a {
  padding-bottom: 5px;
  padding-top: 5px; }

.sideNavies__ li .drpHdng a {
  padding: 0;
  width: 100%; }

.sideNavies__ a {
  display: block;
  padding: 7px 15px;
  color: #6b6b6b; }

.sideNavies__ a.active:hover,
.sideNavies__ a:hover {
  text-decoration: none;
  font-weight: 500;
  color: var(--bg-color); }

.sideNavies__ a.active,
.sideNavies__ a.active:hover {
  font-weight: 700;
  color: var(--bg-color); }

.dropdownMenu {
  transition: all ease 0.5s; }

.dropdownMenu ul {
  display: none;
  padding-left: 15px; }

.sideHead1__ {
  width: 100%;
  min-height: 100px;
  background: var(--bg-color);
  font-size: 17px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  font-weight: 600; }

.drpHdng {
  cursor: pointer;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between; }

.drpHdng > i.icon:before {
  width: auto;
  height: auto;
  position: static; }

.drpHdng span {
  flex: 1; }

.drpHdng.open > i {
  transform: rotate(90deg); }

.drpHdng.open + ul {
  display: block; }

.drpHdng i {
  font-size: 12px; }

.border_R1.bt-0,
.bt-0 {
  border-top: 0px !important; }

.border_R2.bz,
.bz {
  border: 0px solid #000 !important;
  box-shadow: none;
  margin-bottom: 10px; }

.border_R1.bx-0,
.bx-0 {
  border-left: 0px solid #000 !important;
  border-right: 0px solid #000 !important; }

input.border_R1 {
  border-color: #cacaca !important; }

.Select-input > input {
  border: 0px solid #ddd !important;
  padding: 0px 0px !important;
  height: 35px;
  line-height: 37px; }

.default_color {
  color: #1e1e1e !important; }

.br-0 {
  border-right: 0px solid; }

.bl-0 {
  border-left: 0px solid; }

.title_pin01__ h2 {
  font-weight: 600;
  font-family: "gotham-rounded" !important;
  font-size: 25px;
  text-align: center;
  margin-bottom: 10px; }

.row.your [class*="col-"] {
  background-color: #e6e6e6;
  min-height: 100px;
  margin-bottom: 30px;
  border-radius: var(--b-radius);
  margin: 0px;
  height: 250px; }
  .row.your [class*="col-"] a .Ext_1 {
    color: #d12e2f; }
  .row.your [class*="col-"] a .Int_1 {
    color: #09a075; }
  .row.your [class*="col-"] a .Ext_1 {
    font-size: 90px; }
  .row.your [class*="col-"] a .Int_1 {
    font-size: 90px; }

.row.your h4 {
  padding: 30px 15px 0px;
  height: 96px;
  font-size: 24px;
  line-height: 26px;
  font-family: gotham-rounded !important;
  color: #1e1e1e; }

h1.not_msg_v1 {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  color: #adacac;
  font-size: 50px;
  line-height: 50px; }

.mnExtraSmallNumBox .num_stats2 {
  flex-wrap: wrap; }
  .mnExtraSmallNumBox .num_stats2 span {
    font-size: 18px; }

.toggle_list_set_ {
  display: none; }

.extramoremenus {
  float: left;
  display: block;
  right: 0;
  position: absolute;
  width: 200px !important;
  background: #fff;
  border: 1px solid #f6f7f7;
  padding: 5px 0;
  box-shadow: 0 1px 3px 0px #a69f9f;
  z-index: 1; }

.extramoremenus .slds-context-bar__label-action {
  padding: 6px 10px; }

.arrowspan {
  padding-left: 6px;
  display: inline-block;
  margin-top: -5px; }

.arrowspan .arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px; }

.arrowspan .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

#GlobalMenuParent.hidemenuleft {
  display: none; }

#SidebarTogglerParent {
  display: none; }

.v_align_top {
  vertical-align: top;
  max-width: 85%; }

.bordset {
  border: 2px solid #ccc;
  padding: 5px 20px 20px 20px; }

@media only screen and (min-width: 1025px) {
  .maxwidset {
    max-width: 38%; } }

a.but_gray {
  font-size: 15px;
  width: 100%;
  line-height: 15px;
  padding: 12px 15px;
  text-align: center;
  display: block;
  font-size: 20px;
  border-radius: var(--b-radius); }

.but_gray img {
  position: absolute;
  left: 25px; }

input {
  padding: 0px 15px 0px;
  font-size: 14px;
  width: 100%;
  line-height: 40px;
  height: 40px;
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: var(--b-radius); }

a.but_1 {
  font-size: 20px;
  line-height: 20px;
  display: table;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: var(--b-radius); }

.button_plus__ {
  width: 40px;
  height: 40px;
  background: var(--b-color);
  border: 0;
  border-radius: var(--b-radius);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff; }
  .button_plus__ .Add-2-1 {
    font-size: 30px;
    padding-top: 2px; }
  .button_plus__ .Add-2-2 {
    font-size: 23px;
    padding-top: 2px; }

.input_plus__ {
  position: relative; }
  .input_plus__ .btn_0_type,
  .input_plus__ button {
    position: absolute;
    right: 1px;
    height: 38px;
    width: 38px;
    display: inline-flex;
    background: #fff;
    border: 0;
    top: 50%;
    justify-content: center;
    color: var(--txt-color);
    padding: 0px;
    transform: translate(-1px, -50%);
    border-radius: var(--b-radius); }
  .input_plus__ .Add-1 {
    font-size: 30px;
    padding-top: 2px;
    align-items: center; }
  .input_plus__ .Add-2 {
    font-size: 23px;
    padding-top: 2px;
    align-items: center; }

input.but,
a.but,
.but,
button.but {
  font-size: 15px;
  width: 100%;
  line-height: 15px;
  padding: 12px 15px;
  border: 0px;
  text-align: center;
  font-weight: bold;
  border-radius: var(--b-radius);
  background: var(--bg-color);
  background-color: var(--bg-color); }

.Plus_button span {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  font-weight: 400; }
  .Plus_button span.icon-add-icons:before {
    font-size: 25px;
    line-height: 0;
    padding-top: 10px;
    display: table-cell; }

.slots_sp {
  color: #fff;
  padding: 3px 20px;
  font-weight: 600;
  display: inline-block;
  border-radius: var(--b-radius);
  min-width: 70px;
  text-decoration: none !important;
  text-align: center;
  border: 0px solid; }
  .slots_sp.clr_green {
    background: #1eb35a; }
  .slots_sp.clr_purple {
    background: #5963cb; }
  .slots_sp.clr_blue {
    background: #2e9cca; }
  .slots_sp.clr_red {
    background: #ff0000; }
  .slots_sp.clr_yellow {
    background: #feb200; }

.clr_green {
  background: #1eb35a; }

.clr_purple {
  background: #5963cb; }

.clr_blue {
  background: #2e9cca; }

.clr_red {
  background: #ff0000; }

.clr_yellow {
  background: #feb200; }

.header_CABDay .slots_sp {
  font-size: 12px;
  font-weight: 400; }

.cmn-btn1 {
  background-color: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius);
  transition: all ease 0.5s;
  outline: none !important;
  cursor: pointer; }
  .cmn-btn1:hover, .cmn-btn1:focus {
    box-shadow: 0px 5px 15px #cecece;
    color: #fff; }

.cmn-btn2 {
  background-color: #fff;
  color: var(--bg-color);
  border-radius: var(--b-radius);
  transition: all ease 0.5s;
  outline: none !important;
  border: solid 1px var(--bg-color); }
  .cmn-btn2:hover, .cmn-btn2:focus {
    box-shadow: 0px 5px 15px #cecece;
    background-color: var(--bg-color);
    color: #fff; }

.creat_task_btn__ {
  padding: 8px 45px;
  font-size: 14px;
  font-weight: 500;
  float: right;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 10px; }

.NA_btn,
a.NA_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 8px 3px;
  border: 1px solid;
  border-color: var(--bg-color);
  color: var(--bg-color);
  font-weight: 600;
  border-radius: 3px;
  transition: all ease 0.5s;
  font-size: 15px; }

.active_NA_Quiz,
a.active_NA_Quiz {
  background: var(--bg-color);
  color: #fff; }

.main_row_NA_Quiz {
  background: rgba(255, 112, 67, 0.1);
  display: flex;
  flex-direction: column;
  padding: 15px; }

a.NA_btn:hover {
  box-shadow: 0px 5px 15px #cecece; }

.progress_color_01_ {
  background: rgba(46, 156, 202, 0.1); }

.progress_color_01_ .NA_btn,
.progress_color_01_ a.NA_btn,
.progress_color_02_ a.btn_1_Contact,
.progress_color_02_ .btn_1_Contact {
  border-color: #2e9cca;
  color: #2e9cca; }

.progress_color_01_ .active_NA_Quiz,
.progress_color_01_ a.active_NA_Quiz,
.progress_color_02_ a.btn_1_Contact.active,
.progress_color_02_ .btn_1_Contact.active {
  background: #2e9cca;
  color: #fff; }

.success_color_01_ {
  background: #1eb35a26; }

.success_color_01_ .NA_btn,
.success_color_01_ a.NA_btn,
.success_color_02_ a.btn_1_Contact,
.success_color_02_ .btn_1_Contact {
  border-color: #1eb35a;
  color: #1eb35a; }

.success_color_01_ .active_NA_Quiz,
.success_color_01_ a.active_NA_Quiz,
.success_color_02_ a.btn_1_Contact.active,
.success_color_02_ .btn_1_Contact.active {
  background: #1eb35a;
  color: #fff; }

.danger_color_01_ {
  background: rgba(255, 0, 0, 0.1); }

.danger_color_01_ .NA_btn,
.danger_color_01_ a.NA_btn,
.danger_color_02_ a.btn_1_Contact,
.danger_color_02_ .btn_1_Contact {
  border-color: #ff0000;
  color: #ff0000; }

.danger_color_01_ .active_NA_Quiz,
.danger_color_01_ a.active_NA_Quiz,
.danger_color_02_ a.btn_1_Contact.active,
.danger_color_02_ .btn_1_Contact.active {
  background: #ff0000;
  color: #fff; }

.pending_color_01_ {
  background: rgba(254, 178, 0, 0.1); }

.pending_color_01_ .NA_btn,
.pending_color_01_ a.NA_btn,
.pending_color_02_ a.btn_1_Contact,
.pending_color_02_ .btn_1_Contact {
  border-color: #feb200;
  color: #feb200; }

.pending_color_01_ .active_NA_Quiz,
.pending_color_01_ a.active_NA_Quiz,
.pending_color_02_ a.btn_1_Contact.active,
.pending_color_02_ .btn_1_Contact.active {
  background: #feb200;
  color: #fff; }

a.Req_btn_out_1[disabled],
.Req_btn_out_1[disabled],
.NA_btn:disabled,
a.NA_btn[disabled],
button.NA_btn[disabled] {
  opacity: 0.3;
  cursor: no-drop;
  border-color: #777;
  color: #7777; }

button[disabled],
input[disabled] {
  cursor: not-allowed !important;
  opacity: 0.3 !important; }

.input_ra_disabled[disabled] {
  cursor: not-allowed !important;
  opacity: 1 !important; }

a.btn_1_Contact,
.btn_1_Contact {
  font-size: 13px;
  padding: 6px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 2px;
  border: 1px solid;
  border-color: var(--bg-color);
  color: var(--bg-color);
  text-align: center; }

a.btn_1_Contact.active {
  color: #fff;
  background: var(--bg-color); }

.btn.apli_btn__ {
  width: 300px;
  margin-top: 15px;
  margin-bottom: 10px;
  max-width: 100%; }

.eye-btn:after {
  content: "\4f";
  font-family: "icons_me";
  position: absolute;
  right: 10px;
  font-size: 16px;
  top: 4px; }

.hdng_btn.icn_btn12 {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: center;
  min-width: 250px;
  padding-right: 40px;
  position: relative; }

.hdng_btn.icn_btn12 i {
  position: absolute;
  right: 15px; }

a.Plus_button {
  color: #fff; }

a.Plus_button {
  font-size: 14px;
  width: 100%;
  font-weight: 200 !important;
  padding: 9px 5px;
  display: table;
  text-align: center;
  vertical-align: middle; }

a.Plus_button {
  background: #1e1e1e; }

a.Plus_button {
  padding: 4px 5px;
  background: var(--bg-color);
  background-color: var(--bg-color);
  border-radius: var(--b-radius); }

.appli_btn_,
a.appli_btn_ {
  font-size: 13px;
  min-width: 140px;
  background: transparent;
  border: 1px solid;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  padding: 4px 30px;
  border-radius: 3px;
  color: var(--bg-color);
  border-color: var(--bg-color); }

a.appli_btn_ {
  cursor: pointer; }

.appli_btn_.active_1 {
  background: var(--bg-color);
  color: #fff; }

.flag-btn:after {
  content: "\e085";
  font-family: "icons_me";
  position: absolute;
  right: 10px;
  font-size: 16px;
  top: 4px; }

.phone-btn::after {
  content: "\e068";
  font-family: "icons_me";
  position: absolute;
  right: 10px;
  font-size: 16px;
  top: 4px; }

.cmn-btn1.flag-btn,
.cmn-btn1.phone-btn {
  padding-right: 40px !important;
  position: relative;
  max-width: 100%;
  font-size: 14px; }

.inACtive_bTN_span span {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 0;
  color: #777;
  min-width: 65px;
  display: inline-block;
  background: #f5f5f5;
  border-color: #f5f5f5;
  cursor: pointer; }
  .inACtive_bTN_span span.active {
    background: var(--bg-color);
    color: #fff;
    cursor: default; }
  .inACtive_bTN_span span:first-child {
    border-radius: 3px 0px 0px 3px; }
  .inACtive_bTN_span span:nth-child(2) {
    border-radius: 0px 3px 3px 0px; }

.C_NeW_BtN {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  background: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius);
  border: 1px solid var(--bg-color); }
  .C_NeW_BtN span {
    width: 100%;
    text-align: center; }
  .C_NeW_BtN i {
    font-size: 25px;
    display: flex;
    padding-left: 15px; }

a.C_NeW_BtN:hover,
a.C_NeW_BtN:focus {
  box-shadow: 0px 5px 15px #cecece;
  color: #fff;
  transition: all ease 0.5s; }

.but_submit {
  cursor: pointer;
  width: 100%;
  line-height: 15px;
  padding: 12px 15px;
  font-size: 17px;
  background: var(--bg-color);
  border: 0px;
  color: #fff;
  font-weight: 300; }
  .but_submit:hover {
    color: #fff; }

.but_submit.but_outline {
  border: 1px solid var(--bg-color);
  background: none;
  color: var(--bg-color);
  padding: 11px 15px;
  background: #fff; }

.rec_assi .nme_assi {
  border: solid 1px var(--b-color);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  font-size: 13px;
  font-weight: normal;
  color: var(--b-color);
  border-radius: var(--b-radius);
  position: relative;
  padding-right: 40px;
  cursor: pointer;
  word-break: break-all; }
  .rec_assi .nme_assi span,
  .rec_assi .nme_assi button {
    float: right;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 4px;
    background: transparent;
    color: #fff;
    border: 0px solid; }

.counties2 {
  border: solid 1px;
  padding: 1px 5px;
  display: inline-flex;
  margin-right: 5px;
  border-radius: var(--b-radius);
  height: 18px;
  align-items: center; }

.counties2.red {
  border-color: #fe1313;
  color: #fe1313; }

.counties2.green {
  border-color: #1eb35a;
  color: #1eb35a; }

.counties2.orange {
  border-color: var(--b-color);
  color: var(--b-color); }

.Req_btn_out_1 {
  background: transparent;
  border: 1px solid;
  padding: 3px 7px;
  border-radius: 3px;
  display: inline-flex;
  font-size: 12px;
  align-items: center;
  justify-content: center; }
  .Req_btn_out_1._Succes_btn {
    border-color: #14a751;
    color: #14a751; }
  .Req_btn_out_1._Succes_btn.active {
    background: #14a751;
    color: #fff; }
  .Req_btn_out_1._Error_btn {
    border-color: red;
    color: red; }
  .Req_btn_out_1._Error_btn.active {
    background: red;
    color: #fff; }

.Manage_li_a2_ .Req_btn_out_1:first-child {
  border-radius: 3px 0px 0px 3px; }

.Manage_li_a2_ .Req_btn_out_1:nth-child(2) {
  border-radius: 0px 3px 3px 0px; }

.Manage_li_a2_ .Req_btn_out_1 {
  color: #777;
  background: #f5f5f5;
  border-color: #f5f5f5;
  padding: 6px 7px; }

.Manage_li_a2_ .Req_btn_out_1.active_selected {
  background: var(--bg-color);
  color: #fff; }

.search_icons_right input {
  height: 40px;
  line-height: 40px;
  padding: 0px 0px 0px 50px;
  outline: none; }

.search_icons_right button {
  margin: 0px;
  padding: 0px !important;
  border-radius: 0px !important;
  background: transparent;
  box-shadow: 0px 0px 0 !important;
  border-width: 0;
  position: absolute;
  left: 10px;
  z-index: 2;
  top: 50%;
  transform: translate(0, -50%);
  outline: none; }

.cmn-btn1.eye-btn {
  padding-right: 40px !important;
  position: relative;
  max-width: 100%;
  font-size: 12px; }

.upload_btn i.but {
  position: relative; }

.upload_btn .attach_im {
  margin: 0; }

.upload_btn .btn-file .v-c-btn1 {
  position: relative;
  border-radius: var(--b-radius);
  font-style: inherit;
  font-weight: 300;
  font-size: 15px; }

.upload_btn input {
  border: 0px solid; }

.upload_btn .but {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.input_plus__ input {
  padding-right: 40px; }

div[progress_count*="art"] {
  background: #000; }

.default-input {
  height: 40px;
  width: 100%;
  border: 1px solid;
  border-radius: var(--b-radius);
  outline: 0;
  padding: 0 15px;
  border-color: var(--b-color); }

.btn-1 {
  font-weight: 400;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--b-radius);
  background: var(--bg-color);
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  border: 0px; }
  .btn-1.outline-btn-1 {
    background: #fff;
    color: var(--bg-color);
    border: 1px solid var(--bg-color); }
    .btn-1.outline-btn-1:hover {
      background: #fff;
      color: var(--bg-color);
      box-shadow: 0px 5px 15px #cecece; }

.btn-2 {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--b-radius);
  background: #cdcccc;
  color: #1e1e1e;
  font-size: 19px;
  cursor: pointer;
  border: 0px; }

.btn-2.active {
  background: #e6e6e5;
  color: var(--txt-color); }

.btn-3 {
  font-weight: 400;
  font-size: 15px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--b-radius);
  background: var(--bg-color);
  color: #fff;
  font-size: 15px;
  cursor: pointer; }

.btn-1.s2 {
  height: 30px;
  font-size: 15px;
  min-height: 30px; }

.btn-1:hover,
.btn-2:hover,
.btn-3:hover,
.v-c-btn1:hover {
  background: var(--bg-color);
  color: #fff;
  box-shadow: 0px 5px 15px #cecece; }

div.btn-1,
div.btn-2,
div.btn-3,
div.v-c-btn1 {
  background: var(--bg-color);
  cursor: initial; }

div.btn-1:hover,
div.btn-2:hover,
div.btn-3:hover,
div.v-c-btn1:hover {
  background: var(--bg-color); }

.button_unadd i:before {
  background: var(--bg-color);
  background-color: var(--bg-color);
  border-radius: var(--b-radius); }

.but_submit {
  background: var(--bg-color);
  background-color: var(--bg-color);
  border-radius: var(--b-radius); }

.but_submit.w-100 {
  width: 100% !important; }

div.btn-1 {
  background: var(--bg-color);
  background-color: var(--bg-color); }

div.btn-2 {
  background: var(--bg-color);
  background-color: var(--bg-color); }

div.btn-3 {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.v-c-btn1 {
  font-weight: 400;
  display: flex;
  align-items: center;
  min-height: 40px;
  background: var(--bg-color);
  border-radius: var(--b-radius);
  cursor: pointer;
  color: #fff;
  width: 100%;
  border: 0;
  padding: 0; }
  .v-c-btn1 i {
    display: flex;
    align-items: center;
    font-size: 17px;
    height: 100%;
    width: 45px;
    justify-content: center;
    padding-top: 3px;
    padding-right: 15px; }
  .v-c-btn1 span {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    height: 100%;
    padding-left: 15px; }
  .v-c-btn1.n2 {
    min-height: 30px;
    font-weight: 400;
    font-size: 13px; }
  .v-c-btn1.n2 i {
    font-size: 20px;
    padding-top: 1px; }

.add-btn-a1,
.remove-btn-a1 {
  display: inline-flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  font-size: 23px;
  border-radius: var(--b-radius);
  border: 0;
  padding-top: 2px;
  background: var(--bg-color);
  color: #fff;
  outline: 0;
  align-items: center;
  cursor: pointer; }

.add-btn-a1.a2,
.remove-btn-a1.a2 {
  font-size: 30px;
  padding-top: 2px; }

.short_buttons_01,
a.short_buttons_01,
button.short_buttons_01 {
  display: inline-flex;
  background: var(--bg-color);
  color: #fff;
  font-size: 11px;
  padding: 5px 7px;
  border-radius: 3px;
  min-width: 50px;
  justify-content: center;
  cursor: pointer;
  border: 0; }

.F__bTN,
a.F__bTN {
  padding: 7px 25px; }

.btn_color_ndis,
a.btn_color_ndis,
button.btn_color_ndis {
  background: #000; }

.btn_color_ihcyf,
a.btn_color_ihcyf,
button.btn_color_ihcyf {
  background: #e07196; }

.btn_color_assigned,
a.btn_color_assigned,
button.btn_color_assigned {
  background: #2e9cca; }

.btn_color_avaiable,
a.btn_color_avaiable,
button.btn_color_avaiable {
  background: #00bf45; }

.btn_color_offline,
a.btn_color_offline,
button.btn_color_offline {
  background: #c5c5c5; }

.btn_color_archive,
a.btn_color_archive,
button.btn_color_archive {
  background: #ff0000; }

.btn_color_unavailable,
a.btn_color_unavailable,
button.btn_color_unavailable {
  background: #ffb300; }

.short_buttons_01.white_btn {
  background: #fff;
  color: #000;
  border: solid 1px #000; }

.R_bt_co_ {
  color: var(--bg-color); }

.R_bt_co_blue {
  color: #03a9f4; }

.R_bt_co_green {
  color: #00be44; }

.text_ellip_2line {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  line-height: normal;
  width: 100%; }

.AddionalQ_table .text_ellip_2line {
  font-size: 15px; }

.add-btn-z1::after {
  content: "\e04e";
  font-family: "icons_me"; }

.remove-btn-z1::after {
  content: "\e0af";
  font-family: "icons_me"; }

.add_right_site_icons:after {
  content: "";
  margin-left: 6px;
  font-family: "icons_me"; }

.location_at_i:after {
  content: "\e095"; }

.Skilled_label_ {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0px; }

.Skilled_div_01 {
  padding: 15px;
  background: #ececec; }

.Skilled_label1_ {
  font-size: 24px;
  font-weight: 600;
  padding: 7px 0px;
  border-bottom: 1px solid; }

.green_dark small,
.green_regular small,
.green_very_light small,
.red_dark small,
.yello_dark small {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  margin-right: 5px; }

.green_dark small {
  background: #009244; }

.red_dark small {
  background: #e41a28; }

.yello_dark small {
  background: #e19f00; }

.green_regular small {
  background: #08b750; }

.green_very_light small {
  background: #8fd050; }

.upda_Icon:before {
  font-size: 30px;
  color: #1fa87f; }

.upda__Icon:before {
  font-size: 25px;
  color: #1fa87f;
  display: inline-block;
  vertical-align: middle;
  line-height: 0 !important; }

.decr_Icon:before {
  font-size: 30px;
  color: #dc4b5c; }

.folder_Icon:before {
  font-size: 30px;
  color: #1fa87f; }

.archive_Icon:before {
  font-size: 30px;
  color: #dc4b5c; }

.checkbox_conv_btn input {
  display: none; }

.checkbox_conv_btn span {
  padding: 6px 15px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 3px;
  border-color: #1e1e1e;
  border-color: var(--bg-color);
  color: #1e1e1e;
  font-size: 14px;
  cursor: pointer; }

.checkbox_conv_btn input:checked + span {
  background: var(--bg-color);
  color: #fff;
  cursor: pointer; }

.button_set0 {
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background: #fff;
  font-size: 14px;
  color: var(--txt-color);
  border: 1px solid var(--txt-color);
  border-radius: var(--b-radius);
  justify-content: center;
  cursor: pointer; }
  .button_set0.active {
    background: var(--bg-color);
    color: #fff; }
  .button_set0.button_set1 {
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid var(--txt-color);
    border-radius: var(--b-radius);
    background: var(--bg-color);
    color: #fff;
    justify-content: center;
    cursor: pointer; }
    .button_set0.button_set1.bg_danger_set_0 {
      background: #ff0000;
      border-color: #ff0000; }
    .button_set0.button_set1.bg_success_set_0 {
      background: #00bf45;
      border-color: #00bf45; }
  .button_set0:hover {
    box-shadow: 0px 5px 15px #cecece; }

.on_click_btn {
  text-align: center;
  background: var(--bg-color);
  color: #fff;
  padding: 7px 5px;
  border-radius: var(--b-radius);
  cursor: pointer; }

.cal_btn_view_icons_main .rbc-event {
  padding: 0px;
  cursor: inherit; }
  .cal_btn_view_icons_main .rbc-event .rbc-event-content {
    width: 100%;
    padding: 0px; }
    .cal_btn_view_icons_main .rbc-event .rbc-event-content span {
      font-size: 14px; }
    .cal_btn_view_icons_main .rbc-event .rbc-event-content .cal_btn_view_icons {
      display: grid;
      grid-template-columns: 1fr 22px;
      padding: 3px;
      background-color: var(--bg-color); }
      .cal_btn_view_icons_main .rbc-event .rbc-event-content .cal_btn_view_icons i {
        cursor: pointer; }

.time_l_1 {
  display: flex; }

.time_no {
  width: 100px;
  height: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--crm-Rejected_color);
  border-radius: var(--border-radius100);
  font-size: 35px;
  border: 10px solid;
  border-color: var(--bg-color);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-weight: 600;
  color: #fff; }

.time_no_div {
  width: 200px; }

.time_no_div {
  width: 200px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  position: relative; }

.line_h {
  background: var(--bg-color);
  height: 10px;
  width: 25%; }

.time_d_style {
  background: #fff;
  border-radius: var(--b-radius);
  border: 10px solid var(--bg-color);
  padding: 10px 15px; }

.time_l_1 {
  position: relative; }

.time_d_1:after {
  content: '';
  width: 10px;
  height: 100%;
  background: var(--bg-color);
  position: absolute;
  left: 95px;
  z-index: -1;
  top: 0; }

.time_line_parent .line_h {
  width: 100%; }

.time_line_parent .time_d_1:after {
  left: 0; }

.time_line_parent .time_l_1:last-child .time_d_1:after {
  height: 50%; }

.timeline_1 .time_l_1:last-child > .time_d_1:after {
  /* height:50%;  */
  height: 100px; }

.timeline_1 .time_l_1:first-child .time_d_1:after {
  top: 50px; }

.time_line_parent .time_l_1:first-child .time_d_1::before {
  content: '';
  width: 10px;
  height: 100%;
  background: var(--bg-color);
  position: absolute;
  left: 0;
  z-index: -1;
  top: -50%; }

.reminder_icon {
  border: 5px solid;
  border-color: var(--bg-color);
  border-radius: 11px;
  display: inline-block;
  width: 56px;
  height: 92px;
  margin-left: 10px;
  background: #fff; }

.reminder_icon i {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  font-size: 9px;
  color: var(--bg-color); }

.reminder_icon i:first-child {
  font-size: 33px;
  margin-top: 10px;
  margin-bottom: 18px; }

.call_refer_div i {
  width: 70px;
  height: 70px;
  background: var(--bg-color);
  display: inline-flex;
  justify-content: center;
  border-radius: var(--border-radius100);
  font-size: 47px;
  color: #fff;
  align-items: center; }

.call_refer_div {
  text-align: center;
  font-size: 15px;
  font-weight: 400; }

.call_refer_div a {
  font-size: 12px; }

.timeline_h {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 10px; }

.time_txt .task_table_footer a:first-child {
  border-color: #1e1e1e; }

.time_txt .task_table_footer a {
  border-left: 0;
  border-right: 1px solid;
  padding-left: 15px;
  padding-right: 15px; }

.time_txt .task_table_footer span {
  padding-left: 15px; }

.time_txt .task_table_footer {
  justify-content: center; }

.time_d_1 {
  padding-bottom: 15px;
  padding-top: 15px; }

.time_line_date {
  text-align: right;
  font-weight: 400;
  font-size: 14px; }

.reminder_icon a {
  display: inline-block;
  width: 100%; }

.time_txt .task_table_footer {
  font-size: 15px;
  font-weight: 400; }

.timeline1_h {
  font-size: 17px;
  font-weight: 400; }

.timeline1_sub {
  font-size: 14px;
  font-weight: 400; }

.time_d_2 .panel {
  background: 0 0;
  border: 0;
  box-shadow: none; }

.v4-2_ .timeline_h {
  margin: 0;
  color: #fff; }

.v4-2_ .timeline_h span {
  font-weight: 600; }

.task_table_v4-1__ {
  padding: 5px 15px; }

.t_t_v4-1 {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.t_t_v4-1a {
  display: inline-flex; }

.ci_btn, a.ci_btn {
  min-width: 92px;
  text-align: center;
  background: #000;
  color: #fff;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
  border-radius: var(--b-radius);
  margin-right: 15px;
  border: 0; }

a.ci_btn {
  cursor: pointer; }

.ci_date {
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  color: #1e1e1e; }

.t_t_v4-1b, .t_t_v4-1b a {
  display: flex;
  font-size: 14px;
  color: var(--txt-color); }

.t_t_v4-1b a {
  text-decoration: underline !important; }

.t_t_v4-1b span {
  margin: 0 5px; }

.Version_timeline_4 .time_line_parent .time_d_1:after {
  background: url(/assets/images/admin/dotted_intake.svg);
  background-size: 9px;
  background-repeat: round;
  left: 94px;
  top: -18px; }

.Version_timeline_4 .time_line_parent .time_d_style {
  border-color: #dec3f8; }

.Version_timeline_4.timeline_1 .time_line_parent .time_l_1:first-child .time_d_1:after {
  top: -18px; }

.Version_timeline_4 .time_line_parent .time_no {
  width: 70px;
  height: 70px;
  border: 7px solid;
  font-size: 26px;
  border-color: #dec3f8;
  background: #fff;
  color: var(--b-color);
  margin: 20px; }

.Version_timeline_4 .time_line_parent .line_h {
  background: #dec3f8;
  width: 50%;
  margin: 50px 0; }

.Version_timeline_4 .time_line_parent .time_l_1:first-child .time_d_1::before {
  display: none; }

.Version_timeline_4.timeline_1 > .time_l_1:last-child > .time_d_1:after {
  height: auto; }

.Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div {
  align-items: flex-start;
  width: 150px; }

.Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div .line_h {
  margin-top: 50px; }

.Version_timeline_4 .time_d_1 {
  width: 700px; }

.Version_timeline_4 .time_line_parent .time_no_div {
  align-items: flex-start; }

.Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div > .time_no {
  border-radius: var(--b-radius);
  transform: rotate(225deg);
  left: 70px;
  top: 26px;
  width: 60px;
  height: 60px;
  border: 10px solid;
  border-color: var(--b-color);
  background: #fff;
  color: var(--b-color);
  font-size: 27px; }

.Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div > .time_no span {
  transform: rotate(135deg); }

.Version_timeline_4 .time_d_1:after {
  top: 30px; }

.Version_timeline_4 .v4_panel_title_ .collapsed .timeline_h i:before {
  content: "\68"; }

.Version_timeline_4 .time_d_style.v4-1_ {
  padding: 0; }

.Version_timeline_4 .time_d_1:after, .Version_timeline_4 .time_d_style.v4-1_ .panel-heading, .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div .line_h {
  background: var(--b-color);
  border-radius: 0; }

.Version_timeline_4 .time_d_style {
  border-color: var(--b-color); }

.Version_timeline_4 .time_d_style {
  border: 8px solid var(--bg-color); }

.V4_int_cont1__ {
  /* min-height:90px */
  margin-bottom: 5px; }

.Version_timeline_4 .time_line_parent .task_table_footer {
  padding-top: 7px;
  justify-content: flex-end;
  min-height: 26px; }

.V4_int_cont1b__ {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  flex: 1; }

.V4_int_cont1a__ {
  flex: 1; }

.v4_btn_size1__ {
  display: flex;
  align-items: flex-end; }

.v4_btn_size1__ .btn-3 {
  font-size: 13px; }

.V4_int_cont1__ {
  display: flex;
  justify-content: space-between; }

.V4_int_cont1a__ .timeline_h {
  margin: 0;
  text-align: left;
  font-size: 15px; }

.datepicker_my .react-datepicker__day--selected,
.datepicker_my .react-datepicker__day--in-selecting-range,
.datepicker_my .react-datepicker__day--in-range {
  background: var(--bg-color); }

.datepicker_my .react-datepicker__navigation--previous {
  border-right-color: #fff; }

.datepicker_my .react-datepicker__navigation--next {
  border-left-color: #fff; }

.datepicker_my .react-datepicker__time-container {
  position: absolute;
  right: -70px; }

.datepicker_my .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 0 5px;
  font-size: 11px;
  display: flex;
  align-items: center; }

.datepicker_my .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
.datepicker_my .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--bg-color) !important; }

.datepicker_my .react-datepicker__time-container .react-datepicker__time {
  border: solid 1px #aeaeae;
  overflow: hidden; }

.datepicker_my .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: var(--bg-color);
  color: #fff; }

.datepicker_my .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.datepicker_my .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: var(--bg-color); }

.datepicker_my .react-datepicker__time-list {
  height: 200px !important; }

.datepicker_my .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 10px; }

.datepicker_my .react-datepicker__current-month,
.datepicker_my .react-datepicker-time__header {
  font-size: 12px; }

.Funnel_list {
  background: #777;
  display: inline-flex;
  flex-direction: column; }

.Funnel_tr {
  display: flex;
  flex-direction: row;
  width: 170px;
  justify-content: space-between;
  padding: 7px 15px;
  font-weight: 600;
  font-size: 16px; }

.Funnel_common {
  width: 320px;
  justify-content: flex-end; }

.Funnel_skew1 {
  width: 290px;
  height: 130px;
  position: relative;
  justify-content: flex-end; }
  .Funnel_skew1::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 120px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 130px solid #fff;
    position: absolute;
    right: 0; }

.Funnel_skew2 {
  width: 170px;
  height: 150px;
  position: relative; }
  .Funnel_skew2::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 120px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 100px solid #fff;
    position: absolute;
    right: 0; }
  .Funnel_skew2::before {
    content: '';
    width: 120px;
    height: 50px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff; }
  .Funnel_skew2 .Funnel_tr {
    padding-right: 40px; }

.funnel_line_ {
  position: relative; }

.Funnel_td_1 {
  color: red; }

.Funnel_td_2 {
  color: #fff; }

.Funnel_helper {
  display: inline-flex;
  flex-wrap: wrap; }

.Funnel_tl {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px; }
  .Funnel_tl i {
    width: 16px;
    height: 16px;
    display: inline-flex;
    background: #000;
    margin-right: 6px; }

.Funnel_bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  margin-top: -90px;
  position: relative;
  z-index: 9;
  font-size: 14px; }
  .Funnel_bottom .Funnel_helper:first-child {
    width: 250px;
    background: #d6bbf05c; }

.Funnel_lost i {
  background: red; }

.Funnel_Sucess i {
  background: #fff; }

.left_funnel {
  text-align: right;
  padding-left: 40px;
  width: 360px; }
  .left_funnel .Funnel_skew1::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 120px solid transparent;
    border-bottom: 130px solid #fff;
    position: absolute;
    left: 0; }
  .left_funnel .Funnel_skew2 {
    width: 170px;
    height: 150px;
    position: relative; }
  .left_funnel .Funnel_skew2::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 120px solid transparent;
    border-bottom: 100px solid #fff;
    position: absolute;
    left: 0; }
  .left_funnel .Funnel_skew2 .Funnel_tr {
    padding-right: 15px;
    padding-left: 40px; }
  .left_funnel .Funnel_skew1 {
    align-items: flex-end; }
  .left_funnel .Funnel_skew2::before {
    content: '';
    width: 120px;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff; }
  .left_funnel .funnel_line_ i {
    width: 190px;
    height: 2px;
    background: #000;
    position: absolute;
    right: 100%;
    top: 0; }
  .left_funnel .Funnel_skew1.funnel_line_ i {
    right: 100%;
    width: 70px; }
  .left_funnel .funnel_line_ i .Funnle_data {
    position: absolute;
    left: -208px;
    top: -8px;
    color: #000;
    width: 190px; }
  .left_funnel .Funnel_skew1.funnel_line_ i .Funnle_data,
  .left_funnel .funnel_line_ i .Funnle_data {
    display: block; }

.right_funnel {
  text-align: left;
  padding-right: 40px;
  width: 360px; }
  .right_funnel .Funnel_skew1::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 120px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 130px solid #fff;
    position: absolute;
    right: 0;
    left: inherit; }
  .right_funnel .Funnel_skew2 {
    width: 170px;
    height: 150px;
    position: relative; }
  .right_funnel .Funnel_skew2::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 120px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 100px solid #fff;
    position: absolute;
    right: 0;
    left: inherit; }
  .right_funnel .Funnel_skew2 .Funnel_tr {
    padding-left: 15px;
    padding-right: 40px; }
  .right_funnel .Funnel_skew1 {
    align-items: flex-start; }
  .right_funnel .Funnel_skew2::before {
    content: '';
    width: 120px;
    height: 50px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff;
    left: inherit; }
  .right_funnel .funnel_line_ i {
    width: 190px;
    height: 2px;
    background: #000;
    position: absolute;
    left: 100%;
    top: 0; }
  .right_funnel .Funnel_skew1.funnel_line_ i {
    left: 100%;
    width: 70px; }
  .right_funnel .funnel_line_ i .Funnle_data {
    position: absolute;
    right: -200px;
    top: -8px;
    color: #000;
    width: 190px; }
  .right_funnel .Funnel_skew1.funnel_line_ i .Funnle_data,
  .right_funnel .funnel_line_ i .Funnle_data {
    display: block; }

.Funnel_main_div {
  display: flex;
  background: #fff;
  justify-content: center;
  padding: 30px 15px; }

.Funnle_data {
  display: none; }

.Funnel_stage_1 i {
  background: #7e85d3; }

.Funnel_stage_2 i {
  background: #4ebf7a; }

.Funnel_stage_3 i {
  background: #6401c0; }

.Gender_chart__ {
  padding: 5px 15px 15px 15px;
  background: #f6f6f6; }

.Gender_chart__ {
  display: flex;
  flex-direction: column; }

.Gender_title__ {
  display: flex; }

.Gender_title__ {
  display: flex;
  min-height: 40px;
  align-items: center;
  border-bottom: 1px solid var(--b-color); }

.Gender_title__ .G_t0_:first-child {
  border-right: 1px solid var(--b-color);
  color: var(--bg-color); }

.Gender_title__ .G_t0_:nth-child(2n) {
  color: var(--crm-success_color); }

.Gender_title__ .G_t0_ {
  flex-basis: 50%;
  text-align: center;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600; }

.Gender_grhap_ {
  display: flex;
  flex-direction: row; }

.male_per__ {
  display: flex;
  height: 22px;
  flex-basis: 50%; }

.male_per__ {
  display: flex;
  height: 22px;
  flex-basis: 50%;
  justify-content: flex-end; }

.female_per__ {
  display: flex;
  height: 22px;
  flex-basis: 50%;
  justify-content: flex-start; }

.male_per__ > div {
  background: var(--bg-color);
  position: relative; }

.female_per__ > div {
  background: var(--crm-success_color);
  position: relative; }

.Gender_agge_ {
  display: flex;
  justify-content: center;
  font-size: 15px;
  margin: 4px 0;
  font-weight: 600;
  color: var(--b-color); }

.Gender_list01_:first-child {
  margin-top: 20px; }

.Gender_list01_ {
  margin-bottom: 20px; }

.male_per__ .custom_tooltip__ {
  position: absolute;
  width: auto;
  display: flex;
  background: #fff;
  min-height: 35px;
  flex-direction: column;
  font-size: 13px;
  padding: 5px;
  border: 1px solid var(--b-color);
  top: -51px;
  width: 115px;
  left: -115px; }

.custom_tooltip01__ {
  font-size: 12px; }

.custom_tooltip02__ > span:first-child {
  font-weight: 600; }

.custom_tooltip01__ > span:first-child {
  font-weight: 600; }

.custom_tooltip02__ {
  font-size: 11px;
  display: flex;
  align-items: center; }

.male_per__ .custom_tooltip__::before {
  content: '';
  border-left: 8px solid transparent;
  border-top: 10px solid #992bff;
  position: absolute;
  right: -1px;
  bottom: -10px;
  transform: rotate(-7deg);
  z-index: 0; }

.female_per__ .custom_tooltip__::before {
  content: '';
  border-left: 10px solid transparent;
  border-top: 8px solid var(--crm-success_color);
  position: absolute;
  left: -3px;
  bottom: -9px;
  transform: rotate(278deg);
  z-index: 0; }

.female_per__ .custom_tooltip__ {
  position: absolute;
  width: auto;
  display: flex;
  background: #fff;
  min-height: 35px;
  flex-direction: column;
  font-size: 13px;
  padding: 5px;
  border: 1px solid var(--crm-success_color);
  top: -51px;
  width: 115px;
  right: -115px;
  z-index: 1; }

.male_per__ .custom_tooltip__ {
  display: none; }

.female_per__ .custom_tooltip__ {
  display: none; }

.male_per__ > div:hover .custom_tooltip__ {
  display: block; }

.female_per__ > div:hover .custom_tooltip__ {
  display: block; }

.perAlignment div div span {
  margin-top: -10px; }

.perAlignment div {
  position: relative; }

.perAlignment .male_per__ div span {
  top: -7px;
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  right: 0; }

.perAlignment .female_per__ div span {
  bottom: -20px;
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  left: 0; }

.Gender_total_ .Gender_total01_ {
  font-size: 14px;
  margin-top: 7px;
  font-weight: 600; }

.Gender_total02_ .Gender_grhap_ > div > div span {
  font-size: 12px;
  margin: 5px; }

.Gender_total02_ .male_per__ div span {
  color: #fff; }

.Gender_total02_ .female_per__ div span {
  color: var(--crm-Processing_color); }

.custom_tooltip01__ span:first-child {
  color: var(--bg-color); }

.custom_tooltip02__ span:first-child {
  color: var(--bg-color); }

.Partt_d1_txt_2 u {
  word-break: break-all; }

.thread_P.active .male_per__ > div {
  background: #ff5266; }

.thread_P.active .Gender_agge_,
.thread_P.active .male_per__ .custom_tooltip01__ span:first-child,
.thread_P.active .male_per__ .custom_tooltip02__ span:first-child,
.thread_P.active .Gender_title__ .G_t0_:first-child,
.thread_P.active .Gender_total02_ .female_per__ div span {
  color: #ff5266; }

.thread_P.active .Gender_title__ .G_t0_:first-child {
  border-right: 1px solid #ff5266; }

.thread_P.active .male_per__ .custom_tooltip__::before {
  border-top-color: #ff5266; }

.thread_P.active .male_per__ .custom_tooltip__,
.thread_P.active .Gender_title__,
.thread_P.active .Gender_total_.bt-1 {
  border-color: #ff5266; }

.thread_P.active .female_per__ > div {
  background: #f78c98; }

.thread_P.active .Gender_title__ .G_t0_:nth-child(2n),
.thread_P.active .female_per__ .custom_tooltip01__ span:first-child,
.thread_P.active .female_per__ .custom_tooltip02__ span:first-child {
  color: #f78c98; }

.thread_P.active .female_per__ .custom_tooltip__ {
  border-color: #f78c98; }

.thread_P.active .female_per__ .custom_tooltip__::before {
  border-top-color: #f78c98; }

.Private_M.active .male_per__ > div {
  background: #4a7dd6; }

.Private_M.active .Gender_agge_,
.Private_M.active .male_per__ .custom_tooltip01__ span:first-child,
.Private_M.active .male_per__ .custom_tooltip02__ span:first-child,
.Private_M.active .Gender_title__ .G_t0_:first-child,
.Private_M.active .Gender_total02_ .female_per__ div span {
  color: #4a7dd6; }

.Private_M.active .Gender_title__ .G_t0_:first-child {
  border-right: 1px solid #4a7dd6; }

.Private_M.active .male_per__ .custom_tooltip__::before {
  border-top-color: #4a7dd6; }

.Private_M.active .male_per__ .custom_tooltip__,
.Private_M.active .Gender_title__,
.Private_M.active .Gender_total_.bt-1 {
  border-color: #4a7dd6; }

.Private_M.active .female_per__ > div {
  background: #6f9ef0; }

.Private_M.active .Gender_title__ .G_t0_:nth-child(2n),
.Private_M.active .female_per__ .custom_tooltip01__ span:first-child,
.Private_M.active .female_per__ .custom_tooltip02__ span:first-child {
  color: #6f9ef0; }

.Private_M.active .female_per__ .custom_tooltip__ {
  border-color: #6f9ef0; }

.Private_M.active .female_per__ .custom_tooltip__::before {
  border-top-color: #6f9ef0; }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem !important; }

.react-datepicker__year-dropdown {
  overflow-y: scroll !important;
  height: 150px !important; }

.react-datepicker__year-read-view {
  border: 1px solid #fff !important;
  color: #fff !important; }

.react-datepicker__year-read-view--down-arrow {
  top: 10px !important;
  margin-right: 5px; }

.react-datepicker__year-read-view--down-arrow {
  top: 4px !important; }

.react-datepicker__close-icon::after {
  background-color: #1e1e1e !important; }

.react-datepicker__time-box ul {
  height: 150px !important; }

.react-datepicker {
  font-size: 1.1rem !important;
  display: flex !important; }

.react-datepicker__current-month {
  font-size: 1.3rem !important; }

.react-datepicker__header {
  background-color: #1e1e1e; }

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: #fff !important; }

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #1e1e1e; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #1e1e1e; }

.react-datepicker-popper {
  z-index: 999999 !important; }

.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__header {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.datepicker_my .react-datepicker__day-name,
.datepicker_my .react-datepicker__day,
.datepicker_my .react-datepicker__time-name {
  font-size: 11px;
  width: 30px !important;
  height: 30px;
  line-height: 30px; }

.datepicker_my .react-datepicker__day--keyboard-selected {
  background: var(--bg-color);
  color: #fff; }

.datepicker_my .react-datepicker__day--selected,
.datepicker_my .react-datepicker__day--in-selecting-range,
.datepicker_my .react-datepicker__day--in-range {
  background: var(--bg-color); }

.datepicker_my .react-datepicker__navigation--previous {
  border-right-color: #fff; }

.datepicker_my .react-datepicker__navigation--next {
  border-left-color: #fff; }

.datepicker_my .react-datepicker__time-container {
  position: absolute;
  right: -70px; }

.datepicker_my .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 0 5px;
  font-size: 11px;
  display: flex;
  align-items: center; }

.datepicker_my .react-datepicker__time-container .react-datepicker__time {
  border: solid 1px #aeaeae;
  overflow: hidden; }

.datepicker_my .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: var(--bg-color);
  color: #fff; }

.datepicker_my .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.datepicker_my .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: var(--bg-color); }

.datepicker_my .react-datepicker__time-list {
  height: 200px; }

.datepicker_my .csform-group .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 10px; }

.datepicker_my .react-datepicker__current-month,
.datepicker_my .react-datepicker-time__header {
  font-size: 12px; }

.csform-group .react-datepicker__current-month,
.csform-group .react-datepicker-time__header,
.cust_date_picker .react-datepicker__current-month,
.cust_date_picker .react-datepicker-time__header {
  color: #fff; }

.csform-group .react-datepicker__day-name,
.cust_date_picker .react-datepicker__day-name {
  color: #fff; }

.csform-group .react-datepicker__day-name,
.csform-group .react-datepicker__day,
.csform-group .react-datepicker__time-name,
.cust_date_picker .react-datepicker__day-name,
.cust_date_picker .react-datepicker__day,
.cust_date_picker .react-datepicker__time-name {
  font-size: 11px;
  width: 30px !important;
  height: 30px;
  line-height: 30px; }

.csform-group .react-datepicker__day--selected,
.csform-group .react-datepicker__day--in-selecting-range,
.csform-group .react-datepicker__day--in-range,
.cust_date_picker .react-datepicker__day--selected,
.cust_date_picker .react-datepicker__day--in-selecting-range,
.cust_date_picker .react-datepicker__day--in-range {
  background: var(--bg-color); }

.csform-group .react-datepicker__navigation--previous,
.cust_date_picker .react-datepicker__navigation--previous {
  border-right-color: #fff; }

.csform-group .react-datepicker__navigation--next,
.cust_date_picker .react-datepicker__navigation--next {
  border-left-color: #fff; }

.csform-group .react-datepicker__time-container,
.cust_date_picker .react-datepicker__time-container {
  position: absolute;
  right: -70px; }

.csform-group .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item,
.cust_date_picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 0 5px;
  font-size: 11px;
  display: flex;
  align-items: center; }

.csform-group .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
.csform-group .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.cust_date_picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
.cust_date_picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--bg-color) !important; }

.csform-group .react-datepicker__time-container .react-datepicker__time,
.cust_date_picker .react-datepicker__time-container .react-datepicker__time {
  border: solid 1px #aeaeae;
  overflow: hidden; }

.csform-group .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover,
.cust_date_picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: var(--bg-color);
  color: #fff; }

.csform-group .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.csform-group .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.cust_date_picker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.cust_date_picker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: var(--bg-color); }

.csform-group .react-datepicker__time-list,
.cust_date_picker .react-datepicker__time-list {
  height: 200px !important; }

.csform-group .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button),
.cust_date_picker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 10px; }

.csform-group .react-datepicker__current-month,
.csform-group .react-datepicker-time__header,
.cust_date_picker .react-datepicker__current-month,
.cust_date_picker .react-datepicker-time__header {
  font-size: 12px; }

.csform-group.right_0_date_piker .react-datepicker-popper,
.cust_date_picker.right_0_date_piker .react-datepicker-popper {
  margin-left: -120px !important; }
  .csform-group.right_0_date_piker .react-datepicker-popper .react-datepicker__triangle,
  .cust_date_picker.right_0_date_piker .react-datepicker-popper .react-datepicker__triangle {
    left: 150px; }

.csform-group .react-datepicker__close-icon,
.cust_date_picker .react-datepicker__close-icon {
  margin-top: -15px;
  margin-right: -15px; }

.react-datepicker__input-container .csForm_control {
  padding-left: 7px;
  padding-right: 7px; }

/* Start Toast */
.Toastify .Toastify__toast-container {
  width: 430px;
  z-index: 99999999; }
  .Toastify .Toastify__toast-container .Toastify__close-button {
    color: #777;
    opacity: 1; }
  .Toastify .Toastify__toast-container .Toastify__toast {
    text-align: center;
    padding: 0px 8px; }
  .Toastify .Toastify__toast-container .Toastify__close-button {
    margin: 10px;
    border: 1px solid;
    border-radius: 100%;
    min-width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .Toastify .Toastify__toast-container .Toastify__toast--error {
    background: #f8d7da;
    color: #721c24;
    font-weight: 400;
    border-radius: 5px; }
    .Toastify .Toastify__toast-container .Toastify__toast--error .Toastify__close-button,
    .Toastify .Toastify__toast-container .Toastify__toast--error .Toastify_content__,
    .Toastify .Toastify__toast-container .Toastify__toast--error .Toastify_content__ i,
    .Toastify .Toastify__toast-container .Toastify__toast--error .Toastify_content__ p {
      color: #ad0000; }
    .Toastify .Toastify__toast-container .Toastify__toast--error .Toastify__close-button {
      border-color: #ad0000; }
  .Toastify .Toastify__toast-container .Toastify__toast--success {
    color: #155724;
    background: #38af74;
    background-color: #38af74;
    font-weight: 400;
    border-radius: 5px; }
    .Toastify .Toastify__toast-container .Toastify__toast--success .Toastify__close-button,
    .Toastify .Toastify__toast-container .Toastify__toast--success .Toastify_content__,
    .Toastify .Toastify__toast-container .Toastify__toast--success .Toastify_content__ i,
    .Toastify .Toastify__toast-container .Toastify__toast--success .Toastify_content__ p {
      color: #fff; }
    .Toastify .Toastify__toast-container .Toastify__toast--success .Toastify__close-button {
      border-color: #fff; }
  .Toastify .Toastify__toast-container .Toastify__toast--info .Toastify__close-button,
  .Toastify .Toastify__toast-container .Toastify__toast--info .Toastify_content__,
  .Toastify .Toastify__toast-container .Toastify__toast--info .Toastify_content__ i,
  .Toastify .Toastify__toast-container .Toastify__toast--info .Toastify_content__ p {
    color: #fff; }
  .Toastify .Toastify__toast-container .Toastify__toast--info .Toastify__close-button {
    border-color: #fff; }
  .Toastify .Toastify__toast-container button.Toastify__close-button {
    position: absolute;
    top: 0px;
    right: 0px; }

.Toastify_content__ {
  padding: 30px 0;
  margin-top: 5px; }
  .Toastify_content__ p {
    font-size: 15px; }
  .Toastify_content__ i {
    font-size: 45px; }

.weekend_bg-color__ .weekend-day {
  background: var(--crm-success_color); }

.weekend_bg-color__ .weekend-day.rbc-off-range-bg {
  background: #e5e5e5; }

.weekend_bg-color__ .weekend-day-header {
  background: var(--crm-success_color);
  color: var(--txt-color);
  margin-left: 1px; }

.weekend_bg-color__ .rbc-header div {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center; }

.weekend_bg-color__ .rbc-off-range .weekend-day-header {
  background: initial; }

.weekend_bg-color__ .rbc-off-range .weekend-day-header {
  color: #999; }

.weekend_bg-color__ .rbc-day-bg + .rbc-day-bg.weekend-day {
  border-left: 1px solid var(--b-color); }

.weekend_bg-color__ .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #7774; }

.weekend_bg-color__ .rbc-day-bg + .rbc-day-bg.weekend-day.rbc-off-range-bg {
  border-left: 1px solid #7774; }

.priority_task {
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 3px;
  background: #1e1e1e;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 1px;
  cursor: pointer; }

.low_priority {
  background: var(--crm-task_priority_high_color);
  color: #000000; }

.medium_priority {
  background: var(--crm-task_priority_medium_color); }

.high_priority {
  background: var(--crm-task_priority_low_color); }

.weekend-day-header {
  background: var(--crm-success_color);
  color: var(--txt-color);
  margin-left: 1px; }

.rbc-header div {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center; }

.weekday-header,
.weekend-day-header {
  display: flex;
  justify-content: space-between;
  padding: 0 5px; }

.rbc-off-range .weekend-day-header {
  background: initial; }

div.rbc-month-header {
  display: none; }

.rbc-off-range-bg::after {
  content: "";
  position: absolute;
  background: #e5e5e5;
  z-index: 9;
  height: calc(100% - 27px);
  bottom: 0;
  width: 14%; }

.rbc-now.rbc-current {
  background: var(--txt-color);
  color: #fff; }

.rbc-off-range .weekend-day-header {
  color: #999; }

div.rbc-today {
  background-color: transparent; }

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #7774; }

.rbc-day-bg + .rbc-day-bg.weekend-day.rbc-off-range-bg {
  border-left: 1px solid #7774; }

.due_priority {
  margin: 1px 0 0;
  color: #dec3f8; }

.completed_priority {
  margin: 0 0;
  color: var(--bg-color); }

.rbc-off-range-bg::after {
  content: "";
  position: absolute;
  background: #e5e5e5;
  z-index: 9;
  height: calc(100% - 27px);
  bottom: 0;
  width: 14%; }

.weekday-header,
.weekend-day-header {
  display: flex;
  justify-content: space-between;
  padding: 0 5px; }

div.rbc-month-header {
  display: none; }

.rbc-now.rbc-current {
  background: var(--txt-color);
  color: #fff; }

div.rbc-today {
  background-color: transparent; }

.due_priority {
  margin: 1px 0 0;
  color: var(--bg-color);
  cursor: pointer; }

.completed_priority {
  margin: 0 0;
  /* color:#dec3f8 */
  color: var(--crm-success_color);
  cursor: pointer; }

.Schedule_calendar {
  position: relative;
  background: #fff;
  border-radius: var(--b-radius); }
  .Schedule_calendar .rbc-toolbar-label {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 30px;
    margin: 15px 0;
    margin-top: -15px; }
  .Schedule_calendar .rbc-btn-group span.icon-arrow-right {
    position: absolute;
    right: 25px;
    font-size: 20px;
    cursor: pointer; }
  .Schedule_calendar .rbc-btn-group span.icon-arrow-left {
    position: absolute;
    left: 25px;
    font-size: 20px;
    cursor: pointer; }
  .Schedule_calendar .rbc-calendar {
    height: 570px;
    padding: 15px 45px 25px;
    width: 96%;
    margin: 0 auto; }
  .Schedule_calendar .rbc-date-cell {
    font-size: 14px; }
  .Schedule_calendar .rbc-header {
    padding: 0 0;
    font-size: 15px;
    min-height: 35px; }
  .Schedule_calendar .rbc-row:first-child {
    line-height: 25px;
    border-bottom: 1px solid #7774; }
  .Schedule_calendar .customModal {
    position: absolute;
    border-radius: var(--b-radius);
    z-index: 10; }

.rbc-row-content .rbc-row:first-child {
  line-height: 25px;
  border-bottom: 1px solid #7774; }

div.rbc-header {
  padding: 5px 3px;
  font-size: 15px; }

button.rbc-event {
  display: flex !important;
  height: auto !important;
  border-radius: 0 !important; }

.rbc-month-view {
  min-height: 450px; }

.rbc-toolbar-label {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 30px;
  margin: 15px 0px;
  margin-top: -15px;
  font-weight: 600; }

.calendar_table .days .day table {
  border-left: 1px solid #bbbbbb;
  border-bottom: 1px solid #bbbbbb; }

.calendar_table tr:first-child td.day {
  border-top: 1px solid #bbbbbb; }

.calendar_table .days .day table tr td {
  border-bottom: dashed 1px #bbbbbb; }

.calendar_table {
  width: 100%; }

.calendar_table .days .day table {
  width: 100%; }

.calendar_table .days .day table tr td {
  line-height: 20px;
  height: 20px; }

.calendar_table .days .day table tr th {
  line-height: 30px;
  height: 30px;
  padding: 0px 10px; }

.calendar_table .days .day table tr:last-child td {
  border-bottom: 0px; }

.calendar_table .days .day table tr th {
  font-size: 16px; }

.avalibilty_div {
  margin: 0px auto !important;
  padding: 0px;
  background: #e6e6e6;
  border-radius: var(--b-radius);
  padding-top: 30px;
  border-radius: var(--b-radius); }

.avalibilty_div .calendar_me tr td,
.calendar_repeat .calendar_me tr td {
  height: 13px;
  line-height: 0px; }

.avalibilty_div table.calendar_me tr th,
.calendar_repeat table.calendar_me tr th {
  height: 25px;
  width: 14%; }

.avalibilty_div .calendar_me tr td,
.calendar_repeat .calendar_me tr td {
  border-top: dashed 1px #cdcccc !important;
  border-right: 1px solid #cdcccc !important;
  border-bottom: dashed 1px #cdcccc !important; }

.avalibilty_div .calendar_me tr td:last-child,
.calendar_repeat .calendar_me tr td:last-child {
  border-right: 0px solid #cdcccc !important; }

.calendar_me tr td {
  border-right: 1px solid #cdcccc;
  border-left: 1px solid #cdcccc;
  border-top: 1px solid #cdcccc;
  border-bottom: 1px solid #cdcccc; }

.calendar_me tr td {
  background: #e6e6e6; }

.unavaliable {
  background: #e51a29 !important; }

.DarkOliveGreen {
  background: #8fd251 !important; }

.DarkGolden {
  background: #e19f00 !important; }

.calen_accor .panel.panel-default {
  background: #e6e6e6; }

.avaliable {
  background: #009244 !important;
  color: #fff; }

.calendar_me {
  width: 100%; }

.calendar_me tr td {
  height: 18px;
  padding: 4px 5px; }

.calendar_me tr td:first-child,
.calendar_me tr th:first-child {
  border-left: 0px solid transparent; }

.calendar_me tr td:last-child,
.calendar_me tr th:last-child {
  border-right: 0px solid transparent; }

.member_shift #example-component,
.member_shift .rbc-calendar {
  position: relative;
  height: 570px;
  padding: 15px 45px 25px;
  width: 96%;
  margin: 0px auto; }

.member_shift {
  margin-top: 15px;
  background: #fff;
  padding-bottom: 30px;
  border-radius: var(--b-radius); }

.member_shift .rbc-toolbar-label {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 30px;
  margin: 15px 0px;
  margin-top: -15px;
  font-weight: 600; }

.member_shift .rbc-btn-group span.icon.icon-arrow-left {
  position: absolute;
  left: 5px; }

.member_shift .rbc-btn-group span {
  cursor: pointer; }

.member_shift .rbc-btn-group span.icon.icon-arrow-right {
  position: absolute;
  right: 5px; }

.member_shift .rbc-btn-group span {
  cursor: pointer; }

.action_calendar .rbc-event {
  color: var(--bg-color);
  font-size: 14px;
  padding: 5px;
  height: auto !important;
  background: none; }

.action_calendar .rbc-event.rbc-selected {
  background-color: transparent; }

.Crm .rbc-event {
  background-color: inherit;
  color: #b968c7;
  padding: 5px;
  font-size: 12px; }

.Crm .rbc-event.rbc-selected {
  background-color: transparent; }

.recruitment_module .rbc-event {
  background-color: inherit; }

.recruitment_module .rbc-event.rbc-selected {
  background-color: transparent; }

.action_calendar {
  min-height: 300px;
  border-radius: var(--b-radius);
  margin-top: 15px;
  position: relative;
  overflow: hidden; }
  .action_calendar .rbc-calendar {
    height: 600px !important; }
  .action_calendar .rbc-btn-group span.icon-arrow-left:hover,
  .action_calendar .rbc-btn-group span.icon-arrow-right:hover {
    color: var(--bg-color); }
  .action_calendar .rbc-calendar {
    position: relative; }
  .action_calendar .rbc-btn-group span.icon-arrow-left,
  .action_calendar .rbc-btn-group span.icon-arrow-right {
    top: 30px;
    font-size: 30px; }
  .action_calendar .rbc-btn-group span.icon-arrow-left {
    left: 45px; }
  .action_calendar .rbc-btn-group span.icon-arrow-right {
    right: 45px; }
  .action_calendar .rcb-day-name,
  .action_calendar .rcb-day-number {
    font-size: 12px; }
  .action_calendar .rbc-month-view {
    margin-top: 15px; }

.member_shift .rcb-day-name,
.member_shift .rcb-day-number {
  font-size: 12px; }

tr.days > td:last-child {
  border-right: 1px solid #77777778; }

.Crm .set_hide_weekend_calander .rbc-event {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.Crm .set_hide_weekend_calander .rbc-calendar {
  height: 640px; }

.MSG_Tooltip .tooltip-inner {
  background: #000 !important;
  background-color: #000 !important; }

.MSG_Tooltip.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #000 !important; }

.tooltip-inner {
  background-color: #cc0000 !important; }

.tooltip.top .tooltip-arrow {
  border-top-color: #cc0000 !important; }

.tooltip.right .tooltip-arrow {
  border-right-color: #cc0000 !important; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #cc0000 !important; }

.login100-form .tooltip-inner {
  width: 150px; }

.select-parant-validation {
  position: relative; }

.select-parant-validation .tooltip {
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%);
  visibility: hidden; }

.custom_select + .tooltip.top {
  visibility: hidden;
  position: absolute;
  top: -31px;
  left: 50%;
  transform: translate(-50%);
  width: 135px; }

.custom_select + .tooltip.bottom {
  visibility: hidden;
  position: absolute;
  bottom: -31px;
  left: 50%;
  transform: translate(-50%);
  width: 135px; }

.multiple_checkbox_tooltip .tooltip {
  left: 50% !important;
  transform: translate(-50%) !important;
  top: -3px !important;
  visibility: hidden; }

.select-validation-error {
  visibility: visible !important; }

.custom_select .tooltip {
  margin-left: 10px; }

.v4_pro_d1__ {
  background: #fff;
  padding: 0 15px; }

.v4_pro_d1__ .progress {
  margin-bottom: 0; }

.progress-b1 {
  position: relative; }

.progress-b1 .progress-bar {
  background: var(--crm-Processing_color); }

.progress-b2.progress {
  height: 25px; }

.progress-b2.progress div {
  font-size: 15px;
  margin-top: 1px; }

.progress-b3 .progress-b2.progress {
  height: 17px;
  border-radius: var(--b-radius); }

.progress-b3 .progress {
  background: var(--crm-success_color); }

.progress-b3 .progress-bar {
  background: var(--crm-Processing_color); }

.progress-b3 .progress-b2.progress div {
  margin-top: 0;
  font-weight: 400; }

.recruitment_module .progress-b1 .progress {
  background: var(--light-color-background); }

.recruitment_module .progress-b1 .progress-bar {
  background: var(--bg-color); }

.Crm .progress-b1 .progress-b2 {
  background: #e0c0fe; }

.progress_b4 .progress-b2.progress div {
  font-size: 10px; }

.progress_b4 .progress-b2.progress {
  margin-bottom: 10px; }

.sed_set_0_ .Partt_d1_txt_3 {
  line-height: normal;
  margin-top: 7px; }

.progress-b5 .progress-b2.progress {
  height: 55px;
  margin-bottom: 0; }

.recruitment_module .customModal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  position: fixed;
  z-index: 99 !important;
  overflow-y: auto;
  display: none;
  transform: none; }

.customModal.show {
  display: block; }

.cstomDialog.widBig {
  width: 60%;
  min-width: 1000px; }

.cstomDialog {
  height: auto;
  padding: 15px 30px;
  border-radius: var(--b-radius);
  background: #f6f6f6;
  min-height: auto; }

.cstmModal_hdng1-- {
  width: 100%;
  border-top: solid 1px var(--bg-color);
  border-bottom: solid 1px var(--bg-color);
  color: var(--bg-color);
  padding: 10px 0;
  font-size: 20px;
  padding-right: 50px;
  font-weight: 700;
  margin-bottom: 15px; }

.cstmModal_hdng1-- .closeModal {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* border: solid 1px var(--bg-color); */
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
  cursor: pointer;
  margin-right: -50px; }

.customModal {
  z-index: 1;
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  overflow: auto; }

.custom-modal-dialog {
  border-radius: var(--b-radius);
  background: #f5f5f5;
  margin-bottom: 30px;
  padding: 15px 30px 15px 30px; }

.custom-modal-header {
  display: flex;
  border-color: #1e1e1e;
  justify-content: space-between; }

.custom-modal-header .Modal_title {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px;
  display: inline-flex;
  align-items: center;
  color: var(--bg-color); }

.custom-modal-header .Modal_close_i {
  font-size: 20px;
  align-items: center;
  display: inline-flex;
  padding-right: 0px;
  cursor: pointer;
  color: var(--txt-color); }

.custom-modal-footer {
  padding: 30px 0px;
  border-color: #1e1e1e; }

.customModal .Information_modal {
  width: 1024px; }

.Crm .modal-content {
  background: #f5f5f5; }

.modal-dialog .modal:before {
  display: inline-block;
  vertical-align: middle; }

.modal {
  text-align: center;
  padding: 0; }

.modal-dialog {
  text-align: left; }

.Modal_A .close,
.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear; }

.fade-scale.in {
  opacity: 1;
  transform: scale(1); }

.Modal_A .modal-dialog {
  width: 500px; }

.Modal_A .close {
  float: none;
  margin: 0 auto;
  opacity: 1;
  display: table; }

.Modal_A .lock_icon {
  display: table;
  margin: -50px auto 0; }

.Modal_A .modal-body {
  padding: 15px 30px; }

.Modal_A .text {
  font-size: 20px;
  line-height: 40px;
  min-height: 40px; }

.Modal_A .six_digit input {
  width: 240px;
  height: 40px;
  line-height: 40px;
  display: table;
  margin: 0 auto;
  text-align: center;
  padding: 4px 6px;
  outline: 0;
  font-size: 14px; }

.Modal_A .six_digit {
  margin: 25px auto 10px; }

.Modal_B .modal-dialog {
  width: 680px; }

.Modal_B .modal-body {
  display: table;
  width: 100%; }

.Modal_B .modal-body .dis_cell {
  display: table-cell; }

.Modal_B .modal-body .dis_cell:nth-child(2n) {
  width: 40px;
  padding-left: 15px; }

.Modal_B .modal-body {
  padding: 20px 25px; }

.Par_modal .modal-dialog,
.modal-content {
  background: #f7f7f7; }

.mess_V_1 .nav_apps {
  padding: 0; }

.lock_icon .icon-circule:before {
  background: #f7f7f7;
  border-radius: 50%;
  font-size: 55px;
  padding: 10px 15px 15px 15px;
  display: inline-block; }

.lock_icon .icon-mobile:before {
  background: #f7f7f7;
  border-radius: 50%;
  font-size: 50px;
  color: #1e1e1e;
  padding: 7px 7px 9px 7px;
  display: inline-block; }

.lock_icon .icon-lock-icons:before {
  background: #f7f7f7;
  border-radius: 50%;
  font-size: 60px;
  color: #1e1e1e;
  color: var(--txt-color); }

.modal {
  text-align: center;
  padding: 0 !important; }

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; }

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear; }

.fade-scale.in {
  opacity: 1;
  transform: scale(1); }

.Modal_A .modal-dialog {
  width: 500px; }

.Modal_A .close {
  float: none;
  text-align: center;
  margin: 0px auto;
  opacity: 1;
  display: table; }

.Modal_A .lock_icon {
  display: table;
  margin: 0px auto;
  margin-top: -50px; }

.Modal_A .modal-body {
  padding: 15px 30px; }

.Modal_A .text {
  font-size: 20px;
  line-height: 40px;
  min-height: 40px; }

.Modal_A .six_digit input {
  width: 240px;
  display: table;
  margin: 0px auto;
  text-align: center;
  padding: 4px 6px;
  outline: none;
  font-size: 14px; }

.Modal_A .six_digit {
  margin: 25px auto 10px; }

.Modal_B .modal-dialog {
  width: 680px; }

.Modal_big .modal-dialog {
  width: 80%; }

.Modal_B .modal-body {
  display: table;
  width: 100%; }

.Modal_B .modal-body .dis_cell {
  display: table-cell; }

.Modal_B .modal-body .dis_cell:nth-child(2n) {
  width: 40px;
  padding-left: 15px; }

.Modal_B .modal-body {
  padding: 20px 25px; }

.Par_modal .modal-dialog {
  width: 750px !important; }

.Modal_A.Modal_B.O_Modal_size .modal-dialog {
  width: 1024px; }

.Modal_A.Modal_B.M_Modal_size .modal-dialog {
  width: 750px; }

.Modal_A .modal-content,
.Modal_A .six_digit input {
  border-radius: var(--b-radius); }

.Modal_A .ch_Pin_M .lock_icon {
  margin-top: 0; }

.Modal_A .ch_Pin_M a.close_i {
  position: absolute;
  top: 10px;
  right: 10px; }

.Modal_A .text {
  color: #1e1e1e;
  border-top: 1px solid #1e1e1e;
  border-bottom: 1px solid #1e1e1e;
  font-weight: 400 !important;
  border-color: var(--b-color);
  color: var(--txt-color); }

.customModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: none;
  margin: 0 auto;
  padding: 30px 0;
  text-align: center;
  z-index: 999999; }

.customModal.show:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; }

.cstomDialog,
.custom-modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

.customModal::-webkit-scrollbar {
  height: 5px;
  width: 5px; }

.customModal::-webkit-scrollbar-track {
  background-color: #ccc;
  border-left: 1px solid #ccc;
  border-radius: 10px; }

.customModal::-webkit-scrollbar-thumb {
  background-color: #777;
  border-radius: 10px; }

.customModal::-webkit-scrollbar-thumb:hover {
  background-color: #777; }

.modal-dialog.modal_size_in {
  width: 900px; }

.Popup_h_er_1 {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Popup_h_er_1 .close_i i {
  display: flex; }

.Roww_h_1 {
  display: flex;
  align-items: center; }

.customModal .Information_modal.Attach_modal {
  width: 600px; }

.Attach_modal .custom-modal-header .Modal_title {
  font-size: 24px; }

.Attach_modal .custom-modal-footer {
  padding: 15px 30px; }

.schedules_modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 10;
  overflow: auto;
  display: none; }
  .schedules_modal.show {
    display: block; }

.sched_modal_dialog {
  background: #fff;
  border: solid 1px var(--bg-color);
  padding: 15px;
  width: 300px;
  min-height: 200px;
  border-radius: 10px;
  position: relative;
  margin: 50px auto; }
  .sched_modal_dialog::before, .sched_modal_dialog::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    display: none; }
  .sched_modal_dialog.left::before {
    border-width: 15px 15px 15px 0;
    border-color: transparent var(--bg-color) transparent transparent;
    left: -15px;
    top: 50px; }
  .sched_modal_dialog.left::after {
    border-width: 13px 13px 13px 0;
    border-color: transparent #fff transparent transparent;
    left: -13px;
    top: 52px; }
  .sched_modal_dialog .asgndTo {
    font-size: 15px;
    margin-top: 15px; }
  .sched_modal_dialog .attendees1 li {
    text-decoration: underline;
    width: 100%;
    padding: 3px 0; }
  .sched_modal_dialog .attendees1 li span {
    float: right;
    color: var(--bg-color); }

.width_700 .modal-dialog {
  width: 700px; }

.big_modal .modal-dialog {
  width: 1100px; }

.customModal .confirmation_module_size {
  width: 450px;
  padding: 30px; }

.Modal_A.Modal_B.add_new_site_MODAL .modal-dialog {
  width: 70%; }

.Modal_A.Modal_B.update_shift_location_MODAL .modal-dialog {
  width: 1024px; }

.Modal_A.Modal_B.update_house_document_MODAL .modal-dialog {
  width: 800px; }

.create_new_sub_ogr_MODAL .modal-lg.modal-dialog {
  width: 70%; }

.Modal_B.update_member_MODAL .modal-dialog {
  width: 1024px; }

.update_participant_details_MODAL .modal-dialog,
.update_participant_requirement_MODAL .modal-dialog {
  width: 70%; }
  .update_participant_details_MODAL .modal-dialog .Popup_h_er_1,
  .update_participant_requirement_MODAL .modal-dialog .Popup_h_er_1 {
    font-size: 17px;
    line-height: 40px;
    min-height: 40px;
    font-weight: 600;
    color: var(--bg-color); }

.update_org_MODAL .modal-dialog,
.new_contact_org_MODAL .modal-dialog {
  width: 775px; }

.customModal.add_new_details_MODAL .cstomDialog {
  width: 800px;
  min-width: 800px; }

.update_shift_requirement_MODAL .modal-dialog {
  width: 1000px; }

.Modal_A.Imail_filter_by_department_user_Modal .modal-dialog {
  width: 800px; }

@media (min-width: 1200px) {
  .create_new_sub_ogr_MODAL .modal-lg.modal-dialog {
    width: 90%; }
  .update_participant_details_MODAL .modal-dialog,
  .update_participant_requirement_MODAL .modal-dialog {
    width: 90%; }
  .Modal_A.Modal_B.add_new_site_MODAL .modal-dialog {
    width: 90%; } }

@media (min-width: 1550px) {
  .create_new_sub_ogr_MODAL .modal-lg.modal-dialog {
    width: 70%; }
  .update_participant_details_MODAL .modal-dialog,
  .update_participant_requirement_MODAL .modal-dialog {
    width: 70%; }
  .Modal_A.Modal_B.add_new_site_MODAL .modal-dialog {
    width: 70%; } }

.react-confirm-alert .custom-ui.push_to_app_Modal {
  background: #f6f6f6;
  width: 600px;
  padding: 15px 30px; }
  .react-confirm-alert .custom-ui.push_to_app_Modal .confi_header_div {
    border-color: #E07196; }
    .react-confirm-alert .custom-ui.push_to_app_Modal .confi_header_div h3 {
      font-size: 20px;
      color: #E07196; }
    .react-confirm-alert .custom-ui.push_to_app_Modal .confi_header_div span.icon.icon-cross-icons:before {
      font-size: 20px;
      color: #E07196; }
  .react-confirm-alert .custom-ui.push_to_app_Modal .confi_but_div {
    margin-top: 0px;
    justify-content: flex-end;
    border-top: 1px solid;
    border-color: #E07196;
    padding-top: 15px; }
    .react-confirm-alert .custom-ui.push_to_app_Modal .confi_but_div .Confirm_btn_Conf {
      background: #E07196;
      min-height: 40px;
      display: inline-flex;
      align-items: center;
      padding: 10px 15px;
      font-size: 14px;
      border: 1px solid;
      border: 1px solid var(--txt-color);
      border-radius: 4px;
      border-radius: var(--b-radius);
      color: #fff;
      justify-content: center;
      cursor: pointer;
      border-color: #E07196;
      margin-left: 10px; }
    .react-confirm-alert .custom-ui.push_to_app_Modal .confi_but_div .Cancel_btn_Conf {
      min-height: 40px;
      display: inline-flex;
      align-items: center;
      padding: 10px 15px;
      background: #fff;
      font-size: 14px;
      color: #1e1e1e;
      color: #E07196;
      border: 1px solid;
      border-color: #E07196;
      border-radius: 4px;
      border-radius: var(--b-radius);
      justify-content: center;
      cursor: pointer; }
  .react-confirm-alert .custom-ui.push_to_app_Modal p {
    margin: 30px 0px;
    font-size: 15px;
    line-height: normal; }

.commmen_create_feedback_Modal .modal-dialog,
.overlap_shift_popup_Modal .modal-dialog {
  width: 80%; }

.Create_view_phone_interview_Modal .cstomDialog.widMedium {
  width: 900px; }

.select_email_modal .cstomDialog.widMedium {
  width: 420px; }

.cus-footer-flex .slds-modal__footer {
  display: flex; }

.cus-footer-flex .slds-progress {
  max-width: 65% !important; }

.slds-cus-textarea {
  line-height: 1;
  min-height: 80px; }

.slds-cus-popover-heading .slds-text-heading_medium {
  color: #fff !important;
  font-size: 1.25em !important; }

.ndis-shift-cost {
  border: 1px solid #c9c9c9 !important; }

.Toastify__toast-body {
  padding-right: 30px !important; }

.search_bar {
  padding-left: 0;
  position: relative; }
  .search_bar .input_search input {
    font-weight: 300 !important;
    font-size: 20px; }
  .search_bar .srch-inp,
  .search_bar .srch-inp:focus {
    width: 100%;
    height: 40px;
    background-color: #fff;
    border: solid 1px var(--bg-color);
    border-radius: var(--b-radius);
    font-size: 18px;
    outline: none; }
  .search_bar button span {
    color: var(--txt-color); }
  .search_bar.left .srch-inp {
    padding-left: 50px; }
  .search_bar.right .srch-inp {
    padding-right: 50px;
    padding-left: 15px; }
  .search_bar i {
    position: absolute;
    margin-top: 10px;
    font-size: 20px;
    color: var(--bg-color);
    font-weight: 700;
    cursor: pointer; }
  .search_bar.left i {
    left: 20px; }
  .search_bar.right i {
    right: 20px; }
  .search_bar .input_search input {
    border-color: var(--b-color);
    border-radius: var(--b-radius); }
  .search_bar.navbar_search {
    margin: 0 auto;
    width: 500px;
    max-width: 100%; }
  .search_bar.navbar_search input,
  .search_bar.navbar_search input:focus {
    background: transparent;
    border: solid 1px #fff;
    color: #fff;
    height: 40px;
    font-size: 15px; }
  .search_bar.navbar_search input::-webkit-input-placeholder {
    color: #fff; }
  .search_bar.navbar_search input:focus::-webkit-input-placeholder {
    color: #fff; }
  .search_bar.navbar_search i {
    color: #fff;
    margin-top: 10px; }
  .search_bar .input_search input {
    background: var(--bg-color);
    background-color: var(--bg-color); }
  .search_bar .input_search input {
    background: #fff !important;
    background-color: #fff !important;
    font-family: sans-serif !important; }
  .search_bar .input_search input {
    border: 1px solid #1e1e1e; }
  .search_bar button {
    margin: 0px;
    padding: 0px !important;
    border-radius: 0px !important;
    background: transparent;
    box-shadow: 0px 0px 0 !important;
    border-width: 0;
    position: absolute;
    margin-left: 15px;
    z-index: 2;
    outline: none;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    display: inline-flex; }
    .search_bar button i {
      right: -10px !important;
      top: 50% !important;
      transform: translate(-50%, -50%);
      margin-top: 0px; }
  .search_bar .input_search .icon-search:before {
    font-size: 30px;
    line-height: 100%;
    display: flex; }
  .search_bar .input_search {
    width: 100%; }
  .search_bar .input_search input {
    height: 50px;
    padding: 0px 50px;
    outline: none;
    padding-left: 25px;
    line-height: normal;
    display: flex !important;
    align-items: center; }

.search_bar_small.input_search input {
  border-radius: var(--b-radius); }

.search_bar_small button {
  margin: 0px;
  padding: 0px !important;
  border-radius: 0px !important;
  background: transparent;
  box-shadow: 0px 0px 0 !important;
  border-width: 0;
  position: absolute;
  left: 10px;
  z-index: 2;
  top: 50%;
  transform: translate(0, -50%);
  outline: none; }

.search_bar_small.input_search {
  width: 100%;
  position: relative; }

.search_bar_small.input_search input {
  height: 40px;
  line-height: 40px;
  padding: 0px 50px;
  outline: none; }

.input_search.change_b {
  background-clip: initial;
  background-origin: initial; }

.input_search button {
  margin-top: 5px;
  margin: 0px;
  padding: 0px !important;
  border-radius: 0px !important;
  background: transparent;
  box-shadow: 0px 0px 0 !important;
  border-width: 0;
  position: absolute;
  right: 0px;
  z-index: 2;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center; }

.input_search input {
  height: 50px;
  line-height: 50px;
  padding: 10px 50px;
  outline: none; }

.input_search input {
  border-radius: var(--b-radius); }

.table_search_new button {
  top: 5px; }

.table_search_new {
  position: relative; }

.table_search_new button {
  top: 0;
  right: 0; }

.table_search_new button {
  display: flex;
  padding: 4px 10px 0px; }

.table_search_new input {
  padding-right: 35px; }

.table_search_new button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: 0;
  padding: 0px; }

.table_search_new button span {
  font-size: 21px; }

.table_search_new button span {
  color: var(--txt-color); }

.actionSrch_st .srch-inp,
.actionSrch_st .srch-inp:focus {
  font-size: 13px; }

.actionSrch_st:before {
  position: absolute;
  left: 10px;
  margin-top: 9px; }

.actionSrch_st .srch-inp {
  border: solid 1px #000; }

.actionSrch_st i {
  color: #000; }

input.radio_input {
  height: 10px; }
  input.radio_input[type="radio"]:not(old) {
    width: 27px;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0; }
  input.radio_input[type="radio"]:not(old) + label {
    display: inline-block;
    margin-left: -2em;
    line-height: 1.5em; }
  input.radio_input[type="radio"]:not(old) + label > span {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: 5px 3px 0.25em 0.25em;
    border: 1px solid #1e1e1e;
    border-radius: var(--border-radius100);
    background: #fff;
    vertical-align: bottom;
    cursor: pointer; }
  input.radio_input[type="radio"]:not(old):checked + label > span {
    background: #fff; }
  input.radio_input[type="radio"]:not(old):checked + label > span > span {
    display: block;
    width: 11px;
    height: 11px;
    margin: 0.125em;
    border: 0.0625em solid #fff;
    border-radius: 0.125em;
    border-radius: var(--border-radius100);
    background: #000;
    margin-top: 2px; }

.preferences_modal span.d-flex.s-w-d_div {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.preferences_modal .s-w-d_div h4 {
  width: 50%;
  margin: 0px;
  vertical-align: middle;
  align-items: center;
  display: flex;
  padding-left: 15px;
  border-right: 1px solid #ddd; }

.preferences_modal .s-w-d_div .cartoon_div {
  width: 50%;
  margin: 0px;
  vertical-align: middle;
  align-items: center;
  display: flex; }

.preferences_modal .success-cartoon,
.preferences_modal .worning-cartoon,
.preferences_modal .danger-cartoon {
  display: inline-flex !important;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 8px 10%; }

.preferences_modal .s-w-d_div:nth-child(odd) {
  background: #c1c1c166; }

.preferences_modal .success-cartoon input.radio_input[type="radio"]:not(old) + label > span {
  background: url("/assets/images/admin/success-cartoon-1.svg"); }

.preferences_modal .worning-cartoon input.radio_input[type="radio"]:not(old) + label > span {
  background: url("/assets/images/admin/worning-cartoon-1.svg"); }

.preferences_modal .danger-cartoon input.radio_input[type="radio"]:not(old) + label > span {
  background: url("/assets/images/admin/danger-cartoon-1.svg"); }

.preferences_modal .success-cartoon input.radio_input[type="radio"]:not(old) + label > span,
.preferences_modal .success-cartoon input.radio_input[type="radio"]:not(old):checked + label > span > span,
.preferences_modal .worning-cartoon input.radio_input[type="radio"]:not(old) + label > span,
.preferences_modal .worning-cartoon input.radio_input[type="radio"]:not(old):checked + label > span > span,
.preferences_modal .danger-cartoon input.radio_input[type="radio"]:not(old) + label > span,
.preferences_modal .danger-cartoon input.radio_input[type="radio"]:not(old):checked + label > span > span {
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 0;
  background-size: 39px;
  background-repeat: no-repeat;
  opacity: 1 !important;
  margin: 0px !important; }

.preferences_modal .worning-cartoon input.radio_input[type="radio"]:not(old):checked + label > span > span {
  background: url("/assets/images/admin/worning-cartoon.svg");
  background-size: 100%; }

.preferences_modal .success-cartoon input.radio_input[type="radio"]:not(old):checked + label > span > span {
  background: url("/assets/images/admin/success-cartoon.svg");
  background-size: 100%; }

.preferences_modal .danger-cartoon input.radio_input[type="radio"]:not(old):checked + label > span > span {
  background: url("/assets/images/admin/danger-cartoon.svg");
  background-size: 100%; }

.preferences_modal .scroll_active_modal {
  padding: 0px; }

span.circle_but + p {
  color: var(--txt-color);
  margin-top: 10px; }

span.circle_but {
  font-size: 50px;
  width: 100px;
  height: 100px;
  color: #fff;
  font-family: Helvetica;
  display: flow-root;
  text-align: center;
  border-radius: var(--border-radius100);
  line-height: 100px;
  margin: 0px auto;
  display: inline-block;
  font-weight: 600;
  background: #242543;
  background: linear-gradient(-60deg, #7072a1 45%, #242543 100%);
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center; }

.add_access.p-colr,
.circle_but.p-colr {
  background: -moz-linear-gradient(45deg, #6a20a9 0%, #753af0 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #6a20a9), color-stop(100%, #753af0));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, #6a20a9 0%, #753af0 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, #6a20a9 0%, #753af0 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, #6a20a9 0%, #753af0 100%);
  /* ie10+ */
  background: linear-gradient(45deg, #6a20a9 0%, #753af0 100%);
  /* w3c */ }

.add_access.m-colr,
.circle_but.m-colr {
  background: -moz-linear-gradient(45deg, #ed6000 0%, #ff812d 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #ed6000), color-stop(100%, #ff812d));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, #ed6000 0%, #ff812d 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, #ed6000 0%, #ff812d 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, #ed6000 0%, #ff812d 100%);
  /* ie10+ */
  background: linear-gradient(45deg, #ed6000 0%, #ff812d 100%);
  /* w3c */ }

.add_access.i-colr,
.circle_but.i-colr {
  background: #eda220;
  background: -moz-linear-gradient(-45deg, #eda220 0%, #eda220 34%, #eca120 61%, #d68c15 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #eda220), color-stop(34%, #eda220), color-stop(61%, #eca120), color-stop(100%, #d68c15));
  background: -webkit-linear-gradient(-45deg, #eda220 0%, #eda220 34%, #eca120 61%, #d68c15 100%);
  background: -o-linear-gradient(-45deg, #eda220 0%, #eda220 34%, #eca120 61%, #d68c15 100%);
  background: -ms-linear-gradient(-45deg, #eda220 0%, #eda220 34%, #eca120 61%, #d68c15 100%);
  background: linear-gradient(135deg, #eda220 0%, #eda220 34%, #eca120 61%, #d68c15 100%); }

.add_access.i-colr,
.circle_but.i-colr {
  background: -moz-linear-gradient(45deg, #0a9e9a 0%, #50d6cf 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #0a9e9a), color-stop(100%, #50d6cf));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, #0a9e9a 0%, #50d6cf 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, #0a9e9a 0%, #50d6cf 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, #0a9e9a 0%, #50d6cf 100%);
  /* ie10+ */
  background: linear-gradient(45deg, #0a9e9a 0%, #50d6cf 100%);
  /* w3c */ }

.add_access.o-colr,
.circle_but.o-colr {
  background: #09a075;
  background: -moz-linear-gradient(45deg, #09a075 0%, #09a177 39%, #04ae8a 65%, #01bc9d 100%);
  background: -webkit-linear-gradient(45deg, #09a075 0%, #09a177 39%, #04ae8a 65%, #01bc9d 100%);
  background: linear-gradient(45deg, #09a075 0%, #09a177 39%, #04ae8a 65%, #01bc9d 100%); }

.add_access.h-colr,
.add_access.a-colr,
.h-colr,
.circle_but.a-colr {
  background: #0037c0;
  background: -moz-linear-gradient(45deg, #0037c0 0%, #174dcb 37%, #207cca 74%, #207cca 74%, #386cd7 100%);
  background: -webkit-linear-gradient(45deg, #0037c0 0%, #174dcb 37%, #207cca 74%, #207cca 74%, #386cd7 100%);
  background: linear-gradient(45deg, #0037c0 0%, #174dcb 37%, #207cca 74%, #207cca 74%, #386cd7 100%); }

.add_access.s-colr,
.circle_but.s-colr {
  background: -moz-linear-gradient(45deg, #dc71b6 0%, #ff82cc 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #dc71b6), color-stop(100%, #ff82cc));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, #dc71b6 0%, #ff82cc 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, #dc71b6 0%, #ff82cc 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, #dc71b6 0%, #ff82cc 100%);
  /* ie10+ */
  background: linear-gradient(45deg, #dc71b6 0%, #ff82cc 100%);
  /* w3c */ }

.add_access.f-colr,
.circle_but.f-colr {
  background: #d12e2f;
  background: -moz-linear-gradient(45deg, #d12e2f 0%, #d42e2e 38%, #e23030 65%, #e32f30 65%, #fa3232 100%);
  background: -webkit-linear-gradient(45deg, #d12e2f 0%, #d42e2e 38%, #e23030 65%, #e32f30 65%, #fa3232 100%);
  background: linear-gradient(45deg, #d12e2f 0%, #d42e2e 38%, #e23030 65%, #e32f30 65%, #fa3232 100%); }

.add_access.c-colr,
.circle_but.c-colr {
  background: -moz-linear-gradient(45deg, #a002c1 0%, #b968c7 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #a002c1), color-stop(100%, #b968c7));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, #a002c1 0%, #b968c7 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, #a002c1 0%, #b968c7 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, #a002c1 0%, #b968c7 100%);
  /* ie10+ */
  background: linear-gradient(45deg, #a002c1 0%, #b968c7 100%);
  /* w3c */ }

.add_access.r-colr,
.circle_but.r-colr {
  background: #0075ea;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #0075ea 0%, #05adee 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #0075ea 0%, #05adee 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #0075ea 0%, #05adee 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.add_access.finance-colr,
.circle_but.finance-colr {
  background: #464765;
  background: -moz-linear-gradient(45deg, #303150 0%, #464765 38%, #464765 65%, #464765 65%, #464765 100%);
  background: -webkit-linear-gradient(45deg, #303150 0%, #464765 38%, #464765 65%, #464765 65%, #464765 100%);
  background: linear-gradient(45deg, #303150 0%, #464765 38%, #464765 65%, #464765 65%, #464765 100%); }

.add_access.marketing-colr,
.circle_but.marketing-colr {
  background: #464765;
  background: -moz-linear-gradient(45deg, #378abd 0%, #286489 38%, #286286 65%, #286286 65%, #337cab 100%);
  background: -webkit-linear-gradient(45deg, #378abd 0%, #286489 38%, #286286 65%, #286286 65%, #337cab 100%);
  background: linear-gradient(45deg, #378abd 0%, #286489 38%, #286286 65%, #286286 65%, #337cab 100%); }

.add_access.planner-colr,
.circle_but.planner-colr {
  background: #5a64cc;
  background: linear-gradient(45deg, #5a64cc 0%, #909aff 100%); }

.add_access.helpdesk-colr,
.circle_but.helpdesk-colr {
  background: #0a5a60;
  background: linear-gradient(45deg, #0a5a60 0%, #acecf0 100%); }

.add_access.tv-colr,
.circle_but.tv-colr {
  background: #8a3d56;
  background: linear-gradient(45deg, #8a3d56 0%, #f398b5 100%); }

.but_around {
  list-style: none;
  padding: 0px 0px;
  display: flex;
  flex-wrap: wrap; }

.but_around li {
  display: inline-block;
  vertical-align: top;
  width: 10%;
  margin-bottom: 30px; }

.but_around_threee {
  display: flex;
  justify-content: center; }

.but_around_threee li {
  display: inline-flex;
  width: auto;
  margin: 0px 15px; }

.but_around_second {
  list-style: none;
  padding: 0px 60px;
  box-sizing: border-box; }

.but_around_second li a {
  display: table;
  margin: 0px auto; }

.folder_tab span.add_access {
  opacity: 1; }

.folder_tab .but_around_second span.add_access {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  display: flex;
  font-family: sans-serif; }

.folder_tab .but_around_second li {
  padding-top: 40px; }

.folder_tab .nav-tabs > li a {
  font-size: 20px !important; }

.but_around_second li {
  display: inline-block;
  width: 16.6%;
  margin: 3rem 0 3rem; }

.but_around_second span.add_access {
  width: 100px;
  height: 100px;
  display: block;
  margin: 0px auto;
  vertical-align: middle;
  font-size: 75px;
  line-height: 100px;
  border-radius: var(--border-radius100);
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center; }

span.add_access {
  font-family: sans-serif;
  width: 70px;
  height: 70px;
  display: block;
  margin: 0px auto;
  vertical-align: middle;
  font-size: 50px;
  line-height: 70px;
  border-radius: var(--border-radius100);
  font-weight: 600;
  color: #fff;
  opacity: 0.2;
  background: #000; }

.access {
  opacity: 1 !important; }

.row-access a {
  display: table;
  margin: 0px auto; }

.row-access a:hover {
  opacity: 0.5; }

.p-colr + p {
  color: #6820a6; }

.o-colr + p {
  color: #09a075; }

.f-colr + p {
  color: #d12e2f; }

.i-colr + p {
  color: #eda120; }

.i-colr + p {
  color: #0A9E9A; }

.m-colr + p {
  color: #ee6000; }

.s-colr + p {
  color: #dc71b7; }

.c-colr + p {
  color: #a002c1; }

.r-colr + p {
  color: #0075ea; }

.finance-colr + p {
  color: #464765; }

.finance_planner-colr + p {
  color: #5a64cc; }

.h-colr + p, .a-colr + p {
  color: #0037c0; }

.helpdesk-colr + p {
  color: #2f7b81; }

.add_access + p {
  font-weight: 600;
  margin-top: 7px;
  font-size: 16px; }

.is-searchable .Select-input input {
  border-radius: 0px;
  padding-left: 7px !important; }

.Select-placeholder {
  top: 2px !important; }

.Select-placeholder,
.Select-value-label {
  font-size: 14px;
  font-weight: 300 !important;
  color: #fff !important; }

.Select-input {
  background: none !important;
  overflow: hidden; }

div.Select-control {
  height: 40px;
  border: 1px solid #1e1e1e;
  background: #1e1e1e; }

.Select-arrow {
  border-color: #fff transparent transparent !important;
  border-style: solid;
  border-width: 12px 9px 2.5px !important;
  display: inline-block;
  height: 9px;
  width: 0;
  position: absolute !important;
  top: 14px;
  right: 13px; }

.Select.is-open > .Select-control .Select-arrow {
  border-color: transparent transparent #fff !important;
  border-width: 0 9px 12px !important;
  position: absolute !important;
  top: 12px !important;
  right: 13px !important; }

.Select-value-label {
  color: #fff !important; }

.Select-menu-outer {
  border-radius: var(--border-radius15) !important;
  overflow: hidden !important;
  font-size: 14px !important;
  font-weight: 300 !important; }

.Select-arrow,
.Select.is-open > .Select-control .Select-arrow {
  border-width: 0px !important; }

.Select-arrow::before {
  content: "\66";
  font-family: "icons_me";
  border-width: 0px;
  top: -3px;
  position: absolute;
  margin-left: -15px;
  color: #fff;
  font-size: 14px; }

.Select.is-open > .Select-control .Select-arrow::before {
  content: "\68";
  font-family: "icons_me";
  border-width: 0px;
  top: -3px;
  position: absolute;
  margin-left: -15px;
  color: #fff;
  font-size: 14px; }

div.Select.Select--single .Select-control,
div.Select .Select-control,
div.Select.is-focused > .Select-control,
div.Select.is-open > .Select-control,
div.Select.is-focused:not(.is-opne) > .Select-control {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.Select-control .Select-placeholder,
.Select--single > .Select-control .Select-value {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 32px;
  line-height: 32px;
  position: absolute;
  text-align: center;
  width: 100%; }

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  max-width: calc(100% - 25px);
  width: calc(100% - 25px);
  padding-right: 25px !important; }

.Select--single > .Select-control .Select-value .Select-value-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-right: 7px;
  padding-left: 10px; }

.Select--multi .Select-multi-value-wrapper {
  width: 100%; }

.Select--single > .Select-control .Select-value .Select-value-label {
  align-content: center;
  /* display: flex;  */
  justify-content: center;
  align-items: center; }

.Select-input > input,
.Select-input {
  width: 100% !important; }

.Select--single .Select-control,
.Select.is-focused > .Select-control,
.Select.is-open > .Select-control,
.Select.is-focused:not(.is-opne) > .Select-control {
  border-radius: var(--b-radius); }

.modify_select .Select--multi .Select-control,
.modify_select .Select--single .Select-control,
.modify_select .Select.is-focused > .Select-control,
.modify_select .Select.is-open > .Select-control,
.modify_select .Select.is-focused:not(.is-opne) > .Select-control {
  background: #fff;
  background-color: #fff; }

.modify_select .default_validation .Select-control .Select-multi-value-wrapper .Select-input input {
  color: #1e1e1e; }

.modify_select .Select-control {
  background: #fff;
  background-color: #fff;
  font-family: sans-serif;
  border-color: var(--b-color);
  border-radius: var(--b-radius); }

.modify_select .Select .Select-control {
  border-radius: var(--b-radius); }

.modify_select .Select-control .Select-input {
  width: 100%; }

.modify_select .Select-control .Select-input > input {
  width: 100% !important; }

.modify_select .Select.is-searchable.Select--single .Select-control {
  background: #fff !important; }

.modify_select .Select-arrow::before {
  content: "\33" !important;
  font-family: "icons_me" !important;
  border-width: 0px;
  top: -7px;
  position: absolute;
  margin-left: -21px;
  color: #1e1e1e;
  font-size: 22px;
  color: var(--txt-color); }

.modify_select .Select.is-open > .Select-control .Select-arrow::before {
  content: "\33" !important;
  font-family: "icons_me" !important;
  border-width: 0px;
  top: -5px;
  position: absolute;
  margin-left: -21px;
  color: #1e1e1e;
  font-size: 22px;
  color: var(--txt-color); }

.modify_select .Select-placeholder {
  color: #cacaca !important;
  text-align: left;
  padding-left: 15px; }

.modify_select .Select-value-label {
  color: #1e1e1e !important; }

.modify_select .is-clearable .Select-clear-zone {
  width: 24px;
  text-align: left; }

.modify_select.left_text_select .Select-menu-outer div {
  text-align: center;
  justify-content: center; }

.modify_select.left_text_select .Select-control {
  display: grid;
  grid-template-columns: 40px 1fr; }
  .modify_select.left_text_select .Select-control .Select-arrow-zone {
    grid-row: 1;
    grid-column: 1;
    width: 40px;
    background: var(--bg-color); }
  .modify_select.left_text_select .Select-control .Select-multi-value-wrapper {
    grid-column: 2; }
  .modify_select.left_text_select .Select-control .Select-arrow {
    position: inherit !important; }
    .modify_select.left_text_select .Select-control .Select-arrow::before {
      content: "hrs" !important;
      font-family: sans-serif !important;
      position: inherit !important;
      margin: 0px !important;
      padding: 0px !important;
      top: -4px !important;
      left: 7px !important;
      font-size: 14px;
      color: #fff; }

.modify_select.left_text_select .Select.is-open > .Select-control .Select-arrow::before {
  content: "hrs" !important;
  font-family: sans-serif !important;
  position: inherit !important;
  margin: 0px !important;
  padding: 0px !important;
  top: -4px !important;
  left: 7px !important;
  font-size: 14px;
  color: #fff; }

.modify_select.left_text_select .Select-placeholder {
  padding-left: 77px; }

.modify_select.left_text_select.title_minit .Select-control .Select-arrow::before {
  content: "min" !important; }

.modify_select.left_text_select.title_minit .Select.is-open > .Select-control .Select-arrow::before {
  content: "min" !important; }

.text_left_modify_select .Select-value-label {
  text-align: left; }

.filter_select .Select-control {
  background: #ccc !important;
  border: 1px solid #ccc !important;
  color: #1e1e1e !important; }

.filter_select .Select-placeholder {
  color: #1e1e1e !important;
  font-size: 18px !important; }

.filter_select .Select-arrow::before,
.filter_select .Select.is-open > .Select-control .Select-arrow::before {
  color: #1e1e1e; }

.filter_select .Select-value-label {
  color: var(--txt-color) !important; }

.filter_select .Select-value-label {
  font-size: 18px !important; }

.box_wide .Select--single > .Select-control .Select-value .Select-value-label {
  padding-left: 0;
  font-weight: 600 !important; }

.box_wide .Select-placeholder,
.box_wide .Select-value-label {
  font-size: 11px !important; }

.box_wide .Select-input,
.box_wide .Select-control,
.box_wide .Select-value-label,
.box_wide .Select-value {
  height: 20px;
  line-height: 18px;
  font-size: 11px !important; }

.box_wide .Select-placeholder {
  height: 20px;
  line-height: 15px; }

.box_wide .Select-arrow {
  border-width: 7px 6px 2.5px !important;
  top: 8px !important;
  right: 7px !important; }

.box_wide .Select.is-open > .Select-control .Select-arrow {
  border-width: 0px 6px 7px !important;
  top: 8px !important;
  right: 7px !important; }

.box_wide .Select-control,
.box_wide .Select-menu-outer {
  border-radius: 4px !important; }

.box_wide .Select-arrow,
.box_wide .Select.is-open > .Select-control .Select-arrow {
  border-width: 0px !important; }

.box_wide .Select-arrow::before {
  content: "\66";
  font-family: "icons_me";
  border-width: 0px;
  top: -3px;
  position: absolute;
  margin-left: -15px;
  color: #fff;
  font-size: 14px; }

.box_wide .Select.is-open > .Select-control .Select-arrow::before {
  content: "\68";
  font-family: "icons_me";
  border-width: 0px;
  top: -3px;
  position: absolute;
  margin-left: -15px;
  color: #fff;
  font-size: 14px; }

.box_wide .Select-arrow::before,
.box_wide .Select.is-open > .Select-control .Select-arrow::before {
  top: -7px;
  margin-left: -12px;
  font-size: 10px; }

.header_filter .Select-control .Select-input,
.header_filter .Select-control .Select-input {
  padding-right: 30px; }

.header_filter .Select-control {
  height: 35px;
  border-radius: var(--b-radius); }

.header_filter .Select-menu-outer .Select-option {
  display: flex; }

.header_filter .Select-menu-outer .h_ser_div {
  display: inline-flex;
  width: 60px; }

.header_filter span.add_access {
  width: 35px;
  height: 35px;
  display: inline-flex;
  margin: 0px auto;
  vertical-align: middle;
  font-size: 25px;
  font-family: sans-serif;
  border-radius: var(--border-radius100);
  font-weight: 600;
  color: #fff;
  opacity: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-transform: capitalize; }

.header_filter .Select-control {
  height: 35px;
  line-height: 27px; }

.header_filter .Select-control .Select-placeholder {
  text-align: left; }

.select_multi_value__ .Select .Select-control,
.select_multi_value__ div.Select.is-open > .Select-control {
  background: #fff; }

.set_select_small div.Select-control {
  height: 30px !important;
  border: 0px solid; }

.set_select_small .Select-arrow::before,
.set_select_small .Select.is-open > .Select-control .Select-arrow::before {
  font-size: 10px !important; }

.set_select_small .Select--single > .Select-control .Select-value,
.set_select_small .Select-input,
.set_select_small .Select-placeholder {
  height: 30px !important;
  line-height: 30px !important; }

.set_select_small .Select-arrow::before {
  top: -6px;
  margin-left: -6px; }

.set_select_small .Select.is-open > .Select-control .Select-arrow::before {
  margin-left: -10px; }

.set_select_small.req_s1 div.Select-control {
  height: 20px !important; }

.set_select_small.req_s1 .Select--single > .Select-control .Select-value,
.set_select_small.req_s1 .Select-input,
.set_select_small.req_s1 .Select-placeholder {
  height: 20px !important;
  line-height: 20px !important; }

.set_select_small.req_s1 .Select-arrow::before {
  top: -8px;
  margin-left: -6px; }

.set_select_small.req_s1 .Select.is-open > .Select-control .Select-arrow::before {
  margin-left: -5px;
  top: -7px; }

.set_select_small.req_s1 .Select-arrow::before,
.set_select_small.req_s1 .Select.is-open > .Select-control .Select-arrow::before {
  font-size: 9px !important; }

.sLT_gray.week_shifts_show_select div.Select .Select-control,
.sLT_gray.week_shifts_show_select div.Select.is-focused > .Select-control,
.sLT_gray.week_shifts_show_select div.Select.is-open > .Select-control,
.sLT_gray.week_shifts_show_select div.Select.is-focused:not(.is-opne) > .Select-control {
  background: #fff;
  background-color: #fff; }

.sLT_gray.week_shifts_show_select .Select-menu-outer {
  max-height: 700px !important; }

.sLT_gray.week_shifts_show_select .Select-menu {
  max-height: 700px !important; }

.fixed_drop_down_issue_inputrenderer .Select-arrow-zone::after {
  content: '';
  position: absolute;
  top: 0px;
  left: -700%;
  width: 800%;
  height: 40px; }

.radio_F1 {
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative; }
  .radio_F1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    margin-top: 0; }
  .radio_F1 .checkround {
    width: 17px;
    background-color: #fff;
    border-color: var(--bg-color);
    border-style: solid;
    border-width: 2px;
    border-radius: var(--border-radius100);
    content: "";
    display: inline-flex;
    height: 17px;
    min-width: 17px; }
  .radio_F1 input:checked ~ .checkround {
    background-color: #fff; }
  .radio_F1 .checkround:after {
    content: "";
    display: none; }
  .radio_F1 input:checked ~ .checkround:after {
    display: flex;
    justify-content: center;
    align-items: center; }
  .radio_F1 .checkround:after {
    margin: 2px;
    width: 9px;
    height: 9px;
    border-radius: var(--border-radius100);
    background: var(--bg-color); }
  .radio_F1 .cust-btn {
    margin-bottom: 10px;
    background-color: var(--bg-color);
    border-width: 2px;
    border-color: var(--b-color);
    color: #fff; }
  .radio_F1 .cust-btn:hover {
    border-color: var(--b-color);
    background-color: #fff;
    color: var(--txt-color);
    border-radius: 20px;
    transform-style: 2s; }
  .radio_F1 {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    width: 100%; }
  .radio_F1.f1_set_auto {
    width: auto; }
    .radio_F1.f1_set_auto .checkround {
      margin-left: 10px; }
  .radio_F1.w-auto {
    width: auto; }
  .radio_F1 .txtcheck.text_2_0_1 {
    display: inline-flex;
    align-items: center;
    padding-left: 7px; }
  .radio_F1.black_F1 .checkround {
    border-color: #000; }
    .radio_F1.black_F1 .checkround:after {
      background: #000; }
  .radio_F1.c_black .checkround {
    border-color: #000; }
    .radio_F1.c_black .checkround:after {
      background: #000; }

.check_F1 .checkround {
  border-radius: 0px; }

.check_F1 .checkround::after {
  content: "\47";
  font-family: "icons_me" !important;
  border-radius: 0px;
  margin: 0px;
  width: 100%;
  height: 100% !important;
  color: #fff;
  font-size: 11px;
  line-height: 13px; }

.Time_Orient_span_ label.radio_F1 {
  display: inline-flex;
  align-items: center;
  width: auto;
  justify-content: center;
  height: auto;
  margin-bottom: 0; }

.Time_Orient_span_ div {
  display: flex;
  align-items: center;
  width: 50%; }

.Time_Orient_span_ .radio_F1 .checkround {
  width: 16px;
  height: 16px;
  min-width: 16px; }

.Time_Orient_span_ .radio_F1 .checkround:after {
  width: 10px;
  height: 8px; }

.Time_Orient_span_ .radio_F1 + span {
  font-size: 10px;
  padding-left: 3px; }

input.input_c[type="checkbox"] {
  display: none; }
  input.input_c[type="checkbox"] + label {
    background: var(--bg-color);
    background-color: var(--bg-color); }
  input.input_c[type="checkbox"]:checked + label {
    background-image: url(/assets/images/admin/check_trans.svg); }
  input.input_c[type="checkbox"] + label {
    background: #1e1e1e;
    height: 18.665px;
    width: 18.665px;
    display: inline-block;
    padding: 0;
    border-radius: 4px;
    vertical-align: middle; }
  input.input_c[type="checkbox"]:checked + label {
    background-image: url(/assets/images/admin/check_trans.svg);
    height: 18.665px;
    width: 18.665px;
    display: inline-block;
    padding: 0; }

.b_check input.checkbox1[type="checkbox"] + label span {
  border: 1px solid #1e1e1e; }

input.checkbox1[type="checkbox"] {
  display: none; }
  input.checkbox1[type="checkbox"] + label span {
    background: #fff left top no-repeat;
    border: 1px solid #1e1e1e; }
  input.checkbox1[type="checkbox"]:checked + label span {
    background: #1e1e1e;
    background-size: 16px !important;
    position: relative; }
  input.checkbox1[type="checkbox"]:checked + label span:after {
    position: absolute;
    content: "\47";
    font-family: icons_me;
    color: #fff;
    font-size: 14px;
    left: 1px; }
  input.checkbox1[type="checkbox"] + label span {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: -2px 7px 0 10px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px; }
  input.checkbox1[type="checkbox"] + label {
    font-size: 11px; }
  input.checkbox1[type="checkbox"]:checked + label span {
    background: var(--bg-color);
    background-color: var(--bg-color); }
  input.checkbox1[type="checkbox"] + label span {
    border-color: var(--b-color); }
  input.checkbox1[type="checkbox"]:checked + label span {
    color: var(--txt-color); }

input.checkbox1.font[type="checkbox"] + label {
  font-size: 13px; }

input.checkbox2[type="checkbox"] {
  display: none; }
  input.checkbox2[type="checkbox"]:checked + label span {
    background: #1e1e1e;
    background-size: 16px !important;
    position: relative; }
  input.checkbox2[type="checkbox"] + label span {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: -2px 7px 0 10px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
    float: right;
    vertical-align: middle; }
  input.checkbox2[type="checkbox"] + label span {
    background: #fff left top no-repeat;
    border: 1px solid #1e1e1e; }
  input.checkbox2[type="checkbox"]:checked + label span:after {
    position: absolute;
    content: "\47";
    font-family: icons_me;
    color: #fff;
    font-size: 14px;
    left: 1px; }
  input.checkbox2[type="checkbox"]:checked + label span {
    color: var(--txt-color); }
  input.checkbox2[type="checkbox"] + label span {
    background: var(--bg-color);
    background-color: var(--bg-color); }
  input.checkbox2[type="checkbox"] + label span {
    border-color: var(--b-color); }
  input.checkbox2[type="checkbox"]:checked + label span {
    background: var(--bg-color);
    background-color: var(--bg-color); }

.b_check input.checkbox1[type="checkbox"] + label span {
  background: 0 0; }

.include_box input.checkbox1[type="checkbox"] + label span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: -2px 7px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  background: #1e1e1e; }

.include_box input.checkbox1[type="checkbox"]:checked + label span {
  background-image: url(/assets/images/admin/check_trans.svg);
  background-size: 25px;
  background-repeat: no-repeat; }

.include_box small {
  display: inline-block;
  width: 60%;
  line-height: 11px;
  margin-top: 0;
  vertical-align: middle;
  font-size: 11px; }

.include_box.T_check small {
  width: auto; }

.include_box.Num_C small {
  width: auto; }

span.include_box.Num_C {
  padding-right: 30px; }
  span.include_box.Num_C:last-child {
    padding-right: 0; }

span img.pending_mess {
  margin-left: 10px;
  margin-right: 15px;
  width: 20px; }

.include_box input.checkbox1[type="checkbox"]:checked + label span {
  position: relative; }

.include_box input.checkbox1[type="checkbox"] + label span {
  border-color: var(--b-color); }

.include_box input.checkbox1[type="checkbox"] + label span {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.include_box input.checkbox1[type="checkbox"] + label span {
  color: var(--txt-color); }

.include_box input.checkbox1[type="checkbox"].Num_C small {
  font-size: 18px; }

input.checkbox_m_play[type="checkbox"] {
  display: none; }
  input.checkbox_m_play[type="checkbox"] + label span {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: -2px 7px 0 10px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px; }
  input.checkbox_m_play[type="checkbox"] + label span {
    background: #fff left top no-repeat;
    border: 1px solid #1e1e1e; }
  input.checkbox_m_play[type="checkbox"]:checked + label span {
    background: #1e1e1e;
    background-size: 16px !important;
    position: relative; }
  input.checkbox_m_play[type="checkbox"]:checked + label span:after {
    position: absolute;
    content: "\70";
    font-family: icons_me;
    color: #fff;
    font-size: 16px;
    left: 0;
    top: 0; }

input.checkbox_flex[type="checkbox"] {
  display: none; }
  input.checkbox_flex[type="checkbox"] + label {
    font-size: 11px; }
  input.checkbox_flex[type="checkbox"] + label span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: -2px 7px 0 10px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 2px;
    background: #1e1e1e; }
  input.checkbox_flex[type="checkbox"]:checked + label span {
    background: #1e1e1e;
    position: relative; }
  input.checkbox_flex[type="checkbox"] + label small {
    font-size: 14px; }
  input.checkbox_flex[type="checkbox"] + label span {
    background: transparent;
    background-color: transparent;
    border: 2px solid var(--bg-color); }
  input.checkbox_flex[type="checkbox"]:checked + label span {
    background: var(--bg-color);
    background-color: var(--bg-color);
    border: 2px solid var(--bg-color); }
  input.checkbox_flex[type="checkbox"]:checked + label span:after {
    position: absolute;
    content: "\70";
    font-family: icons_me;
    color: #fff;
    left: 0;
    font-size: 12px;
    top: 0px; }

.present_date td {
  background: #8ee2ac;
  border-left: 1px solid #6ba781;
  border-top: dashed 1px #42604d;
  border-bottom: dashed 1px #42604d; }

.calendar-wrap {
  display: table;
  width: 100%;
  padding: 30px 0;
  background: #fff;
  border-radius: var(--b-radius); }

input.checkbox_big[type="checkbox"] {
  display: none; }
  input.checkbox_big[type="checkbox"]:checked + label span {
    background: #1e1e1e;
    position: relative; }
  input.checkbox_big[type="checkbox"] + label span {
    border: 1px solid #1e1e1e;
    background: #1e1e1e;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 2px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px; }
  input.checkbox_big[type="checkbox"]:checked + label span:after {
    position: absolute;
    content: "\70";
    font-family: icons_me;
    color: #fff;
    font-size: 21px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  input.checkbox_big[type="checkbox"] + label span {
    background: var(--bg-color);
    background-color: var(--bg-color); }
  input.checkbox_big[type="checkbox"]:checked + label span {
    background: var(--bg-color);
    background-color: var(--bg-color); }
  input.checkbox_big[type="checkbox"] + label span {
    color: var(--txt-color); }
  input.checkbox_big[type="checkbox"] + label span {
    border-color: var(--b-color); }

label.checkbox_icons input {
  display: none; }

label.checkbox_icons span {
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  border-radius: 4px;
  background: #1e1e1e;
  vertical-align: bottom; }

.checkbox_icons input[type="checkbox"]:checked + span:before {
  content: "\70";
  font-family: icons_me !important;
  position: absolute;
  top: 1px;
  left: 2px;
  color: #fff; }

.c-custom-checkbox {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0; }
  .c-custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    clip: rect(0 0 0 0);
    height: 20px;
    width: 20px;
    padding: 0;
    border: 0;
    left: 0;
    margin-top: 0; }
  .c-custom-checkbox .c-custom-checkbox__img {
    display: inline;
    position: relative;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: none;
    background-color: #fff;
    color: var(--txt-color);
    border: 2px solid;
    border-color: var(--b-color);
    border-radius: 0px;
    cursor: pointer;
    top: 0; }
  .c-custom-checkbox input[disabled]:checked + .c-custom-checkbox__img,
  .c-custom-checkbox input[type="checkbox"]:checked + .c-custom-checkbox__img {
    background-position: 0 0;
    background-color: #eee; }
  .c-custom-checkbox input[disabled]:checked + .c-custom-checkbox__img::before,
  .c-custom-checkbox input[type="checkbox"]:checked + .c-custom-checkbox__img::before {
    font-family: icons_me;
    content: "\47";
    display: flex;
    font-size: 12px;
    padding: 0 1px 0;
    width: 20px;
    height: 20px;
    position: absolute;
    line-height: 0;
    align-items: center; }
  .c-custom-checkbox input[disabled] + .c-custom-checkbox__img {
    opacity: 0.5;
    cursor: initial; }
  .c-custom-checkbox span {
    display: inline-flex;
    height: 20px;
    align-items: center;
    padding-left: 4px;
    font-weight: 500; }

.CH_010 .c-custom-checkbox__img {
  min-width: 16px;
  width: 17px;
  height: 16px;
  overflow: hidden; }

.CH_010 div {
  font-size: 13px;
  margin-left: 5px;
  display: inline-flex;
  align-items: center; }

.CH_010 input[disabled]:checked + .c-custom-checkbox__img::before,
.CH_010 input[type="checkbox"]:checked + .c-custom-checkbox__img::before {
  font-size: 10px;
  padding: 0 1px 0;
  width: 15px;
  height: 15px;
  left: -1px;
  background: #1e1e1e;
  background: var(--bg-color);
  color: #fff;
  top: -2px; }

.CH_010 .c-custom-checkbox__img {
  border-color: var(--bg-color); }

.CH_010 input[type="checkbox"]:checked + .c-custom-checkbox__img {
  background: var(--bg-color); }

.re_ch_al_1 input[disabled]:checked + .c-custom-checkbox__img,
.re_ch_al_1 input[type="checkbox"]:checked + .c-custom-checkbox__img {
  background: var(--bg-color);
  color: #fff; }

.re_ch_al_1,
.re_ch_al_2 {
  margin-top: 3px; }

.add_new_s0 span {
  width: 15px !important;
  height: 15px !important; }

.add_new_s0 input[type="checkbox"]:checked + span:before {
  top: 1px;
  left: 1px;
  font-size: 13px; }

.pro_check_box {
  margin: 0px;
  display: inline-flex;
  align-items: center; }
  .pro_check_box input {
    display: none; }
  .pro_check_box .checkround {
    width: 15px;
    height: 15px;
    border: 1px solid var(--bg-color);
    display: inline-flex;
    border-radius: 3px; }
  .pro_check_box input[type="checkbox"]:checked + .checkround {
    background: var(--bg-color); }
  .pro_check_box input[type="checkbox"]:checked + .checkround::after {
    content: "\47";
    font-family: "icons_me" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #fff; }
  .pro_check_box .font {
    font-size: 12px;
    margin-left: 6px; }

.List_2_ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .List_2_ul .radio_F1 .checkround {
    width: 17px;
    height: 17px;
    min-width: 17px; }
  .List_2_ul .radio_F1 .checkround:after {
    height: 9px; }
  .List_2_ul li {
    display: inline-flex;
    padding: 5px 0px; }
    .List_2_ul li span.text_2_0_1 {
      font-size: 13px;
      padding-left: 7px; }

.Time_line_lables .radio_F1 .checkround {
  width: 16px;
  height: 16px;
  min-width: 16px; }

.Time_line_lables .radio_F1 .checkround:after {
  height: 8px; }

.Time_line_lables span {
  font-size: 10px;
  display: inline-flex;
  align-items: center; }

.Time_line_lables span.txtcheck {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 15px; }

.Time_line_lables .radio_F1 + span {
  margin-left: 5px; }

.cursor_not_allowed input {
  width: 100%;
  opacity: 0 !important; }

.radio_direction_r {
  flex-direction: row-reverse; }
  .radio_direction_r .text_2_0_1 {
    padding-left: 0 !important; }

/***** Color Name and Class define ****/
/*     1.Blue         (class Name : Blue) 
                      (Color code: #0033cc)
                       */
/*     2.Participant_Module(class Name : Participant_Module) 
                      (Color code: #663399)
                       */
/*     3.Orange       (class Name : Orange) 
                      (Color code: #ff6600)
                       */
/*     4.Schedule_Module(class Name : Schedule_Module) 
                      (Color code: #cc66cc)
                       */
/*     5.Green       (class Name : Green) 
                      (Color code: #009966)
                       */
/*     6.Red_fms         (class Name : Red_fms) 
                      (Color code: #D32F2F)
                       */
/*     6.Gold         (class Name : Gold) 
                      (Color code: #eda122; bg: #e89c24)
                       */
:root {
  --bg-color: #1e1e1e;
  --txt-color: #1e1e1e;
  --b-color: #1e1e1e; }

.Blue {
  --bg-color: #242543;
  --txt-color: #242543;
  --b-color: #242543; }

.Participant_Module {
  --bg-color: #b175ff;
  --txt-color: #b175ff;
  --b-color: #b175ff;
  --light-color-background: #b175ff54;
  --drk-color1: #672ea7;
  --drk-color2: #6f4dee; }

.Orange,
.Member_Module {
  --bg-color: #ff7043;
  --txt-color: #ff7043;
  --b-color: #ff7043;
  --light-color-background: #FF704354;
  --drk-color1: #ee5802;
  --drk-color2: #ff7043; }

.Schedule_Module {
  --bg-color: #e07196;
  --txt-color: #e07196;
  --b-color: #e07196;
  --light-color-background:#e0719654;
  --drk-color1: #d04170;
  --drk-color2: #e07196; }

.Green,
.Org_Module {
  --bg-color: #1eb35a;
  --txt-color: #1eb35a;
  --b-color: #1eb35a;
  --light-color-background:#1eb35a54; }

.Red_fms {
  --bg-color: #d32f2f;
  --txt-color: #d32f2f;
  --b-color: #d32f2f;
  --light-color-background:#d32f2f54; }

.Gold {
  --bg-color: #e89c24;
  --txt-color: #eda122;
  --b-color: #e89c24;
  --light-color-background:#e89c2454; }

.Gold {
  --bg-color: #2e9cca;
  --txt-color: #2e9cca;
  --b-color: #2e9cca;
  --light-color-background:#2e9cca54; }

.Crm {
  --bg-color: #992bff;
  --b-color: #992bff;
  --txt-color: #992bff;
  --crm-success_color: #be77ff;
  --crm-Processing_color: #7c00ef;
  --crm-Rejected_color: #5300a0;
  --crm-task_priority_high_color: #ffff00;
  --crm-task_priority_medium_color: #ff8c00;
  --crm-task_priority_low_color: #ff0000;
  --light-color-background: #b175ff54;
  --drk-color4: #7c00ef; }

.Finance_M {
  --bg-color: #464765;
  --b-color: #464765;
  --txt-color: #464765;
  --crm-success_color: #be77ff;
  --crm-Processing_color: #7c00ef;
  --crm-Rejected_color: #5300a0;
  --crm-task_priority_high_color: #ffff00;
  --crm-task_priority_medium_color: #ff8c00;
  --crm-task_priority_low_color: #ff0000;
  --drk-color4: #464765;
  --drk-color2: #5f6081;
  --drk-color3: #c1c1c9;
  --light-color-background: #46476554; }

.Helpdesk_M {
  --bg-color: #005157;
  --b-color: #005157;
  --txt-color: #005157; }

:root {
  --drk-color1: #63bae9;
  --drk-color2: #39a4d1;
  --drk-color3: #2082ac;
  --drk-color4: #136283;
  --drk-color5: #2082ac;
  --drk-color6: #39a4d1;
  --crm-task_priority_high_color: #fed77e;
  --crm-task_priority_medium_color: #05adee;
  --crm-task_priority_low_color: #ff0000; }

.recruitment_module {
  --bg-color: #05adee;
  --txt-color: #05adee;
  --b-color: #05adee;
  --light_color1: #7ddbff;
  --light-color2: #72d0f5;
  --light-color3: #36c7ff;
  --b-radius: 4px;
  --light-color-background: #05adee54; }

/*==================================================================*/
/*============|                    Blue                 |===========*/
/*==================================================================*/
/* Blue */
.clr1 {
  color: #777; }

.clr2 {
  color: #ccc; }

.clr1 {
  color: #0342a2; }

.Select-control input:-webkit-autofill,
.Select-control input:-webkit-autofill:hover,
.Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #242543 inset; }

/* Purple */
.Participant_Module .clr1 {
  color: #672ea7; }

.Participant_Module .Select-control input:-webkit-autofill,
.Participant_Module .Select-control input:-webkit-autofill:hover,
.Participant_Module .Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #672ea7 inset !important; }

.Participant_Module .clr2 {
  color: var(--b-color); }

/* Orange  */
.Orange .clr1 {
  color: #ee5802; }

.Orange .Select-control input:-webkit-autofill,
.Orange .Select-control input:-webkit-autofill:hover,
.Orange .Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ff7043 inset !important; }

/* Gulabi */
.Schedule_Module .clr1 {
  color: #d04170; }

.Schedule_Module .Select-control input:-webkit-autofill,
.Schedule_Module .Select-control input:-webkit-autofill:hover,
.Schedule_Module .Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px inset !important; }

.Schedule_Module .clr2 {
  color: var(--b-color); }

/* Green */
.Green .clr1 {
  color: #0e966e; }

.Green .Select-control input:-webkit-autofill,
.Green .Select-control input:-webkit-autofill:hover,
.Green .Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #09a075 inset !important; }

/* Red */
.Red_fms .clr1 {
  color: #b10607; }

.Red_fms .Select-control input:-webkit-autofill,
.Red_fms .Select-control input:-webkit-autofill:hover,
.Red_fms .Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #d32f2f inset !important; }

/* Gold */
.Gold .Select-control input:-webkit-autofill,
.Gold .Select-control input:-webkit-autofill:hover,
.Gold .Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #e89c24 inset !important; }

/* Gold */
.Gold .Select-control input:-webkit-autofill,
.Gold .Select-control input:-webkit-autofill:hover,
.Gold .Select-control input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #2e9cca inset !important; }

.add_beadcrumb_design {
  display: flex; }
  .add_beadcrumb_design .breadcrumb {
    margin-bottom: 0px; }
    .add_beadcrumb_design .breadcrumb > li + li:before {
      color: var(--bg-color);
      content: ">"; }
    .add_beadcrumb_design .breadcrumb > li a {
      color: var(--bg-color); }

.slds_custom_modal .slds-modal__header {
  box-shadow: none; }

.slds_custom_modal .slds-modal__footer {
  position: inherit; }

form.slds_form input {
  height: auto; }

.remove_previous_header_css {
  box-shadow: none;
  background: inherit; }

.slds_my_card .slds-card {
  position: relative;
  padding: 0;
  background: white;
  border: 1px solid #dddbda;
  border-radius: 0.25rem;
  background-clip: padding-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1); }

.custom_page_header .slds-page-header__name-title h1 {
  font-size: inherit;
  font-weight: inherit; }

.SLDS_a_color {
  color: #006dcc !important; }

.SLDS_date_picker_width .slds-dropdown-trigger {
  width: 100% !important; }

.white_bg_color {
  background: #fff; }

dl.lower_font_size {
  font-size: .8125rem;
  font-weight: inherit; }

.slds-checkbox_add-button [type="checkbox"]:checked ~ .slds-checkbox_faux {
  background: #4bca81 !important; }

.datepicker_100_width .slds-dropdown-trigger {
  width: 100%; }

.white_bg_color {
  background: #fff; }

ul {
  padding-left: 0;
  list-style-type: none; }

.cmn_bg_clr {
  background-color: var(--bg-color) !important; }

.cmn_font_clr {
  color: var(--bg-color) !important; }

.dashboard-section {
  padding: 10px 0; }

.drk-color1 {
  color: var(--drk-color1); }

.drk-color2 {
  color: var(--drk-color2); }

.drk-color3 {
  color: var(--drk-color3); }

.drk-color4 {
  color: var(--drk-color4); }

.drk-color5 {
  color: var(--drk-color5); }

.drk-color6 {
  color: var(--drk-color6); }

.no-pad {
  padding-left: 0;
  padding-right: 0; }

.no_pd_l {
  padding-left: 0; }

.no_pd_r {
  padding-right: 0; }

.main_heading_cmn- {
  color: var(--bg-color);
  border-color: var(--bg-color);
  border-top: solid 1px;
  border-bottom: solid 1px;
  padding: 15px 0;
  margin-top: 15px; }
  .main_heading_cmn- h1 {
    margin-top: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: inherit;
    color: var(--bg-color) !important;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .main_heading_cmn- .hdng_btn {
    padding: 10px 30px;
    float: right; }

.back_col_cmn- {
  margin-top: 15px; }
  .back_col_cmn- .icon {
    font-size: 25px;
    color: var(--bg-color);
    cursor: pointer; }

.status_row-- {
  padding-bottom: 30px;
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap; }

.status_box1 {
  background: #ececec;
  padding: 15px 40px;
  border-radius: var(--b-radius);
  min-height: 230px;
  margin-bottom: 15px;
  height: 100%; }
  .status_box1 h4.hdng {
    text-align: center;
    font-size: 16px !important;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: inherit;
    margin-top: 10px; }

.status_det_list {
  padding-left: 0;
  list-style-type: none;
  min-height: 90px; }
  .status_det_list li {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px; }

.viewBy_dc h5 {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 13px; }

.viewBy_dc ul li {
  display: inline-block;
  width: 33%;
  color: grey;
  cursor: pointer;
  font-weight: normal;
  font-size: 13px; }

.viewBy_dc ul li:hover,
.viewBy_dc ul li.active {
  color: var(--bg-color); }

.Menu4_Div- {
  margin-bottom: 15px;
  border-bottom: solid 1px var(--bg-color);
  padding: 30px 0; }
  .Menu4_Div- ul {
    display: flex;
    width: calc(100% + 20px);
    margin-left: -10px; }
  .Menu4_Div- ul li {
    flex: 1;
    text-align: center;
    padding: 0 10px;
    cursor: pointer; }
  .Menu4_Div- ul li a {
    background-color: #cecece;
    display: block;
    padding: 15px 10px;
    border-radius: var(--b-radius);
    font-weight: 700;
    font-size: 15px;
    color: #4a4a4a; }
  .Menu4_Div- ul li:hover a,
  .Menu4_Div- ul li.active a {
    color: var(--bg-color);
    background-color: #e9e9e9;
    text-decoration: none; }

.sort_row1-- {
  margin-top: 15px;
  margin-bottom: 15px; }

.filter_flx {
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px; }
  .filter_flx .filter_fields__ {
    flex: 1;
    padding: 0px 7px; }

.data_table_cmn.edit_TaskTble .ReactTable .rt-tbody .rt-tr-group {
  margin-bottom: 0; }

.data_table_cmn.edit_TaskTble .ReactTable .rt-tbody {
  margin-top: 0; }

.data_table_cmn .ReactTable {
  min-height: 125px; }

.data_table_cmn .ReactTable .rt-noData {
  width: 100%;
  text-align: center;
  border: solid 1px var(--bg-color);
  color: var(--bg-color);
  border-radius: var(--b-radius);
  margin-top: 15px; }

.data_table_cmn .ReactTable .rt-thead.-header {
  background-color: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius); }

.data_table_cmn .ReactTable .rt-thead .rt-th,
.data_table_cmn .ReactTable .rt-thead .rt-th.-cursor-pointer {
  outline: 0 !important;
  border-right: solid 1px #fff;
  padding: 10px 10px; }

.data_table_cmn .ReactTable .rt-thead .rt-td:last-child,
.data_table_cmn .ReactTable .rt-thead .rt-th:last-child {
  border-right: 0 !important; }

.data_table_cmn .ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0; }

.data_table_cmn .ReactTable {
  border: transparent !important; }

.data_table_cmn .ReactTable .rt-tbody {
  margin-top: 15px; }

.data_table_cmn .ReactTable .rt-thead .rt-tr {
  text-align: left; }

.data_table_cmn .ReactTable .rt-tbody .rt-tr-group {
  margin-bottom: 15px;
  border-radius: var(--b-radius);
  overflow: hidden;
  background: #fff; }

.data_table_cmn .ReactTable .rt-tr .rt-td {
  border-right: solid 1px #dadada;
  padding: 15px 5px;
  padding-left: 15px;
  font-weight: 400;
  font-size: 13px; }

.data_table_cmn .ReactTable .rt-tr-group.active {
  border: solid 1px var(--bg-color); }

.data_table_cmn.tble_2_clr .ReactTable .rt-tr-group:nth-child(even) {
  background: #fff !important; }

.data_table_cmn.tble_2_clr .ReactTable .rt-tr-group:nth-child(odd) {
  background: #e2e2e2 !important; }

.data_table_cmn .Select-control .Select-input:focus {
  background-color: var(--bg-color) !important; }

.data_table_cmn .cmn_select_dv.gr_slctB .Select-control .Select-input:focus {
  background-color: #dcdcdc !important; }

.data_table_cmn .ReactTable .rt-tr {
  position: relative; }

.data_table_cmn.tableType2 .ReactTable .rt-thead.-header {
  border-radius: var(--b-radius) var(--b-radius) 0 0 !important; }

.data_table_cmn.tableType2 .ReactTable .rt-tbody .rt-td,
.data_table_cmn.tableType2 .ReactTable .rt-thead .rt-tr {
  text-align: center; }

.data_table_cmn.tableType2 .ReactTable .rt-tbody {
  margin-top: 00px !important;
  border-radius: 0 0 var(--b-radius) var(--b-radius) !important; }

.data_table_cmn.tableType2 .ReactTable .rt-tr {
  border-radius: 0 !important; }

.data_table_cmn.tableType2 .ReactTable .rt-tbody .rt-tr-group {
  margin-bottom: 0 !important;
  border-radius: 0; }

.data_table_cmn.aplcnt_table .ReactTable .rt-tr .rt-td:nth-child(6),
.data_table_cmn.aplcnt_table .rt-thead .rt-td:nth-child(6),
.data_table_cmn.aplcnt_table .rt-thead .rt-th:nth-child(6) {
  border-right: transparent !important; }

.data_table_cmn.aplcnt_table .ReactTable.-striped .rt-tr.-even {
  background: #fff !important; }

.data_table_cmn.trainTable .ReactTable .rt-tr .rt-td:nth-child(5),
.data_table_cmn.trainTable .rt-thead .rt-td:nth-child(5),
.data_table_cmn.trainTable .rt-thead .rt-th:nth-child(5) {
  border-right: transparent !important; }

.data_table_cmn.hdng_cmn2 .ReactTable .rt-thead.-header {
  color: var(--bg-color);
  background-color: transparent;
  box-shadow: none;
  font-size: 15px;
  font-weight: 600;
  border-bottom: solid 1px var(--bg-color);
  border-radius: 0; }

.data_table_cmn.hdng_cmn2 .ReactTable .rt-thead .rt-th {
  border-right: 1px solid var(--bg-color); }

.data_table_cmn.usrMngeTable .ReactTable .rt-thead .rt-th:nth-child(4),
.data_table_cmn.usrMngeTable .ReactTable .rt-tr .rt-td:nth-child(4) {
  border-right: transparent; }

.data_table_cmn.usrMngeTable .ReactTable .rt-table,
.data_table_cmn.usrMngeTable .ReactTable .rt-tbody {
  overflow: inherit; }

.data_table_cmn.usrMngeTable .ReactTable .rt-tbody .rt-tr {
  position: static; }

.data_table_cmn.taskTable .ReactTable.-striped .rt-tr.-odd {
  background: #e9e9e9 !important; }

.data_table_cmn.taskTable .ReactTable.-striped .rt-tr.-odd + .applicant_info1 {
  background: #e9e9e9; }

.data_table_cmn.depStaffTable__ {
  margin: 15px 0;
  border: solid 1px #efefef;
  border-radius: var(--b-radius); }

.data_table_cmn.depStaffTable__ .ReactTable .rt-thead.-header {
  background: var(--bg-color);
  color: #fff; }

.data_table_cmn.depStaffTable__ .ReactTable .rt-thead .rt-th {
  border-right: 1px solid #fff; }

.data_table_cmn.depStaffTable__ .ReactTable .rt-tbody .rt-tr.-odd {
  background: #efefef !important; }

.data_table_cmn.depStaffTable__ .ReactTable .rt-thead .rt-tr {
  margin-bottom: 0;
  padding: 0; }

.data_table_cmn.depStaffTable__ .ReactTable.-highlight .rt-thead .rt-tr:hover {
  background: 0 0; }

.data_table_cmn.depStaffTable__ .ReactTable {
  min-height: auto; }

.data_table_cmn.departTable .ReactTable .rt-tbody .rt-td:nth-child(4),
.data_table_cmn.departTable .ReactTable .rt-thead .rt-th:nth-child(4) {
  border-right: transparent; }

.data_table_cmn.tableType2.editCabTable1 .ReactTable .rt-tbody {
  max-height: 300px;
  overflow-y: auto; }

.data_table_cmn.taskTable .ReactTable .rt-tbody .rt-tr-group .rt-tr {
  padding: 5px 0; }

.data_table_cmn.taskTable .ReactTable .rt-tr .rt-td {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px; }

.data_table_cmn.taskTable .ReactTable .rt-tr.-even {
  background: #fff !important; }

.data_table_cmn.taskTable .ReactTable .rt-tr .rt-td:nth-child(7),
.data_table_cmn.taskTable .rt-thead .rt-th:nth-child(7) {
  border-right: transparent !important; }

.data_table_cmn.tble_txt_center .ReactTable .rt-thead .rt-tr,
.data_table_cmn.tble_txt_center .ReactTable .rt-tr .rt-td {
  text-align: center; }

.data_table_cmn.tbl_fnt_sm .ReactTable .rt-thead .rt-tr,
.data_table_cmn.tbl_fnt_sm .ReactTable .rt-tr .rt-td {
  font-size: 14px; }

.data_table_cmn.edit_TaskTble .ReactTable .rt-tr .rt-td {
  padding-left: 5px; }

.data_table_cmn.tbl_flx1 .ReactTable .rt-tr .rt-td {
  display: flex;
  align-items: center; }

.data_table_cmn.tbl_flx2 .ReactTable .rt-tr .rt-td {
  display: flex;
  align-items: center;
  justify-content: center; }

.data_table_cmn.tsk_table_mini .ReactTable .rt-tr .rt-td {
  padding-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center; }

.data_table_cmn.tsk_table_mini .ReactTable .rt-tbody .rt-tr-group {
  border: solid 1px #1e1e1e;
  border-bottom: solid 1px #1e1e1e !important; }

.data_table_cmn.tsk_table_mini .ReactTable .rt-tbody .rt-tr {
  background: #f7f7f7 !important;
  padding: 5px 0; }

.data_table_cmn.tsk_table_mini .ReactTable .rt-tr .rt-td {
  border-right: solid 1px #1e1e1e; }

.data_table_cmn.tsk_table_mini .ReactTable .rt-thead .rt-th:nth-child(3),
.data_table_cmn.tsk_table_mini .ReactTable .rt-tr .rt-td:nth-child(2),
.data_table_cmn.tsk_table_mini .ReactTable .rt-tr .rt-td:nth-child(3),
.data_table_cmn.tsk_table_mini .ReactTable .rt-tr .rt-td:nth-child(4) {
  border-right: transparent; }

.data_table_cmn.tsk_table_mini .ReactTable {
  min-height: 50px; }

.data_table_cmn.tsk_table_mini .ReactTable .rt-tr .rt-td {
  padding: 10px 10px; }

.data_table_cmn.sm_td_spce .ReactTable .rt-tr .rt-td {
  padding: 7px 15px; }

.data_table_cmn.disabled_tble .ReactTable .rt-tr .rt-td {
  display: flex;
  align-items: center; }

.data_table_cmn.header_center .rt-thead .rt-tr {
  text-align: center; }

.data_table_cmn.add_devices_react_table .ReactTable .rt-tbody .rt-tr-group {
  margin-bottom: 0; }

.data_table_cmn.add_devices_react_table .ReactTable .rt-tr .rt-td {
  padding: 10px 15px; }

.data_table_cmn.add_devices_react_table .ReactTable .rt-tbody {
  margin-top: 7px; }

.data_table_row1 {
  margin-top: 30px; }

.action_cont_row {
  padding-top: 15px;
  padding-bottom: 30px; }

.new_task_btn {
  padding: 10px 30px;
  float: right;
  width: 250px;
  max-width: 100%; }
  .new_task_btn.w-100 {
    width: 100% !important; }

.legend_row-- {
  margin-top: 15px; }

.legend_ulBC li {
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  padding-left: 20px;
  font-size: 11px; }

.legend_ulBC li .leg_ic {
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 2px;
  left: 0;
  margin-top: 1px; }

.legend_ulBC li .leg_ic.gr_int {
  background-color: var(--drk-color1); }

.legend_ulBC li .leg_ic.trng {
  background-color: var(--drk-color2); }

.legend_ulBC li .leg_ic.Ind {
  background-color: var(--drk-color3); }

.legend_ulBC.small li {
  font-size: 10px; }

.legend_ulBC.small li .leg_ic {
  width: 12px;
  height: 12px;
  border-radius: 0; }

.sched_head1 {
  color: #000; }
  .sched_head1 .sch_type {
    font-size: 15px; }
  .sched_head1 small {
    color: grey; }

.snd_mail1 {
  width: 100%;
  margin-top: 10px;
  padding: 10px; }

.creatn_tg {
  font-size: 9px;
  margin-top: 10px;
  display: block; }

.csForm_control {
  width: 100%;
  height: 40px;
  border-radius: var(--b-radius);
  border: solid 1px var(--bg-color);
  padding-left: 15px;
  padding-right: 15px;
  background: #fff; }
  .csForm_control:focus {
    outline: none; }
  .csForm_control.inp_blue {
    background-color: var(--bg-color);
    color: #fff; }
  .csForm_control.inp_blue:focus {
    color: var(--bg-color);
    background-color: #fff; }
  .csForm_control.txt_area {
    height: 100px;
    max-width: 100%;
    min-width: 100%;
    padding: 15px; }
  .csForm_control.txt_area.editor {
    height: 800px;
    max-height: 700px; }
  .csForm_control.brRad10 {
    border-radius: var(--b-radius); }
  .csForm_control.clr2 {
    border: solid 1px #000;
    color: var(--txt-color);
    color: #000; }
  .csForm_control.bl_bor {
    border: solid 1px #000; }

.csform-group {
  width: 100%;
  margin-bottom: 15px; }
  .csform-group label {
    font-size: 14px; }
  .csform-group > p,
  .csform-group > div {
    word-break: break-all;
    word-break: break-word; }

.recruitment_module .csform-group .react-datepicker__day--keyboard-selected {
  background: var(--bg-color);
  color: #fff; }

.recruitment_module .csform-group .react-datepicker__header {
  background: var(--bg-color); }

.bor_bot1 {
  border-bottom: solid 1px var(--bg-color); }

.bor_bot2 {
  border-bottom: dashed 1px var(--bg-color); }

.bor_line {
  width: 100%;
  height: 1px;
  background: var(--bg-color);
  margin-bottom: 15px; }

.createACtion_Table1 {
  margin-bottom: 15px;
  z-index: 10; }
  .createACtion_Table1 .pagination-bottom {
    display: none; }
  .createACtion_Table1 .ReactTable .rt-tr .rt-td {
    text-align: center;
    padding-left: 0; }

.subsTasks_ul {
  width: 100%; }
  .subsTasks_ul li {
    width: 100%;
    color: #fff;
    background: var(--bg-color);
    padding: 15px 15px;
    border-radius: var(--b-radius);
    position: relative;
    margin-bottom: 15px;
    font-size: 15px;
    cursor: pointer;
    padding-right: 45px;
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .subsTasks_ul li.active:after {
    width: 200px;
    height: 1px;
    position: absolute;
    background: var(--bg-color);
    content: "";
    right: calc(0% - 200px);
    margin-top: 10px; }
  .subsTasks_ul li i {
    position: absolute;
    right: 15px;
    font-size: 22px;
    cursor: pointer;
    margin-top: -2px;
    margin-top: -2px;
    top: calc(50% - 8px); }
  .subsTasks_ul .creat_subTsksBtn {
    padding: 15px;
    width: 100%;
    margin-bottom: 15px;
    font-size: 15px;
    position: relative;
    padding-right: 30px; }

.creat_subTsksBtn span {
  font-size: 40px;
  position: absolute;
  top: 20px;
  margin-left: 10px;
  line-height: 0; }

.subtask_info_box-- {
  width: 90%;
  min-height: 250px;
  background: #fff;
  border: solid 1px var(--bg-color);
  padding: 30px;
  border-radius: var(--b-radius);
  margin-top: 10px;
  margin-left: 10%; }

.subtsks_name {
  margin-top: 0;
  color: grey;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 22px; }

.subtsks_detail {
  padding-bottom: 15px; }

.add_attachments1 {
  width: 45px;
  height: 45px;
  border: solid 1px var(--bg-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg-color);
  font-size: 23px;
  margin-top: 25px;
  float: right;
  cursor: pointer; }
  .add_attachments1:hover {
    background-color: var(--bg-color);
    color: #fff; }

hr {
  margin-top: 50px;
  margin-bottom: 50px;
  border: 0;
  border-top: 3px solid var(--bg-color);
  width: 100px; }

.task_accordnsList_hdng {
  border-bottom: solid 1px var(--bg-color);
  margin-top: 30px; }
  .task_accordnsList_hdng li {
    display: inline-block;
    height: 50px;
    padding-top: 10px;
    border-right: 1px solid var(--bg-color);
    padding-left: 20px;
    color: var(--bg-color);
    font-weight: 600;
    font-size: 17px; }
  .task_accordnsList_hdng li:last-child {
    border-right: none; }

.wd_10 {
  width: 10%; }

.wd_20 {
  width: 20%; }

.wd_15 {
  width: 15%; }

.wd_30 {
  width: 30%; }

.wd_50 {
  width: 50%; }

.wd_10 {
  width: 10%; }

.wd_25 {
  width: 25%; }

.wd_40 {
  width: 40%; }

.TaskAccordion_col .panel-group .taskPanel1 {
  border-radius: var(--b-radius);
  border: transparent;
  overflow: hidden;
  box-shadow: 0px 1px 7px #cecece;
  margin-bottom: 15px;
  margin-top: 15px; }

.TaskAccordion_col .panel-group .taskPanel1 > .panel-heading {
  padding: 0px 0;
  background-image: none;
  position: relative; }

.TaskAccordion_col .panel-group .taskPanel1 > .panel-heading .panel-title a {
  padding: 12px 0;
  display: block; }

.TaskAccordion_col .panel-group .taskPanel1:nth-child(odd) > .panel-heading {
  background-color: #e1e1e1; }

.TaskAccordion_col .panel-group .taskPanel1:nth-child(even) > .panel-heading {
  background-color: #fff; }

.TaskAccordion_col .taskPanel1 .acc_arrow1 {
  position: absolute;
  top: 12px;
  right: 15px;
  color: var(--bg-color); }

.TaskAccordion_col .taskPanel1 .collapsed .acc_arrow1 {
  transform: rotate(-90deg); }

.TaskAccordion_col .taskPanel1 .panel-body {
  padding: 0; }

.TaskAccordion_col .panel-group .taskPanel1:nth-child(odd) .panel-body {
  background-color: #eeeeee; }

.TaskAccordion_col .panel-group .taskPanel1:nth-child(even) .panel-body {
  background-color: #fff; }

.task_hdng_dtl {
  margin-bottom: 0px;
  display: flex; }
  .task_hdng_dtl li {
    display: inline-block;
    padding-left: 20px;
    border-right: 1px solid grey; }
  .task_hdng_dtl li:last-child {
    border-right: none;
    padding-right: 50px; }

.Invited_Attendees__ {
  padding: 15px;
  border-bottom: solid 1px var(--bg-color);
  border-top: solid 1px var(--bg-color); }

.attendees_box {
  width: 100%;
  height: auto;
  border-radius: var(--b-radius);
  border: solid 1px var(--bg-color);
  background: #fff;
  margin-bottom: 15px; }

.attendees_box ul {
  padding-left: 20px;
  margin-bottom: 0;
  display: flex;
  align-items: center; }
  .attendees_box ul ul li {
    display: inline-block;
    padding: 13px 15px;
    border-right: solid 1px #d4d4d4;
    font-size: 15px;
    align-items: center;
    position: relative; }
  .attendees_box ul ul li:last-child {
    border-right: none; }
  .attendees_box ul ul li.action_Invili {
    padding-right: 150px; }

.clr_approved {
  color: #04a651; }

.clr_pending {
  color: #f7941d; }

.clr_declined {
  color: #ed1c24; }

.Attend_status1 {
  font-weight: 700; }

.atndee_tskBtn {
  padding: 5px 20px;
  font-size: 13px; }
  .atndee_tskBtn.right {
    float: right;
    position: absolute;
    right: 15px;
    top: calc(50% - 15px); }

.Day_Sub_Tasks {
  border-bottom: solid 1px var(--bg-color);
  padding: 15px;
  margin-bottom: 15px; }

.Sub_Tasks_Hdng__ {
  background-color: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius);
  padding: 0 15px;
  margin-bottom: 15px; }
  .Sub_Tasks_Hdng__ .subTsks_hdngUl li {
    display: inline-block;
    padding: 10px 15px;
    border-right: solid 1px #fff;
    font-size: 17px;
    font-weight: 600; }
  .Sub_Tasks_Hdng__ .subTsks_hdngUl li:last-child {
    border-right: none; }

.realct_btn {
  margin-top: 10px;
  display: block;
  margin: 10px auto; }

.subtasks_listsBox .panel-heading {
  background-color: #fff;
  background-image: none;
  border-bottom: solid 1px var(--bg-color);
  padding: 0 15px;
  position: relative; }

.subtasks_listsBox .panel-heading .sbtskEye {
  position: absolute;
  top: 8px;
  right: 15px;
  color: var(--bg-color);
  font-size: 24px; }

.subtasks_listsBox .panel-body {
  background-color: #fff !important;
  padding: 8px 15px !important; }

.subtasks_listsBox .panel {
  border-radius: var(--b-radius);
  overflow: hidden;
  border: solid 1px var(--bg-color);
  margin-bottom: 15px; }

.subtasks_listsBox .SubTasks_panLi li {
  border-right: solid 1px #cecece;
  font-size: 18px;
  font-weight: 500; }

.subtasks_listsBox .SubTasks_panLi li:last-child {
  padding-right: 50px;
  border-right: none; }

.cmnUl_Des {
  display: flex;
  align-items: center;
  margin-bottom: 0; }
  .cmnUl_Des li {
    display: inline-block;
    padding: 10px 15px; }

.cstm_checkbox input.checkbox1[type="checkbox"] {
  position: absolute;
  visibility: hidden; }

.csTum_checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px !important;
  line-height: inherit !important; }
  .csTum_checkbox input {
    cursor: pointer;
    width: 0;
    height: 0;
    opacity: 0; }
  .csTum_checkbox input:checked ~ .checkmark {
    background-color: #2196f3; }
  .csTum_checkbox input:checked ~ .checkmark:after {
    display: block; }
  .csTum_checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.contacsTum_checkboxiner:hover input ~ .checkmark {
  background-color: #ccc; }

.checkmark {
  position: absolute;
  top: 2px;
  left: 10px;
  height: 23px;
  width: 25px;
  border-radius: 5px;
  background-color: #eee;
  border: solid 1px var(--bg-color); }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none; }

.noborder_ul {
  text-align: right;
  margin-bottom: 0px; }
  .noborder_ul li {
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
    position: relative; }

.subTasks_Action__ {
  text-align: right;
  margin-bottom: 10px; }
  .subTasks_Action__ li {
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    border-right: 1px solid grey; }

.subTasks_Action__ li:last-child, .noborder_ul li:last-child {
  border-right: none; }

.subTasks_Action__ li .sbTsk_li, .noborder_ul li .sbTsk_li {
  text-decoration: underline !important;
  display: block;
  padding: 0 10px;
  font-weight: normal; }

.subTasks_Action__ li .sbTsk_li2, .noborder_ul li .sbTsk_li2 {
  text-decoration: underline !important;
  display: block;
  padding: 0 5px;
  font-weight: normal; }

.subTasks_Action__ li .sbTsk_li:hover, .noborder_ul li .sbTsk_li:hover {
  color: var(--bg-color); }

.subTasks_Action__ li .sbTsk_li .csTum_checkbox, .noborder_ul li .sbTsk_li .csTum_checkbox {
  top: -5px; }

.subTasks_Action__.left, .noborder_ul.left {
  text-align: left; }

.subTasks_Action__.tsk_center, .noborder_ul.tsk_center {
  text-align: center;
  margin-top: 15px; }

.subTasks_Action__.tsk_center2, .noborder_ul.tsk_center2 {
  text-align: center; }

.subTasks_Action__.tsk_center li, .noborder_ul.tsk_center li {
  font-size: 16px; }

.Time_subTasks_Action__ {
  text-align: right; }
  .Time_subTasks_Action__ li {
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    border-right: 1px solid grey; }
  .Time_subTasks_Action__ li:last-child {
    border-right: none; }
  .Time_subTasks_Action__ li .sbTsk_li {
    text-decoration: underline !important;
    display: block;
    padding: 0 10px;
    font-weight: normal; }
  .Time_subTasks_Action__ li .sbTsk_li:hover {
    color: var(--bg-color); }
  .Time_subTasks_Action__ li span {
    font-size: 12px; }
  .Time_subTasks_Action__ li:last-child .sbTsk_li {
    padding-right: 0px; }

.attendees_navEx {
  padding: 0 15px;
  margin-bottom: 20px; }

.jobMain_dets__ {
  padding: 15px;
  border-bottom: solid 1px var(--bg-color);
  margin-bottom: 10px;
  position: relative; }
  .jobMain_dets__ .vw_aplcnt_btn {
    min-width: 200px;
    padding-right: 30px;
    margin-top: 30px; }

.jobMaindets_ul li {
  font-weight: normal;
  font-size: 13px; }

.bor_right {
  border-right: 1px solid var(--bg-color); }

.bor_left {
  border-left: 1px solid var(--bg-color);
  border-left: 1px solid #8d8d8d; }

.posted_ul12__ {
  width: 220px;
  margin-left: 80px; }
  .posted_ul12__ li {
    padding: 7px 15px;
    padding-right: 30px;
    font-weight: normal;
    font-size: 15px;
    background-color: var(--bg-color);
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: var(--b-radius);
    color: #fff;
    position: relative; }
  .posted_ul12__ li:hover {
    box-shadow: 0px 5px 15px #cecece; }
  .posted_ul12__ li:after {
    content: "\4f";
    font-family: "icons_me";
    position: absolute;
    right: 10px; }

.jobMain_foot__ {
  padding: 5px 15px; }

.creaJobRow1__ {
  margin-bottom: 15px;
  padding-bottom: 15px; }
  .creaJobRow1__ label {
    /* padding-left: 15px; */
    font-size: 18px;
    margin-top: 15px; }

.resume_slider {
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  text-align: center; }
  .resume_slider .slick-slider {
    width: 100%; }
  .resume_slider .slick-slider .slick-slide {
    padding: 10px;
    padding-left: 0; }
  .resume_slider .slick-slider .slick-slide > div:focus {
    outline: none; }
  .resume_slider .slick-slider .slick-slide > div > div:focus {
    outline: none; }
  .resume_slider .slick-arrow:before {
    color: var(--bg-color);
    font-size: 25px; }
  .resume_slider .slick-next,
  .resume_slider .slick-prev {
    width: 30px;
    height: 30px; }
  .resume_slider .slick-arrow {
    top: 100%;
    margin-top: 20px; }
  .resume_slider .slick-prev {
    left: calc(50% - 50px); }
  .resume_slider .slick-next {
    right: calc(50% - 50px); }

.template_slide img {
  width: 100%;
  border: solid 1px var(--bg-color); }

.template_slide h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px; }

.template_slide > div {
  border: solid 1px var(--bg-color);
  padding: 15px;
  border-radius: var(--b-radius);
  padding-top: 25px; }

.template_slide.selected > div {
  background-color: var(--bg-color);
  color: #fff; }

.add_Ques {
  text-align: center; }

.add_ic {
  width: 35px;
  height: 35px;
  color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
  float: right; }

input[type="button"].pst_btn {
  height: 40px;
  width: 100% !important; }

.title_frameDv {
  width: 70%;
  margin: 0 auto; }

input[type="text"].csForm_control.inp_blue::-webkit-input-placeholder {
  color: #fff; }

input[type="text"].csForm_control.inp_blue:focus::-webkit-input-placeholder {
  color: grey; }

.crte_svBtn {
  padding: 12px 40px;
  margin-left: 10px; }

.createJob_sec {
  padding-bottom: 150px; }

.JO_hdng2 {
  border-top: solid 1px var(--bg-color);
  border-bottom: solid 1px var(--bg-color);
  margin-top: 15px;
  margin-bottom: 15px; }
  .JO_hdng2 h4 {
    color: var(--bg-color);
    margin: 10px 0; }

.jOpen_table_dv {
  width: 90%;
  margin: 15px auto;
  padding-bottom: 15px; }

.JoTab_hdng {
  border-bottom: solid 1px #000;
  padding: 10px 0;
  margin-bottom: 15px; }
  .JoTab_hdng li {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    padding-left: 20px; }

.Jlst_detTab {
  background-color: #fff;
  border-radius: var(--b-radius);
  padding: 13px 0;
  margin-bottom: 10px; }
  .Jlst_detTab .task_hdng_dtl > li {
    border-right-color: var(--bg-color);
    position: relative;
    padding-right: 35px;
    word-break: break-all;
    font-size: 13px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .Jlst_detTab .task_hdng_dtl {
    display: flex; }
  .Jlst_detTab .task_hdng_dtl > li .see_docs {
    position: absolute;
    right: 10px;
    color: var(--bg-color);
    cursor: pointer;
    font-size: 20px;
    top: -2px; }

.JoAplcnt_list__ {
  border-bottom: dashed 1px var(--bg-color);
  padding-bottom: 10px;
  margin-bottom: 10px; }

.jbAplc_tags__ span {
  background: #fff;
  color: var(--drk-color1);
  border: solid 1px var(--drk-color1);
  cursor: pointer;
  display: inline-block;
  padding: 7px 18px;
  margin-right: 10px;
  border-radius: var(--b-radius);
  font-size: 13px;
  font-weight: normal; }
  .jbAplc_tags__ span.active, .jbAplc_tags__ span:hover {
    background: var(--drk-color1);
    color: #000; }

.jobOpModal_foot__ {
  border-top: solid 1px var(--bg-color);
  padding: 10px 15px; }

.resume_dialog {
  position: absolute;
  width: 1000px;
  background-color: #fff;
  z-index: 150;
  min-height: 800px;
  top: 100px;
  left: calc(50% - 500px);
  border-radius: 50px;
  box-shadow: 0px 0px 15px #cecece;
  padding: 15px 30px;
  display: none; }
  .resume_dialog.show {
    display: block; }

.tmpDia_hdng {
  border-top: solid 1px var(--bg-color);
  border-bottom: solid 1px var(--bg-color);
  padding: 10px 0;
  margin-top: 15px;
  position: relative; }
  .tmpDia_hdng h3 {
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
    text-align: center;
    font-size: 20px;
    display: inline-block;
    color: var(--bg-color); }

.mdl_CloseTl {
  position: absolute;
  right: 0;
  color: var(--bg-color);
  cursor: pointer;
  font-size: 25px; }

.thumbList_aside {
  width: 150px;
  height: 600px;
  float: left;
  position: relative;
  overflow: hidden; }

.tmp_thum_aside {
  overflow-y: auto;
  height: 100%;
  padding-right: 10px; }
  .tmp_thum_aside p {
    position: absolute;
    margin-top: -20px; }
  .tmp_thum_aside .tmp_thumb {
    border: solid 1px transparent;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer; }
  .tmp_thum_aside .tmp_thumb:hover,
  .tmp_thum_aside .tmp_thumb.active {
    border: solid 1px var(--bg-color); }
  .tmp_thum_aside .tmp_thumb img {
    width: 100%; }

.temp_showcase1__ {
  padding: 15px 0;
  padding-bottom: 0;
  border-bottom: solid 1px var(--bg-color); }

.scrol_des::-webkit-scrollbar {
  width: 7px;
  background-color: transparent;
  border-radius: var(--b-radius);
  margin-right: 10px;
  cursor: pointer; }

.scrol_des::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  outline: none;
  border-radius: var(--b-radius);
  cursor: pointer;
  border: solid 1px var(--bg-color); }

.scrol_des::-webkit-scrollbar-corner {
  background-color: transparent; }

.tmplate_mnShow {
  float: left;
  width: calc(100% - 150px);
  padding: 0 15px;
  padding-right: 50px;
  height: 600px;
  overflow-y: auto; }
  .tmplate_mnShow img {
    width: 100%; }

.dwnld_res_bt {
  width: 150px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  float: right;
  margin-top: 15px; }
  .dwnld_res_bt i {
    color: var(--bg-color);
    font-size: 25px; }
  .dwnld_res_bt p {
    font-size: 16px; }
  .dwnld_res_bt:hover {
    color: var(--bg-color); }

.cstmDLCl {
  right: 30px;
  color: #fff;
  font-size: 30px;
  top: 30px; }

.lab_vrt label {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700 !important; }

.dashboard_Table .ReactTable .rt-tr .rt-td {
  font-size: 16px;
  font-weight: 500; }

.rec-table-icon {
  margin-top: 5px; }

.Req-Staff-Details_tBL .rec-table-icon {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center; }

.dataTab_accrdn_cmn .icn_ar1 {
  font-size: 14px; }

.dataTab_accrdn_cmn .icn_ar1:before {
  position: static; }

.aplcnt_table .icn_ar1 {
  color: var(--bg-color); }

/* table progress */
.table_progress .ReactTable .rt-tbody .rt-tr {
  position: relative; }

.table_progress .ReactTable .rt-tbody .rt-tr:before,
.table_progress .ReactTable .rt-tbody .rt-tr:after {
  content: "";
  position: absolute;
  width: calc(100% - 2px);
  height: 6px;
  left: 1px;
  bottom: -4px;
  border-radius: 0px; }

.table_progress .ReactTable .rt-tbody .rt-tr-group {
  padding-bottom: 5px; }

.table_progress .ReactTable .rt-tbody .rt-tr:before {
  /* background-color: #ffd5c8; */
  z-index: 0; }

.table_progress .ReactTable .rt-tbody .rt-tr:after {
  background-color: var(--bg-color);
  z-index: 0;
  width: 10%; }

.table_progress .applicant_info1 {
  margin-top: -6px; }

.applicant_info1 {
  background-color: #fff;
  /* min-height: 200px; */
  padding: 0 15px;
  margin-top: 0px;
  z-index: 0;
  padding-top: 0px;
  position: relative;
  border-radius: 0px 0px var(--b-radius) var(--b-radius); }

.applicant_info1.training_info1 {
  padding: 0px 30px;
  background: transparent; }

.applicant_info1 .progress {
  margin-bottom: 0;
  width: calc(100% + 30px);
  margin-left: -15px;
  border-radius: 0px 0px var(--b-radius) var(--b-radius);
  background: #ffd5c8;
  height: 23px; }

.applicant_info1 .progress .progress-bar {
  background-color: var(--bg-color);
  background-image: none;
  height: 40px;
  letter-spacing: 0.5px;
  font-weight: normal; }

.applicant_info1 .btn.mob_btn {
  width: 230px;
  max-width: 100%; }

.applicant_info1 .dwnld_btn1 {
  font-size: 24px;
  color: var(--bg-color);
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer; }

.applicant_info1 div {
  font-size: 15px; }

.applicant_info1.usrMng {
  z-index: inherit; }

.bor_top {
  border-top: solid 1px #929292; }

.appli_row1 {
  padding: 15px;
  font-size: 15px; }

.applis_dets1 {
  margin-bottom: 15px; }
  .applis_dets1 p {
    margin-bottom: 0; }
  .applis_dets1 p b {
    word-break: break-all; }

.eye-btn.mob_btn:after {
  content: "\e0a9"; }

.eye-btn.browse_btn:after {
  content: "\e080"; }

.btn.eye-btn.check_complete::after {
  content: "\e04b";
  margin-top: 5px; }

.eye-btn.add_btn:after {
  content: "\e04e"; }

.eye-btn.permisnEdit {
  width: 100%;
  margin-bottom: 10px; }

.eye-btn.permisnEdit:after {
  content: "\e07E"; }

.eye-btn.add_staff {
  padding: 13px 20px; }

.eye-btn.add_staff:after {
  content: "\64";
  margin-top: 1px;
  font-size: 22px; }

.eye-btn.vw_live:after {
  top: 8px; }

.dcmntBtns_dv {
  width: 80%; }
  .dcmntBtns_dv .btn {
    font-size: 15px;
    margin-right: 15px;
    margin-bottom: 15px; }

.cnstRow {
  margin-bottom: 15px;
  border-bottom: solid 1px #c8c8c8;
  padding-bottom: 10px;
  display: flex; }

.apli_nmDv {
  border-bottom: solid 1px var(--bg-color);
  padding-bottom: 15px;
  margin-bottom: 10px; }
  .apli_nmDv h2 {
    font-size: 22px;
    margin-top: 10px; }
  .apli_nmDv h2 span {
    font-size: 16px;
    display: inline-block;
    font-weight: normal; }
  .apli_nmDv p {
    font-size: 15px; }

.apInfo_Slct .Select,
.apInfo_Slct .Select div,
.apInfo_Slct .Select input,
.apInfo_Slct .Select span {
  text-align: center; }

.cntct_info {
  margin-top: 10px; }

.cntct_info table {
  font-size: 16px; }

.cntct_info table tr td {
  padding-bottom: 15px; }

.cntct_info.cntct_inf2 table tr td {
  padding-bottom: 5px;
  vertical-align: top; }

.cntct_info {
  padding-right: 15px; }

.btn.edit_aplctn {
  padding: 8px 30px;
  margin-top: 25px;
  margin-bottom: 15px; }

.pd_tb_15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.srve_liDv {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .srve_liDv .btn.s_bt {
    width: 140px;
    margin-right: 15px; }
  .srve_liDv .btn.b_bt {
    width: 200px; }
  .srve_liDv .btn.s_bt2 {
    width: 170px; }
  .srve_liDv .btn.b_bt2 {
    width: 220px; }

.srve_li23 {
  margin-top: 30px; }
  .srve_li23 li {
    margin-bottom: 15px; }

.dwndldie {
  font-size: 20px;
  cursor: pointer;
  color: var(--bg-color);
  margin-left: 7px; }

.srve_dwdnld_col h4 {
  margin-top: 0; }

.col_hgCmn {
  min-height: 300px; }

.bor_left2 {
  border-left: dashed 1px var(--bg-color); }

.notify_bor {
  border-left: dashed 1px var(--bg-color); }

.notify_ul__ li {
  width: 50%;
  display: inline-block;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer; }
  .notify_ul__ li i {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1px var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 18px;
    color: var(--bg-color); }
  .notify_ul__ li:hover i {
    background-color: var(--bg-color);
    color: #fff; }
  .notify_ul__ li div {
    font-size: 17px;
    margin-top: 5px; }

.quizRslt_btn {
  width: 100%; }

.main_timelineBox {
  position: relative;
  min-height: auto;
  padding: 50px 100px;
  float: left;
  width: 100%; }
  .main_timelineBox::before {
    content: "";
    position: absolute;
    width: 12px;
    height: calc(100% - 220px);
    left: 50%;
    margin-left: -6px;
    background-color: var(--bg-color);
    border-radius: var(--b-radius); }

.timeline_step {
  position: relative;
  height: auto;
  margin-bottom: 30px;
  float: left;
  width: 100%;
  z-index: 0; }
  .timeline_step.done .timeline_stepNum {
    background-color: var(--bg-color);
    color: #fff; }
  .timeline_step:nth-child(odd) .boxesDiv {
    float: left; }
  .timeline_step:nth-child(even) .boxesDiv {
    float: right; }
  .timeline_step.disabled .timeLine_detaiBox {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none; }
  .timeline_step:nth-child(odd) .timeLine_detaiBox {
    float: left; }
  .timeline_step:nth-child(odd) .timeLine_detaiBox:first-child:before {
    right: -19%; }
  .timeline_step:nth-child(even) .timeLine_detaiBox {
    float: right; }
  .timeline_step:nth-child(even) .timeLine_detaiBox:first-child:before {
    left: -19%; }
  .timeline_step .dareStatus {
    width: 100%;
    margin-top: 10px; }
  .timeline_step .dareStatus p {
    width: 40%;
    position: absolute; }
  .timeline_step:nth-child(odd) .dareStatus {
    float: left;
    text-align: left; }
  .timeline_step:nth-child(odd) .dareStatus p {
    left: 0; }
  .timeline_step:nth-child(even) .dareStatus {
    float: right;
    text-align: right; }
  .timeline_step:nth-child(even) .dareStatus p {
    right: 0; }

.timeline_stepNum {
  position: absolute;
  display: flex;
  width: 90px;
  height: 90px;
  border: solid 8px var(--bg-color);
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 50%;
  font-weight: 700;
  color: var(--bg-color);
  left: 50%;
  margin-left: -45px;
  background-color: #fff;
  top: 100px;
  margin-top: -45px;
  z-index: 1; }

.boxesDiv {
  width: 40%;
  position: relative; }
  .boxesDiv .timeLine_detaiBox::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 25px;
    bottom: -30px;
    left: calc(50% - 4px);
    background: var(--bg-color); }
  .boxesDiv .timeLine_detaiBox:last-child::after {
    display: none; }
  .boxesDiv > .timeLine_detaiBox:first-child:before {
    content: "";
    position: absolute;
    width: 19%;
    height: 12px;
    background: var(--bg-color);
    top: 92px;
    margin-top: -4px;
    z-index: -1; }
  .boxesDiv > .timeLine_detaiBox:last-child {
    margin-bottom: 0; }

.timeLine_detaiBox {
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  border: solid 5px var(--bg-color);
  border-radius: var(--b-radius);
  position: relative;
  text-align: center;
  padding: 15px;
  margin-bottom: 25px; }
  .timeLine_detaiBox.dv_full {
    width: 100%; }

.timeLine_hdng {
  position: relative;
  margin-bottom: 15px;
  width: 46%; }
  .timeLine_hdng::after {
    position: absolute;
    content: "";
    background: var(--bg-color);
    width: 8%;
    height: 12px;
    border-radius: var(--b-radius);
    right: -10%;
    margin-right: 1px;
    top: -0; }
  .timeLine_hdng .hdng_pos11 {
    float: right; }
  .timeLine_hdng h3 {
    margin-top: -5px;
    margin-bottom: 0;
    font-size: 19px; }
  .timeLine_hdng p {
    margin-bottom: 0; }

.btn_li {
  border-top: solid 1px var(--bg-color);
  border-bottom: solid 1px var(--bg-color);
  padding-top: 15px;
  margin-top: 15px; }
  .btn_li .btn {
    margin-right: 15px;
    margin-bottom: 15px; }

.timeLine_slct {
  width: 200px;
  margin: 10px auto; }

.cmn_select_dv {
  position: relative;
  /* pending dropdown color */
  /* success dropdown color */
  /* cancelled dropdown color */ }
  .cmn_select_dv .pending .Select-control {
    background-color: #f16522 !important; }
  .cmn_select_dv .pending.is-focused.is-open > .Select-control {
    background: #f16522 !important; }
  .cmn_select_dv .pending.Select.is-focused:not(.is-open) > .Select-control {
    background: #f16522 !important; }
  .cmn_select_dv .pending .Select-control .Select-input:focus {
    background: #f16522 !important; }
  .cmn_select_dv .success .Select-control {
    background-color: #00a551 !important; }
  .cmn_select_dv .success.is-focused.is-open > .Select-control {
    background: #00a551 !important; }
  .cmn_select_dv .success.Select.is-focused:not(.is-open) > .Select-control {
    background: #00a551 !important; }
  .cmn_select_dv .success .Select-control .Select-input:focus {
    background: #00a551 !important; }
  .cmn_select_dv .cancelled .Select-control {
    background-color: #e91e1e !important; }
  .cmn_select_dv .cancelled.is-focused.is-open > .Select-control {
    background: #e91e1e !important; }
  .cmn_select_dv .cancelled.Select.is-focused:not(.is-open) > .Select-control {
    background: #e91e1e !important; }
  .cmn_select_dv .cancelled .Select-control .Select-input:focus {
    background: #e91e1e !important; }

.awaitMsg {
  color: #f16522;
  text-align: center;
  font-size: 16px; }

.btn.rmndr_btn {
  width: 250px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 100%; }

.add_task22 {
  border-top: solid 1px var(--bg-color);
  border-bottom: solid 1px var(--bg-color); }
  .add_task22 span {
    font-size: 40px;
    margin-top: 10px;
    display: inline-block;
    color: var(--bg-color);
    cursor: pointer; }

.cmn-btn2.eye-btn {
  padding-right: 50px !important;
  position: relative; }

.cmn-btn2.active {
  background: var(--bg-color);
  color: #fff; }

.cmn-btn2_size_m {
  min-width: 120px;
  margin-top: 10px; }

.btn.check_complete,
.btn.check_complete:hover .btn.check_complete:focus {
  border: solid 1px #00a551 !important;
  color: #00a551;
  pointer-events: none;
  padding: 5px 30px;
  border-radius: 18px;
  background: #fff; }

.btn.check_complete span {
  text-align: left;
  display: block; }

.cab_doneList {
  border-top: solid 1px var(--bg-color);
  border-bottom: solid 1px var(--bg-color);
  margin: 10px 0;
  width: 100%;
  padding-top: 10px; }
  .cab_doneList li {
    width: 80%;
    border: solid 2px grey;
    border-radius: var(--b-radius);
    position: relative;
    padding: 8px;
    margin: 0 auto;
    font-size: 15px;
    margin-bottom: 10px;
    padding-right: 30px; }
  .cab_doneList li::after {
    position: absolute;
    right: 10px;
    font-family: "icons_me"; }
  .cab_doneList li.done {
    color: #00a551;
    border-color: #00a551; }
  .cab_doneList li.done::after {
    content: "\e04b"; }
  .cab_doneList li.notDone {
    color: #e91e1e;
    border-color: #e91e1e; }
  .cab_doneList li.notDone::after {
    content: "\e043"; }

.apli_info2 {
  width: 90%;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  .apli_info2 h5 {
    font-size: 16px; }

.res_info2D {
  font-size: 15px;
  padding: 0px 15px; }
  .res_info2D ul {
    margin: 30px 0; }
  .res_info2D ul li {
    margin-bottom: 5px; }

.bor_left_right {
  border-left: solid 1px var(--bg-color);
  border-right: solid 1px var(--bg-color); }

.ansRatngUl {
  width: 80%;
  margin-left: 20%; }
  .ansRatngUl li {
    display: flex;
    border: solid 1px var(--bg-color);
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    align-items: center;
    margin-bottom: 15px; }
  .ansRatngUl li .labelLit {
    width: 40%;
    padding-right: 10px; }
  .ansRatngUl li .answer_bar {
    width: 55%;
    padding-right: 30px; }
  .ansRatngUl li .answer_bar .bar1 {
    height: 20px;
    background: grey; }
  .ansRatngUl li .answer_bar .bar1.answer {
    background: #00a551; }
  .ansRatngUl li .answer_bar .bar1.total {
    background: #e91e1e; }
  .ansRatngUl li .labelLit {
    text-align: right;
    padding-right: 15px; }
  .ansRatngUl li .labelLit p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    word-break: break-all; }
  .ansRatngUl li .labelLit span {
    font-size: 15px; }
  .ansRatngUl li .iconEye span {
    color: var(--bg-color);
    font-size: 25px; }
  .ansRatngUl li.active {
    background: var(--bg-color);
    color: #fff; }
  .ansRatngUl li.active .iconEye span {
    color: #fff; }

.resInfo_row__ {
  border-bottom: solid 1px var(--bg-color);
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex; }
  .resInfo_row__ h4 {
    margin-top: 15px; }

.resColrow {
  padding: 15px 0; }

.col_100 {
  width: 130px; }

.resultColM {
  display: flex;
  /* align-items: center; */
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: dashed 1px #1e1e1e; }

.quesAns_box__ {
  width: calc(100% - 260px);
  border: solid 1px var(--bg-color);
  padding: 15px;
  border-radius: var(--b-radius);
  min-height: auto;
  background: #fff;
  padding-bottom: 10px; }
  .quesAns_box__ h4 {
    margin-bottom: 10px;
    margin-top: 15px; }

.AppL_Grop_Footer_set_ {
  background: #fff;
  border-radius: 5px;
  padding: 15px 0px; }
  .AppL_Grop_Footer_set_ .col-lg-6 {
    padding: 0px 15px 15px; }

.Left-Applicant_Answer {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%; }

.Single_Choice_div___ {
  border: 0px solid transparent;
  background: #ececec; }

.circQues {
  width: 90px;
  height: 90px;
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto; }
  .circQues.QuesNo__ {
    background: var(--bg-color);
    color: #fff;
    font-size: 35px;
    font-weight: 700; }
  .circQues.checkieRe:before {
    color: #fff;
    font-size: 40px;
    font-family: "icons_me";
    position: absolute; }
  .circQues.checkieRe.correct {
    background: #00a551; }
  .circQues.checkieRe.correct:before {
    content: "\47"; }
  .circQues.checkieRe.unCorrect {
    background: #e91e1e; }
  .circQues.checkieRe.unCorrect:before {
    content: "\6a";
    font-family: "hcm-ie";
    font-size: 30px; }

.qShwcse {
  margin-top: 0px; }
  .qShwcse p {
    font-size: 15px; }

.remove-after-before h4.mt-0 {
  margin-top: 0px; }

.queBxFoot {
  border-top: solid 1px var(--bg-color);
  padding: 10px 0;
  margin-top: 10px; }

.answrShw {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .answrShw li {
    position: relative;
    width: 100%;
    padding: 7px;
    padding-right: 50px;
    display: inline-block;
    margin-bottom: 10px;
    float: left; }
  .answrShw li span {
    font-size: 14px;
    display: inline;
    color: #696969; }
  .answrShw li b {
    display: inline-block;
    padding-right: 5px; }
  .answrShw li::after {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "icons_me";
    position: absolute;
    right: 15px;
    top: 7px; }
  .answrShw li.wrongAnswer::after {
    content: "\74";
    background: #e91e1e; }
  .answrShw li.rightAnswer::after {
    content: "\e04b";
    background: #00a551; }

.cmn_select_dv.dropDwnType2 .Select-control {
  background-color: #fff !important;
  border: solid 1px var(--bg-color) !important; }

.cmn_select_dv.dropDwnType2 .Select-control .Select-input > input {
  color: #000; }

.cmn_select_dv.dropDwnType2 .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.cmn_select_dv.dropDwnType2 .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #000 !important; }

.cmn_select_dv.dropDwnType2 .Select-arrow::before,
.cmn_select_dv.dropDwnType2 .box_wide .Select-arrow::before {
  color: #000 !important; }

.cmn_select_dv.dropDwnType2 .Select.is-focused:not(.is-open) > .Select-control,
.cmn_select_dv.dropDwnType2 .is-focused.is-open > .Select-control {
  background: #fff !important; }

.cmn_select_dv.dropDwnType2 .Select-placeholder {
  color: #000 !important; }

.cmn_select_dv.dropDwnType2 .Select.is-focused.is-open > .Select-control .Select-placeholder,
.cmn_select_dv.dropDwnType2 .Select.is-focused:not(.is-open) > .Select-control .Select-placeholder {
  color: var(--bg-color); }

.cmn_select_dv.dropDwnType2 .Select-arrow,
.cmn_select_dv.dropDwnType2 .Select.is-open > .Select-control .Select-arrow {
  border-color: var(--bg-color) transparent transparent !important; }

.cmn_select_dv.dropDwnType2 .Select-control .Select-input:focus {
  background: #fff !important; }

.cmn_select_dv.dropDwnType2 .Select-clear {
  color: #000; }

.cmn_select_dv.star_slct.Select--multi {
  z-index: 100; }

.cmn_select_dv.star_slct .Select-menu-outer {
  background: var(--light-color-background);
  /* padding: 15px 0; */ }

.cmn_select_dv.star_slct .Select-menu-outer .Select-menu {
  padding: 15px 0; }

.cmn_select_dv.star_slct .Select-menu-outer .Select-option {
  background: var(--light-color-background);
  padding: 2px 10px; }

.cmn_select_dv.star_slct .Select-arrow {
  border-width: 0 0 0 !important; }

.cmn_select_dv.star_slct .Select.is-open > .Select-control .Select-arrow {
  border-width: 0 0 0 !important;
  transform: rotate(0); }

.cmn_select_dv.star_slct .Select-arrow::before {
  content: "\e07e";
  font-family: "icons_me";
  display: block;
  position: absolute;
  top: -12px;
  right: 0px; }

.cmn_select_dv.star_slct .Select.is-open > .Select-control .Select-arrow:before {
  top: -9px; }

.cmn_select_dv.star_slct .Select-arrow::before,
.cmn_select_dv.star_slct .Select-arrow::before,
.cmn_select_dv.star_slct .Select.is-open > .Select-control .Select-arrow::before {
  content: "\e07e";
  font-family: "icons_me"; }

.cmn_select_dv.star_slct.Select--multi .Select-value {
  border: 0px solid #fff;
  margin-top: 0px;
  background: none; }

.cmn_select_dv.star_slct .Select--multi .Select-multi-value-wrapper {
  vertical-align: -webkit-baseline-middle; }

.cmn_select_dv.star_slct span.Select-value-icon {
  color: #fff;
  border: 0px solid #fff; }

.cmn_select_dv.star_slct .Select--multi .Select-value-icon {
  padding-right: 0px; }

.cmn_select_dv.star_slct .Per_Select {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.cmn_select_dv.star_slct .Per_txt {
  display: inline-block;
  background: #fff;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  color: #1e1e1e;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid var(--bg-color); }

.cmn_select_dv.star_slct .Per_icon i {
  font-size: 20px; }

.cmn_select_dv.star_slct .Per_icon {
  display: inline-flex;
  align-items: center;
  line-height: initial;
  margin-left: 5px; }

.cmn_select_dv.star_slct .Select-menu-outer .Per_Select:hover .Per_txt {
  border: 1px solid var(--drk-color4); }

.cmn_select_dv.star_slct .Select-menu-outer .Per_Select:hover .Per_icon i {
  color: yellow; }

.cmn_select_dv.star_slct .Per_Select {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.cmn_select_dv.star_slct .Select--multi .Select-multi-value-wrapper {
  margin-top: 5px; }

.cmn_select_dv.sl_center .Select-control .Select-value .Select-value-label {
  justify-content: flex-start; }

.cmn_select_dv.sl_center .Select-control .Select-placeholder {
  text-align: left; }

.cmn_select_dv.slct_des23 .Select-menu {
  background: var(--light-color-background) !important;
  padding: 10px;
  border: solid 1px var(--b-color); }

.cmn_select_dv.slct_des23 .Select-option {
  text-align: center;
  margin-bottom: 10px;
  border: solid 1px var(--b-color);
  color: var(--b-color);
  padding: 4px 10px;
  border-radius: var(--b-radius); }

.cmn_select_dv.slct_des23 .Select-option.is-focused,
.cmn_select_dv.slct_des23 .Select-menu-outer div:hover {
  background: var(--b-color);
  color: #fff; }

.cmn_select_dv.slct_des23 .Select-menu-outer:before,
.cmn_select_dv.slct_des23 .Select-menu-outer::after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute; }

.cmn_select_dv.slct_des23 .Select-menu-outer:before {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--b-color) transparent;
  top: -10px;
  right: 7px; }

.cmn_select_dv.slct_des23 .Select-menu-outer:after {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--light-color-background) transparent;
  top: -9px;
  right: 7px; }

.cmn_select_dv.slct_des23 .Select-menu-outer {
  margin-top: 12px;
  overflow: visible !important; }

.cmn_select_dv.srch_select12 div.Select .Select-control,
.cmn_select_dv.srch_select12 div.Select.is-focused .Select-control,
.cmn_select_dv.srch_select12 div.Select.is-open .Select-control,
.cmn_select_dv.srch_select12 div.Select.is-focused.is-open .Select-control {
  background: #fff;
  border: solid 1px #000; }

.cmn_select_dv.srch_select12 div.Select .Select-control .Select-placeholder {
  color: #000 !important;
  text-align: left;
  padding-left: 15px; }

.cmn_select_dv.srch_select12 div.Select .Select-control .Select-value-label {
  color: #000 !important;
  text-align: left;
  justify-content: left;
  padding-left: 15px; }

.cmn_select_dv.srch_select12 .Select-arrow::before,
.cmn_select_dv.srch_select12 .box_wide .Select-arrow::before,
.cmn_select_dv.srch_select12 div.Select.is-focused.is-open .Select-arrow::before {
  content: "\e0b2";
  font-family: "icons_me" !important;
  color: #000; }

.cmn_select_dv.srch_select12.loc_sel .Select-arrow::before,
.cmn_select_dv.srch_select12.loc_sel .box_wide .Select-arrow::before,
.cmn_select_dv.srch_select12.loc_sel div.Select.is-focused.is-open .Select-arrow::before {
  content: "\e095";
  font-family: "icons_me" !important;
  color: var(--bg-color); }

.cmn_select_dv.srch_select12.loc_sel .Select-control .Select-arrow {
  top: 14px !important; }

.cmn_select_dv.srch_select12.loc_sel .Select-input {
  padding-left: 15px !important; }

.cmn_select_dv .Select-input {
  width: 100%; }
  .cmn_select_dv .Select-input > input {
    width: 100% !important; }

.training_info1 > .trngBoxAc > .row {
  display: flex;
  flex-wrap: wrap; }

.trngBoxAc {
  padding-top: 15px;
  border-top: solid 1px #bfbfbf; }
  .trngBoxAc h4 {
    margin: 10px 0; }

.accFootRow1 {
  border-top: solid 1px #bfbfbf;
  padding: 10px 0;
  margin-top: 15px; }
  .accFootRow1 h4 {
    margin-top: 5px;
    font-size: 16px; }

.QId {
  margin-top: 5px; }

.pd_b_20 {
  padding-bottom: 20px; }

.mr_b_20 {
  margin-bottom: 20px; }

.pd_l_15 {
  padding-left: 15px; }

.radio_form_control1_ [type="radio"]:checked, .radio_form_control1_ [type="radio"]:not(:checked) {
  position: absolute;
  /* left: 0px; */
  opacity: 0;
  width: 40px;
  height: 40px; }

.radio_form_control1_ [type="radio"]:checked + label,
.radio_form_control1_ [type="radio"]:not(:checked) + label,
.radio_form_control1_ [type="radio"]:checked + div + label,
.radio_form_control1_ [type="radio"]:not(:checked) + div + label {
  cursor: pointer;
  display: inline-block; }

.radio_form_control1_ [type="radio"]:checked + label:before,
.radio_form_control1_ [type="radio"]:not(:checked) + label:before,
.radio_form_control1_ [type="radio"]:checked + div + label:before,
.radio_form_control1_ [type="radio"]:not(:checked) + div + label:before {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-family: "icons_me";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  background-color: #00a551;
  content: "\e04b"; }

.radio_form_control1_ [type="radio"]:not(:checked) + label:before,
.radio_form_control1_ [type="radio"]:not(:checked) + div + label:before {
  opacity: 0.5; }

.radio_form_control1_ [type="radio"]:checked + label:before,
.radio_form_control1_ [type="radio"]:checked + div + label:before {
  opacity: 1; }

.radio_form_control1_ [type="radio"]:checked, .radio_form_control1_ [type="radio"]:not(:checked) {
  position: absolute;
  /* left: 0px; */
  opacity: 0;
  width: 40px;
  height: 40px; }

.radio_form_control1_ [type="checkbox"]:checked + label,
.radio_form_control1_ [type="checkbox"]:not(:checked) + label,
.radio_form_control1_ [type="checkbox"]:checked + div + label,
.radio_form_control1_ [type="checkbox"]:not(:checked) + div + label {
  cursor: pointer;
  display: inline-block; }

.radio_form_control1_ [type="checkbox"]:checked + label:before,
.radio_form_control1_ [type="checkbox"]:not(:checked) + label:before,
.radio_form_control1_ [type="checkbox"]:checked + div + label:before,
.radio_form_control1_ [type="checkbox"]:not(:checked) + div + label:before {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-family: "icons_me";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  background-color: #00a551;
  content: "\e04b"; }

.radio_form_control1_ [type="checkbox"]:not(:checked) + label:before,
.radio_form_control1_ [type="checkbox"]:not(:checked) + div + label:before {
  opacity: 0.5; }

.radio_form_control1_ [type="checkbox"]:checked + label:before,
.radio_form_control1_ [type="checkbox"]:checked + div + label:before {
  opacity: 1; }

.mr_t_30 {
  margin-top: 30px; }

.ques_col {
  position: relative; }
  .ques_col:after {
    position: absolute;
    height: 100%;
    width: 1px;
    content: "";
    right: 20%;
    opacity: 0.5; }

.trnMod_Foot__ {
  margin-top: 15px;
  padding: 15px 0;
  border-top: solid 2px var(--bg-color); }

.trnMod_Foot__.disFoot1__ {
  border-top: solid 2px var(--bg-color); }

.create_quesBtn {
  padding: 10px 30px; }

.profDet_lSe {
  float: left;
  width: 95%; }

.prof_left {
  float: left;
  width: 150px;
  padding-right: 15px;
  text-align: center; }
  .prof_left .profImg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 15px;
    margin: 0 auto;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .prof_left .profImg img {
    width: 100%;
    height: auto;
    min-height: 100%; }

.edt_btn {
  padding: 5px 20px; }

.prof_right {
  float: right;
  width: calc(100% - 160px);
  padding: 0 15px;
  word-break: break-all; }
  .prof_right div {
    font-weight: normal;
    font-size: 13px; }
  .prof_right div b {
    word-break: break-all;
    display: inline-block; }

.cntBxiE h5 {
  margin-bottom: 0;
  margin-top: 20px; }

.usName {
  margin-top: 0;
  margin-bottom: 10px; }

.hd_para {
  font-size: 16px;
  line-height: inherit; }

.cstmSelect2 {
  position: relative;
  width: 100%;
  margin-bottom: 15px; }

.optSelected_cS {
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  background: var(--bg-color);
  cursor: pointer;
  color: #fff;
  border-radius: var(--b-radius);
  padding: 5px 15px;
  padding-right: 30px; }
  .optSelected_cS span {
    display: inline-block; }
  .optSelected_cS::after {
    position: absolute;
    right: 15px;
    content: "\e07e";
    font-family: "icons_me";
    font-size: 17px; }

.cstmSelect2_optionsBox {
  width: 200px;
  background: #9bdef8;
  padding: 15px;
  min-height: 200px;
  border-radius: 10px;
  position: absolute;
  left: calc(100% + 20px);
  margin-top: -60px;
  display: none;
  z-index: 200; }
  .cstmSelect2_optionsBox p {
    font-size: 16px; }
  .cstmSelect2_optionsBox.show {
    display: block; }
  .cstmSelect2_optionsBox::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #9bdef8 transparent transparent;
    left: -10px;
    margin-top: 14px; }

.csTm2Slct_options li {
  position: relative;
  width: calc(100% - 40px);
  border: solid 1px var(--bg-color);
  border-radius: var(--b-radius);
  background: #fff;
  padding: 3px 10px;
  font-size: 12px;
  margin-bottom: 3px;
  cursor: pointer; }
  .csTm2Slct_options li::after {
    position: absolute;
    content: "\e013";
    font-family: "icons_me";
    right: -25px;
    color: grey;
    font-size: 14px;
    top: 0; }
  .csTm2Slct_options li.active, .csTm2Slct_options li:hover {
    background: var(--bg-color);
    color: #fff; }
  .csTm2Slct_options li:hover::after, .csTm2Slct_options li.active::after {
    color: #ffd900; }

.pd_l_30 {
  padding-left: 40px; }

.usrMngBox {
  position: relative; }

.usrMngBox::before {
  width: 1px;
  height: calc(100% - 80px);
  content: "";
  position: absolute;
  left: 39%;
  background: #bfbfbf; }

.tskRgHeads h2 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 22px; }

.tskRgHeads h3 {
  font-size: 17px;
  margin-top: 0; }

.avlblty_list__ {
  margin: 10px 0;
  width: 100%; }
  .avlblty_list__ .avlblty_box {
    border: solid 1px var(--bg-color);
    padding: 15px;
    border-radius: 10px;
    background: #fff;
    margin-bottom: 15px;
    font-size: 13px; }
  .avlblty_list__ .avlblty_box ul {
    margin-bottom: 0; }

.recruitment_module label {
  font-weight: 600 !important;
  font-size: 14px !important; }

.recruitment_module div.ReactTable .-pagination .-btn {
  color: #fff; }

.recruitment_module .btn_AddFld_cmn {
  background: transparent;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  right: 6px !important;
  top: 6px !important; }
  .recruitment_module .btn_AddFld_cmn i {
    color: var(--bg-color);
    height: 30px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; }
  .recruitment_module .btn_AddFld_cmn .icn_add {
    font-size: 30px; }
  .recruitment_module .btn_AddFld_cmn i.icn_remove {
    font-size: 22px; }

.recruitment_module input.checkbox1[type="checkbox"] + label span {
  border: solid 1px var(--bg-color); }

.recruitment_module input.checkbox1[type="checkbox"]:checked + label span {
  background: var(--bg-color);
  color: var(--bg-color); }

.recruitment_module .modify_select .Select-control,
.recruitment_module .modify_select div.Select.is-open > .Select-control {
  border: solid 1px var(--bg-color);
  height: 40px;
  cursor: text; }

.recruitment_module .modify_select .Select-control .Select-multi-value-wrapper .Select-input input {
  color: #000;
  font-weight: normal;
  height: 40px; }

.recruitment_module .modify_select .Select-control .Select-arrow {
  color: var(--bg-color); }

.recruitment_module .Select-control .Select-value {
  height: 40px; }

.recruitment_module input[type="radio"] {
  -webkit-appearance: radio;
  appearance: radio;
  width: inherit;
  height: inherit;
  position: absolute; }

.recruitment_module .slct_clr2 .Select-control .Select-arrow:before,
.recruitment_module .slct_clr2.modify_select .Select-control .Select-arrow:before {
  color: #000 !important; }

.recruitment_module .slct_clr2 div.Select.Select--single .Select-control,
.recruitment_module .slct_clr2 div.Select.is-focused > .Select-control,
.recruitment_module .slct_clr2 .recruitment_module .slct_clr2 div.Select.is-open > .Select-control,
.recruitment_module .slct_clr2 div.Select.is-focused:not(.is-opne) > .Select-control,
.recruitment_module .slct_clr2 .Select-control,
.recruitment_module .slct_clr2 div.Select.is-open > .Select-control {
  border: solid 1px #000; }

.recruitment_module label.fs_16 {
  font-size: 14px !important; }

.recruitment_module .Select--multi .Select-value {
  background-color: transparent;
  border: 1px solid transparent; }
  .recruitment_module .Select--multi .Select-value .Select-value-icon {
    float: right;
    color: #fff;
    border-right: solid 1px #fff; }
  .recruitment_module .Select--multi .Select-value .Select-control .Select-value {
    height: auto; }
  .recruitment_module .Select--multi .Select-value .Select-value-icon:hover {
    color: #fff; }

.recruitment_module .weekend_bg-color__ .weekend-day {
  background: var(--light-color-background); }

.recruitment_module .weekend_bg-color__ .weekend-day.rbc-off-range-bg::after {
  background: transparent; }

.recruitment_module .weekend_bg-color__ .weekend-day-header {
  background: var(--light-color-background);
  color: #000 !important; }

.recruitment_module .weekend_bg-color__ .rbc-date-cell {
  font-size: 12px; }

.recruitment_module .weekend_bg-color__ .stf_calHdr .icon {
  cursor: pointer;
  color: var(--b-color); }

.recruitment_module .weekend_bg-color__ .stf_calHdr .rbc-toolbar-label {
  font-size: 27px; }

.recruitment_module .rbc-date-cell {
  padding-right: 0; }

.recruitment_module .mt-3 {
  margin-top: 30px; }

.recruitment_module .mt-2 {
  margin-top: 20px; }

.recruitment_module .mt-1 {
  margin-top: 10px; }

.recruitment_module .mb-3 {
  margin-bottom: 30px; }

.recruitment_module .completed_priority {
  color: #05adee54; }

.recruitment_module .rbc-event:focus {
  outline: 0px; }

.recruitment_module .rbc-event {
  cursor: default; }

.recruitment_module input {
  height: auto; }

.recruitment_module .Manage_li_ label {
  margin: 0px;
  height: 100%;
  align-items: center;
  display: inline-flex; }

.pad_l_50Ts {
  padding: 0px 10%; }

label.bg_labs {
  font-size: 20px;
  margin-top: 15px; }

.tskModRow1 {
  position: relative; }

.tskModRow1::before {
  width: 1px;
  height: 100%;
  position: absolute;
  background: var(--bg-color);
  left: calc(50% + 30px);
  content: ""; }

.btn.snd_iniBtn {
  padding: 7px 30px; }

.inviRow {
  margin-top: 15px;
  padding-top: 30px;
  margin-bottom: 15px; }

.openingInfo {
  position: relative; }

.openingInfo::before {
  width: 100%;
  height: 1px;
  background: var(--bg-color);
  content: "";
  position: absolute;
  left: 0; }

.crJoBtn_Col__ .btn {
  margin-bottom: 15px;
  padding: 10px 25px;
  font-size: 12px;
  width: auto;
  height: auto; }

.mr_tp_20 {
  margin-top: 20px; }

/* .sidebar.show{} */
.myRow {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: all ease 5s; }
  .myRow.active {
    display: block;
    transition: all ease 5s;
    visibility: visible;
    height: auto; }
  .myRow p {
    margin-top: 150px;
    opacity: 0.2;
    transition: all ease 1s; }
  .myRow.active p {
    margin-top: 0px;
    opacity: 1; }
  .myRow img {
    opacity: 1;
    transition: all ease 4s; }
  .myRow.active img {
    opacity: 1; }

.slideBox {
  height: 400px;
  overflow: hidden; }
  .slideBox .slides {
    height: 90%;
    opacity: 0.5; }
  .slideBox .slides.active {
    opacity: 1; }

.icon-arrow-red-left:before {
  content: "p" !important;
  top: 0; }

input[type="button"] {
  width: auto !important; }

.RecruitBlue {
  color: var(--bg-color); }

.Cust_Sel_2 .rrs {
  position: relative;
  box-sizing: border-box; }

.Cust_Sel_2 .rrs *,
.Cust_Sel_2 .rrs *:before,
.Cust_Sel_2 .rrs *:after {
  box-sizing: border-box; }

.Cust_Sel_2 .rrs__button {
  position: relative;
  cursor: pointer;
  height: 30;
  line-height: 30px;
  background: var(--bg-color);
  border-radius: 30px; }

.Cust_Sel_2 .rrs__button--disabled {
  color: #999999;
  background: #f5f5f5;
  cursor: default; }

.Cust_Sel_2 .rrs__button:focus {
  outline: 0; }

.Cust_Sel_2 .rrs__button + .rrs__options {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fbf1ee;
  position: absolute;
  z-index: 2;
  top: 40px;
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden; }

.Cust_Sel_2 .rrs--options-visible .rrs__options {
  height: auto;
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 305px;
  padding-bottom: 10px; }

.Cust_Sel_2 .rrs__option {
  cursor: pointer;
  padding: 2px 15px;
  margin: 0; }

.Cust_Sel_2 .rrs__option * {
  pointer-events: none; }

.Cust_Sel_2 .rrs__option.rrs__option--disabled {
  color: #999999;
  background: #f5f5f5;
  cursor: default; }

.Cust_Sel_2 .rrs__option.rrs__option--header {
  color: #1e1e1e;
  cursor: default;
  font-size: 17px;
  font-weight: 400;
  background: #fbf1ee;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid;
  border-color: var(--bg-color);
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px; }

.Cust_Sel_2 .rrs__label {
  padding: 5px 15px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  font-size: inherit;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius); }

.Cust_Sel_2 .rrs__multiselect-label {
  display: inline-flex;
  max-width: 100%;
  line-height: 1; }

.Cust_Sel_2 .rrs__multiselect-label__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 13px; }

.Cust_Sel_2 .rrs__multiselect-label__badge {
  border: 1px solid #1e1e1e;
  padding: 1px 6px;
  margin: 0 0 0 4px;
  border-radius: 8px;
  color: #1e1e1e;
  font-size: 11px;
  vertical-align: middle;
  display: inline-block;
  background: #fff; }

.Cust_Sel_2 .rrs .checkbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  top: -1px;
  margin: 2px 0px 2px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 18px; }

.Cust_Sel_2 .rrs .checkbox-icon {
  position: absolute;
  left: 2px;
  top: 2px; }

.Cust_Sel_2 .rrs__option.rrs__option--selected .checkbox i {
  color: #ffd707; }

.Cust_Sel_2 .rrs .caret-icon {
  position: absolute;
  right: 1rem;
  top: 1.25rem; }

.Cust_Sel_2 .rrs__option div {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.Cust_Sel_2 .rrs_select {
  background: #fff;
  padding: 4px 12px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  width: 82%;
  border: 1px solid;
  border-color: var(--bg-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.Cust_Sel_2 .rrs__option.rrs__option--selected .rrs_select {
  background: var(--bg-color);
  color: #fff;
  border-color: #fff;
  border-radius: 30px; }

.Cust_Sel_2 .rrs__label__text {
  overflow: hidden; }

.Cust_Sel_2 .rrs--options-visible .rrs__button::after {
  content: "";
  border-right: 9px solid transparent;
  border-bottom: 13px solid #fbf1ee;
  width: 14px;
  height: 44px;
  display: inline-block;
  border-left: 10px solid transparent;
  position: absolute;
  top: 0;
  right: 15px; }

.Cust_Sel_2 .rrs--options-visible .caret-icon {
  transform: rotate(180deg); }

.Cust_Sel_2.star_slct .rrs__label {
  align-items: center; }

.radio_form_control1_ {
  display: inline-block;
  position: relative;
  min-width: 130px; }
  .radio_form_control1_ input {
    width: auto;
    visibility: hidden;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%); }

.ad_dv {
  position: relative;
  margin-bottom: 5px; }

.positn_drps {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px; }
  .positn_drps .bigSlct {
    width: calc(100% - 50px); }

.add_bgSl__ {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 35px;
  cursor: pointer;
  color: var(--bg-color); }

.awrd_row1__ {
  border-bottom: solid 1px var(--bg-color);
  padding-bottom: 15px; }

.profile_pic_boxUs {
  width: 100px;
  height: 100px;
  position: relative; }
  .profile_pic_boxUs .prof_picUs {
    width: 100%;
    height: 100%;
    background: grey;
    border-radius: 50%;
    overflow: hidden; }
  .profile_pic_boxUs .prof_picUs img {
    width: 100%;
    height: auto;
    min-height: 100%; }

.edt_icn {
  width: 30px;
  height: 30px;
  background: var(--bg-color);
  color: #fff;
  border: solid 1px var(--drk-color4);
  display: flex;
  border-radius: 50%;
  align-items: center;
  position: absolute;
  top: calc(50% - 15px);
  right: -15px;
  font-size: 14px;
  justify-content: center;
  cursor: pointer; }

.profModal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 100; }
  .profModal.show {
    display: flex !important; }
  .profModal .profmodal_dialog {
    background: #fff;
    width: 500px;
    height: auto;
    padding: 15px;
    position: relative;
    margin: 15px auto; }
  .profModal .profmodal_dialog img {
    width: 100%; }
  .profModal .ReactCrop__crop-selection {
    border-radius: 50%; }

.upldPc_spn {
  display: inline-block;
  padding: 10px 25px;
  background: var(--bg-color);
  margin-top: 10px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: center; }

.cl_prfMod {
  color: #fff;
  font-size: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer; }

/* editor csss */
.cstmEditor.bigHg .cke_chrome .cke_contents {
  height: 400px !important;
  padding-bottom: 45px !important; }

.cstmEditor .cke_chrome {
  border: solid 1px #1e1e1e;
  overflow: hidden;
  border-radius: var(--b-radius);
  position: relative; }

.cstmEditor .cke_toolbar_break {
  display: none !important; }

.cstmEditor .cke_chrome .cke_top {
  position: absolute !important;
  width: 100% !important;
  left: 0 !important;
  bottom: 25px !important;
  background: #ffffff;
  border-top: solid 1px var(--bg-color); }

.cstmEditor .cke_button__cut,
.cstmEditor .cke_button__copy,
.cstmEditor .cke_button__paste,
.cstmEditor .cke_button__pastetext,
.cstmEditor .cke_button__pastefromword,
.cstmEditor .cke_toolbar_separator,
.cstmEditor .cke_button__undo,
.cstmEditor .cke_button__redo,
.cstmEditor .cke_button__scayt,
.cstmEditor .cke_button__unlink,
.cstmEditor .cke_button__anchor,
.cstmEditor .cke_button__table,
.cstmEditor .cke_button__horizontalrule,
.cstmEditor .cke_button__specialchar,
.cstmEditor .cke_button__maximize,
.cstmEditor .cke_button__source,
.cstmEditor .cke_button__removeformat,
.cstmEditor .cke_button__outdent,
.cstmEditor .cke_button__indent,
.cstmEditor .cke_button__blockquote,
.cstmEditor .cke_combo__format,
.cstmEditor .cke_toolbar_last,
.cstmEditor .cke_combo:after {
  display: none; }

.main_dv {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: solid 13px #723077; }

.percBreakdown {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #993da8;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  color: #fff;
  position: relative;
  cursor: pointer; }
  .percBreakdown:hover {
    background: #8e379c; }

.cat1 {
  background: #b867c6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: relative;
  z-index: 100; }
  .cat1:hover {
    background: #d08ddc; }

.hvrDv {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #993da8;
  z-index: 2;
  border-radius: 50%;
  visibility: hidden; }

.cat1:hover + .hvrDv {
  visibility: visible; }

.cat1 .unusedCat {
  position: absolute;
  text-align: center; }

.slideRange {
  padding: 0px !important; }

.hidee {
  display: none; }

.trvw {
  font-size: 12px; }

.update_icDp {
  font-size: 25px;
  color: #72d670;
  display: inline-block;
  height: 25px; }

.mail_icDp {
  font-size: 25px;
  color: #d21818;
  display: inline-block;
  height: 25px; }

.depart_info__ {
  background-color: #fff;
  min-height: 200px;
  padding: 0 15px;
  z-index: 0;
  position: relative;
  border-radius: 0px 0px var(--b-radius) var(--b-radius); }

.departFoot_1__ {
  padding: 7px 0;
  border-top: solid 1px var(--bg-color); }

.lineCmn {
  width: 100%;
  height: 1px;
  background: var(--bg-color); }

.dprt_hdng1 {
  margin-top: 10px; }

label.bg_labs2 {
  font-size: 18px !important;
  font-weight: normal !important; }

.mr_tb_20 {
  margin: 20px 0; }

.wd_250 {
  width: 250px !important; }

.mr_tb_40 {
  margin: 40px 0; }

.dashedLine_1__ {
  border-top: dashed 2px var(--bg-color); }

.pd_lr_30 {
  padding-left: 30px;
  padding-right: 30px; }

.pd_lr_15 {
  padding-left: 15px;
  padding-right: 15px; }

.Analytics_box1__ {
  background: #fff;
  padding: 15px;
  height: 100%;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 15px; }

.showCase_amnt {
  margin-top: 15px;
  width: 60%;
  margin: 0 auto; }
  .showCase_amnt h1 {
    font-size: 80px;
    line-height: 80px; }
  .showCase_amnt h6 {
    min-height: 40px; }

.task_dv {
  min-height: 250px;
  margin-top: 15px; }

.successBg_popUp {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  padding: 15px;
  display: none; }

.popUp_bx_1 {
  background: #40b27bdb;
  position: relative;
  border-radius: 15px;
  padding: 15px;
  color: #fff;
  width: 500px;
  text-align: center;
  max-width: 100%;
  margin: 80px auto; }
  .popUp_bx_1 .popMsg {
    font-weight: normal; }
  .popUp_bx_1 .aprv_ic {
    font-size: 70px;
    line-height: 0;
    margin: 20px 0;
    display: inline-block; }

.mr_b_15 {
  margin-bottom: 15px; }

.editCabTable1 {
  margin: 15px 0; }

.removeAppli_ic {
  font-size: 18px;
  color: var(--bg-color);
  cursor: pointer; }

.cmnScrollBar__ .ReactTable .rt-tbody::-webkit-scrollbar-track {
  background-color: #8e8b8b59;
  position: absolute; }

.cmnScrollBar__ .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5; }

.cmnScrollBar__ .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px; }

.stats_update_list.cmnScrollBar__ .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 3px; }

.cmnDivScrollBar__::-webkit-scrollbar-track {
  background-color: #8e8b8b59;
  position: absolute; }

.cmnDivScrollBar__::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5; }

.cmnDivScrollBar__::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px; }

.cmnDivScrollBar__.prList_sclBar::-webkit-scrollbar {
  width: 15px;
  background-color: var(--light-color-background);
  border-radius: 30px; }

.cmnDivScrollBar__.prList_sclBar::-webkit-scrollbar-track {
  background-color: transparent;
  position: absolute;
  border-radius: 30px; }

.stats_update_list.cmnDivScrollBar__::-webkit-scrollbar {
  width: 3px; }

.SrchFiltTable__ .ReactTable .rt-thead.-header {
  display: none; }

.SrchFiltTable__ .ReactTable .rt-tr .rt-td {
  position: relative; }

.ADd_btn_cstie {
  background: var(--bg-color);
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  padding: 10px;
  left: 0;
  border-radius: 0px 30px 30px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.resume_frame {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #fff; }

.template1 {
  width: 100%;
  background: #fff;
  position: relative; }
  .template1 .left_part_yl {
    height: 100%;
    width: 150px;
    background: #f8931d;
    position: absolute;
    left: 0;
    top: 0; }
  .template1 .content_mn_tble {
    width: 100%; }
  .template1 .right_part_wh {
    width: 100%; }
  .template1 .content_main {
    padding: 15px 50px;
    min-height: 900px; }
    .template1 .content_main ul li,
    .template1 .content_main ol li,
    .template1 .content_main p {
      word-break: break-word; }
  .template1 .logo_img {
    width: 50%;
    margin: 0px auto;
    display: block;
    visibility: hidden;
    padding-top: 15px; }
  .template1 .content_main ul li {
    font-size: 14px;
    line-height: 24px; }
  .template1 .footer_gr {
    background: #3D3D3D;
    padding: 65px 50px; }
  .template1 .foot_logo {
    text-align: center;
    padding: 0px; }
  .template1 .foot_logo img {
    width: 80%; }
  .template1 .footer_gr .mn_tble {
    width: 100%; }
  .template1 .site_lnk {
    text-align: center; }
  .template1 .site_lnk a {
    color: #fff; }

.socio_link li {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 15px; }
  .socio_link li img {
    width: 100%;
    height: auto;
    min-height: 100%; }
  .socio_link li a {
    color: #fff;
    transition: all .3s ease; }
    .socio_link li a svg {
      width: 35px;
      height: 35px;
      fill: #fff;
      transition: all .3s ease; }
    .socio_link li a:hover {
      color: #F37021; }
      .socio_link li a:hover svg {
        fill: #F37021; }

.hdng_1 {
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 30px; }

.FlaggedRow {
  text-align: center;
  padding: 60px 30px; }
  .FlaggedRow i {
    color: #f51515;
    font-size: 90px; }

.notes_panel .panel {
  overflow: hidden; }

.notes_panel .panel-heading {
  background: var(--bg-color);
  color: #fff;
  font-size: 13px; }

.notes_panel .panel-body {
  background-color: #e9e9e9; }

.notes_panel .panel-body h4 {
  font-size: 15px;
  margin-bottom: 10px; }

.notes_panel .panel-body .msgBody {
  background: #fff;
  border-radius: 24px;
  padding: 15px;
  font-size: 14px; }

.notes_panel .panel_cstm {
  margin-bottom: 15px; }

.notes_panel.ipadPanelGroup .panel-body {
  background: #fff; }

.notes_Area__ {
  max-height: 400px;
  overflow-y: auto; }

.pd_lf_15 {
  padding-left: 15px;
  padding-right: 15px; }

.fileAtch_box__ {
  /* border: solid 1px var(--bg-color); */
  padding: 10px;
  border-radius: var(--b-radius);
  background: #fff; }
  .fileAtch_box__ label {
    font-weight: normal !important; }
  .fileAtch_box__ .fle_ic {
    width: 48px;
    display: inline-block;
    font-size: 45px;
    line-height: 0;
    color: var(--bg-color); }
  .fileAtch_box__ .fle_nme__ {
    height: auto;
    word-break: break-all;
    font-size: 13px;
    padding: 0px 15px;
    box-sizing: border-box;
    width: 100%; }
  .fileAtch_box__ .fle_close__ {
    font-size: 20px;
    line-height: 0;
    /* margin-top: -20px; */
    color: var(--bg-color);
    cursor: pointer;
    align-self: flex-start; }

.flexWrap {
  flex-wrap: wrap; }

.mr_tb_10 {
  margin-top: 10px;
  margin-bottom: 10px; }

label.bg_labs3 {
  font-size: 16px !important;
  font-weight: normal !important; }

.ans_colTr {
  padding-left: 45px; }

.singleAnswer__ {
  margin-top: 30px;
  margin-bottom: 15px; }
  .singleAnswer__ .Seek_Q_ul .Seek_Q_li {
    width: 100%;
    justify-content: space-between; }
  .singleAnswer__ .Seek_Q_ul .Seek_Q_li > div {
    display: flex; }
  .singleAnswer__ .Seek_Q_ul .Seek_Q_li > div b {
    margin-right: 10px; }

.CABinfo_lstBox__ {
  font-size: 13px;
  padding: 10px 30px; }
  .CABinfo_lstBox__ > div {
    margin-bottom: 10px; }

.edt_ipad {
  padding: 8px 30px; }

.ipad_hdng135__ {
  padding-right: 30px;
  font-size: 30px;
  font-weight: 700; }

.ipdBody_cnt {
  font-size: 15px;
  line-height: 20px; }

.mr_t_15 {
  margin-top: 15px; }

.ipadPanelGroup .ipadPanel {
  opacity: 0.5;
  cursor: pointer;
  border: solid 1px var(--bg-color);
  height: calc(100% - 15px); }
  .ipadPanelGroup .ipadPanel.active {
    opacity: 1; }

.pd_lr_50 {
  padding-left: 50px;
  padding-right: 50px; }

.capsule_radio {
  border-radius: 30px;
  display: flex;
  border: solid 1px var(--bg-color);
  width: 200px;
  background: #fff;
  overflow: hidden; }
  .capsule_radio label {
    width: 50%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    cursor: pointer; }
  .capsule_radio label div.checkieName {
    padding: 10px 15px;
    width: 100%;
    text-align: center;
    color: var(--bg-color);
    font-weight: normal;
    opacity: 0.5; }
  .capsule_radio label input[type="radio"] {
    visibility: hidden;
    width: 0;
    height: 0; }
  .capsule_radio label input[type="radio"]:checked + div.checkieName,
  .capsule_radio label input[type="radio"]:checked + div + div.checkieName {
    background-color: var(--bg-color);
    color: #fff;
    opacity: 1; }

.cabDayPanel_tab {
  max-height: 300px;
  overflow-y: auto; }

.cabDAy_Panel {
  display: flex;
  align-items: center;
  border-radius: var(--b-radius);
  border: solid 1px #bdbdbd;
  background: #fff;
  margin-bottom: 15px;
  color: #bdbdbd;
  cursor: pointer; }
  .cabDAy_Panel.active, .cabDAy_Panel:hover {
    border: solid 1px var(--bg-color);
    color: #000; }
  .cabDAy_Panel div {
    text-align: center;
    border-right: 1px solid #c1c1c1;
    padding: 10px 0; }
  .cabDAy_Panel div:last-child {
    border-right: none; }
  .cabDAy_Panel div:nth-child(1),
  .cabDAy_Panel .dabDayPanel_header div:nth-child(1) {
    width: 38%; }
  .cabDAy_Panel div:nth-child(2),
  .cabDAy_Panel .dabDayPanel_header div:nth-child(2) {
    width: 24%; }
  .cabDAy_Panel div:nth-child(3),
  .cabDAy_Panel .dabDayPanel_header div:nth-child(3) {
    width: 38%; }

.dabDayPanel_header {
  display: flex;
  font-size: 15px; }

.cstmBar_chart {
  height: 260px;
  border-bottom: 1px solid var(--bg-color);
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
  justify-content: center;
  overflow: hidden;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px; }
  .cstmBar_chart .bars {
    flex: 1;
    opacity: 0.7;
    z-index: 0;
    max-height: 100%;
    cursor: pointer;
    min-height: 5px;
    position: relative; }
  .cstmBar_chart .bars:not(:nth-child(1)) {
    margin-left: -15px; }
  .cstmBar_chart .bars:hover {
    opacity: 0.9;
    z-index: 1; }
  .cstmBar_chart .bars span {
    position: absolute;
    width: 100%;
    left: 0;
    top: -15px;
    text-align: center;
    display: block;
    font-size: 12px; }

.mr_bt_10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.lengend_Ul__ {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px; }
  .lengend_Ul__ li {
    display: block;
    flex: 1; }

.staffTable12 .ReactTable .rt-thead .rt-th:nth-child(5),
.staffTable12 .ReactTable .rt-tr .rt-td:nth-child(5) {
  border-right: 0; }

.dueTsk_tble .ReactTable .rt-td {
  white-space: pre-wrap;
  text-align: center !important; }

.stats_ups {
  background: #fff;
  border-radius: var(--b-radius);
  margin-bottom: 15px;
  overflow: hidden; }
  .stats_ups .sts_dtie {
    font-size: 11px;
    display: flex;
    align-items: center;
    color: var(--drk-color4);
    background: var(--light-color-background);
    padding: 5px 10px; }
  .stats_ups .sts_dtie span {
    color: #000;
    padding-left: 10px; }
  .stats_ups .sts_dtie a i {
    margin-left: 5px;
    font-size: 14px;
    color: #000; }

.stats_update_list {
  height: 700px;
  overflow-y: scroll;
  padding-right: 10px;
  margin-top: 10px; }

.sts_bdy {
  padding: 10px;
  padding-top: 15px; }
  .sts_bdy h6 {
    font-size: 12px;
    color: #3e3e3e;
    font-weight: normal; }

.hdng_btIc {
  font-size: 23px; }

.duly_vw {
  margin: 0 auto;
  max-width: 200px; }

.num_stats1 {
  font-size: 50px;
  margin: 30px auto;
  text-align: center;
  margin-bottom: 40px; }
  .num_stats1 span {
    background: #fff;
    padding: 5px 10px;
    font-weight: 900;
    letter-spacing: 10px;
    text-align: center;
    display: inline-block;
    padding-left: 20px; }

.leg_dv {
  width: 96%;
  margin: 0 auto;
  padding-left: 45px;
  margin-bottom: 30px; }

.taskTable .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center; }

.tsk_nm {
  position: relative;
  width: 100%;
  min-width: 125px; }

.tsk_Stat {
  width: 19px;
  height: 19px;
  background: grey;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  float: left; }

.tsk_nm1 {
  flex: 1;
  text-align: center; }

.tsk_actn_spn {
  font-size: 12px;
  white-space: pre-wrap; }
  .tsk_actn_spn a {
    display: inline-block;
    color: grey;
    font-size: 11px; }
  .tsk_actn_spn {
    color: grey; }
  .tsk_actn_spn a:hover {
    color: var(--b-color); }

.lh-17 {
  line-height: 17px; }

.clr_grey {
  color: #4c4c4c; }

.avl_ints {
  border-bottom: solid 1px;
  padding-bottom: 10px;
  position: relative;
  padding-right: 30px; }
  .avl_ints::after {
    content: "\66";
    font-family: "icons_me";
    right: 0;
    position: absolute;
    font-size: 14px; }

.selectTsk_btn {
  padding: 3px 20px;
  border: transparent;
  font-size: 12px;
  opacity: 0.5; }
  .selectTsk_btn:hover, .selectTsk_btn.active {
    opacity: 1; }

.extra_sm_fnt {
  font-size: 12px; }

.asgnd_num {
  font-weight: normal;
  margin-left: 10px; }

.pr_rec_list {
  width: 95%;
  padding-bottom: 10px;
  max-height: 175px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-right: 15px;
  -ms-flex-wrap: wrap;
  display: -ms-flexbox; }
  .pr_rec_list li {
    width: 50%;
    font-size: 13px;
    text-align: center;
    margin-bottom: 0px;
    padding: 7px 15px;
    cursor: pointer; }
  .pr_rec_list li.active,
  .pr_rec_list li:hover {
    background: var(--bg-color);
    color: #fff;
    border-radius: var(--b-radius); }

.wid-200 {
  width: 200px; }

.wid-80P {
  width: 80%; }

.cmn-btn1.no_bor {
  border: transparent; }

.int_actnBtn {
  margin-right: 5px;
  font-size: 11px;
  padding: 4px 15px; }

.notesArea {
  height: 250px;
  margin-bottom: 10px; }

.bor-l {
  border-left: solid 1px var(--b-color); }

.rec_assignees__ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 180px;
  overflow-y: auto;
  margin-bottom: 30px;
  padding-bottom: 15px; }
  .rec_assignees__ .rec_assi {
    margin-bottom: 15px;
    padding-right: 15px;
    width: 50%; }

.rec_assi:hover .nme_assi,
.rec_assi.active .nme_assi {
  background: var(--b-color);
  color: #fff; }

/* Base for label styling */
.chkLabs.fnt_sm {
  font-size: 11px; }

.customChecks input[type="checkbox"]:not(:checked),
.customChecks [type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

.customChecks [type="checkbox"]:not(:checked) + .chkLabs,
.customChecks [type="checkbox"]:checked + .chkLabs {
  position: relative;
  padding-left: 20px;
  cursor: pointer; }

.customChecks [type="checkbox"]:not(:checked) + .chkLabs:before,
.customChecks [type="checkbox"]:checked + .chkLabs:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }

.customChecks [type="checkbox"]:checked + .chkLabs:before {
  background: #000; }

.customChecks [type="checkbox"]:not(:checked) + .chkLabs:after,
.customChecks [type="checkbox"]:checked + .chkLabs:after {
  content: "\70";
  font-family: "icons_me";
  position: absolute;
  top: 2px;
  left: 1px;
  font-size: 15px;
  line-height: 0.8;
  color: #fff;
  transition: all 0.2s; }

.customChecks [type="checkbox"]:not(:checked) + .chkLabs:after {
  opacity: 0;
  transform: scale(0); }

.customChecks [type="checkbox"]:checked + .chkLabs:after {
  opacity: 1;
  transform: scale(1); }

.clr_all {
  padding-right: 30px;
  position: relative; }
  .clr_all::after {
    content: "\e06f";
    position: absolute;
    right: 5px;
    font-family: "icons_me"; }

.tbl_allWhite.data_table_cmn .ReactTable .rt-tr.-even {
  background: #fff !important; }

.gr_slctB div.Select.Select--single .Select-control,
.gr_slctB div.Select.is-focused > .Select-control,
.gr_slctB div.Select.is-open > .Select-control,
.gr_slctB div.Select.is-focused:not(.is-opne) > .Select-control {
  background: #dcdcdc;
  background-color: #dcdcdc; }

.gr_slctB div.Select .Select-placeholder {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 13px; }

.gr_slctB div.Select .Select-value-label {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 13px; }

.gr_slctB .Select-arrow::before,
.gr_slctB .box_wide .Select-arrow::before {
  color: #000 !important; }

.gr_slctB .Select .Select-control,
.gr_slctB div.Select.is-open > .Select-control {
  border: solid 1px #000; }

.sl_left .Select--single > .Select-control .Select-value .Select-value-label {
  text-align: left; }

.openingInfo.clr_2:before {
  border-top: solid 1px #000; }

a.decSpan {
  text-decoration: underline !important;
  margin-left: 15px; }

.bor-r-b {
  border-right: solid 1px #000; }

.flex-1 {
  flex: 1; }

.lv_adies {
  max-width: 500px;
  margin-bottom: 15px; }

.no_mr_tb {
  margin-top: 0px;
  margin-bottom: 0px; }

.fnt-w-600 {
  font-weight: 600; }

.fs-15 {
  font-size: 15px; }

.vldtn_slct .Select-control {
  overflow: visible; }

.mg_slct1 .Select-arrow::before,
.mg_slct1 .Select.is-open > .Select-control .Select-arrow::before {
  content: "\e02d" !important;
  font-family: "icons_me" !important; }

.mg_slct1 .Select.is-open > .Select-control .Select-arrow::before {
  top: -1px !important; }

.mg_slct1 .Select-input {
  width: auto !important; }

.mg_slct1 .Select-control .Select-value {
  height: 40px;
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse; }

.d-inBl {
  display: inline-block; }

.stf_calHdr {
  position: relative;
  display: inline-block;
  width: 300px;
  max-width: auto; }
  .stf_calHdr div {
    padding: 0 15px; }

.task_dets1_ {
  background: #fff;
  padding: 15px;
  margin-top: 15px;
  min-height: calc(100% - 15px); }

.tsks_shw {
  float: right; }

.low_t i {
  color: var(--crm-task_priority_high_color); }

.medium_t i {
  color: var(--crm-task_priority_medium_color); }

.high_t i {
  color: var(--crm-task_priority_low_color); }

.hdng_btn.icn_btn12.nTsk_bt {
  min-width: auto; }

.fs-14 {
  font-size: 14px; }

.mni_tsk_subs {
  padding: 15px 15px;
  border-top: solid 1px #1e1e1e; }

.pd-lr-50 {
  padding-left: 50px;
  padding-right: 50px; }

.tb_dv_st {
  display: table; }

.tr_dv {
  display: table-row-group; }
  .tr_dv div {
    display: table-cell;
    padding: 5px 10px;
    padding-bottom: 20px; }
  .tr_dv div:first-child {
    min-width: 100px;
    text-align: right; }

.tskMbi_14 {
  padding-left: 20px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; }
  .tskMbi_14 i {
    float: left; }

.w-80 {
  width: 80%; }

.tble_inp1 {
  border: transparent !important;
  background: transparent !important; }
  .tble_inp1:hover, .tble_inp1:focus {
    border: solid 1px var(--b-color) !important; }

.num_stats2 {
  display: flex;
  font-size: 2vw;
  font-weight: 700;
  margin: 15px 0; }
  .num_stats2 span {
    display: inline-block;
    padding: 10px;
    border-right: solid 1px #000;
    border-bottom: solid 1px #000;
    border-top: solid 1px #000;
    background: #fff; }
  .num_stats2 span:first-child {
    border-left: 1px solid #000; }
  .num_stats2 span.comma_cls {
    border-top: 0px solid;
    border-bottom: 0px solid;
    background: transparent !important;
    padding-left: 3px;
    padding-right: 3px;
    line-height: 0px;
    display: flex;
    border-bottom: 0;
    align-items: flex-end; }
  .num_stats2 span.dot_cls {
    border-top: 0px solid;
    border-bottom: 0px solid;
    background: transparent !important;
    padding-left: 3px;
    padding-right: 3px; }
  .num_stats2 span.currency_remove_cls {
    border-top: 0px solid;
    border-bottom: 0px solid;
    background: transparent !important;
    padding-left: 3px;
    padding-right: 3px;
    border-left: 0px solid !important;
    padding-right: 8px; }

.mnNumBox {
  display: inline-block; }

.vw_live {
  height: 40px;
  display: inline-flex;
  align-items: center; }

.atch_ic {
  position: relative; }
  .atch_ic::before {
    content: "\e05c";
    font-family: "icons_me" !important;
    right: 20px;
    position: absolute;
    top: 10px;
    color: var(--bg-color);
    transform: rotate(90deg); }
  .atch_ic input {
    padding-right: 30px; }

.aplis_tsBts > div {
  flex: 1 1; }

.createJobEditor .cke_toolbox {
  display: flex; }

.createJobEditor .cke_toolbox span:nth-child(8) {
  order: -1; }

.slots_flx {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: center; }
  .slots_flx > div {
    width: auto;
    padding: 0 5px;
    /* margin-bottom: 5px; */
    display: inline-flex;
    align-items: center; }

.mb-m-4 {
  margin-bottom: 10px; }

.publ_sal {
  margin-top: 7px !important; }

.customChecks.publ_sal [type="checkbox"]:checked + .chkLabs:before {
  background: var(--bg-color);
  border: solid 1px var(--bg-color); }

.customChecks .tooltip {
  margin-top: -25px;
  margin-left: 8px; }

.pd-l-30 {
  padding-left: 30px; }

.pd-r-30 {
  padding-right: 30px; }

.publish_ques {
  height: 900px;
  overflow-y: scroll;
  padding-right: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
  padding-top: 15px; }

.timeFrame_bx {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap; }

.dts_bxie {
  flex: 1;
  padding-right: 15px;
  display: flex; }

.frm_grp_flx {
  display: flex;
  display: -ms-flexbox;
  padding-left: 7px;
  padding-right: 7px; }

.wid_50 {
  min-width: 45px; }

.mr_l_45 {
  margin-left: 50px; }

.datie_wid .react-datepicker__triangle {
  display: none !important; }

.pst_btn158 {
  margin-top: 45px; }

.accordion_c .accor_body {
  display: none; }

.accordion_c.in .accor_body {
  display: block; }

.accordion_c .accor_hdng {
  cursor: pointer; }

.accordion_c.in .accor_hdng > .icon-arrow-right {
  transform: rotate(90deg); }

.accordion_c.acc_des1 .accor_hdng {
  display: flex;
  display: -ms-flexbox;
  border-bottom: solid 1px #000;
  padding-bottom: 10px; }

.accordion_c.acc_des1 .accor_hdng > div {
  flex: 1;
  padding-right: 10px; }

.sts_co {
  margin-bottom: 15px; }

.sts_footer {
  border-top: solid 1px #efefef;
  margin-top: 5px; }
  .sts_footer .subTasks_Action__ {
    margin-bottom: 0; }
  .sts_footer .subTasks_Action__ li {
    font-size: 11px; }

.text_ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.fl_aplis {
  border-top: solid 1px #bfbfbf;
  padding: 15px 15px;
  word-break: break-word;
  font-size: 14px;
  min-height: auto;
  line-height: 20px;
  background: transparent; }

.mb-m-2 {
  margin-bottom: 20px; }

.mb-m-1 {
  margin-bottom: 10px; }

.appli_ul_14 {
  font-size: 14px;
  margin-top: 20px; }
  .appli_ul_14 li {
    margin-bottom: 10px; }

.mt-m-2 {
  margin-top: 20px; }

.lnkie_sp a {
  margin-bottom: 5px;
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  width: 50%; }
  .lnkie_sp a .slots_sp {
    display: block; }

.under_l_tx {
  text-decoration: underline !important;
  font-size: 12px; }

a.under_l_tx:hover {
  color: var(--b-color) !important;
  text-decoration: underline !important; }

.vwMore_sp {
  color: var(--b-color);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-left: 7px; }

.flg_ap_hd {
  font-size: 16px; }

.bor_l_cols {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap; }
  .bor_l_cols > div {
    border-right: solid 1px #929292;
    padding-left: 30px; }
  .bor_l_cols > div:last-child,
  .bor_l_cols > div:nth-child(3) {
    border-right: transparent; }

.brk_all {
  word-break: break-all; }

.bor_bot_b1 {
  border-bottom: solid 1px #929292; }

.pd-lr-4 {
  padding-left: 4px;
  padding-right: 4px; }

.sp_btn_p {
  width: 100%;
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  display: -ms-flexbox; }
  .sp_btn_p span {
    flex: 1;
    padding-right: 5px; }
  .sp_btn_p i {
    font-size: 17px; }

.pd-r-10 {
  padding-right: 10px; }

.custm_switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 0;
  /* Rounded sliders */ }
  .custm_switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .custm_switch .swtch_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-color);
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .custm_switch .swtch_slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .custm_switch input:checked + .swtch_slider {
    background-color: var(--bg-color); }
  .custm_switch input:focus + .swtch_slider {
    box-shadow: 0 0 1px var(--bg-color); }
  .custm_switch input:checked + .swtch_slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  .custm_switch .swtch_slider.round {
    border-radius: 20px; }
  .custm_switch .swtch_slider.round:before {
    border-radius: 50%; }

.custm_switch_green .custm_switch .swtch_slider {
  background-color: #aeb5b8; }

.custm_switch_green .custm_switch input:checked + .swtch_slider {
  background-color: #1eb35a; }

.smallwid_50 {
  width: 70px; }

.ml_10p {
  margin-left: 10px; }

.mt-3r {
  margin-top: 1rem; }

.Pay_S_Details {
  display: flex;
  flex-direction: column; }
  .Pay_S_Details .Pay_S_li {
    display: flex;
    justify-content: space-between;
    min-height: 16px;
    align-items: center;
    margin: 10px 0px;
    line-height: 16px; }
  .Pay_S_Details .Pay_S_li span {
    flex-basis: 100px;
    min-height: 16px; }
  .Pay_S_Details .Pay_S_li span:last-child {
    flex-basis: 50px; }
  .Pay_S_Details .Pay_S_li > span:first-child {
    flex: 3;
    padding-right: 10px; }
  .Pay_S_Details .P_tdw {
    text-align: center; }
  .Pay_S_Details .Pay_line_1 {
    border-left: 1px solid;
    padding: 0px 10px; }
  .Pay_S_Details .Pay_line_1 + .Pay_line_1 {
    border-right: 1px solid #000; }

.Req_list_1r {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin: 10px 0px;
  padding-right: 30px; }

.P_tdw i {
  display: inline-flex;
  font-size: 19px;
  margin: 3px 1px;
  cursor: pointer; }

.Addtional_li_1a li > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 5px 15px;
  font-size: 15px;
  border-radius: 3px;
  margin: 5px 0px;
  border: 1px solid; }

.Addtional_li_1a ul {
  display: grid;
  grid-template-columns: 17px 1fr;
  align-items: center; }

.Addtional_li_1a li > div div:first-child {
  width: 100%;
  display: flex;
  align-items: center; }

.Addtional_li_1a li > div div {
  margin: 0px; }

.Addtional_li_1a li > div div:first-child textarea {
  width: 100%;
  padding: 0px;
  margin: 0px;
  border: 0px;
  min-height: 18px;
  resize: none;
  height: auto;
  max-height: 110px; }

.Addtional_li_1a li > div div:first-child textarea.editable_class {
  height: 100px !important;
  border-bottom: 1px solid #000;
  border-radius: 0px;
  resize: inherit;
  margin-bottom: 30px; }

.Manage_li_ {
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  padding: 7px 10px;
  width: 100%;
  margin-right: 2%;
  background: #fff;
  margin-bottom: 5px; }

.Manage_ul__popup_ .Manage_li_ {
  width: 100%; }

.row_overflowy {
  height: 235px;
  overflow-y: scroll; }

.Manage_li_a1_ {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding-left: 5px; }

.Manage_li_a2_ {
  display: inline-flex;
  align-items: center; }
  .Manage_li_a2_ i {
    display: inline-flex;
    margin-left: 15px;
    font-size: 20px;
    color: var(--bg-color);
    cursor: pointer; }
  .Manage_li_a2_ i.icon.icon-plus {
    font-size: 23px; }

a.Man_btn_1,
.Man_btn_1,
.Man_btn_2,
a.Man_btn_2 {
  padding: 3px 7px;
  border-radius: 3px;
  display: inline-flex;
  font-size: 12px;
  color: #fff;
  margin: 0px 2px;
  cursor: pointer; }

.cUStom_check input {
  display: none; }

.cUStom_check input:checked + small::after {
  content: "\70";
  font-family: "icons_me";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  margin-left: -1px;
  color: #fff; }

.cUStom_check small {
  display: inline-flex;
  width: 13px;
  height: 13px;
  background: #494949;
  border-radius: 2px;
  position: relative;
  cursor: pointer; }

.Man_btn_2 {
  background: var(--bg-color);
  border: 1px solid var(--bg-color); }

.Man_btn_1 {
  background: #03a9f4;
  border: 1px solid #03a9f4; }

.Manage_ul_ {
  /* background: #fff; */
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #000; }

.step_a {
  background: #05adee54; }

.step_b {
  background: #f6f6f6; }

.Manage_div_1 {
  display: flex; }

.Options_scroll {
  max-height: 450px; }
  .Options_scroll .scrollarea-content.content {
    background: #fff;
    padding: 8px; }

.Seek_Q_ul {
  display: flex;
  flex-wrap: wrap; }
  .Seek_Q_ul .Seek_Q_li {
    width: 50%;
    display: inline-flex;
    margin-bottom: 15px; }
    .Seek_Q_ul .Seek_Q_li span i {
      display: flex;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      background: var(--b-color);
      border-radius: 100%;
      font-size: 17px;
      color: #fff; }
    .Seek_Q_ul .Seek_Q_li span i.icon-cross-icons {
      background: #f50c0c; }
    .Seek_Q_ul .Seek_Q_li span i.icon-accept-approve1-ie {
      background: #4caf50; }
    .Seek_Q_ul .Seek_Q_li span {
      margin-right: 10px; }
    .Seek_Q_ul .Seek_Q_li div {
      padding-top: 4px;
      font-size: 13px; }

.applicant_info_footer_1 {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Group_d_1b__ {
  margin: 10px 0px;
  font-size: 15px; }

.Group_d_1a__ {
  margin-bottom: 30px; }

a.in_prgress_btn_01,
.in_prgress_btn_01 {
  padding: 9px 15px;
  display: inline-flex;
  min-width: 140px;
  justify-content: center;
  border-radius: 25px;
  color: #fff;
  background: #2d9bc9; }

a.Def_btn_01,
.Def_btn_01 {
  padding: 9px 15px;
  display: inline-flex;
  min-width: 140px;
  justify-content: center;
  border-radius: 25px;
  color: #fff;
  background: var(--bg-color); }

a.prgress_btn_01,
.prgress_btn_01 {
  background: #2d9bc9; }

a.success_btn_01,
.success_btn_01 {
  background: #00bf45; }

a.un_success_btn_01,
.un_success_btn_01 {
  background: red; }

a.pending_btn_01,
.pending_btn_01 {
  background: #feb200; }

.result_breakdown_01_ {
  padding: 0px 30px; }

.mark_assess_01 {
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 7px 15px; }

.mark_assess_01a {
  font-size: 30px;
  font-weight: 600; }

a.fail_btn_01b1 {
  padding: 4px 15px;
  min-width: 90px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #ff0000;
  margin-right: 10px;
  color: #ff0000;
  background: #fff; }
  a.fail_btn_01b1:hover, a.fail_btn_01b1.active {
    background: #ff0000;
    color: #fff; }

a.pass_btn_01b2 {
  padding: 4px 15px;
  min-width: 90px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #01be44;
  align-items: center;
  color: #01be44;
  background: #fff; }
  a.pass_btn_01b2:hover, a.pass_btn_01b2.active {
    background: #01be44;
    color: #fff; }

.Questionanswer_tab ul {
  display: flex;
  justify-content: center; }
  .Questionanswer_tab ul li a {
    background: no-repeat;
    font-size: 15px;
    border: 0px;
    border-bottom: 1px solid var(--bg-color);
    border-radius: 0px;
    margin: 0px;
    color: var(--bg-color);
    position: relative; }
  .Questionanswer_tab ul li a::after {
    position: absolute;
    left: 0;
    width: 0;
    bottom: -1px;
    height: 6px;
    background: var(--bg-color);
    content: "";
    transition: all ease 0.5s; }
  .Questionanswer_tab ul li a:hover:after {
    width: 100%; }
  .Questionanswer_tab ul li.active a {
    background: no-repeat;
    border: 0; }
  .Questionanswer_tab ul li.active a::after {
    background: no-repeat;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -1px;
    height: 6px;
    background: var(--bg-color);
    content: ""; }

.Questionanswer_tab .nav > li > a:hover, .Questionanswer_tab .nav > li > a:focus {
  background: none;
  background-color: none;
  border: 0; }

.Flag_application_size .cstomDialog {
  min-width: 700px;
  width: 700px; }

.cstomDialog.widMedium {
  width: 700px;
  max-width: 100%; }

.Question_id_div_ {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center; }
  .Question_id_div_ .customChecks [type="checkbox"]:not(:checked) + .chkLabs:before,
  .Question_id_div_ .customChecks [type="checkbox"]:checked + .chkLabs:before {
    border: 1px solid var(--bg-color);
    border-radius: 2px; }
  .Question_id_div_ .customChecks [type="checkbox"]:not(:checked) + .chkLabs:after,
  .Question_id_div_ .customChecks [type="checkbox"]:checked + .chkLabs:after {
    top: 3px;
    left: 1px;
    font-size: 14px; }
  .Question_id_div_ .customChecks [type="checkbox"]:not(:checked) + .chkLabs,
  .Question_id_div_ .customChecks [type="checkbox"]:checked + .chkLabs {
    padding-right: 0px; }
  .Question_id_div_ .chkLabs.fnt_sm {
    font-size: 14px; }

.Search_for_device_heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #777; }

a[disabled] {
  pointer-events: none;
  cursor: default;
  opacity: 0.5; }

.Line_base_tabs ul {
  border-bottom: 1px solid; }
  .Line_base_tabs ul li a {
    padding: 0px;
    background: no-repeat;
    border: 0px solid;
    border-radius: 0px;
    margin-right: 15px;
    font-size: 15px; }

.References_table.ReactTable {
  border: 0px solid #ddd; }
  .References_table.ReactTable .rt-thead .rt-th,
  .References_table.ReactTable .rt-thead .rt-td {
    border-right: 0px solid #ddd; }
  .References_table.ReactTable .rt-tr.-odd,
  .References_table.ReactTable .rt-tr.-even {
    border-bottom: 1px solid; }
  .References_table.ReactTable .rt-tr.-padRow.-odd,
  .References_table.ReactTable .rt-tr.-padRow.-even {
    border: 0px solid #ddd; }

.References_table .References_table_SubComponent {
  border-bottom: 1px solid; }

.References_table .Refer_colum_1.pl-0,
.References_table .Refer_colum_2.pl-0,
.References_table .Refer_colum_3.pl-0,
.References_table .Refer_colum_4.pl-0 {
  padding-left: 0px; }

.References_table .Refer_colum_1.pr-0,
.References_table .Refer_colum_2.pr-0,
.References_table .Refer_colum_3.pr-0,
.References_table .Refer_colum_4.pr-0 {
  padding-right: 0px; }

.References_table .rt-table {
  font-size: 14px; }

.hide_header_ReferencesTable .rt-thead.-header {
  display: none; }

.border-black {
  border-color: #1e1e1e !important; }

.Award_list_table_ {
  display: flex;
  flex-direction: column;
  margin-left: -7px;
  margin-right: -7px; }

.Award_list_header_ {
  display: flex;
  justify-content: space-between; }

.Award_list_header_ > div {
  padding: 0px 7px;
  align-items: center;
  display: flex;
  justify-content: center; }

.Award_list_ {
  display: flex;
  justify-content: space-between; }

.Award_list_col_1 {
  flex-basis: 20px; }

.Award_list_col_2 {
  flex: 1; }

.Award_list_col_3,
.Award_list_col_4 {
  flex-basis: 90px;
  text-align: center; }

.Award_list_ > div {
  padding: 7px;
  align-items: center;
  display: flex; }

.Award_list_col_3 input,
.Award_list_col_4 input {
  min-height: 30px;
  height: 30px;
  text-align: center; }

.Award_col_1 {
  flex: 2;
  font-size: 15px; }

.Award_col_2,
.Award_col_3 {
  flex-basis: 100px;
  text-align: center; }

.Ch-MO-1 {
  flex: 1;
  font-size: 15px;
  text-align: left; }

.Ch-MO-2 {
  flex-basis: 100px;
  text-align: right; }

.Ch-MO-3 {
  flex-basis: 30px;
  text-align: right; }

.Ch-MO-3 .icon.icon-accept-approve2-ie {
  font-size: 20px;
  color: #00be44;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.3;
  cursor: pointer; }

.Ch-MO-3 .icon.icon-close2-ie {
  font-size: 20px;
  color: red;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.3;
  cursor: pointer; }

.Ch-MO-3 .icon.active {
  opacity: 1; }

.Check_Mand_Option_li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px 0px; }

.Recruit_Time_header {
  display: flex;
  padding-top: 5px; }

.Rec_Right_s_1 {
  flex: 1; }

.Rec_Left_s_1 {
  flex: 2;
  text-align: left; }
  .Rec_Left_s_1 h2 {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: normal; }
  .Rec_Left_s_1 h3 {
    font-size: 14px;
    margin-bottom: 10px; }

.Time_line_error_msg {
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  width: 100%;
  display: flex;
  margin-top: 7px;
  margin-bottom: 7px;
  color: var(--bg-color); }
  .Time_line_error_msg.text_R_1 {
    color: red; }
  .Time_line_error_msg.text_G_1 {
    color: #13a650; }

div.Select-menu-outer {
  z-index: 999 !important;
  position: absolute; }

.Tab_Overflow__ .Select-menu-outer .Select-menu {
  max-height: 100px; }

.slct_s1 .Select-control,
.slct_s1 .Select-control div {
  height: 20px !important;
  line-height: 20px !important; }

.slct_s1 .Select-arrow::before {
  top: -13px !important; }

.slct_s1 .Select.is-open > .Select-control .Select-arrow::before {
  top: -11px !important; }

.Recruit_Time_header .btn.cmn-btn1.phone-btn {
  padding: 3px 10px;
  line-height: 13px; }

.Recruit_Time_header .btn.cmn-btn1.phone-btn::after {
  font-size: 12px; }

.Recruit_Time_header.min-height {
  height: 95px; }

.Recruit_Time_header.bb-1 {
  padding-bottom: 15px;
  border-color: #000; }

.Rerm_time_txt {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid;
  padding-top: 7px;
  margin-top: 15px;
  align-items: center;
  justify-content: flex-end; }

.Rerm_time_txt > div {
  font-size: 12px; }

.Rec_center_s_1a {
  font-size: 16px;
  line-height: normal; }

.Stage_body_01 {
  display: flex;
  justify-content: space-between;
  margin: 5px 0; }

.Stage_Left_1 {
  font-size: 14px; }

.O_btn_1 {
  display: inline-flex;
  font-size: 12px;
  background: #fff;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 3px; }
  .O_btn_1.txt_success {
    color: #13a650;
    border-color: #13a650; }
  .O_btn_1.txt_infor {
    color: var(--bg-color);
    border-color: var(--bg-color); }

.limt_flex_set_0 {
  display: flex;
  flex: 1;
  justify-content: space-between; }

a.limt_btns,
button.limt_btns,
.limt_btns {
  display: inline-flex;
  font-size: 12px;
  background: var(--bg-color);
  padding: 6px 10px;
  border-radius: 3px;
  color: #fff;
  min-width: 130px;
  text-align: center;
  justify-content: center;
  border: 0px solid; }
  a.limt_btns.w-z,
  button.limt_btns.w-z,
  .limt_btns.w-z {
    position: relative; }
  a.limt_btns.w1:after,
  button.limt_btns.w1:after,
  .limt_btns.w1:after {
    content: "\34";
    position: absolute;
    right: 10px;
    font-family: "icons_me"; }

.w-big {
  padding: 3px 10px;
  font-size: 16px; }

.rec_applicatn_timeline .task_table_v4-1__ {
  padding: 10px 15px; }

.rec_applicatn_timeline .ci_btn,
.rec_applicatn_timeline a.ci_btn {
  min-height: 21px; }

.Onboard_btn,
a.Onboard_btn {
  padding: 6px 10px;
  font-size: 12px;
  background: #000;
  color: #fff;
  display: inline-flex;
  min-width: 40px;
  border-radius: 3px;
  justify-content: center;
  align-items: center; }
  .Onboard_btn.y_colo,
  a.Onboard_btn.y_colo {
    background: #14a751; }
  .Onboard_btn.n_colo,
  a.Onboard_btn.n_colo {
    background: red; }

.Time_Orient_div_ {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center; }
  .Time_Orient_div_ > span + span {
    flex: 1; }
  .Time_Orient_div_ > span {
    flex: 2;
    text-align: left; }

span.Time_Orient_span_ {
  display: flex;
  width: 100%; }

.one_time_pass__ {
  position: relative; }
  .one_time_pass__ input {
    width: 135px;
    height: 35px;
    border: 0px;
    background: #ddd; }
  .one_time_pass__::after {
    content: "\e0bd";
    position: absolute;
    right: 0;
    top: 0;
    font-family: "icons_me";
    width: 32px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 22px;
    color: var(--b-color); }
  .one_time_pass__ input {
    padding-right: 35px; }

.R_bt_co_blue.active_selected {
  background: #03a9f4;
  border-color: #03a9f4;
  color: #fff; }

.R_bt_co_.active_selected {
  background: var(--bg-color);
  border-color: var(--bg-color);
  color: #fff; }

.Manage_li_a1_ span {
  cursor: pointer;
  font-size: 14px;
  font-weight: normal; }

.row_NA_Quiz {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px; }

.heading_Na_Quiz {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 7px; }

.MG_inter_label {
  font-size: 15px; }

.appli_row_1 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end; }

.appli_row_a {
  margin-right: 15px; }
  .appli_row_a div {
    display: flex;
    flex-direction: column; }

.grid_padding {
  display: grid;
  grid-template-columns: auto auto; }
  .grid_padding:after, .grid_padding:before {
    display: none; }

.ap_dts_padding {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -20px; }
  .ap_dts_padding > div {
    padding: 0px 20px; }

.CAB-btn,
a.CAB-btn {
  padding: 5px 0px;
  min-width: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background: var(--bg-color);
  border-radius: 3px;
  color: #fff; }

a.CAB-btn {
  cursor: pointer; }

.CAB_table_footer_ {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px 0px;
  border-top: 1px solid;
  margin-top: 15px; }

.Cab_table_footer_div1_ .customChecks [type="checkbox"]:not(:checked) + .chkLabs:before,
.Cab_table_footer_div1_ .customChecks [type="checkbox"]:checked + .chkLabs:before {
  border-radius: 2px;
  border-color: var(--bg-color); }

.R_view_Div__ {
  display: flex;
  flex-direction: column; }

.R_view_List__ {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  min-height: 35px;
  border-top: 1px solid; }

.R_view_icons {
  display: flex;
  align-items: center;
  font-size: 18px; }
  .R_view_icons i {
    margin-left: 5px; }
  .R_view_icons .icon.icon-view1-ie {
    color: var(--bg-color); }
  .R_view_icons .icon.icon-close2-ie {
    color: red; }
  .R_view_icons .icon.icon-accept-approve2-ie {
    color: #14a751; }

.R_view_text {
  font-size: 14px; }

.Con_list_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px; }

.header_CABDay {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .header_CABDay > span:first-child {
    margin-right: 15px; }
  .header_CABDay div.my_wigh {
    min-width: 100px; }

.CAB_table_ .rt-td.border_remove_ {
  border: 0px solid !important; }

.CAB_table_ .rt-td div {
  font-size: 15px; }

.icon_CAB_table i {
  background: var(--bg-color);
  display: inline-flex;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff; }
  .icon_CAB_table i.icon-accept-approve2-ie {
    background: #1eb35a; }
  .icon_CAB_table i.icon-close2-ie {
    background: #ff0000; }

.incorrect_Cls {
  font-weight: 600 !important;
  color: #ff0000; }

.CAB_Day_question_ul__ {
  background: #f6f6f6;
  padding-left: 30px; }

.CAB_Day_question_li__ {
  margin-top: 15px; }
  .CAB_Day_question_li__ p {
    padding: 15px;
    background: #fff;
    border-radius: 3px; }
  .CAB_Day_question_li__ h3 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px; }

.wrong_answers h3 {
  color: red; }

.wrong_answers p {
  background: #ff00001c; }

.right_answers h3 {
  color: #1eb35a; }

.right_answers p {
  background: rgba(30, 179, 90, 0.1); }

.right_part_wh p {
  margin: 0px;
  font-size: 16px; }

.content_mn_tble .right_part_wh p {
  margin: 0 0 10px; }

h3.sub_title_02 {
  padding: 45px 0px 20px; }

.resend_color i.icon.icon-approved2-ie {
  background: transparent;
  width: 18px;
  height: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 14px;
  margin-right: -9px;
  margin-left: 2px;
  color: #000; }

.resend_color i.ie.ie-loading {
  background: transparent;
  width: 18px;
  height: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 15px;
  color: #000;
  margin-right: -9px;
  margin-left: 2px; }

.Btn_01 {
  padding: 1px 14px;
  font-size: 13px;
  background: #1e1e1e;
  color: #fff !important;
  display: inline-flex; }

.remove_color {
  background: var(--bg-color); }

.copy_btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  border-color: var(--bg-color);
  border-radius: 3px; }
  .copy_btn input {
    border: 0px solid;
    color: var(--bg-color);
    background: transparent;
    text-overflow: ellipsis; }
  .copy_btn span {
    width: 45px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    color: var(--bg-color);
    font-size: 18px;
    cursor: pointer; }

.reactTableActionTask {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0px 15px; }

.appLi_listBor__ > div:nth-child(3n) > .apli_infTble1,
.appLi_listBor__ > div:nth-child(4n) > .apli_infTble1 {
  border-top: 1px solid #777; }

.appLi_listBor__ > div > .apli_infTble1 {
  padding-top: 10px; }

.appLi_listBor__ > div:nth-child(3n) > .apli_infTble1 .cstm_tr:last-child div,
.appLi_listBor__ > div:nth-child(4n) > .apli_infTble1 .cstm_tr:last-child div {
  padding-bottom: 0px; }

.Current_apl_tr_td_last {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .Current_apl_tr_td_last div {
    width: 100%;
    text-align: center; }
  .Current_apl_tr_td_last i {
    font-size: 18px;
    padding-right: 10px;
    color: var(--bg-color);
    cursor: pointer; }

.Assi_Rec_Yep i {
  border-radius: 1000%;
  font-size: 25px;
  padding: 1px 3px 0px 2px;
  background: #000;
  color: #fff;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: all ease 0.5s; }
  .Assi_Rec_Yep i.icon-accept-approve1-ie {
    background: #12c412; }
  .Assi_Rec_Yep i.icon-cross-icons-1 {
    background: red; }
  .Assi_Rec_Yep i:hover {
    box-shadow: 0px 5px 5px #777; }

.Hide_tolles .cke_combo_button,
.Hide_tolles .cke_button__language,
.Hide_tolles .cke_button__creatediv,
.Hide_tolles .cke_button__copyformatting,
.Hide_tolles .cke_button__superscript,
.Hide_tolles .cke_button__subscript,
.Hide_tolles .cke_button__strike,
.Hide_tolles .cke_button__selectall,
.Hide_tolles .cke_button__replace,
.Hide_tolles .cke_button__find,
.Hide_tolles .cke_button__templates,
.Hide_tolles .cke_button__print,
.Hide_tolles .cke_button__preview,
.Hide_tolles .cke_button__newpage,
.Hide_tolles .cke_button__bidiltr,
.Hide_tolles .cke_button__bidirtl,
.Hide_tolles .cke_button__showblocks,
.Hide_tolles .cke_button__bgcolor,
.Hide_tolles .cke_button__textcolor,
.Hide_tolles .cke_button__link,
.Hide_tolles .cke_button__unlink,
.Hide_tolles .cke_button__anchor,
.Hide_tolles .cke_bottom.cke_reset_all {
  display: none; }

.cstmEditor.Hide_tolles .cke_chrome .cke_top {
  bottom: 0 !important; }

.Checkbox_convet_Button {
  display: inline-flex; }
  .Checkbox_convet_Button label {
    position: relative;
    margin-bottom: 0px;
    margin-right: 7px; }
  .Checkbox_convet_Button label input {
    position: absolute;
    opacity: 0; }
  .Checkbox_convet_Button label input:checked + span {
    background: var(--bg-color);
    color: #fff; }
  .Checkbox_convet_Button label input + span {
    background-color: transparent;
    color: var(--bg-color);
    border-radius: var(--b-radius);
    transition: all ease 0.5s;
    outline: none !important;
    border: solid 1px var(--bg-color);
    min-width: 120px;
    padding: 8px 12px;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    font-weight: 300; }

.Time_line_panel_heading_0 {
  background-color: transparent !important;
  border-top: 1px solid #1e1e1e !important; }
  .Time_line_panel_heading_0 a[aria-expanded="false"] .Stage_body_01 i .icon-arrow-left {
    display: inline-flex !important;
    align-items: center;
    font-size: 12px;
    flex-basis: 30px; }
  .Time_line_panel_heading_0 a[aria-expanded="false"] .Stage_body_01 i.icon-arrow-down {
    display: inline-flex !important;
    align-items: center;
    font-size: 12px;
    flex-basis: 30px; }
  .Time_line_panel_heading_0 .Stage_Left_1 {
    font-size: 14px;
    text-align: left;
    align-items: center;
    display: inline-flex; }

.Time_line_panel_Main_0 .panel-collapse {
  border-top: 1px solid #1e1e1e; }

.Time_line_panel_Main_0:first-child .Time_line_panel_heading_0 {
  border-top: 0px solid transparent !important; }

.Time_line_panel_body_0 {
  border-color: transparent !important; }

.time_d_style .panel-group .panel + .panel {
  margin-top: 0px; }

.select_active {
  position: relative; }
  .select_active:after {
    content: "\70";
    position: absolute;
    margin: 0;
    background: #4caf50;
    font-family: "icons_me";
    padding: 2px 5px 0px 0px;
    border: 1px solid #fff;
    display: inline-block;
    height: 24px;
    width: 25px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-size: 19px;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.aTTach_nOTe {
  white-space: nowrap;
  display: flex; }

.aTTach_nOTe_li {
  /* width: 33.3%;
  flex-basis: 33.3%; */
  flex: 0 0 33.3%;
  padding-right: 45px;
  font-size: 14px; }

.aTTach_nOTe_Des p {
  white-space: normal;
  min-height: 170px;
  border: 1px solid;
  padding: 10px 15px;
  border-radius: var(--b-radius);
  margin: 10px 0px;
  background: #fff;
  border-color: var(--bg-color); }

.aTTach_nOTe_Name {
  margin-top: 15px; }

.aTTach_nOTe_Date {
  text-align: right; }

.sbTsk_li a {
  color: #404040; }

.border-color-black {
  border-color: #000 !important; }

.React_Google_auto {
  position: relative; }
  .React_Google_auto::after {
    content: "\e095";
    font-family: "icons_me";
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    color: var(--bg-color); }
  .React_Google_auto input {
    padding-right: 45px; }

.inACtive_bTN input {
  display: none; }

.inACtive_bTN input + span {
  color: #777;
  background: #f5f5f5;
  border-color: #f5f5f5;
  padding: 5px 7px;
  min-width: 70px;
  font-size: 12px;
  font-weight: 300;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.inACtive_bTN input[type="checkbox"]:checked + span,
.inACtive_bTN input[type="radio"]:checked + span {
  background: var(--bg-color);
  color: #fff; }

.inACtive_bTN label {
  margin-bottom: 0px; }

.inACtive_bTN label:first-child span {
  border-radius: 3px 0px 0px 3px; }

.inACtive_bTN label:nth-child(2) span {
  border-radius: 0px 3px 3px 0px; }

.App_Answer_btn_ label input + span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  display: inline-flex;
  cursor: pointer; }

.App_Answer_btn_ label input + span::after {
  position: absolute;
  color: #fff;
  font-family: "icons_me";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 21px; }

.App_Answer_btn_ label input[type="radio"]:checked + span::after {
  content: "\e04b"; }

.App_Answer_btn_ label input[type="radio"]:checked + span {
  background: #00a551; }

.App_Answer_btn_ label input {
  display: none; }

.App_Answer_btn_ label input[type="radio"][corrected="true"] + span::after {
  content: "\e06f"; }

.App_Answer_btn_ label input[type="radio"][corrected="true"] + span {
  opacity: 0.2;
  background: #e91e1e; }

.App_Answer_btn_ label input[type="radio"][corrected="false"] + span::after {
  content: "\e04b"; }

.App_Answer_btn_ label input[type="radio"][corrected="false"] + span {
  opacity: 0.2;
  background: #00a551; }

.App_Answer_btn_ label input[type="radio"][corrected="false"]:checked + span {
  opacity: 1; }

.App_Answer_btn_ label input[type="radio"][corrected="true"]:checked + span {
  opacity: 1; }

.Mul_choice_bnt__ {
  margin-left: 10px;
  font-size: 12px;
  display: inline-flex;
  min-width: 120px;
  background: var(--bg-color);
  justify-content: center;
  align-items: center;
  border-radius: var(--b-radius);
  color: #fff;
  padding: 3px 5px; }

.Mg_email_set_ {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%; }

.Small_set__R .input_search input {
  height: 40px;
  font-size: 13px;
  border-color: #1e1e1e !important; }

.Small_set__R .input_search .icon-search::before {
  font-size: 22px;
  color: #1e1e1e; }

.dotted_line_one {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block; }

.pointer-events-none {
  pointer-events: none; }

.slots_sp_size_small {
  font-size: 12px;
  font-weight: 300; }

.font_size_12 {
  font-size: 12px; }

.font_size_13 {
  font-size: 13px; }

.result_graph_DIV_A {
  position: relative;
  background: #ececec; }

.result_graph_TEXT_B {
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  font-size: 18px;
  font-weight: 600; }

.Multiple_Choice_div___ .answrShw li {
  padding-left: 0px; }

.my_tooltip_ {
  padding: 5px 10px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  width: auto;
  text-align: center;
  position: relative; }
  .my_tooltip_::after {
    position: absolute;
    content: "";
    border-bottom: 5px solid #000;
    width: 10px;
    height: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0); }

.Small_btn_az {
  font-size: 12px;
  padding: 5px 7px;
  background: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius);
  min-width: 90px;
  justify-content: center;
  display: inline-flex; }
  .Small_btn_az.green_colr {
    background: #00bf45; }

.my_Spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.cstmSelect2_optionsBox:root {
  --main-color: #05adee; }

@media (min-width: 768px) {
  .recruitment_module .container {
    width: 96%; }
  .sort_row1-- {
    display: flex;
    align-items: center; }
  .pd_r_0_De {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .addColie {
    width: 20%; } }

@media (min-width: 1200px) {
  .customModal .cstomDialog {
    max-width: 1300px; } }

@media (min-width: 1300px) {
  .recruitment_module .container {
    width: 90%;
    max-width: 1600px; } }

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
  .mycol-xl-4 {
    width: 33.33%; }
  .mycol-xl-5 {
    width: 41.66666667%; }
  .mycol-xl-3 {
    width: 25%; }
  .mycol-xl-6 {
    width: 50%; } }

@media (max-width: 1500px) {
  .cstmSelect2_optionsBox {
    left: 0;
    margin-top: 10px; }
  .cstmSelect2_optionsBox::before {
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #9bdef8 transparent;
    top: 0;
    margin-top: -10px;
    left: 30px; }
  .colJ-1 {
    width: 100%; }
  .Cust_Sel_2 .rrs__option.rrs__option--header {
    font-size: 13px; }
  .srve_liDv .btn.s_bt2 {
    margin-right: 7px;
    font-size: 12px;
    max-width: calc(100% - 30px); }
  .taskTable .ReactTable .rt-thead .rt-resizable-header-content {
    font-size: 12px; }
  .data_table_cmn .ReactTable .rt-tr .rt-td {
    font-size: 12px; }
  .prof_left .profImg {
    width: 60px;
    height: 60px; }
  .prof_left {
    width: 80px;
    padding-left: 5px; }
  .prof_left small {
    font-size: 11px; }
  .prof_right {
    width: calc(100% - 80px); }
  .mni_tsk_subs .pd-lr-50 {
    padding-left: 0;
    padding-right: 0; }
  .tskMbi_14 i {
    margin-left: -10px;
    margin-right: 5px; }
  .pub_filCol {
    width: 100%; }
  .dts_bxie {
    padding-right: 0; }
  .pst_btn158 {
    margin-top: 0px; }
  .slots_sp {
    font-weight: normal;
    padding: 3px 10px;
    font-size: 13px; }
  .row_NA_Quiz {
    grid-template-columns: 1fr; }
  .appli_row_a {
    margin-bottom: 10px; }
  .CABinfo_lstBox__ {
    padding: 10px; }
  .applicant_info1 div {
    font-size: 13px; }
  .trngBoxAc h4 {
    font-size: 16px; }
  .MG_inter_label {
    margin-bottom: 10px; } }

@media (max-width: 1300px) {
  .jOpen_table_dv {
    width: 100% !important; }
  .main_timelineBox {
    padding: 50px 20px; }
  .srve_liDv .btn.b_bt2 {
    max-width: 100%; }
  .srve_liDv .btn.b_bt {
    max-width: 100%; }
  .timeLine_detaiBox h4 {
    font-size: 16px; }
  .subTasks_Action__.tsk_center li {
    font-size: 14px; }
  .subTasks_Action__ li {
    font-size: 12px; }
  .accFootRow1 h4 {
    font-size: 13px; }
  .dashboard_Table .ReactTable .rt-tr .rt-td,
  .ReactTable .rt-thead .rt-resizable-header-content {
    font-size: 14px; }
  .PD_Al_h_txt.lt_heads {
    font-size: 13px; }
  a.decSpan {
    font-size: 13px; } }

@media (max-width: 1200px) {
  .grph_dv {
    height: 175px;
    display: flex;
    align-items: end; }
  .Sub_Tasks_Hdng__ .subTsks_hdngUl li {
    font-size: 14px; }
  .cstomDialog {
    width: 90%; }
  .title_frameDv {
    width: 100%; }
  .quesAns_box__ {
    width: 100%; }
  .circQues {
    width: 70px;
    height: 70px; }
  .answrShw li {
    width: 100%; }
  .ansRatngUl {
    width: 100%;
    margin-left: 0; }
  .ansRatngUl li .labelLit p {
    font-size: 15px; }
  .res_info2D {
    font-size: 15px; }
  .crtdByH {
    font-size: 15px; }
  .queBxFoot h4 {
    font-size: 15px; }
  .overlay.show {
    background: transparent; }
  .bodyNormal.right {
    margin-right: 0px; }
  .bodyNormal.left {
    margin-left: 0px; }
  .main_heading_cmn- h1 {
    font-size: 30x; }
  .dcmntBtns_dv .btn {
    font-size: 12px; }
  .cmn-btn1.eye-btn {
    padding-right: 30px !important;
    font-size: 12px; }
  .pad_l_50Ts {
    padding: 0px 15px; }
  .tskModRow1::before {
    left: 50%; }
  .action_calendar .rbc-row-segment .rbc-event-content {
    font-size: 12px; } }

@media (max-width: 1199px) {
  .notify_ul__ li {
    width: 25%; }
  .notify_bor {
    border-left: solid 1px var(--main-color); }
  .col_hgCmn.noty_col {
    min-height: auto;
    padding-bottom: 30px; }
  .status_box1 h4.hdng {
    font-size: 18px !important; } }

@media (max-width: 1024px) {
  .resume_dialog {
    width: 96%;
    left: 2%; }
  .subTasks_Action__.tsk_center li {
    font-size: 14px; }
  .cstomDialog.widBig {
    min-width: auto;
    width: 90%; }
  .subTasks_Action__ li .sbTsk_li {
    font-size: 12px; }
  .posted_ul12__ {
    width: 80%;
    margin-left: 0; }
  .CABinfo_lstBox__ {
    padding: 15px 0px; }
  .ans_colTr {
    padding-left: 15px; } }

@media (max-width: 991px) {
  /* .status_row--{display: block;} */
  .srchCol_r {
    padding-right: 0; }
  .filCol_l {
    padding-left: 0; }
  /* .asideCol1{padding-right: 0;} */
  .mainCntntCol1 {
    padding-left: 0; }
  .prof_left {
    width: 100%;
    padding-right: 0; }
  .prof_left .profImg {
    margin: 10px auto; }
  .prof_right {
    float: left;
    width: 100%;
    padding: 15px 0px; }
  .usName {
    text-align: center; }
  .main_heading_cmn- .hdng_btn {
    margin-top: 0px; }
  .subsTasks_ul li.active:after {
    display: none; }
  .subtask_info_box-- {
    margin-left: 0;
    width: 100%; }
  .mr_ipd_10 {
    margin-bottom: 15px; }
  .Menu4_Div- {
    margin-top: 0; }
  .noPd_R_ipd {
    padding-right: 0; }
  .noPd_l_ipd {
    padding-left: 0; }
  .posted_ul12__ {
    width: 90%; }
  .trngBoxAc h4 {
    font-size: 16px; }
  .pd_lr_50 {
    padding-left: 15px;
    padding-right: 15px; }
  .grph_dv {
    height: auto; }
  .detail_row {
    display: block !important; }
  .st_l.no_pd_l {
    padding-left: 7px; }
  .st_r.no_pd_r {
    padding-right: 7px; } }

@media (max-width: 767px) {
  .status_row-- {
    display: block; } }

.oc_bg_btn {
  background-color: #f37021; }

.s-def1 .Select-arrow::before,
.s-def1 .Select.is-open > .Select-control .Select-arrow::before {
  font-family: icons_me;
  color: #fff;
  font-size: 14px;
  margin-left: -10px; }

.s-def1 .Select-arrow::before {
  content: "\66"; }

.s-def1 .Select.is-open > .Select-control .Select-arrow::before {
  content: "\68"; }

.s-def1 .Select-control {
  height: 40px;
  border: 0;
  border-radius: var(--b-radius) !important;
  position: relative; }

.s-def1 .Select-value-label {
  color: #fff !important; }

.s-def1.s1 .Select-control {
  height: 30px; }

.s-def1 .Select-menu-outer {
  border-radius: var(--border-radius15);
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 300;
  overflow: hidden; }

.s-def1 .Select-menu-outer div:hover,
.s-def1 .s-def2 .Select-menu-outer div:hover {
  background: #e6e6e5;
  color: var(--txt-color); }

.s-def1 .Select--single > .Select-control .Select-value,
.s-def1 .Select-placeholder {
  color: #fff !important;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  line-height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 40px;
  height: 40px; }

.s-def1 .Select-input {
  display: none !important; }

.s-def1 .is-searchable .Select-input {
  display: block !important; }

.s-def1 .is-searchable .Select-input input {
  width: 100% !important; }

.s-def1 .Select-arrow {
  border-width: 0 !important; }

.s-def1.s1 .Select--single > .Select-control .Select-value,
.s-def1.s1 .Select-placeholder {
  line-height: 30px;
  height: 30px; }

.s-def1.s-def1-color div.Select.Select--single .Select-control,
.s-def1.s-def1-color div.Select.is-focused:not(.is-opne) > .Select-control,
.s-def1.s-def1-color div.Select.is-focused > .Select-control,
.s-def1.s-def1-color div.Select.is-open > .Select-control {
  background: #e6e6e6;
  background-color: #e6e6e6; }

.s-def1.s-def1-color .Select-arrow::before,
.s-def1.s-def1-color .Select-value-label,
.s-def1.s-def1-color .Select.is-open > .Select-control .Select-arrow::before {
  color: #1e1e1e !important; }

.s-def1.s-def1-color .Select-placeholder {
  color: #1e1e1e !important; }

.s-def1.s1.req_s1 .Select-control {
  height: 20px; }

.s-def1.s1.req_s1 .Select--single > .Select-control .Select-value,
.s-def1.s1.req_s1 .Select-placeholder {
  line-height: 20px; }

.s-def1.s1.req_s1 .Select.is-open > .Select-control .Select-arrow::before {
  top: -8px; }

.s-def1.s1.req_s1 .Select-arrow::before {
  top: -8px; }

.s-def2 .Select-menu-outer {
  border-radius: var(--border-radius15);
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 300;
  overflow: hidden; }

.s-def2 .Select-arrow {
  border-width: 0 !important; }

.s-def2.s2 .Select--single > .Select-control .Select-value,
.s-def2.s2 .Select-placeholder {
  line-height: 30px;
  height: 30px; }

.s-def2 .Select-control {
  height: 40px;
  border-color: var(--b-color);
  border-radius: var(--b-radius) !important;
  background: 0 0 !important;
  background-color: none !important;
  position: relative; }

.s-def2.s2 .Select-control {
  height: 30px; }

.s-def2.s2 .Select-input {
  height: 30px; }

.s-def2 .Select-arrow::before,
.s-def2 .Select.is-open > .Select-control .Select-arrow::before {
  content: "\e00e";
  font-family: icons_me;
  color: var(--txt-color);
  font-size: 20px;
  margin-left: -15px; }

.s-def2 .Select-control .Select-input {
  width: 100%; }

.s-def2 .Select-control .Select-input > input {
  width: 100% !important; }

.s-def1.s1 .Select-arrow::before {
  top: -6px;
  font-size: 11px;
  margin-left: -6px; }

.s-def1.s1 .Select.is-open > .Select-control .Select-arrow::before {
  font-size: 11px;
  margin-left: -6px; }

.navser {
  width: 500px;
  margin: 0 auto; }
  .navser .s-def2 .Select-control {
    border-color: #fff; }
  .navser .s-def2 .Select-arrow-zone .Select-arrow::before {
    color: #fff !important; }
  .navser .s-def2 input {
    color: #fff; }

.big-search {
  display: flex;
  width: 100%;
  position: relative; }
  .big-search input {
    font-size: 20px;
    height: 50px;
    width: 100%;
    padding: 0 50px;
    outline: 0;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-color: var(--b-color);
    border-radius: var(--b-radius); }
  .big-search button {
    position: absolute;
    background: 0 0;
    right: 5px;
    height: 50px;
    width: 50px;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer; }
  .big-search button span {
    font-size: 30px;
    display: flex;
    justify-content: center;
    color: var(--txt-color); }
  .big-search.l-search button {
    left: 5px; }

.small-search {
  display: flex;
  width: 100%;
  position: relative; }
  .small-search input {
    font-size: 15px;
    height: 40px;
    width: 100%;
    padding: 0 40px;
    padding-left: 15px;
    outline: 0;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-color: var(--b-color);
    border-radius: var(--b-radius); }
  .small-search button {
    position: absolute;
    background: 0 0;
    right: 5px;
    height: 40px;
    width: 40px;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer; }
  .small-search button span {
    font-size: 25px;
    display: flex;
    justify-content: center;
    color: var(--txt-color); }
  .small-search.l-search input {
    padding-left: 40px; }
  .small-search.l-search button {
    left: 5px; }
  .small-search.n2 input {
    min-height: 30px;
    height: 30px;
    padding-left: 30px;
    padding-right: 15px; }
  .small-search.n2 button {
    height: 30px;
    width: 30px; }

.back_arrow {
  color: #1e1e1e; }
  .back_arrow {
    color: var(--txt-color); }
  .back_arrow {
    cursor: pointer; }
  .back_arrow:before {
    font-size: 25px; }
  .back_arrow span {
    font-size: 25px;
    display: inline-flex;
    align-items: center;
    color: var(--txt-color); }

.h-h1 {
  color: var(--txt-color);
  font-weight: 600;
  font-size: 36px; }

.h-c-btn1 {
  display: flex;
  align-items: center;
  height: 40px;
  background: var(--bg-color);
  border-radius: var(--b-radius);
  cursor: pointer;
  color: #fff;
  width: 100%; }
  .h-c-btn1 i {
    display: flex;
    align-items: center;
    font-size: 26px;
    height: 100%;
    width: 45px;
    justify-content: center;
    padding-top: 3px; }
  .h-c-btn1 span {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%; }

.task_table_footer {
  display: flex;
  justify-content: flex-end; }
  .task_table_footer a {
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid;
    cursor: pointer;
    color: initial;
    border-color: #1e1e1e; }
  .task_table_footer a:hover {
    color: var(--txt-color); }
  .task_table_footer a:first-child {
    border-color: transparent; }
  .task_table_footer a u {
    text-decoration: underline; }

.txt_t1 {
  font-size: 18px;
  font-weight: 400; }

.txt_t2 {
  font-size: 15px;
  font-weight: 400; }

.table_view_icon div {
  font-size: 10px;
  font-weight: 400; }
  .table_view_icon div span::before {
    font-size: 35px;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 25px;
    color: var(--txt-color); }

.chart_txt_1,
.chart_txt_2,
.chart_txt_3 {
  font-size: 17px;
  line-height: 18px; }

.chart_txt_1 {
  color: var(--crm-success_color); }

.chart_txt_2 {
  color: var(--crm-Processing_color); }

.chart_txt_3 {
  color: var(--crm-Rejected_color); }

.chart_txt_4 span {
  margin-right: 21px;
  color: #777;
  cursor: pointer;
  font-weight: 300;
  font-size: 15px;
  display: inline-flex; }

.chart_txt_4 span.active {
  color: var(--txt-color); }

.chart_txt_5 span {
  margin-right: 0; }

.circul-c {
  display: table;
  height: 100%;
  width: 100%;
  background: #eee;
  border-radius: var(--b-radius);
  padding: 0 30px;
  padding: 0 15px; }
  .circul-c h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px; }

.tabs_1 ul {
  display: flex;
  width: 100%;
  padding: 0;
  list-style: none;
  margin: 30px 0; }
  .tabs_1 ul li {
    flex: 1 1;
    padding: 0 15px; }
  .tabs_1 ul li a {
    padding: 10px;
    border-radius: var(--b-radius);
    display: block;
    font-size: 18px;
    text-align: center;
    background: #cdcccc;
    font-weight: 400; }
  .tabs_1 ul li a.active {
    color: var(--txt-color);
    background: #e6e6e5; }
  .tabs_1 ul li a:hover {
    color: var(--txt-color);
    background: #e6e6e5; }
  .tabs_1 ul li a:focus {
    color: var(--txt-color);
    background: #e6e6e5; }

.Lates_up_1 {
  display: flex; }

.Lates_up_b {
  display: flex;
  background: #fff;
  border-radius: var(--b-radius) var(--b-radius) 0px 0px;
  border: 1px solid;
  border-color: var(--b-color); }
  .Lates_up_b > div {
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e; }

.Lates_up_btn {
  min-width: 90px;
  text-align: center;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  flex-wrap: wrap; }
  .Lates_up_btn i:before {
    display: flex;
    justify-content: center;
    font-size: 24px;
    line-height: 23px;
    align-items: center;
    cursor: pointer;
    color: var(--txt-color); }
  .Lates_up_btn span {
    display: flex;
    font-size: 10px;
    text-align: center;
    line-height: 10px;
    width: 100%;
    justify-content: center; }

.Lates_up_2 {
  display: flex; }
  .Lates_up_2 .Lates_up_txt2.btn-1 {
    border-radius: 0px 0px var(--b-radius) var(--b-radius);
    padding: 5px 15px;
    font-weight: 300;
    font-size: 13px; }
  .Lates_up_2 div {
    padding: 5px;
    min-height: auto;
    font-size: 15px; }

.Lates_up_txt {
  width: 100%; }

.Lates_up_time_date {
  max-width: 210px;
  width: 210px;
  font-size: 12px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end; }

.Lates_up_txt2 {
  width: calc(100% - 0px);
  margin-top: 0px; }

.Lates_up_a {
  font-size: 15px;
  font-weight: 600;
  color: #1e1e1e; }

.Partt_d1_txt_1 {
  font-size: 15px;
  font-weight: 600; }

.Partt_d1_txt_2 {
  line-height: 15px;
  display: flex;
  font-size: 14px;
  font-weight: 400; }
  .Partt_d1_txt_2 strong {
    font-weight: 600; }
  .Partt_d1_txt_2 span {
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    display: inline-flex;
    word-break: break-all;
    padding-left: 3px; }

.Partt_d1_txt_4 {
  font-size: 24px;
  margin-bottom: 10px; }

.crm_H_set_1 {
  min-width: 100px; }

.crm_H_set_2 {
  min-width: 140px; }

.crm_H_set_3 {
  min-width: 100px; }

.v4_pro_d1_a1__ {
  display: flex;
  padding: 15px 0;
  align-items: top; }
  .v4_pro_d1_a1__.v4_1 {
    margin-right: 45px; }

.in_prog_btn {
  display: inline-flex;
  background: #25aae1;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  min-height: 25px;
  align-items: center;
  border-radius: var(--b-radius);
  min-width: 140px; }

.v4_pro_d2__ {
  background: #fff;
  padding: 15px; }

.action_ix_1__ {
  justify-content: space-between; }
  .action_ix_1__ .icon-views {
    font-size: 23px;
    display: inline-block;
    line-height: 21px;
    vertical-align: middle;
    color: #1ca177; }

.V4_pro_d3__ {
  background: #dec3f8;
  border-radius: var(--b-radius); }
  .V4_pro_d3__ .panel {
    background: 0 0; }
  .V4_pro_d3__ .panel-default .panel-heading {
    background-color: none;
    background: 0 0; }
  .V4_pro_d3__ .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: var(--b-color); }
  .V4_pro_d3__ .v4_panel_title_.panel-title a i {
    color: var(--b-color); }
  .V4_pro_d3__ .schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(even) .other_conter,
  .V4_pro_d3__ .schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(even) .rt-tr {
    background: #fff !important; }
  .V4_pro_d3__ .rt-expandable i {
    color: var(--b-color); }
  .V4_pro_d3__ .v4_panel_title_.panel-title a:focus {
    color: #1e1e1e; }
  .V4_pro_d3__ .panel-group {
    margin-bottom: 0; }

.v4_panel_title_.panel-title a i {
  transform: rotate(180deg);
  top: 0;
  margin-right: 10px;
  font-size: 15px; }

.v4_panel_title_ a > div {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.v4_panel_title_ a > div .Partt_d1_txt_1 {
  margin-bottom: 0; }

.complete_msg .ci_btn {
  background: #13a650; }

.complete_msg .ci_date {
  color: #13a650; }

.incomplete_msg .ci_btn {
  background: #25aae1; }

.incomplete_msg .ci_date {
  color: #25aae1; }

.unsuccessful_msg .ci_btn {
  background: #ff0000; }

.unsuccessful_msg .ci_date {
  color: #ff0000; }

.pending_msg .ci_btn {
  background: #feb200; }

.pending_msg .ci_date {
  color: #feb200; }

.unsuccessful_msg .ci_btn {
  background: red; }

.title_input {
  font-weight: 400;
  font-size: 14px;
  padding-left: 15px; }
  .title_input.pl-0 {
    padding-left: 0; }

.title_sub_modal {
  font-size: 17px;
  font-weight: 600; }

input.p-hidden,
input.p-hidden.error {
  margin-top: -40px;
  visibility: hidden;
  width: 100%; }

.all_notes {
  min-width: 100%;
  overflow: hidden; }
  .all_notes .single_notes:last-child {
    border: 0px solid #777;
    padding-right: 0; }

.all_notes_1 {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 10px; }
  .all_notes_1 .single_notes:first-child {
    padding-left: 0; }

.single_notes {
  width: 33.3%;
  display: inline-flex;
  flex: 0 0 auto;
  white-space: initial;
  padding: 0 15px;
  border-right: 1px dashed;
  border-color: var(--bg-color); }

.single_note_data {
  min-height: 140px;
  background: #fff;
  padding: 10px 15px;
  border-radius: var(--b-radius);
  border: 1px solid;
  border-color: var(--bg-color);
  height: 88%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between; }
  .single_note_data p {
    width: 100%; }
  .single_note_data .text-right {
    width: 100%; }
  .single_note_data .icon {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: var(--crm-Rejected_color);
    color: #fff;
    justify-content: center;
    border-radius: var(--border-radius100);
    cursor: pointer;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    line-height: 38px;
    font-size: 17px;
    margin-left: 3px; }
  .single_note_data .add1_a1 {
    font-size: 26px;
    padding-top: 2px; }
  .single_note_data .icon::before {
    justify-content: center;
    align-items: center;
    height: 100%; }

.Single_note_history {
  text-align: right; }

.notes_txt_area {
  width: 100%;
  padding: 15px;
  border: 1px solid;
  border-color: var(--b-color);
  border-radius: var(--b-radius);
  min-height: 160px; }

.border-das_line {
  border: 1px dashed;
  border-color: var(--b-color); }

.Ser_div1 {
  display: flex;
  background: #fff;
  border-radius: var(--b-radius);
  border: 1px solid;
  border-color: var(--b-color);
  font-weight: 400;
  font-size: 15px; }

.Ser_div_txt1 {
  font-size: 14px;
  font-size: 12px;
  font-weight: 400; }

.s_txt {
  font-size: 15px; }

.sel_div1 {
  height: 40px;
  background: var(--bg-color);
  display: inline-flex;
  padding: 15px;
  width: 100%;
  border-radius: var(--b-radius);
  align-items: center;
  color: #fff;
  font-weight: 300;
  font-size: 14px; }

.sel_div3 {
  display: flex;
  background: var(--bg-color);
  align-items: center;
  border-radius: var(--b-radius);
  height: 40px;
  padding-right: 0; }

.sel_div3:after {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  border-left: 1px solid;
  height: 30px;
  border-color: var(--b-color); }

.dollar_input {
  display: flex;
  position: relative;
  padding-right: 4px;
  padding-left: 0; }
  .dollar_input input {
    padding-left: 30px;
    border-radius: var(--b-radius);
    width: 100%;
    border: 0;
    height: 32px; }
  .dollar_input span {
    position: absolute;
    font-size: 18px;
    width: 40px;
    text-align: center;
    font-weight: 400;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center; }

.allocate_title {
  color: #fff;
  text-align: right;
  color: #fff;
  text-align: right;
  font-weight: 400;
  font-size: 15px; }

.ser_fund_1 {
  min-height: 40px;
  align-items: center;
  background: var(--bg-color);
  border-radius: var(--b-radius);
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  font-weight: 400; }

.ser_fund_b {
  max-width: 40%;
  width: 40%;
  text-align: right;
  display: inline-flex;
  justify-content: space-between;
  align-items: center; }
  .ser_fund_b a.btn-3 {
    font-weight: 400;
    width: 100px;
    display: inline-flex;
    background: var(--crm-Rejected_color);
    margin-right: 5px;
    align-items: center;
    padding: 0;
    padding-bottom: 1px; }

.ser_fund_a {
  border-right: 1px solid #fff;
  padding-left: 30px;
  max-width: 60%;
  width: 60%; }

.ser_fund_b span {
  padding-left: 15px; }

.par_abil_right {
  border: 1px solid;
  border-color: var(--b-color);
  border-radius: var(--b-radius);
  background: #fff;
  display: flex;
  height: 100%; }

.par_abil_right_in {
  width: 100%; }

.par_abil_txt {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding-top: 15px; }

.par_abil_txt span {
  border-bottom: 1px solid;
  border-color: var(--b-color); }

.par_abil_1 {
  padding: 15px 15px; }

.par_abil_2 {
  padding: 30px 15px; }

.par_abil_3 {
  padding: 30px 25px; }

.par_abil_title {
  font-size: 20px;
  font-weight: 600;
  color: var(--txt-color); }

.Partt_d1_txt_3 {
  font-size: 14px; }
  .Partt_d1_txt_3 strong {
    font-weight: 600; }
  .Partt_d1_txt_3 span {
    font-weight: 400; }

.FMs_btn {
  width: 185px;
  margin: 0 auto; }

.shift_week_1 {
  -ms-flex-wrap: wrap;
  display: flex;
  position: relative;
  z-index: 1; }

.shift_days_1 {
  width: 14.28%;
  box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset; }

.Shift_day_blank {
  min-height: 15px;
  z-index: -1;
  position: relative;
  cursor: pointer; }

.Shift_day_n {
  font-weight: 500;
  padding: 5px;
  border-bottom: 1px solid;
  border-color: #888;
  font-size: 15px; }

.add_btn1 {
  display: inline-flex;
  width: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .add_btn1 i:before {
    width: 40px;
    height: 40px;
    background: var(--bg-color);
    color: #fff;
    font-size: 35px;
    display: inline-flex;
    border-radius: var(--border-radius100);
    justify-content: center;
    align-items: center; }
  .add_btn1 i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px; }

.shift_h1 {
  font-size: 17px;
  font-weight: 600;
  color: var(--txt-color); }

.Shift_times_div div {
  align-items: center;
  display: inline-flex;
  margin-right: 3%;
  font-size: 15px;
  font-weight: 400; }
  .Shift_times_div div span {
    width: 17px;
    height: 17px;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    margin-right: 5px; }
  .Shift_times_div div span {
    width: 15px;
    height: 15px;
    border-radius: 3px; }

.am_shift {
  background: #f1ebf6; }

.pm_shift {
  background: #dec3f8; }

.so_shift {
  background: #b05dfc; }

.na_shift {
  background: #992bff; }

.task_modal {
  padding: 15px 30px; }
  .task_modal .custom-modal-header .Modal_title {
    font-size: 20px;
    font-weight: 600;
    padding: 6px 0;
    display: inline-flex;
    align-items: center;
    color: var(--txt-color); }
  .task_modal .custom-modal-header {
    border-color: var(--bg-color); }
  .task_modal .custom-modal-header .Modal_close_i {
    font-size: 24px;
    padding-right: 0; }
  .task_modal .custom-modal-footer {
    border-color: var(--b-color); }

.task_atta_file {
  border-right: 1px dashed;
  border-color: var(--b-color); }
  .task_atta_file div {
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .task_atta_file span i:before {
    font-size: 50px;
    color: var(--txt-color); }
  .task_atta_file span {
    text-align: center;
    width: 100%; }

.task_N_txt textarea {
  width: 100%;
  min-height: 79px;
  border: 1px solid;
  border-color: var(--b-color);
  border-radius: 13px;
  margin: 0;
  padding: 0;
  resize: vertical;
  padding: 15px; }

.v-c-btn1.n3 i {
  font-size: 35px; }

.flex_Table {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }

.flex_Table-row {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  flex-flow: row nowrap;
  border-radius: var(--b-radius);
  margin-bottom: 10px; }
  .flex_Table-row div:last-child {
    border: 0; }
  .flex_Table-row:nth-of-type(odd) {
    background-color: #fff; }
  .flex_Table-row:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.06); }

.flex_Table-row-item {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  flex-basis: 0;
  -ms-flex-preferred-size: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  border-right: 1px solid;
  padding: 8px 15px;
  font-size: 14px;
  border-color: rgba(0, 0, 0, 0.09); }
  .flex_Table-row-item b {
    padding-right: 3px;
    font-weight: 500; }

.ft_1 {
  max-width: 15%; }

.lgOutIc:focus,
.lgOutIc:hover,
.nav_ulic li a:focus,
.nav_ulic li a:hover,
.nav_ulic li span:focus,
.nav_ulic li span:hover,
.nav_ulic li.active a,
.nav_ulic li.active span {
  color: var(--main-color); }

.lgOutIc {
  font-size: 23px;
  color: #fff; }

.nav_ulic {
  width: 100%;
  text-align: right;
  margin-bottom: 0; }
  .nav_ulic li {
    display: inline-block; }
  .nav_ulic li a,
  .nav_ulic li span {
    display: block;
    color: #fff;
    padding: 0 7px;
    font-size: 23px;
    cursor: pointer; }

.flexRow {
  display: flex;
  align-items: center;
  height: 100%; }

.btn-file {
  height: 40px;
  margin-bottom: 0;
  width: 100%; }

.nav_ulil {
  padding: 0;
  text-align: left; }

.txt_sms {
  font-size: 18px;
  font-weight: 400; }

.Break_dow_T {
  font-size: 13px;
  font-weight: 500;
  padding: 15px 15px;
  border-radius: var(--b-radius);
  border: 1px solid;
  border-color: transparent;
  background: #fff; }

.Break_dow_T_a {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis; }

.Break_dow_P1 {
  height: 45px;
  background: var(--crm-success_color);
  margin-top: -25px;
  position: relative;
  z-index: -1;
  border-radius: var(--b-radius); }

.Break_dow_P1 .progress-bar {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 400;
  background: var(--crm-Rejected_color);
  font-size: 10px; }

.Break_dow_Scroll_D {
  height: 260px;
  overflow-y: auto; }

.Staff_U_img {
  width: 80px;
  height: 80px;
  background: #eee;
  border-radius: var(--border-radius100);
  overflow: hidden;
  margin: 0 auto; }

.Staff_U_img img {
  width: 100%; }

a.v-c-btn1:focus {
  color: #fff !important; }

.v-c-btn1 span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-align: center; }

div.v-c-btn1 {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.Cust_Sel_my .rrs {
  position: relative;
  box-sizing: border-box; }

.Cust_Sel_my .rrs *,
.Cust_Sel_my .rrs :after,
.Cust_Sel_my .rrs :before {
  box-sizing: border-box; }

.Cust_Sel_my .rrs__button {
  position: relative;
  cursor: pointer;
  height: 30;
  line-height: 30px;
  background: #fff; }

.Cust_Sel_my .rrs__button--disabled {
  color: #999;
  background: #f5f5f5;
  cursor: default; }

.Cust_Sel_my .rrs__button:focus {
  outline: 0; }

.Cust_Sel_my .rrs__button + .rrs__options {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #f9e0fd;
  position: absolute;
  z-index: 2;
  top: 40px;
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden; }

.Cust_Sel_my .rrs--options-visible .rrs__options {
  height: auto;
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 305px;
  padding-bottom: 10px; }

.Cust_Sel_my .rrs__option {
  cursor: pointer;
  padding: 2px 15px;
  margin: 0; }

.Cust_Sel_my .rrs__option * {
  pointer-events: none; }

.Cust_Sel_my .rrs__option.rrs__option--disabled {
  color: #999;
  background: #f5f5f5;
  cursor: default; }

.Cust_Sel_my .rrs__option.rrs__option--header {
  color: #1e1e1e;
  cursor: default;
  font-size: 17px;
  font-weight: 400;
  background: #f9e0fd;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid;
  border-color: var(--b-color);
  padding-top: 7px;
  padding-bottom: 7px; }

.Cust_Sel_my .rrs__label {
  padding: 0 2rem 0 1rem;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  font-size: inherit;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius); }

.Cust_Sel_my .rrs__multiselect-label {
  display: inline-flex;
  max-width: 100%;
  line-height: 1; }

.Cust_Sel_my .rrs__multiselect-label__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 16px; }

.Cust_Sel_my .rrs__multiselect-label__badge {
  border: 1px solid #1e1e1e;
  padding: 1px 6px;
  margin: 0 0 0 4px;
  border-radius: 8px;
  color: #1e1e1e;
  font-size: 11px;
  vertical-align: middle;
  display: inline-block;
  background: #fff; }

.Cust_Sel_my .rrs .checkbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  top: -1px;
  margin: 2px 8px 2px 0;
  border-radius: 4px;
  color: #e1bfe6;
  font-size: 18px; }

.Cust_Sel_my .rrs .checkbox-icon {
  fill: #fff;
  position: absolute;
  left: 2px;
  top: 2px; }

.Cust_Sel_my .rrs__option.rrs__option--selected .checkbox i {
  color: #ffc107; }

.Cust_Sel_my .rrs .caret-icon {
  position: absolute;
  right: 1rem;
  top: 1.25rem; }

.Cust_Sel_my .rrs__option div {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.Cust_Sel_my .rrs_select {
  background: #fff;
  padding: 4px 12px;
  border-radius: var(--b-radius);
  font-size: 14px;
  font-weight: 400;
  width: 82%;
  border: 1px solid;
  border-color: var(--b-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.Cust_Sel_my .rrs__option.rrs__option--selected .rrs_select {
  background: var(--bg-color);
  color: #fff;
  border-color: #fff; }

.Cust_Sel_my .rrs__label__text {
  overflow: hidden; }

.Cust_Sel_my .rrs--options-visible .rrs__button::after {
  content: "";
  border-right: 9px solid transparent;
  border-bottom: 13px solid #f9e0fd;
  width: 14px;
  height: 44px;
  display: inline-block;
  border-left: 10px solid transparent;
  position: absolute;
  top: 0;
  right: 15px; }

.Cust_Sel_my .rrs--options-visible .caret-icon {
  transform: rotate(180deg); }

.Cust_Sel_my.A2 .rrs__label {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px; }

.Cust_Sel_my.A2 .rrs__button + .rrs__options {
  top: 50px; }

.Cust_Sel_my.A2 .rrs--options-visible .rrs__button::after {
  height: 52px; }

.Cust_Sel_my.A2 .rrs__label i {
  font-size: 22px;
  line-height: 0; }

.Cust_Sel_my .rrs--options-visible .rrs__options::-webkit-scrollbar,
.Cust_Sel_my .dep_table .ReactTable .rt-tbody::-webkit-scrollbar {
  width: 3px; }

.Cust_Sel_my .rrs--options-visible .rrs__options::-webkit-scrollbar-track,
.Cust_Sel_my .dep_table .ReactTable .rt-tbody::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 10px; }

.Cust_Sel_my .rrs--options-visible .rrs__options::-webkit-scrollbar-thumb,
.Cust_Sel_my .dep_table .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 10px; }

.Cust_Sel_my .rrs--options-visible .rrs__options::-webkit-scrollbar-thumb:hover,
.Cust_Sel_my .dep_table .ReactTable .rt-tbody::-webkit-scrollbar-thumb:hover {
  background-color: var(--crm-Rejected_color); }

.Dis_R_div1 {
  height: 5px;
  background: var(--bg-color);
  width: 100%; }

.Dis_R_div2 {
  display: inline-block;
  display: inline-block;
  background: #ffff;
  padding: 5px 15px;
  border-radius: var(--b-radius);
  border: 5px solid;
  border-color: var(--bg-color); }
  .Dis_R_div2 p {
    margin: 0;
    font-size: 15px; }

.Htag {
  padding: 0 5px;
  border-left: 1px solid;
  border-right: 1px solid; }

.Staff_task_div1 {
  display: inline-flex;
  list-style: none;
  align-items: center; }
  .Staff_task_div1 li {
    display: inline-flex;
    margin-right: 15px; }
  .Staff_task_div1 li i {
    font-size: 18px;
    margin-right: 5px;
    line-height: 13px; }
  .Staff_task_div1 li span {
    font-size: 10px;
    font-size: 10px;
    align-items: center;
    display: inline-flex;
    color: #777; }

.Staff_VIcon i {
  display: flex;
  color: var(--txt-color);
  cursor: pointer; }

.Staff_TIcon i {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-left: 3px; }

.Suc_task {
  color: var(--crm-task_priority_high_color); }

.Pro_task {
  color: var(--crm-task_priority_medium_color); }

.Rej_task {
  color: var(--crm-task_priority_low_color); }

.priority_high_task {
  color: var(--crm-task_priority_high_color); }

.priority_medium_task {
  color: var(--crm-task_priority_medium_color); }

.priority_low_task {
  color: var(--crm-task_priority_low_color); }

.Staff_details_left {
  background: #fff;
  border-radius: var(--b-radius);
  padding: 15px 7px 30px; }

.Staff_f {
  justify-content: center;
  font-size: 17px;
  font-weight: 400;
  border-color: #1e1e1e;
  padding-top: 10px;
  margin-top: 30px; }

.PD_Al_h_txt {
  text-align: center;
  padding: 15px 5px;
  border-bottom: 1px solid;
  border-color: var(--b-color);
  margin-bottom: 15px;
  font-size: 19px; }

.PD_Al_icon {
  display: flex;
  line-height: 0;
  justify-content: space-between; }
  .PD_Al_icon a {
    display: inline-flex; }

.PD_Al_h_txt.lt_heads {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 17px; }

.PD_Al_div1 {
  border-radius: var(--b-radius);
  background: #ececec;
  padding: 0px 15px;
  min-height: 800px;
  max-height: 800px; }

.Per_txt {
  display: inline-block;
  background: #fff;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  color: #1e1e1e;
  padding: 5px;
  border-radius: var(--border-radius15);
  border: 1px solid var(--b-color); }

.Per_icon {
  display: inline-flex;
  align-items: center;
  line-height: initial;
  margin-left: 5px; }
  .Per_icon i {
    font-size: 20px; }

.Per_SC .Select-menu-outer {
  background: #f9e0fd; }
  .Per_SC .Select-menu-outer .Select-option {
    background: #f9e0fd;
    padding: 2px 10px; }

.s-def1.Per_SC div.Select-arrow::before {
  content: "\e07e";
  font-family: icons_me; }

.s-def1.Per_SC .Select-arrow::before,
.s-def1.Per_SC .Select.is-open > .Select-control .Select-arrow::before {
  content: "\e07e";
  font-family: icons_me; }

.s-def1.Per_SC .Select-menu-outer div:hover {
  background: #f9e0fd; }

.s-def1.Per_SC .Select--multi .Select-value {
  border: 0 solid #fff;
  margin-top: 0;
  background: 0 0; }

.s-def1.Per_SC .Select--multi .Select-multi-value-wrapper {
  vertical-align: -webkit-baseline-middle; }

.s-def1.Per_SC span.Select-value-icon {
  color: #fff;
  border: 0 solid #fff; }

.s-def1.Per_SC .Select--multi .Select-value-icon {
  padding-right: 0; }

.LA_i1 {
  font-size: 25px;
  display: inline-flex;
  align-items: center;
  line-height: 0; }

.LA_i2 {
  font-size: 21px;
  display: inline-flex;
  align-items: center;
  line-height: 0; }

.flex_break {
  width: 100%; }

.crm_plus_icon i {
  color: #b968c7; }

.Scroll_div {
  background: #fff;
  padding: 12px 15px;
  border-radius: var(--border-radius15);
  overflow-y: auto;
  min-height: 190px;
  max-height: 190px; }

.Scroll_div_parents {
  overflow: hidden;
  border: 1px solid var(--b-color);
  border-radius: var(--border-radius15); }

.Other-option_div {
  background: #fff;
  border: 1px solid var(--b-color);
  padding: 6px 15px;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border-radius: var(--b-radius);
  text-align: left; }

.br_das {
  border-right: 1px dashed;
  border-color: var(--b-color); }

.bl_das {
  border-left: 1px dashed;
  border-color: var(--b-color); }

.Doc_D1_01 {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--b-color);
  padding: 10px 15px;
  align-items: center;
  border-radius: var(--border-radius15); }

.Doc_D1_03 {
  display: flex;
  align-items: center; }
  .Doc_D1_03 i {
    font-size: 40px;
    color: var(--txt-color);
    display: inline-flex; }
  .Doc_D1_03 small {
    font-size: 12px; }

.Doc_D1_04 {
  font-size: 25px;
  color: var(--txt-color);
  cursor: pointer; }

.Call_Ref_r1 {
  display: flex;
  align-items: center; }
  .Call_Ref_r1 i {
    display: inline-flex;
    font-size: 20px;
    background: var(--bg-color);
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff; }

.Call_Ref_r1_1 {
  width: 73px;
  border: 2px solid var(--b-color);
  border-radius: var(--border-radius100);
  margin-right: 15px; }

.Call_Ref_i2 {
  display: inline-flex;
  font-size: 15px;
  background: var(--bg-color);
  padding: 6px 12px;
  border-radius: 20px;
  color: #fff;
  font-weight: 300; }
  .Call_Ref_i2 i {
    margin-left: 5px; }
  .Call_Ref_i2:hover {
    color: #fff; }

.Call_Ref_te_t1 {
  font-size: 15px;
  font-weight: 400;
  display: inline-flex;
  margin-right: 10px; }

.L-d-1 {
  min-height: 100%;
  max-height: 300px;
  overflow: auto; }

.L-d-t1,
small.L-d-t1 {
  font-size: 13px;
  font-weight: 400; }

.L-d-i1 {
  color: #fff;
  width: 130px;
  height: 130px;
  background: #01a451;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 86px;
  line-height: 0;
  border-radius: 12px;
  margin: 0 auto; }

.shift_week_add {
  align-items: flex-end;
  display: inline-flex;
  margin-left: 7px; }

.shift_week_title {
  font-size: 12px;
  display: inline-flex;
  min-width: 50px; }

.Update-all-1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #fff;
  border: 1px solid;
  border-color: var(--b-color);
  border-radius: var(--b-radius);
  overflow: hidden; }

.Update-all-1a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: var(--bg-color); }
  .Update-all-1a span {
    padding: 7px 15px;
    color: #fff; }

.Update-all-1b {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .Update-all-1b span {
    padding: 15px 15px; }

.Update-all-1bb {
  border-left: 1px solid;
  border-color: var(--b-color); }
  .Update-all-1bb div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .Update-all-1bb div a {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .Update-all-1bb div i {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .Update-all-1bb div i {
    font-size: 30px;
    color: var(--txt-color); }
  .Update-all-1bb div small {
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 5px; }

.Update-all-1ba {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  display: inline-flex;
  justify-content: left;
  align-items: center; }

.Update-all-1aa {
  font-size: 17px;
  font-weight: 400;
  align-items: center;
  display: inline-flex; }

.Update-all-1ab {
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  display: inline-flex; }

.Update-all-main {
  min-height: 100%;
  max-height: 545px;
  overflow: auto;
  padding-right: 10px;
  margin-top: 10px; }
  .Update-all-main .Update-all-1 {
    margin: 15px 0; }

.durationtk_ul__ {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px; }
  .durationtk_ul__ li {
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
    user-select: none; }
  .durationtk_ul__ li span {
    padding-left: 15px;
    padding-right: 15px;
    color: var(--bg-color);
    opacity: 0.5; }
  .durationtk_ul__ li.active span,
  .durationtk_ul__ li:active,
  .durationtk_ul__ li:hover span {
    opacity: 1;
    text-decoration: underline; }

.Crm .durationtk_ul__ li span {
  color: var(--txt-color); }

.cmn_font_crm {
  color: var(--txt-color); }

.Ser_Anal_a1 {
  display: flex;
  background: #e6e6e5;
  padding: 30px 15px;
  border-radius: var(--b-radius); }

.Ser_Anal_ab {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between; }

.Ser_Anal_aba {
  display: flex;
  display: flex;
  margin: 30px 0; }

.Ser_Anal_aba-2 {
  padding-left: 45px; }
  .Ser_Anal_aba-2 h4 {
    margin-left: -30px;
    margin-top: 5px; }
  .Ser_Anal_aba-2 div {
    font-size: 15px;
    font-weight: 300;
    margin-top: 3px; }

.Ser_Anal_aba-1 i {
  font-size: 27px;
  position: relative; }

.Ser_Anal_aba-1 i span {
  position: absolute;
  width: 1px;
  height: 13px;
  background: #000;
  bottom: -6px;
  left: 50%; }

.Ser_Anal_aba-1 i::after {
  content: "";
  width: 45px;
  height: 1px;
  background: #000;
  display: inline-block;
  position: absolute;
  left: 13px;
  bottom: -6px; }

.Ser_Anal_aba-1 i span::after {
  display: inline-flex;
  width: 7px;
  height: 7px;
  background: #000;
  content: "";
  position: absolute;
  right: -45px;
  top: 9px;
  border-radius: var(--border-radius100); }

.Oncall_P .Ser_Anal_aba-1 i span,
.Oncall_P .Ser_Anal_aba-1 i span::after,
.Oncall_P .Ser_Anal_aba-1 i::after {
  background: var(--b-color); }

.Oncall_P .Ser_Anal_aba-1 i {
  color: var(--b-color); }

.thread_P .Ser_Anal_aba-1 i span,
.thread_P .Ser_Anal_aba-1 i span::after,
.thread_P .Ser_Anal_aba-1 i::after {
  background: #ff5266; }

.thread_P .Ser_Anal_aba-1 i {
  color: #ff5266; }

.Private_M .Ser_Anal_aba-1 i span,
.Private_M .Ser_Anal_aba-1 i span::after,
.Private_M .Ser_Anal_aba-1 i::after {
  background: #4a7dd6; }

.Private_M .Ser_Anal_aba-1 i {
  color: #4a7dd6; }

.DG_a1 {
  background: #e6e6e5;
  padding: 30px 25px;
  border-radius: var(--b-radius); }

.DG_a1.V4_DG2__ {
  background: #fff; }

.DG-aa-3a {
  display: flex;
  margin: 15px 0; }

.DG-aa-3b span {
  background: #1e1e1e;
  color: #fff;
  min-width: 25px;
  display: inline-flex;
  font-size: 13px;
  min-height: 25px;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  border-radius: var(--border-radius100);
  margin-right: 10px;
  position: relative; }

.DG-aa-3c {
  font-size: 14px;
  font-weight: 300; }

.DG-aa-3c span {
  font-size: 15px;
  font-weight: 400;
  display: flex;
  margin-top: 2px; }

.DG_aa {
  display: flex;
  flex-wrap: wrap; }

.Oncall_P .DG-aa-3b span,
.Oncall_P .DG-aa-3b span::before {
  background: var(--b-color); }

.Oncall_P .DG_aa-1.br-1 {
  border-color: var(--b-color); }

.thread_P .DG-aa-3b span,
.thread_P .DG-aa-3b span::before {
  background: #ff5266; }

.thread_P .DG_aa-1.br-1 {
  border-color: #ff5266; }

.Private_M .DG-aa-3b span,
.Private_M .DG-aa-3b span::before {
  background: #4a7dd6; }

.Private_M .DG_aa-1.br-1 {
  border-color: #4a7dd6; }

.btn_g__ {
  display: inline-flex;
  padding: 6px 3px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  min-height: 40px;
  background: #ccc;
  border-radius: var(--b-radius);
  font-weight: 400; }
  .btn_g__ a {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; }

.cir_1 {
  font-size: 14px;
  margin-right: 10px; }

.tsk_sp > span {
  color: #000; }

.to_used__ {
  background: #f0eaf5;
  padding: 15px;
  border-radius: var(--b-radius); }

.to_used__ .Partt_d1_txt_3 {
  font-size: 13px; }

.Partt_d1_txt_1 span {
  font-weight: 300; }

.Downldad_signed_doc {
  display: inline-flex;
  align-items: center; }
  .Downldad_signed_doc i {
    padding-left: 10px; }
  .Downldad_signed_doc span {
    font-size: 12px;
    text-decoration: underline;
    margin-right: 5px; }
  .Downldad_signed_doc:hover {
    color: inherit; }

.Manage_div_1 .cstmSCroll1 {
  background: #fff;
  height: 100%; }

.cstmSCroll1 .scrollarea .scrollbar-container {
  opacity: 1 !important;
  background: var(--light-color-background) !important;
  border-radius: 20px; }

.cstmSCroll1 .scrollarea .scrollbar-container.vertical .scrollbar {
  background: var(--bg-color);
  border-radius: 20px; }

.cstmSCroll1 .scrollarea .scrollbar-container.active,
.cstmSCroll1 .scrollarea .scrollbar-container:hover {
  opacity: 1 !important; }

.cstmSCroll1.CrmScroll .scrollarea .scrollbar-container {
  background: var(--light-color-background) !important; }

.cstmSCroll1.CrmScroll .scrollarea .scrollbar-container.vertical .scrollbar {
  background: var(--bg-color) !important; }

.task_table_footer {
  padding-top: 7px; }

.Ser_Anal_aba_v4- {
  display: flex; }

.Ser_Anal_aba_v4--1 {
  margin-right: 15px; }
  .Ser_Anal_aba_v4--1 span {
    width: 25px;
    height: 25px;
    display: inline-flex;
    background: #000;
    border-radius: var(--b-radius); }
  .Ser_Anal_aba_v4--1 span {
    width: 25px;
    height: 25px;
    display: inline-flex;
    background: #000;
    border-radius: var(--b-radius); }

.Ser_Anal_aba_v4--2 h4 {
  font-size: 16px;
  font-weight: 600; }

.Ser_Anal_aba_v4--2 div {
  font-size: 14px;
  margin: 10px 9px 15px; }

.Ser_Anal_aba_v4- {
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%; }
  .Ser_Anal_aba_v4-.Oncall_P .Ser_Anal_aba_v4--1 span {
    background: #6401c0; }
  .Ser_Anal_aba_v4-.thread_P .Ser_Anal_aba_v4--1 span {
    background: #d22e2e; }
  .Ser_Anal_aba_v4-.Private_M .Ser_Anal_aba_v4--1 span {
    background: #2e9cca; }

.Ser_Anal_aba_v4- {
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%; }
  .Ser_Anal_aba_v4-.Oncall_P .Ser_Anal_aba_v4--1 span {
    background: #6401c0; }
  .Ser_Anal_aba_v4-.thread_P .Ser_Anal_aba_v4--1 span {
    background: #d22e2e; }
  .Ser_Anal_aba_v4-.Private_M .Ser_Anal_aba_v4--1 span {
    background: #2e9cca; }

.Ver_4_SAG_ {
  background: #fff;
  border-radius: var(--b-radius);
  padding: 30px;
  display: flex;
  width: 100%; }
  .Ver_4_SAG_ > .row {
    width: 100%;
    display: flex;
    align-items: center; }

.V4_DG1__ {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .V4_DG1__::after, .V4_DG1__::before {
    content: "";
    display: none; }

.DG-aa-2a {
  font-size: 14px; }

.DG-aa-2 h4,
.DG_aa-1 h4 {
  font-weight: 600;
  font-size: 16px; }

.DG-aa-3 {
  font-weight: 600;
  font-size: 16px; }

.attach_ul__ {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .attach_ul__ .attach_ll__ {
    width: 120px;
    text-align: center;
    display: flex;
    align-items: self-start;
    flex-wrap: wrap;
    align-content: baseline;
    margin-bottom: 30px;
    padding-right: 15px; }
  .attach_ul__ .attach_ll__ div {
    width: 100%;
    position: relative; }

.attach_icon__ {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .attach_icon__ span {
    font-size: 45px;
    color: var(--bg-color); }

.attach_title__ {
  font-size: 12px;
  word-break: break-word;
  font-weight: 600;
  margin-bottom: 10px; }

.attach_ll__ > .attach_ll11__ {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  min-height: 85px;
  align-content: space-between; }

.attach_subname__ {
  font-size: 12px;
  line-height: normal;
  word-break: break-all; }

.F_Park__ {
  padding-top: 20px; }

.report_chart_box {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px; }

.Pro_Not_act_1 i {
  background: var(--crm-Rejected_color); }

.Pro_Pen_1 i {
  background: var(--crm-Processing_color); }

.Pro_Suc_a3 i {
  background: var(--crm-success_color); }

.gm-style-iw.gm-style-iw-c {
  padding-top: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 30px !important; }

.Map_header {
  font-size: 16px;
  min-width: 290px;
  font-weight: 600;
  color: var(--bg-color);
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid;
  border-top: 1px solid; }

button.gm-ui-hover-effect {
  top: 21px !important;
  right: 17px !important;
  border: 1px solid var(--b-color) !important;
  display: flex !important;
  align-content: center;
  justify-content: center !important;
  margin: 0 !important;
  width: 16px !important;
  height: 16px !important;
  line-height: 23px !important;
  border-radius: 100%; }

.gm-ui-hover-effect img {
  margin: 0 !important; }

.list_map {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0; }
  .list_map.Map-margin {
    margin-top: 10px;
    margin-bottom: 15px; }
  .list_map.Map_footer {
    margin-top: 15px; }

.Total_Fund_details_01 {
  float: right;
  text-align: right;
  background: #fff;
  padding: 15px 15px 15px 30px; }

span.Total_Fund_boy {
  font-size: 14px;
  font-weight: 600;
  color: var(--bg-color); }

.Total_Fund_head {
  font-size: 13px;
  font-weight: 600;
  border-bottom: 2px solid var(--bg-color);
  margin-bottom: 10px;
  padding-bottom: 5px; }

.Break_dow_SD {
  margin-bottom: 15px; }

.V4_int_cont1b__ .set_select_small {
  width: 140px;
  float: right; }

@media screen and (max-width: 1550px) and (min-width: 1024px) {
  .tabs_1 ul li a {
    display: inline-flex;
    padding: 6px 3px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    min-height: 35px; }
  .s-def1 .Select-control {
    height: 35px; }
  .big-search input {
    height: 45px; }
  .big-search button span {
    font-size: 27px; }
  .LA_i1 {
    font-size: 20px; }
  .LA_i2 {
    font-size: 20px; }
  .PD_Al_icon {
    height: 100%; }
  .btn-1 {
    padding: 6px 3px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    min-height: 35px; }
  .Partt_d1_txt_2 {
    font-size: 13px; }
  .v-c-btn1.n2 {
    font-size: 14px; }
  .default-input,
  .small-search input {
    height: 35px; }
  .v-c-btn1 {
    min-height: 35px; }
  .call_refer_div i {
    height: 60px;
    width: 60px;
    font-size: 33px; }
  .line_h {
    width: 50%; }
  .timeline1_h,
  .timeline_h {
    font-size: 15px; }
  .time_txt .task_table_footer {
    font-size: 13px; }
  .time_line_date {
    font-size: 12px; }
  .Lates_up_txt2 {
    font-size: 15px; }
  .Lates_up_time_date {
    max-width: 225px;
    width: 225px;
    font-size: 11px; }
  .Partt_d1_txt_3 {
    font-size: 14px; }
  .btn-3 {
    font-size: 13px; }
  .PD_Al_h_txt {
    margin-bottom: 0; }
  .add_btn1 i:before {
    height: 35px;
    width: 35px; }
  .shift_h1 {
    font-size: 17px; }
  .Break_dow_T {
    font-size: 13px; }
  .Break_dow_Scroll_D {
    height: 235px; }
  .Cust_Sel_my.A2 .rrs__label {
    min-height: 35px;
    padding: 0 15px; }
  .chart_txt_4 .mt-5 {
    margin-top: 15px; }
  .chart_txt_1,
  .chart_txt_2,
  .chart_txt_3 {
    font-size: 15px;
    line-height: 16px;
    font-weight: 400; }
  h2.cl_black {
    font-size: 26px; }
  .update_button_g:before {
    font-size: 40px; }
  .chart_txt_4 span {
    margin-right: 10px; }
  .btn_AddFld_cmn {
    right: 3px;
    top: 3px; }
  .Version_timeline_4 .time_d_1 {
    width: 620px; }
  .time_no_div {
    width: 160px; }
  .Version_timeline_4 .time_line_parent .time_d_1:after {
    left: 78px; } }

@media screen and (max-width: 1199px) and (min-width: 100px) {
  .btn_AddFld_cmn {
    right: 0;
    top: 0; } }

.color {
  color: var(--txt-color); }

[class^="icon-"],
[class*=" icon-"] {
  display: inline-flex; }

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none; }

.row {
  margin-right: -7px;
  margin-left: -7px; }

.bt-1,
.by-1 {
  border-top: 1px solid;
  border-color: var(--b-color); }

.br-1 {
  border-right: 1px solid;
  border-color: var(--b-color); }

.bb-1,
.by-1 {
  border-bottom: 1px solid;
  border-color: var(--b-color); }

.bl-1 {
  border-left: 1px solid;
  border-color: var(--b-color); }

.user_info {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-align: center; }
  .user_info li {
    display: inline-block;
    margin: 0 10px;
    line-height: 20px; }
  .user_info li {
    color: #777;
    color: var(--txt-color);
    font-weight: 400 !important;
    font-size: 20px; }
  .user_info li span {
    color: #1e1e1e; }

.content .scroll-3 .bt-0 {
  border-top: 0px !important; }

.message-text {
  display: block;
  background: #fff;
  padding: 8px 15px;
  line-height: initial;
  border: 1px solid #545454;
  font-size: 15px; }

.int_email_button span {
  background: #1e1e1e;
  color: #fff;
  display: inline-block;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  line-height: 42px;
  font-size: 20px;
  cursor: pointer; }
  .int_email_button span:last-child {
    float: right; }
  .int_email_button span {
    background: var(--bg-color);
    background-color: var(--bg-color); }

.date_and_content .message-text,
.int_mail_1-D .message-text,
.message .message-text,
.message-avatar span.icon.icon-share {
  border: 0 solid #fff; }

.bg_grey .modal-content {
  background: #e6e6e6 !important; }

button.but_share_s {
  background: 0 0;
  border: 0; }

.but_share_s button span {
  background: #1e1e1e;
  color: #fff;
  display: inline-block;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  line-height: 42px;
  font-size: 20px;
  cursor: pointer; }

.but_share_s button {
  background: 0 0 !important;
  border: 0 !important; }

.icons_NEW button i.icon.icon-add-icons:before {
  height: 40px;
  background: 0 0 !important;
  padding: 0 !important;
  display: flex;
  line-height: 25px;
  color: #33a918;
  font-size: 40px;
  margin-right: 7px;
  align-items: center;
  justify-content: center; }

.icons_NEW button i.icon_cancel_1:before {
  background: 0 0 !important;
  line-height: 0;
  color: #e41a28;
  font-size: 28px;
  margin-right: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center; }

.flex_p {
  display: flex;
  justify-content: center;
  margin-top: 150px; }

.error_img_404 {
  width: 130px;
  margin-right: 15px; }

span.notif_MSG {
  display: inline-flex;
  background: #d12e2f;
  width: auto;
  min-width: 45px;
  height: 45px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  font-weight: 300; }

.notif_MSG_div {
  position: absolute;
  right: -15px;
  top: -15px; }

.m-arrow {
  color: #fe7b31; }

.o-arrow {
  color: #fe7b31; }

.p-arrow {
  color: #6f4dee; }

.s-arrow {
  color: #fd84cb; }

.f-arrow {
  color: #d22e2f; }

.a-arrow {
  color: #03f; }

.c-cust-check_1 {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0; }
  .c-cust-check_1 input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    clip: rect(0 0 0 0);
    height: 20px;
    width: 20px;
    padding: 0;
    border: 0;
    left: 0;
    margin-top: 0; }
  .c-cust-check_1 .c-cust-check_1__img {
    display: inline;
    position: relative;
    left: 0;
    border-color: #1e1e1e;
    width: 20px;
    height: 20px;
    background-image: none;
    background-color: #fff;
    color: var(--crm-txt-color);
    border: 1px solid;
    border-color: var(--crm-b-color);
    border-radius: 3px;
    cursor: pointer;
    top: 0; }
  .c-cust-check_1 input[disabled]:checked + .c-cust-check_1__img,
  .c-cust-check_1 input[type="checkbox"]:checked + .c-cust-check_1__img {
    background-position: 0 0; }
  .c-cust-check_1 input[disabled]:checked + .c-cust-check_1__img::before,
  .c-cust-check_1 input[type="checkbox"]:checked + .c-cust-check_1__img::before {
    margin-left: -4px;
    font-family: icons_me;
    content: "\70";
    display: flex;
    font-size: 18px;
    padding: 0 1px 0;
    width: 20px;
    height: 20px;
    position: absolute;
    line-height: 0;
    align-items: center; }
  .c-cust-check_1 input[disabled] + .c-cust-check_1__img {
    opacity: 0.5;
    cursor: initial; }
  .c-cust-check_1 span {
    display: inline-flex;
    height: 20px;
    align-items: center;
    padding-left: 4px;
    font-weight: 500; }
  .c-cust-check_1 .c-cust-check_1__img {
    border-color: var(--b-color); }

.c-cust-check_1__img.color {
  color: var(--txt-color); }

.Linkcase_icon {
  display: flex;
  height: 100%;
  align-items: center; }
  .Linkcase_icon a {
    display: inline-flex;
    align-items: center;
    height: 100%; }

a[aria-expanded="true"] .glyphicon-plus:before {
  content: "\66";
  font-family: "icons_me"; }

a[aria-expanded="false"] .glyphicon-plus:before {
  content: "\68";
  font-family: "icons_me"; }

.Group_F_dow {
  display: flex;
  justify-content: space-between; }
  .Group_F_dow a {
    color: #1e1e1e; }
  .Group_F_dow a {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block; }
  .Group_F_dow a i {
    font-size: 16px;
    margin-right: 5px; }

.Group_replay .Group_F_dow a {
  color: #fff; }

.default_btn {
  background: no-repeat;
  border: none;
  margin: 0;
  padding: 0; }

.Upload_file_box_1 {
  background: #fff;
  padding: 10px;
  border-radius: 10px; }

.Upload_FN {
  font-size: 15px;
  font-weight: 600; }

.Upload_FS {
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px; }

span.MN_info_a1 {
  position: absolute;
  right: -10px;
  top: -10px;
  min-height: 35px;
  min-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--b-radius);
  background: #ff2d55;
  color: #fff; }

.table_footer-1 {
  text-align: right;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block; }

.M_t0 {
  white-space: nowrap;
  position: absolute;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis; }

.define_input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 0;
  overflow: scroll;
  white-space: pre;
  font-size: 15px;
  font-family: "Helvetica LT Std-1";
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none; }

.textarea-max-size {
  resize: horizontal; }

.more-less {
  float: right; }

label.btn.btn-default.btn-sm.center-block.btn-file {
  padding: 0;
  border-radius: 0;
  border: 0;
  background: 0 0; }

p.select_all span {
  float: left;
  padding-right: 6px; }

.on_f_to_ul li {
  float: none;
  display: table-cell; }
  .on_f_to_ul li input {
    text-align: center;
    padding: 0 10px; }

ul.on_f_to_ul li {
  padding-left: 10px; }

.odometer {
  height: 62px;
  line-height: normal !important;
  overflow: hidden;
  font-size: 50px;
  line-height: 49px;
  font-weight: 800; }

.time_on_div {
  width: 50%;
  float: left;
  color: initial;
  margin-top: 10px; }
  .time_on_div span {
    color: #db4255 !important; }

.time_on_div_2 {
  width: 50%;
  float: right;
  text-align: right; }

td.child h4 {
  margin-top: 10px; }

.Total_text {
  padding-left: 15px;
  color: #777;
  border-bottom: 1px solid #777; }
  .Total_text.r {
    padding-left: 0; }

.total_div a {
  color: inherit;
  padding-left: 10px;
  font-size: 11px; }

.counter_1 {
  font-size: 5.8vw !important;
  line-height: 5.1vw !important;
  height: auto;
  font-weight: 800 !important;
  height: auto; }
  .counter_1 span.odometer-digit {
    line-height: 5.5vw !important;
    height: 6.5vw !important; }
  .counter_1 span.odometer-digit {
    line-height: 5.5vw !important;
    height: auto !important; }
    .counter_1 span.odometer-digit .odometer-value {
      padding-top: 0.5vw; }

ul.pagination_your li {
  list-style: none;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
  line-height: 16px;
  font-size: 16px; }

.view_by {
  line-height: 14px;
  margin-bottom: 30px; }

.enter_but li {
  background: #1e1e1e;
  padding: 0;
  text-align: center;
  border-radius: var(--b-radius); }
  .enter_but li a {
    display: block;
    font-size: 30px;
    padding: 10px;
    font-weight: 300 !important; }

.label_equla_but {
  line-height: 40px;
  height: 40px;
  display: block;
  text-align: center;
  font-size: 18px;
  border-radius: var(--b-radius); }

.Mathed_div {
  border-left: dashed 1px #1e1e1e;
  border-right: dashed 1px #1e1e1e;
  padding: 0;
  border-left: dashed 1px;
  border-right: dashed 1px;
  border-color: var(--b-color); }

.Mathed_div label {
  display: block;
  line-height: 85px;
  text-align: center;
  height: 85px;
  border-top: solid 1px #1e1e1e;
  border-bottom: solid 1px #1e1e1e;
  border-color: var(--b-color); }

table.shift_table {
  width: 100%; }
  table.shift_table td {
    padding: 4px;
    background: 0 0 !important; }
  table.shift_table tr td {
    font-size: 13px;
    border-right: 1px solid #ddd; }
    table.shift_table tr td:last-child {
      min-width: 30px; }
    table.shift_table tr td span {
      color: #777; }

.up_down_arrow {
  width: 23px; }

.date_div {
  width: 90px; }

.duration_div {
  width: 80px; }

.start_div {
  width: 65px; }

.combine_div {
  display: flex;
  flex-wrap: wrap; }
  .combine_div .accordion_me .panel-title i {
    padding-top: 7px;
    padding-right: 8px; }
  .combine_div p.sm_size {
    line-height: 1.2vw;
    font-size: 10px; }
  .combine_div .icons_right a img {
    width: 1.5vw; }
  .combine_div .icons_right a:first-child img {
    width: 1vw; }
  .combine_div p.Resi_details span {
    font-size: 10px; }
  .combine_div p.Resi_details span font {
    font-size: 10px; }

.padding_right {
  padding-right: 15px; }

.padding_left {
  padding-left: 15px; }

.content {
  overflow: auto;
  position: relative;
  padding: 0;
  margin: 0;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.textarea_height textarea {
  height: 150px;
  width: 100%; }

table.Schedules_calendar_table {
  width: 100%; }

table.Schedules_calendar_table tr td {
  padding: 10px; }

td.day table th:nth-child(2) {
  text-align: right; }

.other-month table tbody {
  opacity: 0.5; }

.center_box,
.left_side,
.right_side {
  display: table-cell; }

.left_side,
.right_side {
  width: 45px;
  text-align: center;
  vertical-align: top; }

.Month ul.list {
  display: inherit; }
  .Month ul.list li {
    display: inherit;
    padding: inherit;
    width: inherit;
    text-align: inherit; }

h1.month_name {
  color: #1d1d1b; }

textarea {
  overflow-x: hidden; }

.Site_Par tfoot tr td {
  padding: 0 !important; }

.Plan_accordion .panel-title div {
  padding-top: 3px;
  padding-bottom: 3px; }

.Plan_accordion .panel-body {
  padding-top: 4px;
  padding-bottom: 4px; }

.Plan_accordion .panel-body {
  padding: 0; }

.Plan_accordion .bor_B {
  border-bottom: 1px solid #777 !important; }

.Plan_accordion .panel-default {
  border-color: #777; }

.Plan_accordion .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #777; }

.Plan_accordion .panel-body .col-md-4,
.Plan_accordion .panel-title div span {
  color: var(--txt-color); }

.Plan_accordion .panel-title .col-md-8 {
  font-weight: 600; }

.Plan_accordion div,
.Plan_accordion .panel-title div,
.Plan_accordion .panel-title div span {
  font-size: 14px !important; }

.Plan_accordion .panel-title div span {
  line-height: 0; }

.Plan_accordion .panel.panel-default:nth-child(2n + 1) .panel-heading {
  background: 0 0 !important; }

ul.our_goals span.tag {
  font-size: 10px; }
  ul.our_goals span.tag:after {
    position: absolute;
    content: "";
    left: 3.5px;
    height: 30px;
    top: -28px;
    z-index: 9;
    background: #1e1e1e;
    width: 1px; }
  ul.our_goals span.tag:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    top: -29px;
    border-radius: 10px;
    background: #1e1e1e;
    z-index: 1; }

.table_a {
  display: table;
  width: 100%;
  padding-right: 30px !important; }

textarea.col-md-12 {
  width: 100% !important; }

.icon-arrow-red-left {
  position: relative;
  height: 18px;
  line-height: 18px !important; }
  .icon-arrow-red-left:before {
    transition: all 0.15s ease-in-out;
    content: "" !important;
    background-size: 15px;
    height: 18px;
    width: 18px;
    position: absolute;
    top: -5px;
    left: 0; }

span.icon.icon-arrow-right {
  position: relative;
  height: 18px;
  line-height: 18px !important; }

.paginate_button.next.disabled span,
.paginate_button.previous.disabled span {
  opacity: 0.2; }

.date_and_time {
  text-align: center;
  font-size: 15px !important; }

.calendar_me tr th {
  border: 1px solid #cdcccc;
  font-size: 15px !important;
  text-align: center;
  cursor: pointer; }

.calendar_me tr td {
  cursor: pointer; }

.calendar_me tr td.avaliable {
  cursor: default; }

.width_130 {
  width: 130px; }

.share_div {
  display: none;
  background: #545454;
  z-index: 1;
  border-radius: 0;
  margin-top: 50px;
  height: 94.9%;
  width: 250px;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  padding: 10px 10px; }

.message-bubble {
  background: #34444c;
  color: #fff;
  display: table-cell;
  vertical-align: top;
  width: 45px;
  min-width: 45px; }
  .message-bubble span img {
    width: 25px;
    display: inline-block; }
  .message-bubble span {
    background: #fff;
    height: 40px;
    width: 40px;
    display: inherit;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
    border: 1px solid #545454;
    border-radius: var(--b-radius); }

.timing {
  display: table-cell;
  vertical-align: bottom;
  font-size: 12px;
  width: 75px;
  min-width: 75px;
  padding-left: 5px;
  font-weight: 300 !important; }

.message-text {
  display: block;
  background: #fff;
  padding: 8px 15px;
  line-height: initial;
  border: 1px solid #545454;
  font-size: 15px;
  border-radius: var(--b-radius); }

.message .message-text {
  font-size: 15px; }

.user-received {
  display: table; }

i.history_button {
  font-size: 30px;
  color: #1e1e1e;
  color: var(--txt-color); }

.invoice_button:before {
  font-size: 30px;
  color: #c90;
  line-height: 0; }

.plan_address i:before {
  font-size: 30px;
  vertical-align: middle; }

.add_i_icon .icon-add-icons:before {
  background: #1e1e1e;
  color: #fff;
  font-size: 32px;
  border-radius: var(--b-radius);
  padding: 4px 4px 4px 4px;
  background: var(--bg-color);
  background-color: var(--bg-color); }

a {
  text-decoration: none !important;
  color: initial; }

i.update_button:before {
  font-size: 27px;
  color: #096; }

.update_button_g {
  background: var(--bg-color);
  background-color: var(--bg-color); }

a.attach_button i {
  color: #096;
  font-size: 30px; }

a.un-attach_button i {
  color: #c36;
  font-size: 30px; }

.size_add span.icon.icon-add-icons {
  font-size: 30px;
  color: #1e1e1e;
  margin-right: -3px; }

.icon-caller-icons.volume_button:before {
  font-size: 30px;
  color: #1e1e1e; }

.icon-phone-icons.phone_icon:before {
  font-size: 30px;
  color: #1e1e1e; }

.f_color_size {
  display: flex; }
  .f_color_size.col-md-3 {
    text-align: right; }
  .f_color_size .col-md-3 {
    color: var(--txt-color); }

.h_small i.history_button {
  font-size: 20px;
  vertical-align: middle;
  padding-left: 4px; }

.dotted_line {
  border-bottom: 1px dashed #777;
  border-color: var(--b-color); }

.archive_button {
  font-size: 30px;
  color: #c36; }

.panel-title {
  overflow: hidden !important; }

.big_circule_icon:before {
  font-size: 43px;
  background: #1e1e1e;
  height: 47px;
  width: 47px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 47px !important; }

.big_mobile_icon:before {
  font-size: 41px;
  background: #1e1e1e;
  height: 47px;
  width: 47px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 47px !important; }

.big_add_icon:before {
  background: #1e1e1e;
  font-size: 33px;
  height: 47px;
  width: 47px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 47px !important; }

.br_1_gray {
  border-right: 1px solid #ccc; }

.bl_1_gray {
  border-left: 1px solid #ccc; }

.px-3px {
  padding-left: 3px;
  padding-right: 3px; }

.icon_table1 {
  display: flex;
  height: 100%;
  align-items: center;
  line-height: 100%; }
  .icon_table1 span:before {
    line-height: 100%;
    font-size: 21px;
    display: inline-flex;
    align-items: center; }
  .icon_table1 .Arch_tab_i,
  .icon_table1 .add_tab_i,
  .icon_table1 .unat_tab_i {
    color: var(--txt-color);
    cursor: pointer; }

.start_text {
  color: #c36 !important; }

.counter_td {
  width: 102px; }

.Name_div {
  width: 118px; }

.place_text {
  font-size: 10px;
  line-height: 5px; }
  .place_text:before {
    content: "";
    width: 5px;
    border-radius: 100%;
    height: 5px;
    background: #1e1e1e;
    display: inline-block;
    margin-right: 5px; }

.heading_prefer {
  font-size: 12px;
  margin: 0; }

.approve_line {
  display: block;
  width: 100%;
  height: 1px; }

.match_with div {
  display: table-cell;
  vertical-align: middle; }

.match_with span.icon.icon-cross-icons {
  font-size: 25px;
  display: inline-block;
  vertical-align: middle !important;
  line-height: 29px;
  color: #ef0202; }

.match_with span.icon.icon-input-type-check {
  display: inline-flex;
  vertical-align: middle !important;
  line-height: 25px;
  color: #328836;
  border: 1px solid #328836;
  width: 25px;
  justify-content: center;
  opacity: 0.4;
  height: 25px;
  border-radius: 100%;
  align-items: center; }

.line_me {
  height: 0;
  border-top: 1px solid #000;
  display: table;
  width: 95%;
  margin: auto;
  border-color: var(--b-color); }

.fill_shifts_icons:before {
  font-size: 67px;
  line-height: 62px;
  display: inline-block; }

.key_color ul {
  list-style: none; }
  .key_color ul li {
    margin-bottom: 5px; }
  .key_color ul li {
    font-weight: 200;
    font-size: 13px; }

.key_color_calendar {
  text-align: center; }
  .key_color_calendar ul li {
    font-size: 13px;
    display: inline-block;
    text-align: center; }
  .key_color_calendar ul {
    display: inline-block; }
  .key_color_calendar ul li span {
    display: inline-block;
    vertical-align: middle; }

.key_heading {
  font-size: 20px; }

.black_funcing small,
.defaout_funding small,
.inside_funding small,
.inside_funding_count small,
.outside_funding small {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  margin-right: 5px; }

.inside_funding small {
  background: #9c9; }

.outside_funding small {
  background: #f66; }

.inside_funding_count small {
  background: red; }

.defaout_funding small {
  background: #999; }

.black_funcing small {
  background: #000; }

.outside_funding_number td {
  background: red; }

.defaout_funding td {
  background: #999; }

.black_funcing_number td {
  background: #000; }

.d_table-cell {
  display: table-cell; }

span.inside_funding_date,
span.outside_funding_date {
  border-radius: 100%;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  line-height: 25px; }

span.inside_funding_date {
  background: #9c9; }

span.outside_funding_date {
  background: #f66; }

.hidden_date {
  visibility: hidden; }

.border_dotted_right {
  border-right: 1px dashed #1e1e1e; }

.border_dotted_top {
  border-top: 1px dashed var(--bg-color); }

.make_up div {
  font-size: 14px;
  justify-content: center;
  align-self: center;
  text-align: center; }

.make_header {
  font-size: 15px;
  background: var(--b-color);
  padding: 10px 0px;
  border-radius: 3px; }
  .make_header div {
    color: #fff;
    border-color: #fff !important; }
  .make_header input.checkbox_flex[type="checkbox"] + label span {
    border-color: #fff; }

.collaspe_roster .flex .pdd-b {
  padding-bottom: 15px; }

.collaspe_roster .flex:nth-of-type(even) .make_up {
  background-color: #ececec; }

.collaspe_roster .flex:nth-of-type(odd) .make_up {
  background-color: #fff;
  box-shadow: 0 0 18px 1px #ddd; }

.repeat_number {
  width: 100px;
  display: block;
  margin: 0 auto; }

.Search_common i:before {
  font-size: 22px;
  line-height: 35px;
  color: #1e1e1e;
  color: var(--txt-color); }

.popup_calander {
  background: #e6e6e6; }

.height_auto {
  height: auto !important; }

.Resi_details span {
  color: #777;
  font-size: 16px;
  line-height: 16px; }

.Resi_details span font {
  color: #1e1e1e;
  font-size: 16px;
  line-height: 16px; }

.scroll_active_modal {
  display: block;
  height: 100%;
  overflow-y: auto;
  padding: 15px 10px;
  width: 100%; }

.overflow-hidden {
  overflow: hidden; }

.min-h-120 {
  min-height: 120px; }

.message-avatar span {
  background: #fff;
  height: 40px;
  width: 40px;
  display: inherit;
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #545454; }

.message-avatar span img {
  width: 25px;
  display: inline-block; }

.message-avatar span.icon.icon-share {
  text-align: center;
  font-size: 15px;
  transform: rotate(40deg);
  padding: 0;
  background: #233ef6;
  color: #fff;
  border: 1px solid #233ef6;
  line-height: 10px;
  background: var(--bg-color);
  background-color: var(--bg-color);
  border-color: var(--b-color); }

.scroll-1 {
  background: #cacaca; }

.scroll-1 {
  border-radius: var(--b-radius); }

.scroll-2 {
  background: #e4e4e4; }

.scroll-2 .message-avatar span img {
  width: 18px;
  vertical-align: text-bottom; }

.scroll-2 .message-avatar span {
  width: 30px;
  height: 30px; }

.scroll-2 .message-avatar {
  width: 35px;
  min-width: 35px; }

.scroll-2 .type_text {
  display: table;
  width: 100%; }

.scroll-2 .type_text .message-avatar {
  vertical-align: bottom;
  padding-bottom: 10px; }

.scroll-2 .type_text textarea {
  font-size: 13px;
  line-height: 15px;
  padding: 10px;
  max-width: 100%;
  height: 100px;
  resize: vertical; }

.scroll-2 .but_share_s .message-avatar span.icon.icon-share,
.scroll-2 span.icon.icon-share {
  width: 40px;
  height: 40px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  transform: rotate(3deg);
  font-size: 20px;
  cursor: pointer; }

.scroll-2 .message-avatar span {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding: 7px; }

.scroll-2 .message-avatar span img {
  width: 100%;
  vertical-align: text-bottom; }

.scroll-2 {
  border-radius: var(--b-radius); }

.scroll-2 .bb-1 {
  border-color: #cacaca; }

.scroll-3 {
  border-radius: var(--b-radius); }

.scroll-3 .panel {
  border-color: var(--b-color); }

.scroll-3 .panel-title i.more-less {
  transform: rotate(0);
  top: 8px;
  left: 0;
  font-size: 20px; }

.scroll-3 .panel-title .collapsed i.more-less {
  transform: rotate(270deg);
  top: 9px;
  left: 0; }

.scroll-3 .resident_name {
  padding-left: 25px; }

.scroll-3 {
  background: #cacaca; }

.scroll-3 #accordion.panel-group .panel,
.scroll-3 .panel,
.scroll-3 .panel-default > .panel-heading,
.scroll-3 .panel-group {
  background: 0 0;
  background-color: none;
  border-radius: 0 !important;
  border-bottom: 0 solid;
  box-shadow: none; }

.scroll-3 .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top: 0 solid #fff; }

.scroll-3 .panel {
  border-top: 1px solid; }

.scroll-3 .panel-title i.more-less {
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translate(0, -50%); }

.scroll-3 .panel-title {
  position: relative; }

.scroll-3 .panel-heading {
  padding-left: 30px; }

.scroll-3 .panel-body {
  padding: 0 30px 15px; }

.scroll-3 .panel-body ul {
  list-style: none;
  padding: 0; }

.scroll-1,
.scroll-2,
.scroll-3 {
  overflow: auto;
  position: relative;
  padding: 0;
  margin: 0;
  max-width: 100%;
  height: 450px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.reply_your .message-text {
  background: #233ef624;
  background: var(--bg-color);
  background-color: var(--bg-color); }

.text_app {
  font-size: 20px;
  text-align: center;
  padding: 10px 15px;
  background: #eee;
  border-radius: var(--b-radius);
  color: var(--txt-color); }

.add_input {
  position: relative; }

.add_input button {
  position: absolute;
  top: 0;
  right: 3px;
  background: no-repeat;
  border: none;
  height: 40px; }

.add_input button i {
  font-size: 31px; }

.add_input button i.icon.icon-add-icons {
  display: flex;
  align-items: center;
  line-height: 40px;
  vertical-align: middle;
  padding: 3px 0; }

.add_input button i:before {
  border-radius: var(--b-radius); }

.header_description {
  width: 100%; }

.header_description tr th {
  padding: 5px 10px;
  border-right: 1px solid #ddd;
  background: #1e1e1e;
  color: #fff;
  font-weight: 400; }

.header_description tr td {
  padding: 10px;
  border-right: 1px solid #ddd; }

.header_description tr td,
.header_description tr th {
  width: 25%; }

.header_description tr th:first-child {
  border-radius: var(--b-radius) 0px 0px var(--b-radius); }

.header_description tr th:last-child {
  border-radius: 0px var(--b-radius) var(--b-radius) 0px; }

a.no_icos {
  font-size: 24px;
  color: red;
  display: inline-block;
  vertical-align: middle; }

a.yes_icons {
  font-size: 16px;
  border: 1px solid #4caf50;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  padding: 0 5px 0 3px;
  color: #4caf50;
  border-radius: var(--b-radius); }

.panel-group.Approvals_accordi .panel + .panel:nth-child(2n + 0) {
  background: red !important; }

.Approvals_accordi .panel-default {
  background: 0 0 !important; }

.create_new span.icon.icon-update {
  font-size: 90px;
  margin-top: 25px; }

.create_new span {
  border: 0 solid #fff !important;
  border-radius: var(--border-radius100);
  text-align: center;
  background-clip: inherit !important;
  font-size: 120px;
  line-height: 60px;
  text-align: center;
  color: #1c65cc;
  display: table;
  margin: 1rem auto 2rem;
  color: var(--txt-color); }

.mt-7 {
  margin-top: 7rem; }

.icon_cancel_1 {
  font-size: 24px;
  padding: 0;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: var(--txt-color); }

.roles_icon span {
  border: 4px solid #1d64cd;
  color: #1d64cd;
  font-size: 53px;
  padding: 10px 15px 0;
  border-radius: 55px;
  text-align: center;
  display: table;
  margin: 0 auto;
  width: 95px;
  height: 95px; }

.Plus_button:hover,
a.but:hover {
  color: #fff; }

.Plus_button:focus,
a.but:focus {
  color: #fff; }

.css-rsyb7x {
  line-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0; }

.css-rsyb7x,
.css-va7pk8 {
  color: #fff; }

.css-1rtrksz,
.css-1wy0on6 {
  background: #1e1e1e; }

.css-1rtrksz {
  padding: 0 8px; }

.css-1aya2g8 {
  border: 1px solid #1e1e1e;
  background: #1e1e1e; }

.css-1492t68 {
  color: #ccc; }

.css-1aya2g8,
.css-2o5izw {
  box-shadow: none;
  overflow: hidden; }

.css-d8oujb {
  background: 0 0; }

.css-2o5izw:focus,
.css-2o5izw:hover {
  border: 1px solid #1e1e1e; }

.email_call_icon i {
  text-align: center;
  font-size: 25px;
  vertical-align: middle;
  padding-left: 10px; }
  .email_call_icon i.icon-notes {
    color: #2ba8f7; }
  .email_call_icon i.icon-caller-icons {
    color: #1e1e1e; }

.error_bg p {
  font-size: 24px;
  color: #1e1e1e;
  line-height: 25px; }

.error_bg div a:hover {
  opacity: 0.5; }

.error_bg h2 {
  font-size: 36px !important;
  font-family: sans-serif;
  font-weight: 600;
  color: #000;
  margin-bottom: 7px; }

.mb_4_vw {
  margin-bottom: 4vw; }

i.plus_but:before {
  font-size: 27px;
  color: #1e1e1e;
  color: var(--txt-color); }

.br-0 {
  border-right: 0 solid #000 !important; }

.tr_between {
  border-collapse: separate;
  border-spacing: 0 10px; }

.shifts_deetails tr:nth-child(even),
.shifts_deetails tr:nth-child(odd) {
  font-weight: 400 !important; }

.shifts_deetails tr:nth-child(even) {
  box-shadow: 0 0 1px 2px #3833331a; }

.shifts_deetails tr:nth-child(odd) {
  box-shadow: 0 0 1px 2px #3833331a; }

.shifts_deetails tr:nth-child(even) td {
  background: #e6e6e6; }

.shifts_deetails tr:nth-child(odd) td {
  background: #fff; }

.shifts_deetails tr:nth-child(even),
.shifts_deetails tr:nth-child(odd) {
  border-radius: var(--b-radius); }

.booking_details_parent {
  font-size: 16px; }

.internal_shift_parent {
  border-radius: var(--b-radius); }
  .internal_shift_parent:nth-child(even) {
    background: #e6e6e6;
    box-shadow: 0 0 9px 2px #0000002e; }
  .internal_shift_parent:nth-child(odd) {
    background: #fff;
    box-shadow: 0 0 9px 2px #0000002e; }

.calendar_but {
  color: #1e1e1e; }

.calernder_button {
  font-size: 26px;
  color: #1e1e1e; }

.check_button {
  border: 1px solid #1e1e1e;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  text-align: center;
  height: 25px;
  font-size: 15px;
  line-height: 28px;
  color: #1e1e1e; }

.available_member_table_parent {
  border-collapse: separate;
  border-spacing: 0 10px; }
  .available_member_table_parent td:first-child {
    border-radius: var(--b-radius) 0px 0px var(--b-radius); }
  .available_member_table_parent td:last-child {
    border-radius: 0px var(--b-radius) var(--b-radius) 0px; }
  .available_member_table_parent tr td {
    background: #fff; }

.uncheck_button {
  font-size: 26px;
  display: inline-grid;
  vertical-align: middle;
  color: #1e1e1e; }

.circule_button {
  font-size: 46px;
  background: #1e1e1e;
  display: inline-block;
  line-height: 59px;
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  border-radius: var(--b-radius); }

.mobile_button {
  font-size: 45px;
  width: 50px;
  height: 50px;
  line-height: 60px;
  display: inline-block;
  vertical-align: middle;
  background: #1e1e1e;
  color: #fff;
  text-align: center;
  color: var(--txt-color);
  border-radius: var(--b-radius); }

.but_one {
  background: #e6e6e6;
  color: #1e1e1e;
  display: table;
  width: 100%;
  text-align: center;
  padding: 9px 10px; }

.cal_button {
  font-size: 30px;
  background: #1e1e1e;
  display: inline-block;
  line-height: 59px;
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle; }

.house_accordi .panel.panel-default:nth-child(odd) .panel-heading {
  background: 0 0 !important; }

.house_accordi .panel.panel-default:nth-child(even) {
  background: #e6e6e6 !important; }

.user_profile .user_img {
  width: 110px;
  height: 110px;
  border-radius: var(--border-radius100);
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 3px solid;
  border-color: var(--b-color); }
  .user_profile .user_img + div {
    width: 110px;
    height: 110px; }

.user_profile td {
  vertical-align: top; }

.user_profile p.select_all span {
  float: none;
  padding-right: 6px;
  vertical-align: top; }

.user_profile .box_wide {
  width: 100px;
  margin-right: 10px;
  margin-bottom: 7px; }

.user_profile ul {
  list-style: none;
  padding: 0px;
  margin: 0px; }
  .user_profile ul li {
    font-size: 16px;
    line-height: 16px;
    line-height: initial;
    font-weight: 600; }
    .user_profile ul li span {
      font-size: 16px;
      line-height: 16px;
      font-weight: 300 !important; }

.user_profile .box_wide,
.user_profile .pull-left p {
  margin-bottom: 0; }

.required {
  position: relative;
  display: block; }
  .required:before {
    font-size: 7px;
    background-size: 6px;
    position: absolute;
    content: "";
    background: url("/assets/images/admin/star_icons.svg");
    right: -2px;
    line-height: 10px;
    top: -9px;
    text-align: right;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat; }
  .required .react-datepicker-wrapper,
  .required .react-datepicker__input-container {
    width: 100% !important; }

.required_select {
  position: relative; }
  .required_select:before {
    position: absolute;
    content: "";
    background: url("/assets/images/admin/star_icons.svg");
    right: 0;
    line-height: 10px;
    top: -10px;
    text-align: right;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat; }

input.p-hidden,
input.p-hidden.error {
  margin-top: -40px; }

.visibilty-hidden {
  visibility: hidden; }

.landing_graph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  justify-content: space-between; }

.landing_graph li {
  width: 50%;
  padding: 20px 30px; }

.landing_graph li:nth-child(2n) {
  margin: 0 2%; }

.landing_graph .radi_2 h2.cl_black {
  color: #000 !important;
  width: 100%; }

.landing_graph .radi_2 {
  display: flex;
  flex-wrap: wrap;
  border-radius: var(--b-radius); }

.landing_graph h2,
.landing_graph .radi_2 .P_15_b {
  font-weight: 600; }

ul.landing_graph.enter_but li a:hover {
  color: #fff; }

.landing_graph li {
  background: #e6e6e6; }

.landing_graph h2 {
  color: #1e1e1e;
  color: var(--txt-color); }

.landing_graph_item_2 li:nth-child(2n) {
  margin-right: 0px; }

.landing_graph_item_2 {
  justify-content: center; }

.landing_crm__ .radi_2 {
  width: 50%; }

.landing_crm__ li:nth-child(2n) {
  margin: 0;
  margin-left: 7px; }

.landing_crm__ li:first-child {
  margin-right: 7px; }

textarea.width {
  height: 150px; }

.int_textarea {
  min-height: 150px;
  border-radius: var(--b-radius); }

.call_hist {
  border-collapse: separate;
  border-spacing: 0px 14px; }
  .call_hist td span {
    color: #777; }
  .call_hist td {
    border-right: 1px solid #cccccc;
    background: #e6e6e6 !important; }
  .call_hist tr {
    border-radius: var(--b-radius); }
  .call_hist tr td:first-child {
    border-radius: var(--b-radius) 0px 0px var(--b-radius); }
  .call_hist tr td:last-child {
    border-radius: 0px var(--b-radius) var(--b-radius) 0px; }
  .call_hist td span {
    color: var(--txt-color);
    font-size: 16px;
    line-height: 16px;
    font-weight: 400; }
  .call_hist td {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400; }
  .call_hist.logs_of_case_table {
    border-collapse: separate;
    border-spacing: 0px 14px; }
  .call_hist .logs_of_case_table tr td:first-child {
    border-radius: var(--b-radius) 0px 0px var(--b-radius); }
  .call_hist .logs_of_case_table tr td:last-child {
    border-radius: 0px var(--b-radius) var(--b-radius) 0px; }

table.case_id.dataTable td span {
  display: block;
  margin-bottom: 7px; }

table.case_id.dataTable {
  border-collapse: separate;
  border-spacing: 0px 14px; }

table.case_id.dataTable td span {
  font-weight: 400 !important; }

table.case_id tbody tr.odd td:first-child,
table.case_id tbody tr.odd td:first-child,
table.case_id tbody tr.even td:first-child,
table.case_id tbody tr.even td:first-child {
  border-radius: var(--b-radius) 0px 0px var(--b-radius); }

table.case_id tr td:last-child,
table.case_id tbody tr.odd td:last-child,
table.case_id tbody tr.odd td:last-child,
table.case_id tbody tr.even td:last-child,
table.case_id tbody tr.even td:last-child {
  border-radius: 0px var(--b-radius) var(--b-radius) 0px; }

table.case_id.dataTable td span {
  color: #777; }

.Line_base_tabs .nav-tabs > li.active > a {
  background: none;
  border: 0px solid #000000;
  border-bottom: 3px solid #000000;
  color: #000000; }
  .Line_base_tabs .nav-tabs > li.active > a:hover, .Line_base_tabs .nav-tabs > li.active > a:focus {
    background: none;
    border: 0px solid #000000;
    border-bottom: 3px solid #000000;
    color: #000000; }

.nav-tabs {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 0px; }
  .nav-tabs li a {
    text-align: center;
    line-height: 20px;
    font-size: 18px;
    font-weight: 400 !important;
    border-radius: var(--b-radius); }
  .nav-tabs li a {
    background: #ececec;
    color: #1e1e1e; }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    background: #e6e6e6;
    color: #777;
    cursor: pointer; }
  .nav-tabs.Nav_ui__ > li.active > a,
  .nav-tabs.Nav_ui__ > li.active > a:focus,
  .nav-tabs.Nav_ui__ > li.active > a:hover {
    background: var(--bg-color);
    color: #fff; }
  .nav-tabs.create-new-par__ li a {
    padding: 5px 20px;
    border-radius: 25px 25px 0 0;
    width: 130px;
    display: inline-flex;
    font-size: 16px;
    border: 1px solid;
    border-color: var(--b-color);
    background: #fff;
    min-height: 53px;
    text-align: center;
    justify-content: center;
    align-items: center; }
  .nav-tabs.create-new-par__ > li.active > a,
  .nav-tabs.create-new-par__ > li.active > a:focus,
  .nav-tabs.create-new-par__ > li.active > a:hover {
    background: var(--bg-color);
    color: #fff; }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus,
  .nav-tabs .Total_text {
    color: var(--txt-color); }
  .nav-tabs > li.Oncall_P.active a,
  .nav-tabs > li.Oncall_P.active a:focus,
  .nav-tabs > li.Oncall_P.active a:hover {
    background: var(--b-color);
    color: #fff; }
  .nav-tabs > li.thread_P.active a,
  .nav-tabs > li.thread_P.active a:focus,
  .nav-tabs > li.thread_P.active a:hover {
    background: #ff5266;
    color: #fff; }
  .nav-tabs > li.Private_M.active a,
  .nav-tabs > li.Private_M.active a:focus,
  .nav-tabs > li.Private_M.active a:hover {
    background: #4a7dd6;
    color: #fff; }

.Ser_Anal_tab_1 li a {
  background: #fff;
  border: 1px solid; }

.Ser_Anal_tab_1 li.Oncall_P a {
  color: var(--b-color);
  border-color: var(--b-color); }

.Ser_Anal_tab_1 li.thread_P a {
  color: #ff5266;
  border-color: #ff5266; }

.Ser_Anal_tab_1 li.Private_M a {
  color: #4a7dd6;
  border-color: #4a7dd6; }

/*Auto complete*/
.auto-s {
  min-height: 0px !important;
  left: 0px;
  width: 100% !important;
  max-height: 100% !important; }
  .auto-s input + div {
    border-radius: var(--border-radius15) !important;
    height: auto !important;
    position: initial !important; }
  .auto-s .search_result {
    padding: 15px !important;
    font-weight: 400 !important; }
  .auto-s input {
    width: 100% !important; }
  .auto-s div:first-child {
    width: 100% !important; }
  .auto-s button {
    margin-top: 0px !important; }

/**/
.Quali_table tr:nth-child(even) td {
  background: #fff; }

.Quali_table tr:nth-child(odd) td {
  background: #fff; }

.Quali_table tfoot td {
  background: none !important; }

.Quali_table tbody tr,
.Quali_table thead tr {
  border-radius: var(--b-radius); }

.Quali_table tbody tr.odd td:first-child,
.Quali_table tbody tr.odd td:first-child,
.Quali_table tbody tr.even td:first-child,
.Quali_table tbody tr.even td:first-child,
.Quali_table tbody tr td:first-child {
  border-radius: var(--b-radius) 0px 0px var(--b-radius); }

.Quali_table tbody tr.odd td:last-child,
.Quali_table tbody tr.odd td:last-child,
.Quali_table tbody tr.even td:last-child,
.Quali_table tbody tr.even td:last-child,
.Quali_table tbody tr td:last-child {
  border-radius: 0px var(--b-radius) var(--b-radius) 0px; }

.Quali_table tbody tr.odd td {
  background: #e6e6e6; }

.Quali_table tfoot tr td {
  padding: 0px; }

.Quali_table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0 1em !important; }

.Quali_table tr td {
  line-height: 18px; }

.Quali_table.Site_Par span {
  color: #1e1e1e; }

.Quali_table tbody tr td,
.Quali_table tbody tr td:first-child,
.Quali_table.Site_Par tbody tr.odd td:first-child,
.Quali_table.Site_Par tbody tr.odd td:first-child,
.Quali_table.Site_Par tbody tr.even td:first-child,
.Quali_table.Site_Par tbody tr.even td:first-child {
  font-weight: 400 !important; }

.Quali_table tbody tr td,
.Quali_table tbody tr td:first-child {
  font-size: 16px;
  line-height: 16px; }

.Quali_table tr td {
  font-size: 18px; }

.table_def thead tr {
  box-shadow: none !important;
  background: none !important; }

.table_def thead tr th {
  border-top: 1px solid #1e1e1e;
  border-bottom: 1px solid #1e1e1e;
  border-right: 1px solid #1e1e1e !important; }

.table_def thead tr th:last-child {
  border-right: 0px solid #000 !important; }

.table_def td span {
  color: #777; }

.table_def tbody tr.odd td:first-child,
.table_def tbody tr.odd td:first-child,
.table_def tbody tr.even td:first-child,
.table_def tbody tr.even td:first-child {
  border-radius: var(--b-radius) 0px 0px var(--b-radius); }

.table_def tbody tr.odd td:last-child,
.table_def tbody tr.odd td:last-child,
.table_def tbody tr.even td:last-child,
.table_def tbody tr.even td:last-child {
  border-radius: 0px var(--b-radius) var(--b-radius) 0px; }

.table_def tbody tr,
.table_def thead tr {
  border-radius: var(--b-radius); }

.table_def {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0 1em !important; }

.table_def tr td {
  padding: 7px 15px;
  line-height: 18px; }

.table_def tbody tr,
.table_def thead tr {
  box-shadow: 0px 0px 4px 0px #cacaca; }

.table_def tbody tr.odd td,
.table_def tbody tr.odd td {
  background: #e6e6e6 !important; }

.table_def td span.break_1 {
  display: block;
  width: 100%;
  margin-bottom: 0px; }

.table_def tbody tr td,
.table_def thead tr th {
  border-right: 1px solid #cccccc; }

.table_def tbody .child td,
.table_def tbody .child {
  border: 0px !important; }

.table_def tbody .child {
  border-radius: var(--b-radius); }

.Schedules_Multiple_checkbox {
  border: 1px solid #1e1e1e;
  background: #fff;
  position: relative;
  height: 150px;
  border-radius: var(--b-radius);
  border-color: var(--b-color); }
  .Schedules_Multiple_checkbox input.checkbox1[type="checkbox"] + label span,
  .Schedules_Multiple_checkbox input.checkbox1[type="checkbox"]:checked + label span {
    background: var(--bg-color);
    background-color: var(--bg-color); }
  .Schedules_Multiple_checkbox input.checkbox1[type="checkbox"] + label span {
    background: none;
    border: 1px solid #1e1e1e;
    border: 1px solid var(--b-color); }
  .Schedules_Multiple_checkbox input.checkbox1[type="checkbox"]:checked + label span {
    background: #1e1e1e;
    background: var(--bg-color);
    background-size: 16px !important;
    position: relative; }
  .Schedules_Multiple_checkbox input.checkbox1[type="checkbox"]:checked + label span:after {
    position: absolute;
    content: "\70";
    font-family: "icons_me";
    color: #fff; }
  .Schedules_Multiple_checkbox span label {
    margin-bottom: 0px !important; }
  .Schedules_Multiple_checkbox input.checkbox1[type="checkbox"] + label span {
    border-radius: 2px;
    height: 17px;
    width: 18px; }
  .Schedules_Multiple_checkbox input.checkbox1[type="checkbox"]:checked + label span {
    background-size: 20px 19px;
    background-position: center; }
  .Schedules_Multiple_checkbox .w_50 {
    width: 49%;
    display: inline-block;
    vertical-align: top; }
  .Schedules_Multiple_checkbox span label {
    font-size: 14px !important; }

aside ul.side_menu {
  background: #cdcccc;
  padding: 0px;
  margin: 0px;
  list-style: none;
  background-size: 100%;
  border-radius: var(--b-radius); }
  aside ul.side_menu li a.major_button {
    color: #777;
    font-size: 11px; }
  aside ul.side_menu li a {
    position: relative; }
  aside ul.side_menu li a span.mess_V1_noti {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%); }
  aside ul.side_menu li a {
    font-size: 20px;
    font-weight: 400 !important; }
  aside ul.side_menu li a {
    justify-content: center;
    line-height: 21px;
    padding: 30px 10px;
    display: inline-flex;
    cursor: pointer;
    width: 100%; }
  aside ul.side_menu li a.active {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 110%; }
  aside ul.side_menu li a.major_button {
    padding: 10px;
    font-size: 14px; }
  aside ul.side_menu li a.major_button img {
    position: absolute;
    left: 25px; }
  aside ul.side_menu li a {
    color: #1e1e1e;
    border-bottom: 1px solid #f6f6f6; }
  aside ul.side_menu li a.active {
    background: #e6e6e5;
    color: #777;
    box-shadow: 0px 2px #bdbdbd; }
  aside ul.side_menu li a:hover {
    background: #e6e6e5;
    color: #777; }
  aside ul.side_menu li:first-child a {
    border-radius: var(--b-radius) var(--b-radius) 0px 0px; }
  aside ul.side_menu li:last-child a {
    border-radius: 0px 0px var(--b-radius) var(--b-radius); }
  aside ul.side_menu li a {
    font-size: 17px; }
  aside ul.side_menu li a.active,
  aside ul.side_menu li a:hover {
    color: var(--txt-color); }
  aside ul.side_menu li a.major_button {
    font-size: 14px !important; }

ul.side_menu li.unique_menu a.active,
ul.side_menu li.unique_menu a:hover {
  border-radius: var(--b-radius); }

.quali_td1___,
.quali_td2___,
.quali_td3___ {
  flex: 1;
  min-height: 40px;
  align-items: center;
  display: inline-flex;
  padding: 0 15px;
  border-left: 1px solid; }
  .quali_td1___.bl-0,
  .quali_td2___.bl-0,
  .quali_td3___.bl-0 {
    border-color: transparent !important; }

.quali_tr___.bg_dark_Pink:nth-child(even) .quali_td1___,
.quali_tr___.bg_dark_Pink:nth-child(even) .quali_td2___,
.quali_tr___.bg_dark_Pink:nth-child(even) .quali_td3___,
.quali_tr___.bg_dark_Pink:nth-child(odd) .quali_td1___,
.quali_tr___.bg_dark_Pink:nth-child(odd) .quali_td2___,
.quali_tr___.bg_dark_Pink:nth-child(odd) .quali_td3___ {
  border-color: #a71646; }

.quali_tr___.bg_dark_yello:nth-child(even) .quali_td1___,
.quali_tr___.bg_dark_yello:nth-child(even) .quali_td2___,
.quali_tr___.bg_dark_yello:nth-child(even) .quali_td3___,
.quali_tr___.bg_dark_yello:nth-child(odd) .quali_td1___,
.quali_tr___.bg_dark_yello:nth-child(odd) .quali_td2___,
.quali_tr___.bg_dark_yello:nth-child(odd) .quali_td3___ {
  border-color: #bf7500; }

.quali_tr___ .quali_td4___ i.icon-email-pending {
  color: #dc4b5c; }

.quali_tr___.bg_dark_Pink .quali_td4___ i.icon-email-pending,
.quali_tr___.bg_dark_yello .quali_td4___ i.icon-email-pending {
  color: #fff; }

.quali_td4___ {
  min-height: 40px;
  align-items: center;
  display: inline-flex;
  padding: 0 15px; }

.quali_tr___ {
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  border-radius: var(--b-radius);
  margin: 10px 0;
  box-shadow: 0 0 4px -1px #6b6b6b9e; }

.quali_tr___:nth-child(odd) {
  background: #e6e6e6;
  color: #1e1e1e; }

.quali_tr___:nth-child(even) {
  background: #fff;
  color: #1e1e1e; }

.quali_tr___:nth-child(even) .quali_td1___,
.quali_tr___:nth-child(even) .quali_td2___,
.quali_tr___:nth-child(even) .quali_td3___,
.quali_tr___:nth-child(odd) .quali_td1___,
.quali_tr___:nth-child(odd) .quali_td2___,
.quali_tr___:nth-child(odd) .quali_td3___ {
  border-color: #c5c5d5; }

.quali_tr___.bg_dark_Pink {
  background: #db4255;
  color: #fff; }

.quali_tr___.bg_dark_yello {
  background: #e2a100;
  color: #fff; }

.quali_td4___ i.icon-email-pending {
  display: flex;
  font-size: 26px;
  cursor: pointer; }

.quali_tr___ b {
  padding-left: 3px; }

.quali_td4___ span {
  display: flex;
  align-items: center; }

.quali_td4___ a,
.quali_td4___ a i {
  display: inline-flex;
  align-items: center; }

.quali_td4___ i.icon-update {
  font-size: 26px;
  color: #096; }

.quali_width li {
  width: 16.6%; }

.quali_width li.text_dark_yello .path_file,
ul.file_down li.text_dark_yello .icon-file-icons:before {
  color: #e2a100 !important; }

li.text_dark_Pink .path_file,
ul.file_down li.text_dark_Pink .icon-file-icons:before {
  color: #db4255 !important; }

.W_M_Y_box {
  text-align: center; }
  .W_M_Y_box span {
    padding: 0px 8px;
    font-weight: 400 !important;
    color: #999999; }
  .W_M_Y_box span.active {
    color: #1e1e1e; }
  .W_M_Y_box span.color {
    color: var(--txt-color); }

.accordion_member ul li:last-child {
  float: none; }

.accordion_member ul {
  display: flex;
  width: 100%; }

.accordion_member ul li {
  display: inline-flex; }

.accordion_member ul li:first-child {
  width: 55%; }

.accordion_member ul li:last-child {
  width: 40%; }

.accordion_member .panel-body {
  padding: 0px; }

.accordion_member .panel.panel-default:nth-child(even) .calendar_me tr td,
.accordion_member .panel.panel-default:nth-child(even) .calendar_me tr th,
.accordion_member .panel.panel-default:nth-child(even) {
  background: #fff; }

.accordion_member .panel.panel-default:nth-child(odd) .calendar_me tr td,
.accordion_member .panel.panel-default:nth-child(odd) .calendar_me tr th,
.accordion_member .panel.panel-default:nth-child(odd) {
  background: #e6e6e6; }

.accordion_member .panel.panel-default:nth-child(even) .panel-heading,
.accordion_member .panel.panel-default:nth-child(odd) .panel-heading {
  background: none; }

.accordion_me {
  /* & ul li:last-child span {color: #777;} */ }
  .accordion_me.Par_care_ac .panel-default:nth-of-type(odd) {
    background: #fff; }
  .accordion_me.Par_care_ac .panel-default:nth-of-type(even) {
    background: #e6e6e6; }
  .accordion_me.Par_care_ac .panel-heading:nth-of-type(odd) {
    background: none !important; }
  .accordion_me.Par_care_ac .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border: 0px solid #000 !important; }
  .accordion_me .panel-group .panel + .panel {
    padding: 10px; }
  .accordion_me .panel-heading {
    padding: 0;
    line-height: 18px !important;
    border: 0;
    font-size: 18px; }
  .accordion_me ul {
    padding: 0px;
    margin: 0px;
    display: table; }
  .accordion_me ul li:last-child {
    float: right;
    padding-right: 15px;
    padding-left: 10px;
    width: 135px; }
  .accordion_me ul li:nth-child(2) {
    width: 80%; }
  .accordion_me ul li {
    display: table-cell;
    padding: 12px 14px;
    vertical-align: top; }
  .accordion_me .panel-title i {
    padding-top: 10px;
    padding-right: 10px;
    display: table-cell; }
  .accordion_me ul.our_goals li:last-child span {
    color: #1e1e1e !important; }
  .accordion_me .panel-group .panel + .panel {
    padding: 0px; }
  .accordion_me ul {
    width: 100%; }
  .accordion_me .panel-title i {
    display: table-cell;
    position: absolute;
    right: 0px; }
  .accordion_me .panel-group .panel + .panel,
  .accordion_me #accordion.panel-group .panel {
    border-radius: var(--b-radius); }
  .accordion_me .panel-default > .panel-heading {
    color: #1e1e1e;
    /*background-color: #f5f5f5;*/
    border-color: #ddd; }
  .accordion_me .panel-heading {
    background-color: #e6e6e6;
    border-color: #fff;
    border-radius: var(--b-radius); }
  .accordion_me ul li:first-child {
    border-left: 0px solid #ddd; }
  .accordion_me .icon-arrow-1-down:before {
    content: "\3b";
    font-family: "icons_me"; }
  .accordion_me .icon-arrow-1-up:before {
    content: "\3e";
    font-family: "icons_me"; }
  .accordion_me ul li:last-child {
    border-left: 1px solid #ddd; }

.name-user {
  display: table-cell;
  vertical-align: top;
  padding-right: 7px; }

.name-user .message-text:hover {
  cursor: pointer; }

ul.int_mail_1-D.ul_1 {
  list-style: none; }

.int_mail_1-D {
  padding: 0px !important;
  background: #cacaca; }
  .int_mail_1-D.ul-1 li {
    padding: 15px 15px 15px 15px;
    border-bottom: 1px solid #0a3ef6; }
  .int_mail_1-D .message-text {
    font-size: 13px;
    display: table;
    margin-bottom: 5px;
    display: inline-flex;
    word-break: break-all; }
  .int_mail_1-D .message .message-text {
    font-size: 13px;
    font-weight: 300; }
  .int_mail_1-D .message-text {
    font-size: 13px; }
  .int_mail_1-D.ul-1 li {
    border-color: var(--b-color); }
  .int_mail_1-D.ul-1 li.bb-0 {
    border-bottom: 0px solid #fff !important; }

.int_mail_2-D {
  padding: 15px;
  border-bottom: 1px solid #0a3ef6;
  border-color: var(--b-color);
  border-bottom: 0px solid #fff !important; }
  .int_mail_2-D .user-received {
    width: 100%;
    display: table; }
  .int_mail_2-D .message-text {
    font-weight: 300;
    font-size: 13px; }

.header_chat h3 {
  border-bottom: 1px solid #0a3ef6;
  border-color: var(--b-color); }
  .header_chat h3.bb-0 {
    border-bottom: 0px solid #fff !important; }

.Internal_M_D {
  height: 600px;
  background: #cbcbca;
  overflow-y: hidden;
  border-radius: var(--border-radius15); }

.Internal_M_D .mess_V_a {
  flex: 1; }

.Internal_M_D .mess_vn_in_2 {
  padding-right: 30px; }

.Internal_M_D .mess_V1_1:first-child {
  padding-top: 15px; }

.Internal_M_D .mess_V1_1:last-child {
  padding-bottom: 15px; }

.Internal_M_D_3 .mess_V1_1 {
  border-radius: var(--border-radius15);
  background: none; }

.Internal_M_D_3 {
  height: 600px;
  background: #e2e3e2;
  border-radius: var(--border-radius15); }

.Internal_M_D_2 {
  height: 600px;
  width: 100%;
  background: #cbcbca;
  border-radius: var(--border-radius15);
  display: inline-flex; }

.Internal_M_D_2 .mess_V1_1 {
  background: none;
  display: flex;
  min-height: 100%; }

.Internal_M_D_2 .mess_V1_1 {
  padding-top: 15px;
  padding-bottom: 15px; }

.Internal_M_D_2 .mess_V1_1 {
  width: 100%; }

.custom_scolling {
  overflow-y: auto;
  height: 100%; }

.mess_V1_1 {
  background: #cbcbca;
  padding: 7px 15px; }
  .mess_V1_1 .mess_v1 {
    background: #fff; }
  .mess_V1_1 .mess_div_VB {
    background: #fff;
    border-radius: var(--b-radius); }
  .mess_V1_1 + .mess_V1_1.active {
    padding-bottom: 15px;
    margin-top: 10px; }
  .mess_V1_1 .mess_div_VB {
    width: 100%; }
  .mess_V1_1.active {
    background: #dcdcdc;
    padding-top: 15px;
    padding-bottom: 15px; }
  .mess_V1_1.active + .mess_V1_1 {
    padding-top: 15px; }

.mess_v1 {
  display: flex;
  align-items: stretch;
  -ms-flex-pack: justify;
  padding: 10px 15px;
  border-radius: var(--border-radius15); }

.mess_vn_1 {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.mess_vn_in_1 {
  display: flex;
  align-items: stretch; }

.mess_V_2 {
  width: 100%;
  display: flex;
  align-items: stretch;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.mess_V_a {
  padding-left: 5px; }

.mess_V_b {
  -ms-flex-align: end;
  align-items: flex-end;
  display: flex;
  font-size: 9px;
  padding-right: 10px; }

.mess_V_1 {
  width: 40px;
  max-width: 40px; }

.mess_V_1 span {
  width: 40px;
  display: flex;
  height: 40px;
  justify-content: center;
  border: 1px solid #1e1e1e;
  border-radius: 50%;
  overflow: hidden; }

.mess_V_a1 {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 2px; }

.mess_V_a2 {
  font-size: 13px; }

.mess_vn_in_2 p {
  line-height: 14px;
  font-size: 12px; }

.mess_vn_2 {
  width: 30px;
  max-width: 30px;
  justify-content: center;
  text-align: right;
  -ms-flex-align: end;
  align-items: flex-end;
  display: flex; }
  .mess_vn_2 .attach_im,
  .mess_vn_2 .warning-im,
  .mess_vn_2 .flag-im {
    font-size: 20px;
    cursor: pointer; }
  .mess_vn_2 .warning-im {
    margin-left: -10px; }

.Imail_btn_left_v1 i,
.Imail_btn_right_v1 i {
  background: var(--bg-color);
  background-color: var(--bg-color);
  display: inline-flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 17px;
  color: #fff;
  cursor: pointer; }

.Imail_btn_left_v1 i {
  margin-right: 10px; }

.Imail_btn_right_v1 i {
  margin-left: 10px; }

.mees_pdf_v1 {
  display: inline-block;
  width: 120px;
  text-align: center;
  margin-bottom: 15px; }
  .mees_pdf_v1 i {
    font-size: 36px; }
  .mees_pdf_v1 p {
    line-height: 10px;
    font-size: 8px; }

.textarea_height_3 {
  height: 240px; }

.mess_v1_scroll {
  overflow-y: auto;
  height: 360px; }

.Internal_GroupMsg {
  border-radius: var(--border-radius15);
  background: #e2e3e2; }
  .Internal_GroupMsg .panel.panel-default {
    border: 0px solid #000;
    border-bottom: 1px solid #1e1e1e;
    border-radius: 0px !important;
    background: none; }
  .Internal_GroupMsg .panel-default > .panel-heading {
    background: none; }
  .Internal_GroupMsg .panel-default:last-child {
    border-bottom: 0px; }
  .Internal_GroupMsg .icon-arrow-right:before {
    position: inherit !important; }
  .Internal_GroupMsg .panel-title a span:before {
    transform: rotate(0deg);
    display: inline-block;
    padding-right: 5px;
    position: inherit;
    top: 0;
    left: 0; }
  .Internal_GroupMsg .panel-title a.collapsed span:before {
    transform: rotate(0deg);
    display: inline-block; }
  .Internal_GroupMsg .panel-title a span {
    display: inline-block;
    vertical-align: middle; }
  .Internal_GroupMsg .collapsed span:first-child:before {
    content: "\69" !important;
    font-family: "icons_me" !important; }
  .Internal_GroupMsg .panel.panel-default.bb-0,
  .Internal_GroupMsg .bb-0,
  .Internal_GroupMsg .panel-group .panel-default:last-child {
    border-bottom: 0px solid #fff !important; }
  .Internal_GroupMsg .panel.panel-default {
    border-color: var(--b-color); }

.Group_head_v1 {
  font-size: 17px;
  font-weight: 400; }

.GroupMSG_team_v1 {
  padding-left: 20px; }

.GroupMSG_team {
  font-weight: 300;
  background: #fff;
  border-radius: var(--b-radius);
  padding: 10px 15px;
  margin-bottom: 10px;
  justify-content: space-between;
  display: flex; }

.Imail_btn_right_v1 .mess_v1_plus::before {
  font-size: 37px; }

span.mess_V1_noti {
  background: #ee3758;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
  border-radius: 50%; }

.int_text_but {
  position: relative; }

.int_text_but_v1 {
  position: absolute;
  right: 15px;
  bottom: 10px; }

.mess_Cht_1 {
  font-size: 16px;
  font-weight: 400;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center; }
  .mess_Cht_1 i.icon.icon-notes:before {
    display: inline-flex;
    font-size: 25px;
    line-height: 29px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer; }
  .mess_Cht_1 i.icon.icon-notes {
    display: inline-flex;
    line-height: 0;
    height: 24px; }

.mess_Teamchating .textarea_height_3 {
  height: 150px; }

.parant_active i,
.Opacity_03 {
  opacity: 0.3; }

.parant_active i.active,
.Opacity_03.active {
  opacity: 1; }

.mess_div_scroll {
  height: 450px;
  overflow: auto;
  display: flex;
  width: 100%;
  flex-wrap: wrap; }

.mess_div_scroll .mess_v1 {
  width: 100%; }

.mess_div_scroll .mess_v1:first-child .mess_vn_1.bt-1 {
  border-top: 0px; }

.Group_v1 {
  padding: 0px; }

.Group_v1_a {
  line-height: 14px;
  font-size: 12px;
  font-weight: 300;
  margin-top: 5px; }

.Group_v1_b {
  background: #fff;
  padding: 10px;
  border-radius: var(--border-radius15); }
  .Group_v1_b .mess_V_1 {
    width: 30px;
    max-width: 30px; }
  .Group_v1_b .mess_V_1 span {
    height: 30px;
    width: 30px;
    padding: 0px; }
  .Group_v1_b .mess_V_a {
    -ms-flex-align: end;
    align-items: flex-end;
    display: flex; }
  .Group_v1_b .mess_V_b {
    align-items: self-end; }

.Group_replay .Group_v1_b {
  background: #a0a0a0;
  color: #fff; }

.Group_replay .mess_V_a {
  padding-right: 5px; }

.Group_replay .Group_v1_b .mess_V_1 span {
  background: #f2d8b4; }

.Group_replay .Group_v1_b {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.Gro_scroll_mess {
  background: #caccce;
  padding: 0px 0px;
  border-radius: var(--border-radius15);
  height: 280px;
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 20px; }

.GroupMSG_team.active {
  background: var(--bg-color);
  color: #fff; }

.block_MSG .mess_v1 {
  background: #fcf3e4; }

.h_ser_div_2 {
  width: 85%; }

.h_ser_div_3 {
  display: inline-flex; }

.h_ser_div_3 span {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  font-size: 19px; }

.CU_input {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0px; }
  .CU_input input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    clip: rect(0 0 0 0);
    height: 25px;
    width: 25px;
    padding: 0;
    border: 0;
    left: 0;
    margin-top: 0px; }
  .CU_input .CU_input__img::before {
    color: #d22e2f;
    content: "\e06e";
    font-family: "icons_me" !important;
    position: absolute;
    left: 50%;
    top: 50%;
    font-style: initial;
    font-size: 22px;
    transform: translate(-50%, -50%); }
  .CU_input .CU_input__img {
    display: inline;
    position: relative;
    left: 0;
    width: 25px;
    height: 25px;
    background-image: none;
    color: var(--crm-txt-color);
    border-radius: 3px;
    cursor: pointer;
    top: 0px; }
  .CU_input input[type="checkbox"]:checked + .CU_input__img,
  .CU_input input[disabled]:checked + .CU_input__img {
    background-position: 0 0; }
  .CU_input input[type="checkbox"]:checked + .CU_input__img::before,
  .CU_input input[disabled]:checked + .CU_input__img::before {
    font-family: "recruitment-icon";
    content: "\68";
    display: flex;
    font-size: 12px;
    padding: 0px 1px 0px;
    width: 20px;
    height: 20px;
    position: absolute;
    line-height: 0;
    align-items: center; }
  .CU_input input[disabled] + .CU_input__img {
    opacity: 0.5;
    cursor: initial; }
  .CU_input span {
    display: inline-flex;
    height: 20px;
    align-items: center;
    padding-left: 4px;
    font-weight: 500; }
  .CU_input input[type="checkbox"]:checked + .CU_input__img::before,
  .CU_input input[disabled]:checked + .CU_input__img::before {
    content: "\e04a";
    font-family: "icons_me" !important;
    position: absolute;
    left: 50%;
    top: 50%;
    font-style: initial;
    font-size: 22px;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border-radius: var(--border-radius100);
    color: #009966; }

.Imail_Name_div {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Imail_Name_div_1 {
  display: inline-flex;
  align-items: center; }

.Imail_close_icon {
  display: inline-flex;
  align-items: center;
  margin-left: 4px; }

.Imail_Img_div_1 img {
  width: 100px; }

.Imail_Img_div_1 {
  overflow: hidden;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: var(--border-radius100);
  border: 1px solid;
  border-color: #ce1e1e;
  display: flex;
  justify-content: center;
  align-items: center; }

.Imail_close_icon {
  display: inline-flex;
  align-items: center;
  height: 100%;
  max-width: 40px;
  width: 40px;
  justify-content: center;
  font-size: 22px;
  color: #fff;
  line-height: 0;
  cursor: pointer; }

.Imail_Name_div_1 {
  display: inline-flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border-radius: 25px; }

.Imail_Name_txt {
  width: 160px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  margin: 0px; }

.Imail_Des_div {
  background: #fff;
  border-radius: var(--border-radius15);
  padding: 10px;
  margin-top: 5px; }

.Imail_Des_Title_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  border-color: #ccc; }

.Imail_Des_Time {
  font-size: 11px;
  font-weight: 400; }

.Imail_Des_txt {
  font-size: 13px;
  font-weight: 400; }

.Imail_Des_Title1 label {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px; }

.Imail_Des_Title1 {
  width: 140px; }

.Imail_Des_txt {
  font-size: 13px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px; }

.Imail_Reply_btn a {
  font-size: 12px;
  color: #fff;
  background: #ff2d55;
  display: inline-flex;
  align-items: center;
  width: 56px;
  justify-content: center;
  height: 23px;
  font-weight: 400;
  border-radius: 10px; }

.Imail_Reply_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px; }

.Imail_MSG_div {
  padding: 20px 10px;
  border-bottom: 1px solid;
  border-color: #857e7e78; }

.Imail_Des_Title1 label::first-letter,
.Imail_Name_txt::first-letter {
  text-transform: capitalize; }

.Imail_btn_left_v1 .upload_btn input {
  opacity: 0; }

._Common_back_a a {
  display: inline-flex; }

.ho {
  cursor: pointer; }

._Common_He_a {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  padding: 15px 0px; }

._Common_back_a {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding: 15px 0px; }

._Common_Search_a {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  padding: 15px 0px; }

._Common_He_a h1,
h3.color {
  display: flex;
  line-height: normal;
  align-items: center; }

ul.our_goals li {
  position: relative; }
  ul.our_goals li, ul.our_goals li:nth-child(2), ul.our_goals li:last-child {
    border-radius: var(--b-radius); }
  ul.our_goals li, ul.our_goals li:nth-child(2), ul.our_goals li:last-child {
    display: inline-block;
    width: 7.5% !important;
    height: 50px;
    float: none; }
  ul.our_goals li.jan, ul.our_goals li.feb, ul.our_goals li.mar, ul.our_goals li.apr, ul.our_goals li.may, ul.our_goals li.jun, ul.our_goals li.july, ul.our_goals li.aug, ul.our_goals li.set, ul.our_goals li.oct, ul.our_goals li.nov, ul.our_goals li.dec {
    position: relative; }
  ul.our_goals li ul.our_goals span.tag {
    position: absolute;
    bottom: -31px;
    margin-left: -4%;
    height: 24px;
    line-height: 19px;
    width: 400px;
    left: 50%;
    color: #1e1e1e; }
  ul.our_goals li.Refused_to_Participate {
    background: #e51a29; }
  ul.our_goals li.Hand_over_hand_physical_Assistance {
    background: #db4255; }
  ul.our_goals li.Partial_Physical_Assistance {
    background: #e07a00; }
  ul.our_goals li.Model {
    background: #e2a100; }
  ul.our_goals li.Full_Assistance_Direct_Verbal {
    background: #8fd150; }
  ul.our_goals li.Indirect_Verbal {
    background: #09b750; }
  ul.our_goals li.Natural_CueIndependen {
    background: #f1cd83; }
  ul.our_goals li.Gesture {
    background: #009245; }
  ul.our_goals li.set {
    background: #87dbaa; }
  ul.our_goals li.oct {
    background: #f18993; }
  ul.our_goals li.nov {
    background: #87dbaa; }
  ul.our_goals li.dec {
    background: #82c8a4; }
  ul.our_goals li:hover span {
    display: block !important; }

ul.file_down {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  ul.file_down li.active span:after,
  ul.file_down .selected_docs span:after {
    content: "\70";
    position: absolute;
    margin: 14px -48px;
    background: #4caf50;
    font-family: "icons_me";
    padding: 2px;
    border: 1px solid #fff;
    display: inline-block;
    height: 24px;
    width: 25px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-size: 19px;
    border-radius: 20px; }
  ul.file_down li .path_file {
    font-size: 11px;
    line-height: 15px;
    width: auto;
    word-break: break-word;
    text-align: center;
    display: inline-block; }
  ul.file_down li .path_file {
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 20px;
    font-weight: 400 !important; }
  ul.file_down li ._pf_ {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  ul.file_down li.w-20 {
    width: 20%; }
  ul.file_down li span {
    margin: 0px auto;
    position: relative;
    display: inline-block; }
  ul.file_down li.active span:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0px; }
  ul.file_down span.fms_file {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  ul.file_down li {
    float: left;
    width: 12.5%;
    text-align: center;
    padding: 1%;
    padding-top: 0px;
    display: grid;
    justify-content: center; }
  ul.file_down li img {
    display: table;
    text-align: center;
    margin: 0px auto;
    width: 35px; }
  ul.file_down li .path_file {
    margin-top: 0px; }
  ul.file_down .icon-file-icons:before {
    font-size: 55px;
    color: #777;
    color: var(--txt-color); }

.attach_item {
  word-break: break-all;
  text-align: center;
  margin-bottom: 20px; }
  .attach_item h5 {
    font-size: 14px;
    margin-bottom: 10px;
    min-height: 30px; }
  .attach_item i {
    font-size: 45px;
    color: var(--bg-color); }
  .attach_item p {
    font-size: 12px;
    line-height: 12px; }

.attch_row {
  display: flex;
  flex-wrap: wrap; }

.atchd_btn1__ {
  padding: 12px 15px;
  margin-bottom: 10px; }

.multiple_checkbox,
.multiple_checkbox input.checkbox1[type="checkbox"] + label span {
  border-color: var(--b-color); }

.multiple_checkbox {
  border-radius: var(--b-radius); }

.multiple_checkbox span label {
  font-size: 14px !important;
  margin-bottom: 0px !important; }

.multiple_checkbox input.checkbox1[type="checkbox"] + label span {
  border-radius: 2px;
  height: 17px;
  width: 18px; }

.multiple_checkbox input.checkbox1[type="checkbox"]:checked + label span {
  background-size: 20px 19px;
  background-position: center;
  background: var(--bg-color); }

.multiple_checkbox {
  height: 120px; }

.multiple_checkbox {
  border-color: var(--b-color); }

.multiple_checkbox .w_100 {
  width: 100%;
  display: inline-block; }

.multiple_checkbox input.checkbox1[type="checkbox"] + label span {
  background: #fff left top no-repeat;
  border: 1px solid #1e1e1e;
  border: 1px solid var(--b-color); }

.multiple_checkbox {
  border: 1px solid var(--bg-color); }

.multiple_checkbox input.checkbox1[type="checkbox"]:checked + label span {
  background-size: 16px !important;
  position: relative; }

.multiple_checkbox input.checkbox1[type="checkbox"]:checked + label span:after {
  position: absolute;
  content: "\70";
  font-family: "icons_me";
  color: #fff; }

.multiple_checkbox_tooltip .tooltip {
  white-space: nowrap;
  width: auto !important; }

ul.on_form_to_search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  align-items: center;
  margin-bottom: 0px; }
  ul.on_form_to_search li {
    border-color: var(--b-color);
    border-radius: var(--b-radius);
    text-align: center;
    float: left;
    border: 1px solid #1e1e1e;
    display: grid;
    background: #fff;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding-left: 7px; }
    ul.on_form_to_search li span:first-child {
      color: gray; }
    ul.on_form_to_search li span {
      display: inline-block !important;
      font-size: 12px; }
    ul.on_form_to_search li span input {
      border: 0px solid #000 !important;
      padding: 0px;
      padding-left: 5px;
      background: none;
      font-size: 12px;
      text-align: left;
      width: 75px;
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: center;
      vertical-align: middle;
      align-items: center;
      width: 100%;
      margin-bottom: 0px; }
    ul.on_form_to_search li .csform-group .react-datepicker__close-icon,
    ul.on_form_to_search li .cust_date_picker .react-datepicker__close-icon {
      margin-right: -1px; }

input::-webkit-input-placeholder {
  font-weight: 300; }

input::-moz-placeholder {
  font-weight: 300; }

input:-ms-input-placeholder {
  font-weight: 300; }

input:-moz-placeholder {
  font-weight: 300; }

input.input_3::placeholder,
input.input_3::-webkit-input-placeholder,
input.input_3::-moz-placeholder,
input.input_3::-ms-input-placeholder,
input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder,
input::-ms-input-placeholder,
input::-moz-placeholder {
  color: #cacaca;
  font-weight: 300; }

.Unfilled_div {
  padding: 0; }
  .Unfilled_div h1 {
    font-weight: 600; }
  .Unfilled_div .sm_size {
    font-size: 8px;
    line-height: initial; }
  .Unfilled_div .Resi_details span font {
    font-size: 8px;
    line-height: initial; }
  .Unfilled_div .icons_right a img {
    height: 1.3vw; }
  .Unfilled_div h1 {
    display: block;
    line-height: 85px;
    text-align: center;
    height: 85px; }
  .Unfilled_div .accordion_me .panel-title i {
    padding-top: 7px;
    padding-right: 7px; }
  .Unfilled_div h1 {
    border-top: solid 1px #1e1e1e;
    border-bottom: solid 1px #1e1e1e; }
  .Unfilled_div .panel.panel-default:nth-child(odd) .panel-heading {
    background: 0 0 !important; }
  .Unfilled_div .panel.panel-default:nth-child(even) .panel-heading {
    background: 0 0 !important; }
  .Unfilled_div h1 {
    border-color: var(--b-color); }
  .Unfilled_div #accordion.panel-group .panel {
    border-radius: var(--border-radius15); }

.Avai_Member_div {
  padding: 0; }
  .Avai_Member_div .sm_size {
    font-size: 8px;
    line-height: initial; }
  .Avai_Member_div .Resi_details span font {
    font-size: 8px;
    line-height: initial; }
  .Avai_Member_div .icons_right a img {
    height: 1.3vw; }
  .Avai_Member_div h1 {
    display: block;
    line-height: 85px;
    text-align: center;
    height: 85px; }
  .Avai_Member_div .accordion_me .panel-title {
    padding-top: 7px;
    padding-right: 7px; }
  .Avai_Member_div h1 {
    border-top: solid 1px #1e1e1e;
    border-bottom: solid 1px #1e1e1e; }
  .Avai_Member_div .accordion_me ul li {
    display: list-item !important;
    font-size: 10px;
    padding: 4px !important;
    line-height: normal; }
  .Avai_Member_div #accordion.panel-group .panel {
    border-radius: var(--border-radius15); }
  .Avai_Member_div h1 {
    border-color: var(--b-color); }

/* Start counter */
.counter_div {
  position: relative;
  font-size: 2rem;
  font-family: monospace;
  width: max-content; }

.counter_lines {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-image: linear-gradient(to right, black 1px, transparent 1px);
  background-size: 67px; }

.counter_text {
  display: block;
  box-sizing: border-box;
  border-width: 1px 0 1px;
  border-color: #000;
  border-style: solid;
  font: inherit;
  padding: 0 10px 0 13px;
  letter-spacing: 24px;
  font-size: 76px;
  font-family: sans-serif;
  font-weight: 600;
  width: calc(100% - 21px);
  background: #fff; }

.counter_wrapper {
  margin-left: 21px; }

.Over_counter_ {
  display: flex;
  justify-content: space-between; }

.Over_counter_div_ {
  text-align: center; }

.Over_counter_tex_ span {
  font-size: 12px;
  cursor: pointer;
  margin: 0 7px; }
  .Over_counter_tex_ span.active {
    color: var(--txt-color); }

.Overview_conter {
  margin-left: 0; }
  .Overview_conter .counter_lines {
    background-size: 80px; }
  .Overview_conter .counter_text {
    font-size: 100px; }

.Over_counter_ .Over_counter_div_ {
  padding: 30px;
  background: #fff;
  border-radius: var(--b-radius); }
  .Over_counter_ .Over_counter_div_ .Overview_conter {
    padding-left: 10px; }
    .Over_counter_ .Over_counter_div_ .Overview_conter .counter_div {
      margin: 0px auto; }

/* end counter */
.default_validation .Select-control {
  overflow: visible; }

.close_ic {
  cursor: pointer;
  font-size: 21px; }

.close_i i.icon.icon-cross-icons {
  font-size: 24px;
  color: #1e1e1e;
  color: var(--txt-color); }

.horizontal_scrollbar_Container {
  display: flex;
  height: 100%;
  margin-bottom: 15px; }
  .horizontal_scrollbar_Container div {
    flex-shrink: 0;
    height: 200px;
    width: 200px;
    background: #ddd;
    border: 5px solid red; }

.horizontal_scroll::-webkit-scrollbar {
  width: 5px; }

.horizontal_scroll::-webkit-scrollbar-track {
  background-color: #ccc;
  border-left: 1px solid #ccc;
  border-radius: 10px; }

.horizontal_scroll::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 10px; }

.horizontal_scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--bg-color); }

.horizontal_scroll > div {
  position: initial !important; }

.Horizontal-scrollbar-track {
  position: relative; }

.Horizontal-scrollbar-thumb {
  position: absolute;
  z-index: 1;
  height: 15px; }
  .Horizontal-scrollbar-thumb::after {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    background: #f7b7a2;
    z-index: 0;
    height: 15px; }

.vertical_scroll::-webkit-scrollbar {
  width: 5px; }

.vertical_scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-radius: 10px; }

.vertical_scroll::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 10px; }

.vertical_scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--bg-color); }

.accordion_me .panel-title {
  position: relative; }

.accordion_me .panel-title a i {
  transform: rotate(180deg);
  top: 9px;
  margin-right: 10px;
  font-size: 15px; }

.resident_name {
  padding: 10px; }

.resident_name.py-0 {
  padding-top: 0;
  padding-bottom: 0; }

.Resi_details {
  margin: 0; }

.modal-backdrop.in {
  opacity: 0.7 !important; }

.modal-backdrop {
  z-index: 9999 !important; }

.phone_icons {
  width: 45px; }

button.add_i_icon {
  background: 0 0;
  border: 0;
  padding: 0; }

.button_unadd i:before {
  background: #1e1e1e;
  color: #fff;
  padding: 8px 8px 7px 7px; }

button.button_unadd {
  background: 0 0;
  border: 0;
  padding: 0; }

input[type="checkbox"][disabled] + span {
  opacity: 0.2; }

input[disabled] {
  border: 1px solid #ddd;
  cursor: no-drop !important; }

input[disabled] + .tooltip {
  display: none !important; }

.custom_input + .tooltip {
  top: -4px;
  left: 50%;
  transform: translate(-50%);
  visibility: hidden; }

.icon.icon-add-icons.create_add_but::before {
  display: inline-block;
  vertical-align: middle;
  text-align: left !important;
  float: left;
  font-size: 31px; }

a.inherit-color {
  color: inherit !important; }

.no_record {
  background: #e6e6e6;
  color: #777;
  text-align: center;
  border-radius: var(--b-radius); }

.no_record1 {
  min-height: 35px;
  display: inline-flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: var(--b-radius); }

.pin_error {
  text-align: center;
  color: #c00;
  border-radius: var(--b-radius); }

.forgetten_pin a {
  color: #1e1e1e;
  font-weight: 500;
  font-size: 15px;
  text-decoration: underline; }

.Pin_success {
  color: #329c36;
  font-weight: 500;
  font-size: 17px; }

.custom_select + .tooltip {
  white-space: nowrap;
  width: auto !important; }

.margin_right_side .rt-resizable-header-content {
  overflow: visible !important;
  text-overflow: initial !important;
  margin-right: -30px !important;
  text-align: right !important; }

.avalibilty_div {
  margin: 0 auto !important;
  padding: 0;
  background: #e6e6e6;
  padding-top: 30px; }
  .avalibilty_div .calendar_me tr td {
    height: 20px; }
  .avalibilty_div table.calendar_me tr th {
    height: 25px; }
  .avalibilty_div .calendar_me tr td {
    border-top: dashed 1px #cdcccc !important;
    border-right: 1px solid #cdcccc !important; }

.set_alignment {
  min-height: 300px;
  max-height: auto; }

.clearable-show .Select-clear {
  display: block !important;
  margin-right: 10px; }

.bg_dark_Pink .archive_Icon:before,
.bg_dark_yello .archive_Icon:before {
  font-size: 30px;
  color: #f6f6f6; }

.start_in_color {
  color: #c36; }

.icon_h-1:before {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  cursor: pointer;
  color: var(--txt-color); }

.p-relative {
  position: relative; }
  .p-relative div {
    border-radius: var(--border-radius15) !important .p-relative div div;
      border-radius-position: absolute !important;
      border-radius-height: auto !important;
      border-radius-width: 100% !important;
      border-radius-top: 42px !important;
      border-radius-left: 0 !important;
      border-radius-min-height: 100%;
      border-radius-float: left;
      border-radius-border-radius: var(--border-radius15) !important;
      border-radius-overflow: unset !important;
      border-radius-z-index: 9999; }
    .p-relative div div li {
      display: block !important;
      width: 100%;
      list-style: none; }
    .p-relative div div li:first-child {
      border-radius: var(--border-radius15) var(--border-radius15) 0 0 !important; }
    .p-relative div div li:last-child {
      border-radius: 0 0 var(--border-radius15) var(--border-radius15) !important; }

.update_button_g {
  color: #fff;
  padding: 2px;
  background: var(--bg-color);
  display: inline-flex;
  font-size: 45px;
  border-radius: var(--border-radius100);
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center; }

.color_d span {
  color: #1e1e1e; }

.call_icon {
  font-size: 21px;
  display: inline-block;
  vertical-align: middle; }
  .call_icon:before {
    display: inline-block;
    vertical-align: top;
    font-size: 21px;
    color: var(--txt-color); }

.input_search.change_b {
  border-radius: var(--b-radius);
  position: relative; }
  .input_search.change_b span.icon.icon-search {
    font-size: 27px;
    color: #1e1e1e; }
  .input_search.change_b button {
    margin-top: 2px; }

.rejected_shift {
  opacity: 0.4 !important; }

.accepted_shift {
  opacity: 1 !important; }

.shift_start_heading span.g_heading_color {
  color: #4caf50; }

.shift_start_heading.day_lessthan_3_color span {
  color: red; }

.shift_start_heading.day_greater_3_lessthan_7_color span {
  color: #ffb300; }

.shift_start_heading.day_greater_7_color span {
  color: #4caf50; }

ul.status_new {
  list-style: none;
  display: inline-block; }
  ul.status_new li {
    display: inline-grid;
    vertical-align: middle;
    margin-right: 16px;
    font-size: 14px;
    font-weight: 300;
    grid-template-columns: auto auto;
    align-items: center; }
  ul.status_new span {
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    margin-right: 5px; }

.Confirmed {
  background: #89e0a9; }

.Unconfirmed {
  background: #e0da8c; }

.Cancelled {
  background: #ff7a7b; }

.Unfilled {
  background: #a3b5c7; }

.Completed {
  background: #00a551; }

.flex_ul_div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 300;
  padding: 5px 15px;
  font-size: 14px;
  flex: 1;
  border-bottom: 1px solid #77777729;
  background: rgba(0, 0, 0, 0.07);
  margin-bottom: 0px; }
  .flex_ul_div div:first-child {
    width: 20%;
    border-right: 1px solid #777; }
  .flex_ul_div div:nth-child(2) {
    width: 20%;
    border-right: 1px solid #777; }
  .flex_ul_div div:nth-child(3) {
    width: 60%; }
  .flex_ul_div div {
    padding: 0px 15px; }
  .flex_ul_div.heading_list_net {
    background: var(--bg-color);
    margin-bottom: 0px; }
    .flex_ul_div.heading_list_net div {
      color: #fff;
      border-color: #fff; }

.roster_border_td {
  border-right: 0 !important;
  border-left: 1px solid #ddd !important;
  min-width: 30px !important;
  width: 30px !important;
  vertical-align: bottom;
  padding-left: 10px; }

.width_130 path.CircularProgressbar-path {
  stroke: #009688; }

.width_130 path.CircularProgressbar-trail {
  stroke: red; }

.hand_effect .rt-tbody .rt-tr-group {
  cursor: pointer; }

.rt-resizable-header-content span small {
  font-size: 10px; }

.add_input button i:before {
  background: #fff; }

.add_input button i.icon_cancel_1 {
  font-size: 23px;
  padding-top: 4px; }

.date_and_content {
  display: flex;
  vertical-align: bottom;
  align-items: baseline;
  justify-content: end;
  word-break: break-word; }
  .date_and_content .message-text {
    width: 100%; }

.no_Msg {
  font-weight: 400;
  text-align: center;
  margin-top: 210px;
  font-size: 25px; }

.timer_div .react-datepicker,
.timer_div .react-datepicker-popper,
.timer_div .react-datepicker__time-container,
.timer_div .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100% !important; }

.timer_div .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-right: 0 !important; }

span.Short_text {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: var(--border-radius100);
  display: inline-flex;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  border: 1px solid #1e1e1e;
  font-size: 18px;
  font-weight: 600;
  margin-right: 5px; }

span.Nam_d_1 {
  background: #fff;
  display: inline-flex;
  padding: 1px 11px;
  margin-right: 5px;
  border-radius: var(--b-radius); }

.update_team {
  font-size: 32px;
  display: inline-flex;
  justify-content: center;
  margin-left: 0;
  vertical-align: middle;
  color: #09a275;
  cursor: pointer; }

.colorPicker {
  border: 1px solid #1e1e1e;
  display: flex;
  height: 40px;
  padding: 9px;
  text-align: center;
  background: #fff;
  border-radius: var(--b-radius); }
  .colorPicker div {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: var(--border-radius100);
    margin: 0 auto; }

.team_members_div {
  display: flex;
  margin-bottom: 5px; }

.t_m_d_1 {
  background: #fff;
  padding: 7px;
  border-radius: var(--border-radius100);
  margin-right: 7px;
  height: 40px;
  width: 40px;
  vertical-align: middle;
  align-items: center;
  justify-content: center; }

.t_m_d_2 {
  background: #fff;
  display: flex;
  padding: 5px 25px;
  margin-right: 5px;
  font-size: 15px;
  font-weight: 500;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 2px;
  border-radius: var(--b-radius); }

.t_m_d_3 i.icon.icon-email-pending {
  font-size: 31px;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  color: #c36; }

.modal {
  z-index: 9999999 !important; }

.react-confirm-alert-overlay {
  z-index: 99999999 !important;
  background: rgba(0, 0, 0, 0.7) !important; }

.tooltip {
  z-index: 8; }

.w_100_date .react-datepicker-wrapper,
.w_100_date .react-datepicker__input-container {
  width: 100%; }

.icon-un_attach_btn {
  display: flex;
  align-items: center;
  line-height: 0;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
  color: #c36; }
  .icon-un_attach_btn i:before {
    font-size: 26px;
    display: flex;
    align-items: center;
    line-height: 0;
    height: 100%;
    width: 100%; }
  .icon-un_attach_btn i:before {
    color: #c36; }

.icon-attach_btn {
  display: flex;
  align-items: center;
  line-height: 0;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px; }
  .icon-attach_btn i:before {
    font-size: 26px;
    display: flex;
    align-items: center;
    line-height: 0;
    height: 100%;
    width: 100%; }
  .icon-attach_btn i:before {
    color: #11b362; }

.fms_accor .panel-title ul li.li_fms_1 {
  width: 77%; }

.fms_accor .panel-title ul li.li_fms_2 {
  min-width: 170px;
  width: 17%; }

.moni_btn {
  background: #ccc;
  display: flex;
  height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: #1e1e1e; }
  .moni_btn.active {
    background: #e6e6e6;
    color: #777; }
  .moni_btn:before {
    font-family: icons_me;
    content: "\69";
    position: absolute;
    left: 25px;
    font-size: 15px; }

.pm_green_regular {
  background: #08b750 !important;
  border: 1px solid #08b750 !important; }

.align_e_1 {
  display: inline-flex;
  align-items: center;
  margin: 2px 0; }

.align_e_2 {
  display: inline-flex;
  align-items: center;
  margin: 2px 0;
  justify-content: flex-end;
  border-bottom: 1px solid transparent; }

.h_small i {
  display: inline-flex;
  align-items: center; }

.not_found_div {
  padding: 7px !important;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 17px;
  font-weight: 300;
  color: #777;
  border-radius: var(--b-radius); }

.align_top_to_1 .align_e_1,
.align_top_to_1 .align_e_2 {
  align-items: flex-start; }

.vw_bx12 span {
  font-size: 12px;
  cursor: pointer; }

.vw_bx12 h5 {
  margin-bottom: 0; }

.myLegend h4 {
  font-weight: 700;
  margin-bottom: 10px; }

span.booking_L_T1 i {
  display: inline-block;
  line-height: 23px !important;
  vertical-align: middle;
  cursor: pointer; }

.booking_L_T1 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 100%; }

.booking_L_T2 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 27px;
  line-height: 0; }

.but_Change {
  width: 100%;
  line-height: 15px;
  padding: 13px 15px;
  border: 0;
  text-align: center;
  display: table;
  background: #ccc;
  font-weight: 400;
  color: #1e1e1e;
  font-size: 17px;
  border-radius: var(--b-radius); }
  .but_Change.active {
    background: #e6e6e6;
    color: var(--txt-color); }

.react-confirm-alert {
  background: #fff;
  width: 450px;
  border-radius: var(--b-radius); }
  .react-confirm-alert .custom-ui {
    padding: 30px; }

.confi_but_div {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; }

.confi_header_div {
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid;
  border-top: 1px solid;
  align-items: center;
  margin-bottom: 15px; }
  .confi_header_div h3 {
    color: #1e1e1e;
    font-weight: 600;
    font-size: 23px; }

span.icon.icon-cross-icons:before {
  display: flex;
  font-size: 22px;
  cursor: pointer;
  opacity: 1;
  font-weight: 600 !important; }

.Confirm_btn_Conf {
  display: inline-flex;
  justify-content: center;
  font-size: 16px;
  min-width: 140px;
  margin-right: 0;
  background: #39b54a;
  border: 0;
  height: 35px;
  align-items: center;
  line-height: 0;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: var(--b-radius); }

a.Confirm_btn_Conf:hover {
  color: #fff; }

.Cancel_btn_Conf {
  display: inline-flex;
  justify-content: center;
  font-size: 16px;
  width: 140px;
  margin-right: 0;
  background: #ed1c24;
  border: 0;
  height: 35px;
  align-items: center;
  line-height: 0;
  color: #fff;
  border-radius: var(--b-radius); }

.custom-ui p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px; }

.U_btn_new a {
  border: 1px solid #1e1e1e;
  display: flex;
  background: #fff;
  color: #1e1e1e;
  font-size: 16px;
  justify-content: center;
  height: 40px;
  align-items: center;
  font-weight: 400;
  border-radius: var(--b-radius);
  border-color: var(--b-color);
  color: var(--txt-color); }
  .U_btn_new a.active {
    background: var(--bg-color);
    background-color: var(--bg-color);
    color: #fff !important; }

.R1_TEST_1 {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 7px; }

.color-initial {
  color: initial; }

.cbtn_i {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  float: right; }
  .cbtn_i i {
    display: flex;
    font-size: 35px;
    padding: 0; }

.Sae_row tr td {
  background: #7773; }

.CH_row {
  display: flex;
  width: 100%;
  background: #fff;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--b-radius); }
  .CH_row .CH_d1 {
    display: inline-flex;
    height: 100%;
    align-items: center;
    padding: 5px 15px; }
  .CH_row .CH_d1:first-child {
    border-right: 1px solid;
    min-width: 120px;
    border-color: rgba(0, 0, 0, 0.17); }
  .CH_row .CH_date {
    padding-right: 10px; }

.CH_i2 {
  font-size: 22px;
  line-height: 22px;
  width: 30px;
  text-align: center; }

.CH_i1 {
  font-size: 28px;
  line-height: 22px;
  color: #05adee;
  width: 30px;
  text-align: center; }

.CH_div .CH_row {
  margin: 10px 0;
  font-weight: 400; }
  .CH_div .CH_row:nth-child(odd) {
    background: rgba(0, 0, 0, 0.06); }

.aprvl_rcTable11 .rt-tbody .rt-td {
  padding-left: 10px !important; }

.apr_col i {
  font-size: 27px;
  margin-right: 10px;
  opacity: 0.5;
  cursor: pointer; }
  .apr_col i.active, .apr_col i:hover {
    opacity: 1; }
  .apr_col i.deny_request {
    color: red; }
  .apr_col i.approve_request {
    color: green; }
  .apr_col i.active {
    cursor: default; }

.but.cnfrm_btn {
  width: 200px !important; }

.inl_ulAp {
  margin-bottom: 10px; }
  .inl_ulAp li {
    display: inline-block !important;
    margin-right: 15px;
    margin-bottom: 10px; }

.pin_ic1 {
  color: orange;
  font-size: 15px;
  font-size: 22px;
  margin-right: 10px; }

.dsBlck {
  align-items: center;
  line-height: 0; }
  .dsBlck.active i {
    color: #1ca177; }

.adrsName {
  background-color: #efefef;
  border-radius: 30px;
  padding: 10px 15px;
  padding-right: 60px;
  position: relative; }
  .adrsName::before {
    content: "";
    position: absolute;
    width: 1px;
    background: #bcbcbc;
    height: 100%;
    top: 0;
    right: 50px; }
  .adrsName span {
    display: block;
    color: #555;
    font-size: 13px;
    font-weight: 700; }
  .adrsName i {
    position: absolute;
    width: 40px;
    height: 100%;
    font-size: 20px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    color: #d22e2f; }

.AdInline {
  margin-top: 20px; }
  .AdInline .adrsName {
    float: left;
    margin-right: 15px;
    min-width: 265px; }
  .AdInline .adrsBlSP {
    margin-right: 15px; }

.AdBlock .adrsName {
  width: 100%;
  margin-bottom: 15px; }

.wd80M {
  width: 80%; }

.adIc12 {
  float: left; }

.adrsBlSP {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }

.bcolor {
  border-color: #1e1e1e; }

.DD_list ul {
  margin-left: 25px; }
  .DD_list ul li {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px; }
  .DD_list ul li:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #333;
    display: inline-block;
    align-items: center;
    border-radius: 20px;
    vertical-align: middle;
    margin-right: 5px; }
  .DD_list ul li:hover {
    cursor: pointer; }

.A_R_btn {
  display: flex;
  align-items: center;
  height: 40px;
  background: var(--bg-color);
  cursor: pointer;
  color: #fff;
  width: 100%;
  border: 0;
  padding: 0;
  border-radius: var(--b-radius); }
  .A_R_btn span {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    justify-content: left;
    align-items: center;
    height: 100%;
    padding-left: 15px; }
  .A_R_btn i {
    display: flex;
    align-items: center;
    font-size: 26px;
    height: 100%;
    width: 45px;
    justify-content: center;
    padding-top: 0;
    padding-right: 6px; }

.CSD_modal .align_e_1 {
  font-size: 17px;
  font-weight: 400; }

.inactive_btn {
  padding: 0;
  background: 0 0;
  font-size: 27px;
  line-height: 0;
  color: #ce1e1e; }
  .inactive_btn:focus, .inactive_btn:hover {
    color: #ce1e1e; }

.active_btn {
  padding: 0;
  background: 0 0;
  font-size: 27px;
  line-height: 0;
  color: #096; }

.Unot_A_table .ReactTable .rt-tbody .rt-td:first-child {
  padding: 0; }
  .Unot_A_table .ReactTable .rt-tbody .rt-td:first-child span {
    padding-left: 15px; }

.Unot_A {
  display: inline-flex;
  width: 100%;
  background: #ed1c24;
  min-height: 40px;
  align-items: center;
  color: #fff;
  border-radius: var(--b-radius) 0 0 var(--b-radius); }

.Tb_class_d1 > span {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%; }

.Tb_class_d1 span div {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  line-height: normal; }

.Tb_class_d1 span {
  white-space: nowrap; }

.Tb_class_d1 span div {
  overflow: hidden;
  text-overflow: ellipsis; }

.Tb_icon_L1 {
  font-size: 23px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #c00; }

.Tb_icon_P1 {
  display: inline-flex;
  font-size: 22px;
  align-items: center; }

.header_none_react_table .rt-thead.-header {
  display: none; }

.align_e_2 {
  justify-content: flex-end;
  text-align: right; }

.ConH_Btn span {
  font-size: 16px; }

.ConH_Btn i::before {
  font-size: 26px;
  display: flex;
  height: 32px;
  align-items: center;
  padding-right: 3px; }

.ConH_modal .text {
  height: 45px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 22px; }

.ConH_modal .booking_L_T1 i:before {
  display: flex;
  font-size: 23px;
  align-items: center; }

.ConH_modal .listing_table .ReactTable.-striped .rt-tr.-even {
  box-shadow: 0 0 5px 1px #d2d2d282; }

.ConH_modal .listing_table .ReactTable.-striped .rt-tr.-odd {
  box-shadow: 0 0 5px 1px #d2d2d282; }

.ConH_modal .ReactTable .rt-tbody {
  padding: 1px; }

.ConH_Sear input {
  height: 45px;
  padding: 0 15px 0;
  font-size: 17px;
  line-height: 44px; }

.ConH_Sear button span {
  font-size: 26px; }

.attach_txt {
  display: flex; }
  .attach_txt span {
    font-size: 14px;
    font-weight: 300; }
  .attach_txt i {
    margin-left: 5px;
    cursor: pointer;
    color: red;
    font-size: 17px; }

.VEC_btn {
  border-color: #1e1e1e;
  color: #1e1e1e; }

.archive_Icon {
  cursor: pointer; }

.but.VEC_btn {
  color: #fff;
  background: #1e1e1e; }

.Edit_i1 {
  font-size: 21px;
  color: #1ca177;
  cursor: pointer; }

.Arch_i1 {
  font-size: 23px;
  color: #c36;
  cursor: pointer; }

.H_B_1 {
  display: inline-flex;
  width: 100%;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  background: #ccc;
  border-radius: var(--b-radius); }

.Pass_Ma1 {
  text-align: center;
  margin-top: 70px; }

.pagination-bottom {
  margin: 0 auto; }
  .pagination-bottom ul {
    margin-bottom: 0px; }

.hand_cursor {
  cursor: pointer; }

span.mail_d_not {
  position: absolute;
  right: -10px;
  background: #ff2d55;
  min-width: 35px;
  min-height: 35px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  border-radius: var(--border-radius100);
  top: -10px; }

.AL_flex {
  display: flex;
  align-items: flex-end; }

.AL_flex::after,
.AL_flex::before {
  content: none; }

.Select_MSG_Show {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 400;
  margin-top: 14px;
  border: 1px solid var(--bg-color);
  position: relative;
  padding-right: 0; }
  .Select_MSG_Show::after {
    border-bottom: 17px solid var(--bg-color);
    border-left: 2px solid var(--bg-color);
    content: "";
    position: absolute;
    left: 10px;
    top: -17px; }

.Select_MSG_Show_scroll {
  min-height: auto;
  overflow-y: auto;
  max-height: 72px; }
  .Select_MSG_Show_scroll div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative; }
  .Select_MSG_Show_scroll div i {
    position: absolute;
    right: 8px;
    font-size: 11px;
    top: 3px;
    cursor: pointer; }

.compose_scroll {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 540px;
  overflow: auto; }

.dropdownMenu ul.Sub_in_sub {
  display: block; }

.dropdownMenu.removeDropdown ul {
  display: block; }

.dropdownMenu.removeDropdown .icon-arrow-right {
  display: none; }

div.sideHead1__ {
  min-height: 65px; }

footer {
  z-index: -1; }

.Sub_in_sub ul li a {
  padding: 0;
  font-weight: 400;
  padding-left: 25px;
  font-size: 13px;
  padding-bottom: 3px; }

.create-new-par__ {
  padding: 30px 0 0; }

label.t_input-01__ {
  padding-left: 0; }

._pf_ span {
  padding-right: 5px; }

.Sched_shift-02__ {
  display: flex;
  flex-direction: column;
  width: 100%; }

.Sched_shift-02-1__ {
  display: flex;
  flex-direction: row;
  margin: 5px 0; }
  .Sched_shift-02-1__ div {
    min-width: 100px;
    text-align: center;
    font-size: 15px;
    font-weight: 400; }

.Sched_shift-02-2__ {
  display: flex;
  flex-direction: row;
  margin: 5px 0; }
  .Sched_shift-02-2__ div {
    min-width: 100px;
    text-align: center;
    font-size: 15px;
    font-weight: 400; }
  .Sched_shift-02-2__ div span {
    padding: 9px 15px 5px 14px;
    background: #e6e6e6;
    display: inline-block; }
  .Sched_shift-02-2__ div:first-child {
    text-align: left; }
  .Sched_shift-02-2__ div:first-child span {
    min-width: 70px;
    text-align: center; }

.time_d_1 .V4_int_cont1b__ input[value="0"] + .Select-control {
  background: #f7941d !important; }

.time_d_1 .V4_int_cont1b__ input[value="1"] + .Select-control {
  background: #14a751 !important; }

.time_d_1 .V4_int_cont1b__ input[value="2"] + .Select-control {
  background: #ff0000 !important; }

.time_d_1 .V4_int_cont1b__ input[value="3"] + .Select-control {
  background: #25aae1 !important; }

.add_input_b1__ {
  height: 30px;
  display: block; }

.ellipsis_line__ {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  line-height: normal; }

.ellipsis_line1__ {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  line-height: normal; }

.Action_align_c__ {
  display: block;
  text-align: center; }

._align_c__ {
  text-align: center; }

._align_l__ {
  text-align: left; }

._align_r__ {
  text-align: right; }

.Ar_Symbol_1__ {
  display: flex;
  justify-content: center;
  align-items: center; }
  .Ar_Symbol_1__ i.icon-email-pending {
    font-size: 30px;
    margin-top: 2px; }

.GroupMSG_team_v1 i {
  margin-left: 5px; }

.view_by_ {
  display: flex;
  justify-content: center;
  align-items: center; }
  .view_by_ ul li {
    display: inline-flex;
    margin: 0 4px;
    font-size: 13px;
    color: var(--b-color);
    padding: 2px;
    cursor: pointer; }
  .view_by_ ul li.active {
    color: #000; }
  .view_by_ span {
    font-size: 13px;
    font-weight: 600;
    color: var(--b-color); }

.Plan_div_ {
  display: flex;
  align-items: center;
  background: #fff; }

.Plan_div_site_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  .Plan_div_site_ i {
    display: flex;
    font-size: 33px;
    color: var(--txt-color);
    cursor: pointer; }

.Plan_heading_ {
  flex: 3;
  justify-content: flex-end;
  display: inline-flex;
  padding: 8px 10px;
  color: var(--txt-color); }

.Plan_heading_details_ {
  flex: 9;
  justify-content: flex-start;
  display: inline-flex;
  padding: 8px 10px; }

.Plan_icon_div_ {
  padding: 10px; }
  .Plan_icon_div_ .font_ic_1 {
    display: inline-flex;
    font-size: 28px;
    margin-right: 10px;
    color: #c36; }
  .Plan_icon_div_ .font_ic_2 {
    display: inline-flex;
    font-size: 28px;
    color: #c90; }

.panel-group.Nds_plan .panel-heading + .panel-collapse > .list-group,
.panel-group.Nds_plan .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid var(--b-color); }

.Nds_plan .panel-title i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%); }

.Nds_plan .panel-body,
.Nds_plan .panel-heading {
  padding: 0; }

.Nds_plan .panel {
  border-color: var(--b-color);
  overflow: hidden; }

.Par_care_ac table td {
  padding: 10px 10px; }

.Par_care_ac table td:first-child {
  width: 85px;
  padding-right: 0; }

.Par_care_ac table td:nth-child(2) {
  width: 160px;
  text-align: right;
  padding-left: 0; }

.Par_care_ac table td:nth-child(3) {
  padding-right: 30px; }

.Par_care_ac .panel-body table tr:first-child td {
  padding-top: 3px; }

.Par_care_ac table td:nth-child(2) {
  border-right: 1px solid #ccc;
  color: #1e1e1e; }

.Par_care_ac a[aria-expanded="true"] .collapsed_me {
  white-space: pre-wrap;
  text-overflow: initial; }

.Par_care_ac table td:nth-child(2),
.Par_care_ac table td:nth-child(3) {
  font-size: 16px;
  line-height: 16px; }

.Par_care_ac table td:first-child {
  font-weight: 300 !important; }

.Par_care_ac table td:first-child {
  font-size: 11px; }

.User_infomation_fileds_ {
  display: flex;
  flex-wrap: wrap; }
  .User_infomation_fileds_ .User_info_td_ {
    width: 20%;
    display: flex;
    flex-direction: column;
    padding: 15px; }

.User_info_td_ label {
  font-size: 14px;
  font-weight: 800 !important;
  word-break: break-all; }

.User_info_td_ span {
  font-size: 14px;
  font-weight: 300;
  word-break: break-all; }

div.pac-container {
  z-index: 99999999 !important; }

.mac_line_e_.rt-noData {
  margin-top: 25px; }

.mac_line_h_ .rt-tbody .rt-tr .rt-td {
  min-height: 40px; }

.font_issue {
  display: inline-flex;
  height: 100%;
  align-items: center; }
  .font_issue .ellipsis-one {
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden; }

.Graph_flex {
  display: flex; }

.action_ix__ {
  display: flex;
  align-items: center;
  height: 100%; }
  .action_ix__ a {
    display: inline-flex; }

.gendar_txt_ {
  min-width: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
  min-height: 40px;
  border-radius: var(--b-radius);
  color: #fff; }

.selected_docs span:after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0; }

.Park-Rejected-crm .modal-dialog {
  width: 768px; }

a.button_plus__ {
  color: #fff; }

.Tb_class_d2 div {
  width: 100%;
  text-align: center;
  padding-right: 20px; }

.remove_icon_table_d_ span::after {
  position: absolute;
  content: "\e0c8";
  font-family: icons_me;
  color: #fff;
  font-size: 14px;
  left: 1px; }

.bor_T,
.bt-1,
.bor_B,
.bb-1,
.by-1,
.Select-control,
textarea,
.input,
input,
.input_search.change_b .form-control,
.colorPicker,
.t_m_d_1,
.ReactTable .-pagination .-pageSizeOptions,
.bcolor,
.ReactTable .-pagination .-pageJump input {
  border-color: var(--b-color); }

.Select .Select-control,
div.Select.is-open > .Select-control {
  border-color: var(--bg-color); }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: var(--b-color); }

.radius_first_last tr th:first-child,
.radius_first_last tr td:first-child {
  border-radius: var(--b-radius) 0px 0px var(--b-radius); }

.radius_first_last tr th:last-child,
.radius_first_last tr td:last-child {
  border-radius: 0px var(--b-radius) var(--b-radius) 0px; }

.modal-lg .modal-content {
  border-radius: var(--b-radius); }

.even_odd tbody tr td:first-child {
  border-radius: var(--b-radius) 0px 0px var(--b-radius); }

.even_odd tbody tr td:last-child {
  border-radius: 0px var(--b-radius) var(--b-radius) 0px; }

.resident_name,
.cont_heading,
.cont_heading font {
  font-size: 16px;
  line-height: 16px; }

textarea,
.name,
.css-1492t68,
.make_up,
.font_w_3 {
  font-weight: 300 !important; }

.col-md-3.f {
  font-weight: 300 !important; }

.col-md-9.f {
  font-weight: 600; }

.Newest {
  font-weight: 500;
  font-size: 50px; }

.small {
  font-size: 10px; }

.dtr-data h4 {
  color: inherit;
  font-size: 16px;
  line-height: 16px; }

.font_w_3 tr td span {
  font-size: 13px; }

.view_by,
.font_w_3 tr td {
  font-size: 14px !important; }

.but {
  font-style: unset; }

input {
  font-weight: normal; }

textarea {
  font-size: 15px; }

.schedule_listings .ReactTable .Cus_Check_1,
.listing_table .ReactTable .Cus_Check_1 {
  margin-bottom: 0px;
  position: relative;
  align-self: center;
  justify-content: center;
  display: flex; }
  .schedule_listings .ReactTable .Cus_Check_1 input[type="checkbox"]:not(:checked),
  .schedule_listings .ReactTable .Cus_Check_1 input[type="checkbox"]:checked,
  .listing_table .ReactTable .Cus_Check_1 input[type="checkbox"]:not(:checked),
  .listing_table .ReactTable .Cus_Check_1 input[type="checkbox"]:checked {
    left: 0;
    height: 15px;
    width: 15px;
    top: 0;
    bottom: 0;
    margin: 0px;
    cursor: pointer;
    position: absolute;
    opacity: 0; }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1,
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1 {
    position: relative;
    padding-left: 0;
    display: inline-flex; }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:before,
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:before,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:before,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid var(--bg-color);
    background: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .schedule_listings .ReactTable .Cus_Check_1.minus_select__ [type="checkbox"]:not(:checked) + .chk_Labs_1:before,
  .schedule_listings .ReactTable .Cus_Check_1.minus_select__ [type="checkbox"]:checked + .chk_Labs_1:before,
  .listing_table .ReactTable .Cus_Check_1.minus_select__ [type="checkbox"]:not(:checked) + .chk_Labs_1:before,
  .listing_table .ReactTable .Cus_Check_1.minus_select__ [type="checkbox"]:checked + .chk_Labs_1:before {
    content: "\e0c8";
    background: var(--bg-color);
    color: #fff;
    font-family: "icons_me"; }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:before,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:before {
    background: var(--bg-color); }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1.white_bg:before,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1.white_bg:before {
    background: #fff; }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:after,
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:after,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:after,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:after {
    content: "\70";
    width: 16px;
    height: 16px;
    font-family: "icons_me";
    font-size: 15px;
    line-height: 0.8;
    color: #fff;
    transition: all 0.2s;
    position: absolute;
    left: 0;
    top: 2px; }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1.white_bg:after,
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1.white_bg:after,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1.white_bg:after,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1.white_bg:after {
    color: #000; }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:after,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:after {
    opacity: 0;
    transform: scale(0); }
  .schedule_listings .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:after,
  .listing_table .ReactTable .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:after {
    opacity: 1;
    transform: scale(1); }
  .schedule_listings .ReactTable .Cus_Check_1 .chk_Labs_1 span,
  .listing_table .ReactTable .Cus_Check_1 .chk_Labs_1 span {
    padding-left: 5px; }

.schedule_listings .ReactTable .Set_Sec___,
.listing_table .ReactTable .Set_Sec___ {
  display: inline-flex;
  vertical-align: middle; }

.h4_edit__ {
  font-weight: 400; }

/**************************/
.save_new_roster_model input.checkbox_flex[type="checkbox"] + label span {
  margin: 0px !important; }

.save_new_roster_model input.checkbox_flex[type="checkbox"]:checked + label span:after {
  top: 0 !important; }

.save_new_roster_model .save_new_roster_table_:nth-child(even) .make_up {
  background: rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 5px 0px #b7adad;
  border-radius: var(--b-radius);
  padding: 4px 0px; }

.save_new_roster_model .save_new_roster_table_:nth-child(odd) .make_up {
  background: #fff;
  box-shadow: 0px 0px 5px 0px #b7adad;
  border-radius: var(--b-radius);
  padding: 4px 0px; }

.hamburger .icon.icon-menu.menu_bar_icon {
  display: flex;
  align-content: center;
  align-items: center; }

.active_user_new .default_but_remove {
  display: inline-flex;
  align-items: center; }

.active_user_new .row {
  display: flex;
  align-items: center; }

.footer_right_v2 .row {
  display: flex;
  align-content: center; }
  .footer_right_v2 .row div {
    align-self: center; }

.footer_right_v2.bt-1 {
  border-color: #857e7e78; }

.select_type_box {
  height: 40px;
  display: flex;
  padding: 5px 13px;
  border: 1px solid #b7b7b7;
  border-radius: var(--b-radius);
  background: #b7b7b7;
  color: #000;
  align-items: center; }

.input_type_box {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  background: #fff;
  border: 1px solid #1e1e1e;
  border-radius: var(--b-radius); }
  .input_type_box.text-left {
    text-align: left; }
  .input_type_box.text-right {
    text-align: right; }

.bg_w {
  background: #fff; }

.app_infoBox__ {
  padding: 30px 45px; }

.row_status_scucces {
  flex-grow: 1; }
  .row_status_scucces .FScroll {
    padding: 15px;
    background: rgba(123, 211, 90, 0.5); }
    .row_status_scucces .FScroll .scrollarea .scrollbar-container {
      background: #fff !important; }
      .row_status_scucces .FScroll .scrollarea .scrollbar-container .scrollbar {
        background: green !important; }
  .row_status_scucces p {
    margin: 0px;
    color: green;
    padding-bottom: 7px; }

.row_status_error {
  flex-grow: 1; }
  .row_status_error .FScroll {
    padding: 15px;
    background: rgba(206, 17, 38, 0.05); }
    .row_status_error .FScroll .scrollarea .scrollbar-container {
      background: #fff !important; }
      .row_status_error .FScroll .scrollarea .scrollbar-container .scrollbar {
        background: red !important; }
  .row_status_error p {
    margin: 0px;
    color: red;
    padding-bottom: 7px; }

.Site_icon,
.Participant_icon,
.Sub_org_icon,
.Org_icon,
.Member_icon,
.House_icon,
.Other_icon {
  display: grid;
  grid-template-columns: 25px 1fr; }
  .Site_icon .icon,
  .Participant_icon .icon,
  .Sub_org_icon .icon,
  .Org_icon .icon,
  .Member_icon .icon,
  .House_icon .icon,
  .Other_icon .icon {
    font-family: "hcm-ie" !important;
    display: inline-flex; }
    .Site_icon .icon::before,
    .Participant_icon .icon::before,
    .Sub_org_icon .icon::before,
    .Org_icon .icon::before,
    .Member_icon .icon::before,
    .House_icon .icon::before,
    .Other_icon .icon::before {
      font-family: "hcm-ie" !important;
      font-style: normal;
      font-size: 20px; }

.Site_icon .icon {
  padding-right: 5px; }
  .Site_icon .icon::before {
    content: "\6c" !important;
    color: #1EB35A; }

.Participant_icon .icon::before {
  content: "\6f" !important;
  color: #B175FF; }

.Member_icon .icon::before {
  content: "\6f" !important;
  color: #FF7043; }

.Member_icon.male .icon::before,
.Participant_icon.male .icon::before {
  content: "\6f" !important; }

.Member_icon.female .icon::before,
.Participant_icon.female .icon::before {
  content: "\6e" !important; }

.Sub_org_icon .icon::before {
  content: "\6d" !important;
  color: #1EB35A; }

.Org_icon .icon::before {
  content: "\6b" !important;
  color: #1EB35A; }

.House_icon .icon::before {
  content: "\6c" !important;
  color: #1EB35A; }

.Other_icon .icon::before {
  content: "\6f" !important;
  color: #000; }

.custom_tooltip_set_0 span {
  position: relative;
  cursor: pointer; }
  .custom_tooltip_set_0 span i {
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translate(-50%, -10px);
    font-size: 12px !important;
    background: #000;
    color: #fff;
    font-style: normal;
    padding: 2px 5px;
    border-radius: var(--b-radius);
    display: none !important; }
  .custom_tooltip_set_0 span:hover i {
    display: inline-flex !important; }
    .custom_tooltip_set_0 span:hover i::after {
      content: "";
      display: inline-block;
      border-top: 5px solid black;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      width: 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 7px); }

.f-12 {
  font-size: 12px; }

.f-13 {
  font-size: 13px; }

.f-14 {
  font-size: 14px; }

.f-15 {
  font-size: 15px; }

.f-16 {
  font-size: 16px; }

.f-17 {
  font-size: 17px; }

.f-18 {
  font-size: 18px; }

.f-19 {
  font-size: 19px; }

.f-20 {
  font-size: 20px; }

.f-21 {
  font-size: 21px; }

.f-22 {
  font-size: 22px; }

.f-23 {
  font-size: 23px; }

.f-24 {
  font-size: 24px; }

.f-25 {
  font-size: 25px; }

.fund_tble > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px; }
  .fund_tble > div > div {
    padding: 3px; }

.grph_dur_handler {
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto; }
  .grph_dur_handler li {
    background: var(--bg-color);
    color: #fff;
    cursor: pointer;
    margin: 5px;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 13px;
    min-width: 70px;
    max-width: 100%;
    font-weight: normal;
    text-align: center;
    flex: 1;
    opacity: 0.5; }
    .grph_dur_handler li.active {
      opacity: 1;
      cursor: default; }
    .grph_dur_handler li:hover {
      opacity: 1; }

.all_box {
  max-width: 350px; }
  .all_box .all_head {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 11px; }
    .all_box .all_head > div {
      padding: 5px 5px; }
      .all_box .all_head > div.h_amt__ {
        text-align: right; }
      .all_box .all_head > div.h_head__ {
        padding-left: 20px; }
  .all_box .all_body {
    padding: 15px 0; }
    .all_box .all_body .all_row_grp {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 5px; }
      .all_box .all_body .all_row_grp > div {
        padding: 5px; }
        .all_box .all_body .all_row_grp > div.amt__ {
          text-align: right; }
        .all_box .all_body .all_row_grp > div.head__ {
          position: relative;
          padding-left: 20px;
          display: flex; }
          .all_box .all_body .all_row_grp > div.head__ span.hBox {
            width: 15px;
            height: 15px;
            background: #000;
            display: inline-flex;
            margin-right: 5px;
            min-width: 15px; }
          .all_box .all_body .all_row_grp > div.head__.color_1:before {
            background: #e4d5f8; }
          .all_box .all_body .all_row_grp > div.head__.color_2:before {
            background: #be8dfd; }
          .all_box .all_body .all_row_grp > div.head__.color_3:before {
            background: #7000ff; }
          .all_box .all_body .all_row_grp > div.head__.color_4:before {
            background: #29005d; }
  .all_box .all_footer {
    border-top: solid 2px grey;
    padding: 10px 0;
    text-align: right; }
    .all_box .all_footer > div {
      margin-bottom: 7px; }

.plan_progress .progress {
  margin-bottom: 5px;
  background: #b175ff; }
  .plan_progress .progress .progress-bar {
    background-color: #6c548c;
    padding: 0px 10px; }

.label_2_1_1.mb-0 {
  margin-bottom: 0px; }

.label_2_1_1.mr-0 {
  margin-right: 0px; }

.no_bor_r {
  border-right: 0px !important; }

.tble_circle {
  width: 15px;
  height: 15px;
  background: var(--b-color);
  border-radius: 50%;
  display: inline-block; }

.inpHeight_btn {
  height: 40px;
  line-height: 25px; }

.Bg_W_moule {
  background: #fff;
  padding: 45px 15px 45px; }

.textAra_big1__ {
  height: 130px; }

.big_btn__ {
  font-size: 17px;
  font-weight: 500;
  padding: 7px 35px; }

.enq_btn {
  min-width: 150px;
  max-width: 100%;
  padding: 7px 25px; }

.enquiryView {
  padding: 50px 0;
  border-bottom: solid 1px var(--b-color); }
  .enquiryView:last-child {
    border-bottom: none; }

.c_pointer {
  cursor: pointer; }

.f-17 {
  font-size: 17px; }

.white_box1_ {
  background: #fff;
  padding: 50px; }

.codePin {
  font-size: 40px;
  font-weight: 700; }

.boxi {
  box-shadow: 0px 0px 10px #ccc;
  margin-bottom: 30px;
  padding: 30px 120px;
  background: #fff;
  min-height: 150px;
  position: relative; }

.no_outline {
  outline: none !important;
  cursor: all-scroll !important; }

.cancel_i {
  color: #ec3728;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px; }

.picAl_part {
  width: 200px;
  text-align: center;
  margin-right: 30px; }

.prof_pic__1__ {
  width: 200px;
  height: 200px;
  border: solid 1px #cecece;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto; }
  .prof_pic__1__ .def_ic {
    font-size: 130px;
    line-height: 0; }

.Drag_Drop {
  background: #efefef;
  position: relative;
  text-align: center;
  z-index: 0;
  cursor: pointer; }
  .Drag_Drop input {
    display: block !important;
    width: 0;
    height: 0;
    margin: 0 auto;
    background: transparent;
    border: transparent; }
  .Drag_Drop section > div {
    padding: 15px; }
    .Drag_Drop section > div:focus {
      outline: none; }
  .Drag_Drop section input.error {
    border: 0 solid transparent; }
  .Drag_Drop section i {
    font-size: 36px;
    color: #000;
    margin-bottom: 10px; }
  .Drag_Drop section p {
    margin-bottom: 0; }

.custom_errormsg {
  background: #cc0000;
  color: #fff;
  padding: 7px 15px;
  text-align: center;
  font-size: 13px; }

.picGroup_part {
  display: flex;
  align-items: center; }

.picUp_part {
  flex: 1; }

.customModal .cstomDialog.wid_Medium {
  max-width: 100%;
  width: 600px; }

.prof_cropper .cropper-view-box {
  border-radius: 50%; }

.prof_cropper .cropper-modal {
  background-color: #797979;
  opacity: 0.9; }

.wiz_thead {
  background: #f6f6f6; }

.mod_circle {
  width: 25px;
  height: 25px;
  display: inline-flex;
  margin-left: 10px;
  margin-right: 7px;
  border-radius: 50%;
  background: grey;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  align-items: center;
  justify-content: center; }
  .mod_circle.big_mod {
    width: 35px;
    height: 35px; }
  .mod_circle.participantintake_ic {
    background: linear-gradient(45deg, #a002c1, #b968c7); }
  .mod_circle.finance_ic {
    background: linear-gradient(45deg, #303150 0%, #464765 38%, #464765 65%, #464765 65%, #464765 100%); }
  .mod_circle.fms_ic {
    background: linear-gradient(45deg, #d12e2f, #d42e2e 38%, #e23030 65%, #e32f30 0, #fa3232); }
  .mod_circle.recruitment_ic {
    background: linear-gradient(45deg, #0075ea, #05adee); }
  .mod_circle.recruitment_app_ic {
    background: linear-gradient(45deg, #48377d, #8c7dbc); }
  .mod_circle.finance_planner_ic {
    background: linear-gradient(45deg, #303150, #464765 38%, #464765 65%, #464765 0, #464765); }
  .mod_circle.imail_ic {
    background: linear-gradient(45deg, #0a9e9a, #50d6cf); }
  .mod_circle.marketing_ic {
    background: linear-gradient(45deg, #378abd, #286489 38%, #286286 65%, #286286 0, #337cab); }
  .mod_circle.member_ic {
    background: linear-gradient(45deg, #ed6000, #ff812d); }
  .mod_circle.admin_ic {
    background: linear-gradient(45deg, #0037c0, #174dcb 37%, #207cca 74%, #207cca 0, #386cd7); }
  .mod_circle.organization_ic {
    background: linear-gradient(45deg, #09a075, #09a177 39%, #04ae8a 65%, #01bc9d); }
  .mod_circle.organisation_portal_ic {
    background: linear-gradient(45deg, #09a075, #ff812d); }
  .mod_circle.participant_ic {
    background: linear-gradient(45deg, #6a20a9, #753af0); }
  .mod_circle.schedule_ic {
    background: linear-gradient(45deg, #dc71b6, #ff82cc); }
  .mod_circle.client_ic {
    background: linear-gradient(0deg, #ff812d, #09a075); }

.colorPick_ul {
  margin-bottom: 0;
  padding-left: 0;
  text-align: left;
  margin: 0 auto;
  display: inline-block; }
  .colorPick_ul li {
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 5px; }
  .colorPick_ul .colorPick {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: #000;
    border-radius: 50%;
    margin-right: 25px;
    cursor: pointer; }
    .colorPick_ul .colorPick.color_1 {
      background: #242543; }
    .colorPick_ul .colorPick.color_2 {
      background: #474866; }
    .colorPick_ul .colorPick.color_3 {
      background: #29658a; }
    .colorPick_ul .colorPick.color_4 {
      background: #2e9cca; }
    .colorPick_ul .colorPick.color_5 {
      background: #b175ff; }
    .colorPick_ul .colorPick.color_6 {
      background: #992bff; }
    .colorPick_ul .colorPick.color_7 {
      background: #e07196; }
    .colorPick_ul .colorPick.color_8 {
      background: #d32f2f; }
    .colorPick_ul .colorPick.color_9 {
      background: #ff7043; }
    .colorPick_ul .colorPick.color_10 {
      background: #ff8a67; }
    .colorPick_ul .colorPick.color_11 {
      background: #1eb35a; }
    .colorPick_ul .colorPick.active {
      transform: scale(1.4);
      box-shadow: 0px 0px 5px #808080;
      border: solid 2px #fff; }

.ReactTable .rt-td.color_align {
  overflow: auto;
  white-space: pre-wrap;
  text-overflow: initial; }

.module_subComp {
  background: #f1f1f1;
  padding: 30px 10%; }

.List_2_ul.module_checkies___ li {
  width: 25%;
  padding-right: 15px;
  margin-bottom: 15px; }
  .List_2_ul.module_checkies___ li .lab_span {
    font-size: 16px; }

.feed_subComp {
  border-top: solid 1px #999999;
  background: #fff;
  padding: 30px; }

.upploadedDoc_ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  margin-bottom: 30px; }
  .upploadedDoc_ul li {
    padding: 15px;
    width: 20%;
    position: relative;
    text-align: center; }
    .upploadedDoc_ul li .close_ic__ {
      position: absolute;
      right: 15px;
      top: 0;
      cursor: pointer;
      font-size: 18px; }
    .upploadedDoc_ul li .file_ic__ {
      font-size: 50px; }
    .upploadedDoc_ul li p {
      word-break: break-all; }

.allocAmt {
  display: flex;
  display: -ms-flexbox;
  align-items: center; }
  .allocAmt i {
    font-size: 20px;
    cursor: pointer; }
  .allocAmt input {
    width: calc(100% - 30px); }

.input_sm {
  height: 30px; }

.fund_duration_doc {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  margin-top: 25px; }
  .fund_duration_doc > div {
    padding: 0 7px; }
  .fund_duration_doc > div:nth-child(2) {
    flex: 1; }

.cropeZoomer_Dv__ {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  margin: 0 auto;
  max-width: 300px;
  margin-top: 30px; }
  .cropeZoomer_Dv__ .sm_ic__ {
    font-size: 18px;
    margin-right: 10px;
    line-height: 0; }
  .cropeZoomer_Dv__ input {
    height: 10px;
    border: none;
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    background: transparent; }
    .cropeZoomer_Dv__ input::-webkit-slider-runnable-track {
      background-color: #d7dbdd;
      height: 5px;
      border-radius: 3px;
      border: 1px solid transparent; }
    .cropeZoomer_Dv__ input::-moz-range-track {
      background-color: #d7dbdd;
      height: 5px;
      border-radius: 3px;
      border: none; }
    .cropeZoomer_Dv__ input::-moz-range-thumb {
      border-radius: 20px;
      height: 18px;
      width: 18px;
      border: none;
      background: none;
      background-color: #606670; }
    .cropeZoomer_Dv__ input:active::-moz-range-thumb {
      outline: none; }
    .cropeZoomer_Dv__ input::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      border-radius: 100%;
      background-color: #fff;
      border: solid 1px #2e9cca;
      height: 13px;
      width: 13px;
      margin-top: -5px; }
  .cropeZoomer_Dv__ .lg_ic__ {
    font-size: 30px;
    margin-left: 10px;
    line-height: 0; }

.grey_box__S {
  background: #efefef;
  padding: 50px 10%; }

.tag_funding_info {
  margin-bottom: 0px;
  align-self: center;
  margin-left: 30px; }
  .tag_funding_info li {
    font-size: 13px; }

.creRosterTable_parent {
  background: #fff;
  padding: 45px 15px 15px 45px; }
  .creRosterTable_parent .creRosterTable {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 55px; }
    .creRosterTable_parent .creRosterTable .creRosterTable_td_1 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; }
      .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable {
        border: 1px solid #1e1e1e;
        margin-top: -1px;
        margin-left: -1px; }
        .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcHeader {
          border-bottom: 1px solid #1e1e1e;
          padding: 5px 7px;
          font-size: 14px; }
        .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_1 {
          display: grid;
          align-content: center;
          grid-template-columns: 1fr 25px;
          padding: 3px 7px;
          background: var(--bg-color);
          border-radius: var(--b-radius);
          margin-top: 2px; }
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_1 span,
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_1 a {
            overflow: hidden;
            text-overflow: ellipsis;
            color: #fff;
            font-size: 13px; }
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_1 i {
            display: inline-flex;
            justify-content: center;
            font-size: 10px;
            align-items: center;
            color: #fff; }
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_1.inside_funding {
            background: #1eb35a; }
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_1.outside_funding {
            background: #ff0000; }
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_1.line_item_expire {
            background: purple; }
        .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_2 {
          text-align: center; }
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .rosterShiftTr_2 i {
            color: var(--txt-color);
            font-size: 17px; }
        .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .simplebar-track.simplebar-vertical {
          width: 4px;
          background: #ccc !important;
          right: 1px; }
          .creRosterTable_parent .creRosterTable .creRosterTable_td_1 .child_calcTable .child_calcBody .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            left: 1px;
            right: 1px;
            background: #000; }
    .creRosterTable_parent .creRosterTable .creRosterTable_td_2 {
      display: flex;
      justify-content: flex-end;
      align-self: flex-end; }

.set_pay_roll_chart {
  position: relative;
  background: #ececec; }
  .set_pay_roll_chart::after {
    content: "";
    width: 1px;
    height: 62%;
    background: #000;
    display: inline-block;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(10%, -50%);
    z-index: 0; }
  .set_pay_roll_chart .pay_roll_title_top {
    position: absolute;
    top: 30px;
    left: 2.1%;
    z-index: 1;
    font-size: 14px;
    font-weight: 600; }
  .set_pay_roll_chart .pay_roll_title_bottom {
    position: absolute;
    bottom: 9%;
    left: 10%;
    font-weight: 600; }

.cust_list_pdf_101 {
  display: grid;
  justify-content: center;
  text-align: center;
  grid-template-rows: 15px auto 15px;
  grid-gap: 7px; }
  .cust_list_pdf_101 span {
    word-break: break-all; }
  .cust_list_pdf_101 .title_pdf_101 {
    font-size: 13px; }
  .cust_list_pdf_101 .icon_pdf_101 i {
    font-size: 50px; }
  .cust_list_pdf_101 .path_pdf_101 {
    font-size: 13px; }

.search_bar_back {
  position: relative; }
  .search_bar_back input {
    padding-right: 50px; }
  .search_bar_back span {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg-color);
    color: #fff;
    font-size: 18px;
    cursor: pointer; }

.search_bar_back.left input {
  padding-left: 50px;
  padding-right: 10px; }

.search_bar_back.left span {
  left: 0; }

.search_bar_back.right input {
  padding-right: 50px;
  padding-left: 10px; }

.search_bar_back.right span {
  right: 0; }

.plan_history_panel_1 .panel-default {
  position: relative;
  margin-bottom: 0; }

.plan_history_panel_1 .panel-collapse {
  position: absolute;
  width: 100%;
  background-color: #fff !important;
  border: 1px solid var(--bg-color);
  z-index: 1; }

.penel_s_header_first {
  display: grid;
  grid-template-columns: 75px 1fr 60px 55px 1fr;
  align-items: center;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
  padding: 10px 15px;
  padding-right: 32px;
  background: var(--bg-color);
  color: #fff;
  border-radius: var(--b-radius); }
  .penel_s_header_first div {
    font-size: 12px;
    border-right: 1px solid #fff;
    word-break: break-all; }
    .penel_s_header_first div.p_s_f_w5 {
      border-right: 0px; }
  .penel_s_header_first.sec2_1 {
    grid-template-columns: 1fr 1fr 1fr; }
    .penel_s_header_first.sec2_1 .p_s_f_w3 {
      border-right: 0px; }

.penel_s_header_ {
  display: grid;
  grid-template-columns: 75px 1fr 60px 55px 1fr;
  align-items: center;
  width: 100%;
  gap: 5px; }
  .penel_s_header_ div {
    font-size: 12px;
    border-right: 1px solid #777;
    word-break: break-all; }
    .penel_s_header_ div.p_s_w5 {
      border-right: 0px; }
  .penel_s_header_.sec2_1 {
    grid-template-columns: 1fr 1fr 1fr; }
    .penel_s_header_.sec2_1 .p_s_w3 {
      border-right: 0px; }

.manage_panel_shift .panel {
  margin-bottom: 5px;
  margin-top: 5px; }
  .manage_panel_shift .panel .panel-title span.icon.icon-arrow-right,
  .manage_panel_shift .panel .panel-title span.icon.icon-arrow-down,
  .manage_panel_shift .panel .panel-title span.icon.icon-arrow-up {
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    align-items: center; }
    .manage_panel_shift .panel .panel-title span.icon.icon-arrow-right::before,
    .manage_panel_shift .panel .panel-title span.icon.icon-arrow-down::before,
    .manage_panel_shift .panel .panel-title span.icon.icon-arrow-up::before {
      font-size: 12px !important; }

.schedule_fill_shift_row {
  display: flex;
  padding: 0px 10px; }
  .schedule_fill_shift_row .schedule_fill_shift_td_one {
    background: #ececec; }
  .schedule_fill_shift_row .schedule_fill_shift_td_two {
    background: #fff; }
  .schedule_fill_shift_row .schedule_fill_shift_td_three {
    background: #ececec; }

.ro_recond_shift_shedules {
  padding: 10px 15px;
  background: #fff;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: var(--b-radius);
  opacity: 0.8;
  font-size: 12px; }

.penel_s_header_Shift_set_1 {
  display: flex;
  padding: 0px 10px; }
  .penel_s_header_Shift_set_1 .schedule_fill_shift_td_one {
    background: #ececec;
    padding: 11px 0px;
    text-align: center;
    border-bottom: 1px solid var(--b-color);
    font-size: 18px; }
  .penel_s_header_Shift_set_1 .schedule_fill_shift_td_two {
    background: #fff;
    padding: 11px 0px;
    text-align: center;
    border-bottom: 1px solid var(--b-color);
    font-size: 18px;
    border-left: 1px dashed var(--bg-color);
    border-right: 1px dashed var(--bg-color); }
  .penel_s_header_Shift_set_1 .schedule_fill_shift_td_three {
    background: #ececec;
    padding: 11px 0px;
    text-align: center;
    border-bottom: 1px solid var(--b-color);
    font-size: 18px; }

.penel_s_header_Shift_set_2 {
  display: flex;
  padding: 0px 10px; }
  .penel_s_header_Shift_set_2 .schedule_fill_shift_td_one {
    background: #ececec;
    padding-top: 10px;
    padding-bottom: 5px; }
  .penel_s_header_Shift_set_2 .schedule_fill_shift_td_two {
    background: #fff;
    border-left: 1px dashed var(--bg-color);
    border-right: 1px dashed var(--bg-color); }
  .penel_s_header_Shift_set_2 .schedule_fill_shift_td_three {
    background: #ececec;
    padding-top: 10px;
    padding-bottom: 5px; }

.aval_member_list_show_ {
  margin: 5px;
  display: grid;
  grid-template-columns: 35px 1fr 35px; }
  .aval_member_list_show_ .icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    font-size: 17px;
    border-radius: 100%; }
    .aval_member_list_show_ .icon.icon-accept-approve2-ie {
      background: #00be47; }
    .aval_member_list_show_ .icon.icon-close2-ie {
      background: red; }
    .aval_member_list_show_ .icon.rejected_shift:hover {
      cursor: pointer;
      opacity: 1 !important; }
  .aval_member_list_show_ .s0line_ {
    position: relative; }
    .aval_member_list_show_ .s0line_::before {
      content: "";
      width: 92%;
      height: 1px;
      display: flex;
      position: absolute;
      background: #000;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

.unfiled_shift_table_body {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-row-gap: 5px;
  font-size: 12px;
  grid-column-gap: 5px; }

.avail_shift_table_body {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 10px; }
  .avail_shift_table_body .s0_avail_2 {
    display: grid;
    grid-template-columns: 1fr 1fr; }
    .avail_shift_table_body .s0_avail_2 > div {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 5px; }
      .avail_shift_table_body .s0_avail_2 > div .bullet_icon {
        font-size: 12px; }
        .avail_shift_table_body .s0_avail_2 > div .bullet_icon::before {
          content: "";
          width: 4px;
          height: 4px;
          background: #000;
          border-radius: 100%;
          display: inline-block;
          margin: 2px;
          margin-left: 7px; }

.lab_el_300_parent {
  display: flex;
  flex-direction: column; }
  .lab_el_300_parent li {
    display: grid;
    grid-template-columns: 1fr 3fr; }
    .lab_el_300_parent li .lab_el_299 {
      text-align: right;
      font-size: 13px;
      font-weight: bold;
      padding: 8px 0px;
      padding-right: 10px;
      border-bottom: 1px dashed transparent; }
    .lab_el_300_parent li .lab_el_298 {
      border-bottom: 1px dashed;
      border-color: var(--bg-color);
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      padding: 8px 0px; }
      .lab_el_300_parent li .lab_el_298 .icon {
        align-self: flex-end;
        font-size: 16px;
        cursor: pointer; }

.show_tex_list_0 {
  display: grid;
  column-gap: 5px; }
  .show_tex_list_0.f-12 {
    font-size: 12px; }
  .show_tex_list_0.f-13 {
    font-size: 13px; }
  .show_tex_list_0.f-14 {
    font-size: 14px; }
  .show_tex_list_0.f-15 {
    font-size: 15px; }
  .show_tex_list_0.f-16 {
    font-size: 16px; }
  .show_tex_list_0.f-17 {
    font-size: 17px; }
  .show_tex_list_0.f-18 {
    font-size: 18px; }
  .show_tex_list_0 .f-19 {
    font-size: 19px; }
  .show_tex_list_0.f-20 {
    font-size: 20px; }
  .show_tex_list_0.f-21 {
    font-size: 21px; }
  .show_tex_list_0.f-22 {
    font-size: 22px; }
  .show_tex_list_0.f-23 {
    font-size: 23px; }
  .show_tex_list_0.f-24 {
    font-size: 24px; }
  .show_tex_list_0.f-25 {
    font-size: 25px; }
  .show_tex_list_0.auto {
    grid-template-columns: auto; }
  .show_tex_list_0.auto_1fr {
    grid-template-columns: auto 1fr; }

.d-grid {
  display: grid;
  column-gap: 5px; }
  .d-grid.auto {
    grid-template-columns: auto; }
  .d-grid.auto_1fr {
    grid-template-columns: auto 1fr; }
  .d-grid.auto_1fr_1fr {
    grid-template-columns: auto 1fr 1fr; }
  .d-grid.add_1fr_1fr_1fr {
    grid-template-columns: 1fr 1fr 1fr; }

.your_app_heading {
  width: 100%;
  display: flex;
  text-align: center;
  background: #e6e6e6;
  padding: 10px;
  font-size: 18px;
  border-radius: var(--b-radius);
  justify-content: center;
  margin-top: 50px; }

.bb-1-dashed {
  border-bottom: 1px dashed;
  border-color: var(--bg-color); }

.panel_title_child_div {
  display: grid;
  grid-template-columns: 250px 2fr;
  grid-column-gap: 15px;
  padding: 0px 0px 0px 0px; }
  .panel_title_child_div .col_one_panel {
    border-right: 1px solid #ddd;
    padding: 10px 10px 10px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr; }
  .panel_title_child_div .col_two_panel {
    padding: 10px 30px 10px 0px; }

.panel_body_cont_div {
  padding: 0px; }
  .panel_body_cont_div .col_two_panel {
    text-align: right;
    padding-right: 10px; }

.creater_phone_interview_V0e4e {
  border-bottom: 1px dashed;
  border-color: #d7dbdd;
  padding: 10px 0px; }
  .creater_phone_interview_V0e4e .quesAns_box__ .pr_set_lef:first-child {
    padding-right: 15px; }

.default_answer_v0e1 {
  margin-right: 5px;
  width: 25px;
  height: 25px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid;
  border-color: #e2d7d7; }
  .default_answer_v0e1.wrong_answer {
    background-color: #f50c0c;
    color: #fff;
    border-color: #f50c0c; }
  .default_answer_v0e1.write_answer {
    background-color: #13a650;
    color: #fff;
    border-color: #13a650; }

.set_inline_required {
  display: inline-block; }
  .set_inline_required::after {
    font-size: 7px;
    background-size: 6px;
    content: "";
    background: url("/assets/images/admin/star_icons.svg");
    line-height: 10px;
    text-align: right;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
    display: inline-block;
    padding-bottom: 12px;
    margin-left: 2px; }

.placeholder-ul {
  visibility: hidden; }

.placeholder-li:hover {
  background: #F1F1F1; }

.editor_custom_box_imail .rdw-block-wrapper {
  display: none !important; }

.editor_custom_box_imail .rdw-fontsize-wrapper {
  display: none !important; }

.editor_custom_box_imail .editor_custom_box {
  border: 1px solid; }

.editor_custom_box_imail .rdw-editor-wrapper {
  position: relative; }

.editor_custom_box_imail .rdw-editor-toolbar {
  position: absolute;
  top: 100%;
  width: 100%;
  border-top: 1px solid var(--b-color);
  padding-top: 15px;
  padding-bottom: 8px; }

.editor_custom_box_imail .editorClassName {
  padding: 8px 15px 10px 15px !important;
  margin-bottom: 50px;
  max-height: 150px;
  overflow: auto;
  min-height: 150px; }

.send_btn_editor.int_text_but_v1 {
  top: 79%;
  bottom: inherit !important; }

.bor_editor {
  border: 1px solid;
  padding-bottom: 2px; }

.editor_custom_box {
  background-color: #fff; }
  .editor_custom_box .public-DraftStyleDefault-block {
    margin: 0 !important; }
  .editor_custom_box .editorClassName {
    padding: 0px 15px;
    min-height: 150px; }
  .editor_custom_box .rdw-block-wrapper .rdw-dropdown-wrapper.rdw-block-dropdown {
    width: 200px; }
    .editor_custom_box .rdw-block-wrapper .rdw-dropdown-wrapper.rdw-block-dropdown .rdw-dropdownoption-default {
      font-size: 12px; }
  .editor_custom_box .rdw-dropdown-selectedtext {
    font-size: 12px; }

.disable_all_anchor_tag a {
  cursor: not-allowed !important; }

.slds-form-element.choose_account_margin {
  margin-top: 52px; }

.vertical_line_or {
  border-left: 1px solid #dddbda;
  position: absolute;
  left: 50%; }

.slds-tab-overflow-x ul {
  overflow: hidden;
  overflow-x: auto; }

.docusignToolBar {
  border: 0px !important; }

.Toastify__toast--warning p {
  color: #fff !important;
  font-weight: 600 !important; }

.Toastify__toast--warning .Toastify__close-button--warning {
  color: #fff !important; }

.float-right {
  float: right; }

.bor-non {
  border: none !important; }

.slds-para-truncate {
  height: auto;
  vertical-align: middle;
  display: inline-block;
  white-space: break-spaces;
  word-wrap: break-word; }

.document_mandatory_popover #popover-heading-dialog-body {
  max-height: 200px;
  overflow-y: auto; }

.combox_box-to-cus-contact .slds-dropdown .slds-listbox__option_has-meta .slds-media__body:not(:only-child) {
  padding-top: 6px; }

.service_agreement_modal .slds-modal__footer {
  z-index: 999999 !important; }

.slds-popover-multiselect .slds-popover {
  max-height: 200px;
  overflow: auto; }

#popover-heading-dialog-heading {
  color: black !important; }

.modal_headet_txt-col .slds-text-heading_small {
  color: white !important; }

.ndis_line_item-shift {
  border: 1px solid #c9c9c9;
  min-height: 50px;
  min-width: 100%;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden; }

.ndis-shift-cost {
  width: 100px; }

.parent-line-item {
  background: #ccc; }

.table-line-item tr.slds-hint-parent:last-child section.slds-popover {
  position: absolute !important; }

.slds-custom-notification-container {
  top: 3.5rem;
  right: 1.25rem; }

#cus-header-notifications-popover, #cus-header-notifications-readed-dialog-body {
  box-shadow: none;
  border: none; }
  #cus-header-notifications-popover .slds-global-header__notification_unread, #cus-header-notifications-popover .slds-global-header__notification--unread, #cus-header-notifications-readed-dialog-body .slds-global-header__notification_unread, #cus-header-notifications-readed-dialog-body .slds-global-header__notification--unread {
    background-color: #ecebea !important; }
  #cus-header-notifications-popover .slds-popover__close, #cus-header-notifications-readed-dialog-body .slds-popover__close {
    position: relative;
    top: -3;
    color: #4dace8; }
  #cus-header-notifications-popover .slds-notification__close, #cus-header-notifications-readed-dialog-body .slds-notification__close {
    position: relative;
    float: right; }
  #cus-header-notifications-popover h3, #cus-header-notifications-readed-dialog-body h3 {
    font-size: 1em; }
  #cus-header-notifications-popover a.all_read, #cus-header-notifications-readed-dialog-body a.all_read {
    position: relative;
    padding: 12px;
    z-index: 10;
    color: #4dace8;
    text-decoration: underline !important;
    margin-right: 38px; }
  #cus-header-notifications-popover .absolute-positioned ul, #cus-header-notifications-readed-dialog-body .absolute-positioned ul {
    max-height: 317px !important;
    overflow: auto;
    width: 100%; }

.view-all-div {
  text-align: center; }

.slds-nubbin--top-right:after, .slds-nubbin--top-right:before, .slds-nubbin_top-right-corner:after, .slds-nubbin_top-right-corner:before, .slds-nubbin_top-right:after, .slds-nubbin_top-right:before {
  left: auto;
  right: 1.5rem;
  top: -.5rem;
  margin-right: -.5rem; }

.Cus_Check_1 {
  margin-bottom: 0px;
  position: relative; }
  .Cus_Check_1 input[type="checkbox"]:not(:checked),
  .Cus_Check_1 input[type="checkbox"]:checked {
    left: 0;
    height: 15px;
    width: 15px;
    top: 0;
    bottom: 0;
    margin: 0px;
    cursor: pointer;
    position: absolute; }
  .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1,
  .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1 {
    position: relative;
    padding-left: 0;
    display: inline-flex; }
  .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:before,
  .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    background: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
  .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:before {
    background: #000; }
  .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:after,
  .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:after {
    content: "\70";
    width: 16px;
    height: 16px;
    font-family: "icons_me";
    font-size: 15px;
    line-height: 0.8;
    color: #fff;
    transition: all 0.2s;
    position: absolute;
    left: 0;
    top: 2px; }
  .Cus_Check_1 [type="checkbox"]:not(:checked) + .chk_Labs_1:after {
    opacity: 0;
    transform: scale(0); }
  .Cus_Check_1 [type="checkbox"]:checked + .chk_Labs_1:after {
    opacity: 1;
    transform: scale(1); }
  .Cus_Check_1 .chk_Labs_1 span {
    padding-left: 5px; }

.Tab_hadeing {
  font-size: 20px; }

.FD_task_table .List_tBL {
  background: #ececec;
  min-height: 670px; }
  .FD_task_table .List_tBL .rt-tr-group {
    margin-top: 0px; }
    .FD_task_table .List_tBL .rt-tr-group .rt-tr.-even,
    .FD_task_table .List_tBL .rt-tr-group .rt-tr.-odd {
      border-bottom: 1px solid #ddd; }
  .FD_task_table .List_tBL .rt-tbody {
    max-height: 640px; }
    .FD_task_table .List_tBL .rt-tbody .rt-td {
      border-right: 0px solid #000;
      font-size: 14px; }
  .FD_task_table .List_tBL .tBL_Sub {
    border-bottom: 1px solid #ddd;
    padding: 15px; }
    .FD_task_table .List_tBL .tBL_Sub .tBL_des {
      font-size: 14px; }

.Out_title {
  font-weight: 600;
  color: var(--bg-color);
  padding: 10px 0px;
  margin-bottom: 15px; }

a.B_tn,
button.B_tn {
  background: var(--bg-color);
  color: #fff;
  font-size: 14px; }
  a.B_tn:hover,
  button.B_tn:hover {
    color: #fff;
    box-shadow: 0px 0px 5px #777; }
  a.B_tn:focus,
  button.B_tn:focus {
    color: #fff; }

.FD_ul_ {
  display: flex;
  padding: 15px;
  background: #ececec;
  border-radius: var(--b-radius);
  margin: 30px 0px; }
  .FD_ul_ .FD_li_ {
    flex-grow: 1;
    display: flex;
    align-items: center; }
    .FD_ul_ .FD_li_ i {
      display: inline-flex;
      font-size: 22px; }
    .FD_ul_ .FD_li_ i.ie-usd {
      color: #00be44; }
    .FD_ul_ .FD_li_ i.ie-dollarl-cancel {
      color: #f57575; }
    .FD_ul_ .FD_li_ i.ie-ie-profit {
      color: #00be44; }
    .FD_ul_ .FD_li_ span {
      padding-left: 10px; }

.Fil_ter_ToDo {
  display: flex;
  align-items: center; }
  .Fil_ter_ToDo > label {
    margin-bottom: 0px;
    padding-right: 5px; }
  .Fil_ter_ToDo > span input {
    text-align: center;
    padding-left: 0;
    padding-right: 0; }

.sLT_gray div.Select .Select-control,
.sLT_gray div.Select.is-focused > .Select-control,
.sLT_gray div.Select.is-open > .Select-control,
.sLT_gray div.Select.is-focused:not(.is-opne) > .Select-control {
  background: #b7b7b7;
  background-color: #b7b7b7; }

.sLT_gray .Select-arrow::before,
.sLT_gray .Select.is-open > .Select-control .Select-arrow::before,
.sLT_gray .Select-value-label,
.sLT_gray .Select-placeholder {
  color: #1e1e11 !important;
  font-weight: 600 !important; }

.sLT_gray .Select-placeholder + input {
  border: 0px solid #777; }

.sLT_gray .Select .Select-control,
.sLT_gray div.Select.is-open > .Select-control {
  border-color: #b7b7b7; }

.sLT_gray .Select-menu-outer {
  border-color: #b7b7b7; }

.sLT_gray .Select-input {
  border: 0px solid #777; }

.sLT_gray .Select-option.is-selected {
  background: #b7b7b754; }

.sLT_gray .Select-input {
  position: relative; }
  .sLT_gray .Select-input input {
    top: 0;
    left: 0;
    height: 100% !important; }

.sLT_gray.left .Select-value-label,
.sLT_gray.left .Select-placeholder {
  text-align: left; }

.left-aRRow .Select-arrow::before {
  font-family: "icons_me";
  content: "\69"; }

.left-aRRow .Select.is-open > .Select-control .Select-arrow::before {
  font-family: "icons_me";
  content: "\66"; }

.Parent-List_2_ul {
  padding: 15px 10px 15px 15px;
  background: #fff;
  border-radius: var(--b-radius);
  border: 1px solid;
  height: 100%; }

.FScroll .scrollarea .scrollbar-container {
  background: #c4c4c4 !important; }
  .FScroll .scrollarea .scrollbar-container .scrollbar {
    background: #747474 !important; }

.label_2_1_1 {
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 15px; }

.Time_line_lables {
  display: flex;
  flex-wrap: wrap; }
  .Time_line_lables .label_2_1_2,
  .Time_line_lables .label_2_1_3 {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0px; }
  .Time_line_lables .label_2_1_1 {
    margin-bottom: 0px; }

.File-Drag-and-Drop_bOX .tooltip {
  left: 50%;
  top: -15px;
  transform: translate(-50%, -15px); }
  .File-Drag-and-Drop_bOX .tooltip .tooltip-arrow {
    left: 50% !important; }

.File-Drag-and-Drop_bOX > div {
  display: flex;
  display: flex;
  align-items: center;
  border: 1px dashed;
  border-color: var(--bg-color);
  border-radius: var(--b-radius);
  padding: 25px 15px;
  background: #e2e2e2; }
  .File-Drag-and-Drop_bOX > div input {
    display: none; }
  .File-Drag-and-Drop_bOX > div .Drop_bOX_content_1 {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    font-size: 14px; }
    .File-Drag-and-Drop_bOX > div .Drop_bOX_content_1 i {
      font-size: 120px;
      color: var(--bg-color); }
    .File-Drag-and-Drop_bOX > div .Drop_bOX_content_1 span h3 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 15px; }
    .File-Drag-and-Drop_bOX > div .Drop_bOX_content_1 span span {
      font-weight: 600;
      font-size: 14px; }
  .File-Drag-and-Drop_bOX > div .Drop_bOX_content_2 {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    flex-flow: column; }
    .File-Drag-and-Drop_bOX > div .Drop_bOX_content_2 i {
      font-size: 90px;
      color: var(--bg-color);
      margin-bottom: 15px; }

._mYcustom_1 {
  width: 100%;
  display: flex;
  min-height: 20px;
  background: var(--drk-color3);
  border-radius: var(--b-radius); }
  ._mYcustom_1 ._mYcustom_Bar {
    position: relative;
    background: var(--bg-color);
    border-radius: var(--b-radius) 0px 0px var(--b-radius); }
    ._mYcustom_1 ._mYcustom_Bar span {
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translate(-50%, -13px);
      min-width: 130px;
      font-size: 12px;
      color: #fff;
      background: var(--bg-color);
      padding: 4px;
      text-align: center;
      border-radius: 5px 5px 5px 5px; }
    ._mYcustom_1 ._mYcustom_Bar span::after {
      content: "";
      border-left: 5px solid transparent;
      border-top: 5px solid var(--bg-color);
      border-right: 5px solid transparent;
      position: absolute;
      bottom: -4px;
      left: 50%; }

.cNTer_mYcustom ._mYcustom_Bar {
  position: inherit; }
  .cNTer_mYcustom ._mYcustom_Bar span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent; }
  .cNTer_mYcustom ._mYcustom_Bar span::after {
    display: none; }

.cUS_error_class {
  display: inline-flex;
  align-items: center; }
  .cUS_error_class i {
    font-size: 20px; }
  .cUS_error_class span {
    font-size: 14px;
    padding-top: 3px;
    padding-left: 10px; }

.error_cLass {
  color: #cc0000; }

.success_cLass {
  color: #00bf45; }

.pAY_heading_01 {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between; }
  .pAY_heading_01 .tXT_01 {
    font-weight: 600;
    color: var(--bg-color); }
  .pAY_heading_01 .icon {
    display: inline-flex;
    align-items: center;
    font-size: 19px;
    color: var(--bg-color); }

.aDDitional_bTN_F0,
.aDDitional_bTN_F1 {
  background: transparent;
  border: 0px;
  padding: 0px;
  color: var(--bg-color);
  font-size: 20px; }

.btn-1.out_line,
button.btn-1.out_line,
a.btn-1.out_line {
  border: 1px solid var(--bg-color);
  color: var(--bg-color);
  background: #fff; }

.pRO_div {
  display: flex; }
  .pRO_div .uSer_pRO {
    width: 100px;
    height: 100px;
    background: transparent;
    border-radius: 100%;
    border: 1px solid;
    overflow: hidden; }
    .pRO_div .uSer_pRO span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%; }

.dEtail_pRO li {
  display: flex;
  margin-bottom: 5px; }

.dEtail_pRO .dEtail_a {
  min-width: 100px;
  justify-content: flex-end;
  display: inline-flex;
  font-weight: 600;
  margin-right: 5px;
  font-size: 14px; }

.dEtail_pRO .dEtail_b {
  font-size: 14px; }

.pLace_left_side {
  padding-left: 15px; }
  .pLace_left_side .dEtail_a {
    min-width: auto; }

.F_module_Panel .panel {
  border-radius: var(--b-radius); }
  .F_module_Panel .panel .panel-heading {
    padding: 0px;
    background: transparent;
    border-color: transparent; }
    .F_module_Panel .panel .panel-heading .panel-title a > div {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      padding: 10px 15px; }
      .F_module_Panel .panel .panel-heading .panel-title a > div p {
        margin: 0px; }
      .F_module_Panel .panel .panel-heading .panel-title a > div span {
        display: none; }
    .F_module_Panel .panel .panel-heading a[aria-expanded="true"] span.icon-arrow-down {
      display: flex; }
    .F_module_Panel .panel .panel-heading a[aria-expanded="false"] span.icon-arrow-right {
      display: flex; }
  .F_module_Panel .panel .panel-body {
    background: transparent;
    border-top: 1px solid;
    border-top-color: #1e1e1e !important; }

.F_module_Panel.arrow_down_app_panel .panel .panel-heading a[aria-expanded="true"] span.icon-arrow-down {
  display: none; }

.F_module_Panel.arrow_down_app_panel .panel .panel-heading a[aria-expanded="false"] span.icon-arrow-down {
  display: flex; }

.F_module_Panel.arrow_down_app_panel .panel .panel-heading a[aria-expanded="true"] span.icon-arrow-up {
  display: flex; }

.E_ven_and_O_dd-color .panel:nth-child(odd) {
  background: #fff; }

.E_ven_and_O_dd-color .panel:nth-child(even) {
  background: #ebebeb; }

.E_ven_color_ .panel:nth-child(odd) {
  background: #fff; }

.E_ven_color_ .panel:nth-child(even) {
  background: #fff; }

.O_dd_color_ .panel:nth-child(odd) {
  background: #ebebeb; }

.O_dd_color_ .panel:nth-child(even) {
  background: #ebebeb; }

.Border_0_F .panel {
  border-color: transparent; }

.Finance__panel_1 .panel-title div p {
  width: 100%;
  text-align: center;
  font-weight: 600; }

.Bg_F_moule {
  background: #ebebeb;
  padding: 45px 15px 45px; }

.Left_info_Content_box {
  display: flex;
  background: #ebebeb; }
  .Left_info_Content_box .body_bOX_left {
    padding: 30px;
    width: 100%; }
    .Left_info_Content_box .body_bOX_left h5 {
      margin-bottom: 10px;
      font-weight: 600; }

.iNput__div {
  padding: 10px;
  font-size: 14px;
  color: #777; }

.body_bOX_left_text_1 div {
  font-size: 14px;
  padding: 4px 0px; }

/* Table Sub Component */
.left_validation .tooltip.left {
  top: 5px !important; }
  .left_validation .tooltip.left .tooltip-arrow {
    top: 10px !important;
    border-left-color: #cc0000 !important; }

.ratio_input {
  display: inline-flex;
  align-items: center; }
  .ratio_input span:nth-child(2) {
    font-size: 40px;
    line-height: 34px;
    align-items: flex-start;
    font-family: sans-serif;
    margin-top: -4px;
    padding: 0px 7px;
    font-weight: 600; }
  .ratio_input span {
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .ratio_input span input {
      max-width: 50px;
      padding: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center; }

.sUB_heding_tBL {
  font-size: 17px;
  font-weight: 600; }

.remove-search span.Select-arrow::before {
  display: none; }

.remove-search .Select-arrow-zone {
  display: none; }

.remove-search .Select-clear-zone {
  padding-right: 10px; }

.Select_Search_Type_ {
  display: flex;
  justify-content: space-between;
  align-content: center; }
  .Select_Search_Type_ .text_set {
    font-size: 15px; }
  .Select_Search_Type_ i {
    font-size: 22px; }
  .Select_Search_Type_ .Participant_icon i {
    content: "\e0b8";
    color: #B175FF; }
  .Select_Search_Type_ .Site_icon i::before {
    content: "\e0b8";
    color: #1EB35A; }
  .Select_Search_Type_ .Sub_org_icon i::before {
    content: "\e0b8";
    color: #1EB35A; }
  .Select_Search_Type_ .Org_icon i::before {
    content: "\e0b8";
    color: #1EB35A; }
  .Select_Search_Type_ .Member_icon i::before {
    content: "\e0b8";
    color: #FF7043; }
  .Select_Search_Type_ .House_icon i::before {
    content: "\e0b8";
    color: #1EB35A; }

.position-relative {
  position: relative; }

.sort_expander_custom .sort_expander_custom_icons {
  position: relative;
  width: 100%;
  text-align: center; }

.sort_expander_custom.-sort-asc .sort_expander_custom_icons::before {
  content: "\e02e";
  font-family: "icons_me";
  font-size: 14px;
  margin-left: 5px;
  position: absolute;
  right: -5px;
  width: 20px;
  text-align: right;
  top: 50%;
  transform: translate(0, -50%); }

.sort_expander_custom.-sort-desc .sort_expander_custom_icons::before {
  content: "\e030";
  font-family: "icons_me";
  font-size: 14px;
  margin-left: 5px;
  position: absolute;
  right: -5px;
  width: 20px;
  text-align: right;
  top: 50%;
  transform: translate(0, -50%); }

.list_shift_AZ__ {
  display: flex;
  flex-direction: column; }
  .list_shift_AZ__ .tr_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    border-radius: var(--b-radius);
    margin: 0px 0px 15px;
    min-height: 40px;
    box-shadow: 0px 0px 6px #c5c5c5; }
  .list_shift_AZ__ .tr_list:nth-child(odd) {
    background: #fff; }
  .list_shift_AZ__ .tr_list:nth-child(even) {
    background: #e6e6e6; }
  .list_shift_AZ__ .tr_list .td_list .default_but_remove {
    display: inline-flex; }
  .list_shift_AZ__ .tr_list .td_list {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 4px; }
  .list_shift_AZ__ .tr_list .td_list .icon-done-arrow {
    font-size: 26px;
    color: #009966; }
  .list_shift_AZ__ .icon-circule {
    font-size: 36px;
    cursor: pointer; }
    .list_shift_AZ__ .icon-circule::before {
      display: flex;
      line-height: 26px;
      width: 30px; }

.my_pdf_viewer .scale_width_set {
  display: flex;
  justify-content: flex-start; }
  .my_pdf_viewer .scale_width_set .btn-group {
    display: flex;
    align-items: center; }

.my_pdf_viewer .previous_next_btn {
  display: flex;
  justify-content: center; }
  .my_pdf_viewer .previous_next_btn .btn-group {
    display: flex;
    align-items: center; }

.my_pdf_viewer .rotate_left {
  display: flex;
  justify-content: flex-end; }
  .my_pdf_viewer .rotate_left .btn-group {
    display: flex;
    align-items: center; }

.my_pdf_viewer .btn {
  padding: 0px;
  line-height: 0px;
  width: 40px;
  height: 40px;
  background: transparent;
  color: #fff;
  border-radius: 100% !important; }

.my_pdf_viewer .small {
  height: 100%;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  background: transparent;
  margin: 0px 10px;
  color: #fff; }

.my_pdf_viewer .btn:nth-child(2) {
  margin: 0px 10px; }

.my_pdf_viewer .bg-dark {
  background: #404040; }

iframe #toolbar #aligner {
  display: none !important; }

.file_drag_validation__ {
  position: relative; }
  .file_drag_validation__ .tooltip {
    top: -15px !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important; }
    .file_drag_validation__ .tooltip.top .tooltip-arrow {
      left: 50% !important; }

.custom_scroll_set__ {
  background: #fff;
  border: 1px solid var(--bg-color);
  padding: 15px;
  border-radius: var(--b-radius); }
  .custom_scroll_set__ .stats_update_list {
    margin-top: 0px; }
  .custom_scroll_set__ .px_set_textarea__ {
    padding: 0px 7px 0px 7px;
    display: flex; }

.await_ln {
  background: #fd505f;
  color: #fff;
  padding: 7px;
  border-radius: 30px;
  font-size: 15px; }

.member_shift button.rbc-event {
  padding: 7px 5px; }

.detail_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 5px; }

.f-bold {
  font-weight: 600; }

.add_dotted_012::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #000;
  margin: 0px 8px 0px 0px;
  border-radius: 100%; }

.simplebar-track {
  background: var(--light-color-background) !important;
  border-radius: 5px; }
  .simplebar-track div::before {
    background: var(--bg-color);
    opacity: 1; }

.Box_lJobCat {
  background: #e2e2e2;
  display: flex;
  flex-direction: column;
  padding: 10px; }
  .Box_lJobCat .Ul_lJobCat {
    display: flex;
    flex-wrap: wrap; }
    .Box_lJobCat .Ul_lJobCat .lJobCat {
      display: inline-flex;
      margin-right: 10px;
      margin-bottom: 10px; }
      .Box_lJobCat .Ul_lJobCat .lJobCat div {
        padding: 8px;
        background: #fff;
        font-size: 12px; }
        .Box_lJobCat .Ul_lJobCat .lJobCat div i {
          font-style: normal;
          margin-right: 5px; }
      .Box_lJobCat .Ul_lJobCat .lJobCat .remove_x {
        width: 20px;
        justify-content: center;
        align-items: center;
        font-size: 8px;
        background: var(--bg-color);
        color: #fff;
        cursor: pointer; }
  .Box_lJobCat .Add_lJobCat_icon {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    color: var(--bg-color); }

.Select > .Select-control .Select-value .Select-value-label {
  padding-right: 15px; }

/*
=====================================================================
PUSH SIDEBAR
=====================================================================
*/
#PushMenuCloseOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.4;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: inline-block; }

#GlobalMenu {
  position: absolute;
  left: 0;
  width: 100%;
  max-width: 245px;
  min-width: 200px;
  height: calc(100% - 53px);
  z-index: 2; }

#GlobalMenuParent,
#PushMenuCloseOverlay {
  display: none; }

.open #GlobalMenuParent,
.open #PushMenuCloseOverlay {
  display: block; }

.open #GlobalMenuParent {
  position: absolute;
  top: 0;
  z-index: 1039;
  height: 100%;
  background-color: white; }

#SidebarTogglerParent > button {
  background-color: black;
  color: white;
  border-radius: 0;
  font-size: 23px;
  line-height: 0; }

.is-guest #SidebarTogglerParent {
  display: none !important; }

.is-logged-in #page-content-wrapper-inner {
  display: flex; }
  .is-logged-in #page-content-wrapper-inner > div:first-child {
    width: 100%;
    max-width: 245px; }
  .is-logged-in #page-content-wrapper-inner > div:last-child,
  .is-logged-in #page-content-wrapper-inner > section:last-child {
    min-height: calc(100vh - 118px);
    height: 100%;
    width: 100%; }

@media (min-width: 768px) {
  #SidebarTogglerParent,
  #PushMenuCloseOverlay {
    display: none; }
  #GlobalMenuParent {
    position: static !important;
    display: block;
    height: auto !important;
    align-items: stretch; }
  #GlobalMenu {
    position: static;
    height: 100%; } }

/*
Overrides an INCONVENIENT style declaration in custom.scss.
a {
    text-decoration: none !important;  <--- this one
    color: initial;
}
*/
a.default-underlined {
  text-decoration: underline !important; }
  a.default-underlined:hover {
    text-decoration: none !important; }

a.reset {
  text-decoration: none !important; }
  a.reset:hover {
    text-decoration: underline !important; }

section[aria-describedby="new-docusign-modal-content"] footer {
  z-index: 9000 !important; }

section[aria-describedby="new-docusign-modal-content"] section.slds-popover header h2 {
  color: white; }

section[aria-describedby="new-docusign-modal-content"] section.slds-popover header,
section[aria-describedby="new-docusign-modal-content"] section.slds-popover div#popover-error-dialog-body {
  text-align: left; }

section[aria-describedby="new-docusign-modal-content"] section.slds-popover ul {
  margin-left: 1rem;
  margin-bottom: 8px;
  list-style-type: initial !important; }

.bg-grey {
  background-color: #eee; }

.slds-popover-multiselect .slds-combobox__input,
.slds-popover-multiselect #combobox-dialog {
  border-color: #dddbda !important;
  border: 1px solid #dddbda !important; }

.Select .Select-multi-value-wrapper [role="combobox"] input[readonly],
input[readonly][role="combobox"] {
  border: none !important;
  background: transparent !important; }

.timepickerselected .slds-dropdown__item.slds-is-selected a {
  background-image: url("/assets/images/check_tick.png");
  background-repeat: no-repeat;
  background-position: 6px center; }

.timepickerselected .slds-dropdown__item a {
  padding-left: 30px !important; }

.parent-line-item {
  background-color: #b7b7b7; }

button[disabled],
input[disabled] {
  cursor: not-allowed !important;
  opacity: 0.9 !important; }

.slds_icon_iscorrect {
  fill: green; }

.slds_icon_iswrong {
  fill: red; }

#oa_result_eval {
  width: 150px !important; }

.checkbox_eval {
  display: inline-block; }

#oa_form_question .slds-icon-action-check {
  background-color: #b5b7b6;
  cursor: pointer; }

#oa_form_question input[type=checkbox]:checked + label .slds-icon-action-check {
  background-color: #009966; }

div.question_draggable {
  margin: 10px;
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 4px;
  color: #666;
  cursor: move; }
  div.question_draggable:hover {
    background-color: #eee; }

button[disabled],
input[disabled] {
  cursor: not-allowed !important;
  opacity: 0.9 !important; }

.org_service_area_tooltip {
  background-color: white; }

.p-line-height {
  line-height: 1.5; }

.answer-link, .selected-question {
  width: 30px;
  height: 30px;
  background: grey;
  display: inline-block;
  text-align: center;
  color: white;
  border-radius: 15px;
  line-height: 2.3;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  text-decoration: underline solid;
  /*  animation-name: example;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;   */ }

.selected-question {
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse; }

.answer-link_indication {
  width: 10px;
  height: 10px;
  background: grey;
  display: inline-block;
  text-align: center;
  color: white;
  border-radius: 5px; }

/*   div {
    width: 100px;
    height: 100px;
    background-color: red;
    position: relative;
    animation-name: example;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;  
  } */
@keyframes example {
  0% {
    box-shadow: 0px 0px 2px 2px yellow; }
  50% {
    box-shadow: 0px 0px 2px 2px black; }
  100% {
    box-shadow: 0px 0px 2px 2px yellow; } }

.assessment_eval > .slds-page-header {
  position: fixed;
  z-index: 1;
  top: 95px;
  width: 97.5vw; }

.p-ws {
  white-space: break-spaces;
  line-height: 1.5; }

/* Common Table Classes */
.ReactTable .rt-td.Tb_class_d1 {
  white-space: initial; }

.ReactTable .-pagination select {
  border: 0 solid !important; }

.td_overflow_issue_fixed .ReactTable .rt-td {
  overflow: inherit; }

span.icon.next {
  background: var(--bg-color);
  width: 37px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius100); }

.pagination-bottom span.icon.next,
.pagination-bottom span.icon.privious {
  width: 37px;
  height: 36px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: var(--b-radius); }

span.icon.privious {
  background: var(--bg-color);
  width: 37px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius100); }

.pagination-bottom span.icon.next {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.pagination-bottom span.icon.privious {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.on_form_to_search .react-datepicker__close-icon::after {
  left: 100%; }

.ReactTable .rt-noData {
  z-index: 0; }

div.ReactTable .rt-noData {
  z-index: 0; }

.schedule_listings .ReactTable .rt-thead .rt-th,
.schedule_listings .ReactTable .rt-thead .rt-td {
  padding: 0px 5px; }

.listing_table .ReactTable .rt-noData,
.listing_table .archive_btn,
.listing_table .inactive_btn,
.listing_table .active_btn,
.listing_table .rt-tr,
.listing_table .ReactTable .rt-thead .rt-tr,
.listing_table .privious:before,
.listing_table .ReactTable .rt-tbody .rt-tr,
.listing_table .ReactTable .rt-thead .rt-tr,
.listing_table span.-pageInfo,
.listing_table .next:before {
  border-radius: var(--b-radius); }

.listing_table i.icon.icon-views:before {
  font-size: 20px;
  display: inline-block;
  line-height: 21px;
  vertical-align: middle;
  color: #1ca177; }

.listing_table .next:before,
.listing_table .privious:before {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.listing_table .ReactTable {
  border: 0px; }

.listing_table .rt-tr-group {
  margin: 10px 0px 0px; }

.listing_table .ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
  border: 0px;
  align-items: center;
  min-height: 40px; }

.listing_table .ReactTable.-striped .rt-tr.-even {
  background: #fff;
  border: 0px;
  align-items: center;
  min-height: 40px; }

.listing_table .ReactTable .rt-thead .rt-th {
  padding: 11px 10px;
  text-align: left; }

.listing_table .ReactTable .rt-thead .rt-tr {
  color: #fff; }

.listing_table .ReactTable .rt-tbody .rt-td,
.listing_table .ReactTable .rt-thead .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.listing_table .ReactTable .rt-thead.-header {
  box-shadow: none; }

.listing_table .ReactTable .rt-thead .rt-tr,
.listing_table span.-pageInfo,
.listing_table .ReactTable .rt-noData,
.listing_table .ReactTable .rt-tbody .rt-td,
.listing_table .ReactTable .rt-thead .rt-th {
  font-weight: 400 !important; }

.listing_table .next:before,
.listing_table .privious:before {
  border-color: var(--b-color); }

.listing_table .ReactTable.-striped .rt-tr.-even,
.listing_table .ReactTable.-striped .rt-tr.-odd {
  min-height: 52px; }

.listing_table.table_progress .ReactTable.-striped .rt-tr.-odd + .progressInline + .applicant_info1 {
  background: #ebebeb; }

.listing_table.table_progress .ReactTable.-striped .rt-tr.-even + .progressInline + .applicant_info1 {
  background: #fff; }

.listing_table.odd_even_marge-1_tBL.table_progress .ReactTable.-striped .rt-tr.-odd + .progressInline + .applicant_info1,
.listing_table.odd_even_marge-1_tBL.table_progress .ReactTable.-striped .rt-tr.-even + .progressInline + .applicant_info1 {
  background: #fff; }

.listing_table.odd_even_marge-2_tBL.table_progress .ReactTable.-striped .rt-tr.-odd + .progressInline + .applicant_info1,
.listing_table.odd_even_marge-2_tBL.table_progress .ReactTable.-striped .rt-tr.-even + .progressInline + .applicant_info1 {
  background: #ebebeb; }

.listing_table.table_progress .progressInline {
  display: inline-block;
  background: var(--bg-color);
  height: 6px;
  position: relative;
  margin-top: -2px; }

.listing_table.table_progress .rt-thead.-header .progressInline {
  display: none; }

.listing_table.table_progress .ReactTable .rt-tbody .rt-tr:after {
  width: 0%; }

.listing_table.table_progress .ReactTable .rt-tbody .rt-tr.-padRow:before,
.listing_table.table_progress .ReactTable .rt-tbody .rt-tr.-padRow:after,
.listing_table.table_progress .ReactTable .rt-tbody .rt-tr.-padRow + .progressInline {
  display: none; }

.listing_table .ReactTable .rt-thead .rt-tr {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.ReactTable .-pagination .-pageSizeOptions {
  border-radius: var(--b-radius); }

.ReactTable .-pagination .-pageInfo {
  display: flex;
  align-content: center;
  height: 40px;
  line-height: 40px; }

.ReactTable .-pagination .-pageJump {
  display: inline-block;
  margin-right: 10px;
  margin-left: 5px; }

.ReactTable .-pagination .-totalPages {
  padding-left: 10px; }

.ReactTable div.-pageJump input {
  border-radius: var(--b-radius) !important; }

.ReactTable .-pagination .-pageJump input {
  height: 35px;
  background: var(--bg-color);
  background-color: var(--bg-color); }

.ReactTable .-pagination .-btn {
  color: #fff; }

.ReactTable .-pageJump input[type="number"]::-webkit-inner-spin-button,
.ReactTable .-pageJump input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important; }

.ReactTable .-pageJump input[type="number"] {
  -moz-appearance: textfield !important; }

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
  display: flex;
  height: 35px;
  align-items: center;
  padding: 0;
  vertical-align: middle;
  line-height: 0;
  color: #fff; }

.ReactTable .-pagination select {
  height: 35px;
  border: 1px solid transparent; }

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
  overflow: hidden;
  border: 1px solid #ddd; }

.ReactTable .-pagination .-center {
  flex: 15; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0px 0px 0px 0px #000 !important; }

.ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content:after {
  content: "\e030";
  font-family: "icons_me";
  font-size: 14px;
  margin-left: 5px; }

.ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content:after {
  content: "\e02e";
  font-family: "icons_me";
  font-size: 14px;
  margin-left: 5px; }

.ReactTable .-pagination {
  margin-top: 15px; }

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 2; }

.ReactTable .-pagination {
  background: none;
  box-shadow: 0px 0px 0px 0px !important;
  border-top: 0px solid #fff !important; }

.ReactTable .-pagination .-btn {
  background: none !important; }

.ReactTable .-next button {
  text-align: left;
  margin-top: 0px; }

.ReactTable .-previous button {
  text-align: right;
  margin-top: 0px; }

.ReactTable .rt-tr.-padRow.-odd,
.ReactTable .rt-tr.-padRow.-even {
  visibility: hidden; }

.ReactTable .rt-noData {
  width: 100%;
  text-align: center;
  padding: 7px !important;
  border: 1px solid #ddd; }

.ReactTable .-pagination .-btn:not([disabled]):hover {
  color: #1e1e1e !important; }

.ReactTable div.rt-tbody .rt-tr-group {
  border-bottom: 0px solid; }

.ReactTable .rt-tfoot .rt-td {
  padding: 0px;
  height: auto;
  line-height: normal;
  margin: 0;
  border: 0px; }

.ReactTable .rt-tfoot {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.ReactTable .rt-thead.-header .rt-tr {
  font-weight: 400; }

.ReactTable .rt-thead.-header {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15); }

.ReactTable .rt-thead .rt-th .rt-resizable-header-content:after {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(-4px, -50%); }

span.select-wrap.-pageSizeOptions {
  min-width: 120px;
  position: relative;
  color: #fff; }
  span.select-wrap.-pageSizeOptions select::-ms-expand {
    display: none; }
  span.select-wrap.-pageSizeOptions select {
    position: relative;
    z-index: 1;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer; }
  span.select-wrap.-pageSizeOptions::after {
    content: "\66";
    position: absolute;
    right: 0;
    font-family: "icons_me";
    z-index: 0;
    font-size: 13px;
    width: 22px;
    height: 35px;
    text-align: center;
    display: flex;
    top: 0;
    justify-content: start;
    align-items: center; }
  span.select-wrap.-pageSizeOptions.react_arrow_flip::after {
    content: "\68"; }
  span.select-wrap.-pageSizeOptions select option {
    background: var(--bg-color); }
  span.select-wrap.-pageSizeOptions {
    background: var(--bg-color); }

.Tab_Overflow__ .ReactTable .rt-tbody .rt-tr-group {
  overflow: visible; }

.odd_even_tBL .ReactTable.-highlight .rt-tr.-odd,
.odd_even_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub {
  background: #ebebeb; }

.odd_even_tBL .ReactTable.-highlight .rt-tr.-even,
.odd_even_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub {
  background: #fff; }

.odd_even_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-odd:not(.-padRow):hover {
  background: #ebebeb; }

.odd_even_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-even:not(.-padRow):hover {
  background: #fff; }

.odd_even_marge-1_tBL .ReactTable.-highlight .rt-tr.-odd,
.odd_even_marge-1_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub {
  background: #fff; }

.odd_even_marge-1_tBL .ReactTable.-highlight .rt-tr.-even,
.odd_even_marge-1_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub {
  background: #fff; }

.odd_even_marge-1_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-odd:not(.-padRow):hover {
  background: #fff; }

.odd_even_marge-1_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-even:not(.-padRow):hover {
  background: #fff; }

.odd_even_marge-2_tBL .ReactTable.-highlight .rt-tr.-odd,
.odd_even_marge-2_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub {
  background: #ebebeb; }

.odd_even_marge-2_tBL .ReactTable.-highlight .rt-tr.-even,
.odd_even_marge-2_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub {
  background: #ebebeb; }

.odd_even_marge-2_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-odd:not(.-padRow):hover {
  background: #ebebeb; }

.odd_even_marge-2_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-even:not(.-padRow):hover {
  background: #ebebeb; }

.line_space_tBL .rt-tr.-odd,
.line_space_tBL .rt-tr.-even {
  padding: 7px 0px; }
  .line_space_tBL .rt-tr.-odd .rt-td,
  .line_space_tBL .rt-tr.-even .rt-td {
    border-color: rgba(0, 0, 0, 0.4);
    padding: 4px 7px; }

.listing_table.H-Set_tBL .ReactTable.-striped .rt-tr.-odd,
.listing_table.H-Set_tBL .ReactTable.-striped .rt-tr.-even {
  min-height: 40px; }
  .listing_table.H-Set_tBL .ReactTable.-striped .rt-tr.-odd .rt-td,
  .listing_table.H-Set_tBL .ReactTable.-striped .rt-tr.-even .rt-td {
    font-size: 14px; }

.listing_table.H-Set_tBL .ReactTable.-striped .rt-th {
  font-size: 14px; }

.expander_bind {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  align-items: center; }
  .expander_bind.justify-content-center {
    justify-content: center; }

.header_cnter_tabl > div,
.header_cnter_tabl .rt-resizable-header-content {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center; }

.header_cnter_tabl.checkbox_header > div {
  justify-content: start; }

.listing_table.Remove-Margin-tBL .rt-tr-group {
  margin: 0px; }

.Remove-Border-body-tBL .rt-tr.-odd .rt-td,
.Remove-Border-body-tBL .rt-tr.-even .rt-td {
  border-color: rgba(0, 0, 0, 0); }

/* Finance Table  */
.L-I-P_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.L-I-P_Table .tBL_des {
  font-size: 14px;
  margin-bottom: 15px; }

.L-I-P_Table .L-I-P_list {
  display: flex;
  flex-wrap: wrap; }
  .L-I-P_Table .L-I-P_list li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .L-I-P_Table .L-I-P_list li span:first-child {
      color: var(--bg-color); }
    .L-I-P_Table .L-I-P_list li span {
      flex: 1;
      padding-top: 5px;
      font-size: 14px; }

.L-I-P-G_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.L-I-P-G_Table .tBL_des {
  font-size: 14px;
  margin-bottom: 15px; }

.L-I-P-G_Table .L-I-P_list {
  display: flex;
  flex-wrap: wrap; }
  .L-I-P-G_Table .L-I-P_list li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .L-I-P-G_Table .L-I-P_list li span:first-child {
      color: var(--bg-color); }
    .L-I-P-G_Table .L-I-P_list li span {
      flex: 1;
      padding-top: 5px;
      font-size: 14px; }

.Payr-Das_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Payr-Das_Table .L-I-P_list {
  display: flex;
  flex-wrap: wrap; }
  .Payr-Das_Table .L-I-P_list li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .Payr-Das_Table .L-I-P_list li span:first-child {
      color: var(--bg-color); }
    .Payr-Das_Table .L-I-P_list li span {
      flex: 1;
      padding-top: 5px;
      font-size: 14px; }

.Payr-Das_Table .L-I-P_list li span:first-child {
  color: #1e1e1e; }

.Quot-Home_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Quot-Home_Table .rt-td {
  font-size: 14px; }

.Invoic-Das_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Invoic-Das_Table .rt-td {
  font-size: 14px; }

.Invoic-Scheduler_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Invoic-Scheduler_Table .rt-td {
  font-size: 14px; }

.Invoic-Scheduler-History_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Invoic-Scheduler-History_Table .rt-td {
  font-size: 14px; }

.Credit-Note_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Credit-Note_Table .rt-td {
  font-size: 14px; }

.Refunds_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Refunds_Table .rt-td {
  font-size: 14px; }

.Shift_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Shift_Table .rt-td {
  font-size: 14px; }

.Shift-Queries_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Shift-Queries_Table .rt-td {
  font-size: 14px; }

.F-Payroll_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.F-Payroll_tBL .rt-td {
  font-size: 14px; }

.View-Finance-User_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.View-Finance-User_tBL .rt-td {
  font-size: 14px; }

.NDIS-Error-Tracking_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.NDIS-Error-Tracking_tBL .rt-td {
  font-size: 14px; }

.NDIS-Billing_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.NDIS-Billing_tBL .rt-td {
  font-size: 14px; }

.Finance-Statement_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Finance-Statement_tBL .rt-td {
  font-size: 14px; }

.Finance-Exisiting-Payroll_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Finance-Exisiting-Payroll_tBL .rt-td {
  font-size: 14px; }

/* Recruitment  Table */
.Req-Task_tBL .overdue_task_class {
  background-color: red;
  width: 100%;
  display: block;
  color: #fff;
  padding: 2px 0px;
  border-radius: 3px; }

.Req-Applicant_tBL .table_progress .rt-tbody .rt-tr:before {
  background: var(--light-color-background);
  z-index: 0; }

.Req-User-Management_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Req-Manage-Group-Interview-List_tBL .react-datepicker__input-container input[disabled] {
  opacity: 1 !important;
  pointer-events: none; }

.Req-Manage-Group-Interview-List_tBL .rt-td.Show_td_stracture {
  padding-left: 0px !important;
  border-right: 0 !important; }

.Req-Manage-Group-Interview-List_tBL .rt-thead.-header {
  display: none; }

.Req-Manage-Group-Interview-List_tBL .ReactTable.-highlight .rt-tr.-odd,
.Req-Manage-Group-Interview-List_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub {
  background: transparent;
  border-bottom: 1px solid #000000;
  border-radius: 0px; }

.Req-Manage-Group-Interview-List_tBL .ReactTable.-highlight .rt-tr.-even,
.Req-Manage-Group-Interview-List_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub {
  background: transparent;
  border-bottom: 1px solid #000000;
  border-radius: 0px; }

.Req-Manage-Group-Interview-List_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-odd:not(.-padRow):hover {
  background: transparent; }

.Req-Manage-Group-Interview-List_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-even:not(.-padRow):hover {
  background: transparent; }

.Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tr.-odd,
.Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub {
  background: #ebebeb;
  border-bottom: 1px solid transparent; }
  .Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tr.-odd .no_record,
  .Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub .no_record {
    background: #fff; }

.Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tr.-even,
.Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub {
  background: #fff;
  border-bottom: 1px solid transparent; }

.Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-thead .rt-tr:not(.-padRow):hover {
  background: var(--bg-color); }

.Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-odd:not(.-padRow):hover {
  background: #ebebeb; }

.Req-Management-Group-Interview-Inner_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-even:not(.-padRow):hover {
  background: #fff; }

.Req-Management-Group-Interview-Inner_tBL .ReactTable .rt-thead.-header {
  display: flex; }

.Req-Management-Group-Interview-Inner_tBL .ReactTable .rt-tbody {
  margin-top: 0px; }
  .Req-Management-Group-Interview-Inner_tBL .ReactTable .rt-tbody .rt-tr-group {
    margin-bottom: 0px; }

.Req-Staff-Details_tBL .ReactTable .rt-tbody .rt-tr-group .rt-tr.-odd,
.Req-Staff-Details_tBL .ReactTable .rt-tbody .rt-tr-group .rt-tr.-even {
  border: 1px solid #1e1e1e; }
  .Req-Staff-Details_tBL .ReactTable .rt-tbody .rt-tr-group .rt-tr.-odd + .tBL_Sub,
  .Req-Staff-Details_tBL .ReactTable .rt-tbody .rt-tr-group .rt-tr.-even + .tBL_Sub {
    border: 1px solid #1e1e1e;
    margin-top: -1px;
    background: #fff; }

.Req-Disable-Recruiter-tBL .tble_inp1 {
  min-height: 30px;
  height: 30px; }

.Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .td_Overflow {
  overflow: visible !important; }
  .Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .td_Overflow .Select-control > *:last-child {
    padding-right: 0px; }
  .Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .td_Overflow .Select-arrow-zone {
    width: 0; }
  .Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .td_Overflow input {
    border: 0px solid #000;
    padding-left: 15px;
    height: 30px; }

.Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .modify_select .Select-arrow::before {
  top: -11px; }

.Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .modify_select .is-open .Select-arrow::before {
  top: -9px; }

.Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .modify_select .Select-control,
.Req-Disable-Recruiter-tBL .ReactTable .rt-tbody .modify_select div.Select.is-open > .Select-control {
  height: 30px;
  position: relative; }

.Tab_Overflow__ .ReactTable .rt-tbody .rt-td.td_Overflow {
  overflow: visible !important; }

.Req-Dashboard_tBL .listing_table .ReactTable .rt-thead .rt-tr {
  background: transparent; }
  .Req-Dashboard_tBL .listing_table .ReactTable .rt-thead .rt-tr .rt-th {
    border-right: 1px solid transparent !important;
    border-left: 1px solid transparent !important; }
  .Req-Dashboard_tBL .listing_table .ReactTable .rt-thead .rt-tr div {
    color: #1e1e1e; }

.Req-Dashboard_tBL .listing_table .ReactTable .ellipsis_line__ {
  word-break: break-all; }

.Req-Dashboard_tBL .LA_i1 {
  font-size: 16px; }

.Req-Communications-logs_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Req-Communications-logs_tBL .L-I-P_list {
  display: flex;
  flex-wrap: wrap; }
  .Req-Communications-logs_tBL .L-I-P_list li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .Req-Communications-logs_tBL .L-I-P_list li span:first-child {
      color: var(--bg-color); }
    .Req-Communications-logs_tBL .L-I-P_list li span {
      flex: 1;
      padding-top: 5px;
      font-size: 14px; }

.Req-Communications-logs_tBL .L-I-P_list li span:first-child {
  color: #1e1e1e; }

.Req-Group-Interview-Addional-Questions_tBL .customChecks [type="checkbox"]:not(:checked) + .chkLabs:before {
  background: transparent; }

.Req-Group-Interview-Addional-Questions_tBL .odd_even_tBL .ReactTable.-highlight .rt-tr.-odd,
.Req-Group-Interview-Addional-Questions_tBL .odd_even_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub {
  background: #e2e2e2; }

.Req-Group-Interview-Addional-Questions_tBL .odd_even_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-odd:not(.-padRow):hover {
  background: #e2e2e2; }

/* CRM  Table */
.Allocate-Funding-Amount_tBL .All_div_amount {
  display: flex; }
  .Allocate-Funding-Amount_tBL .All_div_amount .small-search.n2 {
    margin-right: 15px; }
    .Allocate-Funding-Amount_tBL .All_div_amount .small-search.n2 input {
      padding-left: 40px;
      font-size: 12px; }
    .Allocate-Funding-Amount_tBL .All_div_amount .small-search.n2 button {
      background: var(--bg-color);
      left: 0;
      border-radius: 3px 0px 0px 3px;
      color: #fff; }
  .Allocate-Funding-Amount_tBL .All_div_amount .All_div_amount_but a {
    font-size: 17px;
    display: inline-flex;
    align-items: center;
    margin: 5px;
    color: var(--bg-color); }

.Crm-Applicant_tBL .table_progress .rt-tbody .rt-tr:before {
  background: #e0c0fe;
  z-index: 0; }

.Crm-Applicant_tBL .table_progress .progress-b1 .progress {
  background: #e0c0fe; }

.crm_create_shift_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.schedule_listings .rt-tr-group {
  margin-bottom: 10px;
  overflow: hidden; }

.schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(odd) .rt-tr,
.schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(odd) .other_conter {
  background: #fff !important; }

.schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(even) .rt-tr,
.schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(even) .other_conter {
  background: rgba(0, 0, 0, 0.07) !important; }

.schedule_listings .ReactTable .rt-thead.-header {
  margin-bottom: 15px !important;
  margin-top: 15px !important; }

.schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(odd) .rt-td,
.schedule_listings .ReactTable .rt-tbody .rt-tr-group:nth-child(even) .rt-td {
  background: none !important; }

.schedule_listings .ReactTable.-striped .rt-tr.-odd,
.schedule_listings .ReactTable.-striped .rt-tr.-even {
  background: none !important;
  font-weight: 400 !important; }

.schedule_listings .rt-td.rt-expandable div i:before {
  line-height: 28px; }

.schedule_listings .ReactTable .rt-th,
.schedule_listings .ReactTable .rt-td {
  padding: 0px 5px 0px 5px;
  line-height: 40px; }

.schedule_listings .ReactTable label {
  margin-bottom: -5px; }

.schedule_listings .ReactTable .rt-thead .rt-resizable-header-content {
  line-height: 40px; }

.schedule_listings .rt-resizable-header-content {
  text-align: left; }

.schedule_listings .ReactTable {
  border: 0px solid #fff; }

.schedule_listings .ReactTable .rt-thead.-header .rt-tr {
  border-top: 1px solid #1e1e1e;
  border-bottom: 1px solid #1e1e1e; }

.schedule_listings .ReactTable .rt-tbody .rt-tr {
  font-weight: 400; }

.schedule_listings .ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.09); }

.schedule_listings .other_conter {
  font-weight: 400;
  padding: 15px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.09); }

.schedule_listings .other_conter li {
  list-style: none; }

.schedule_listings .rt-expandable i:before {
  display: inline-block;
  vertical-align: middle; }

.schedule_listings.aprvl_rcTable11 .ReactTable .rt-thead .rt-td,
.schedule_listings.aprvl_rcTable11 .ReactTable .rt-thead .rt-th {
  padding: 0px 5px; }

.schedule_listings.aprvl_rcTable11 .ReactTable .rt-thead .rt-th:first-child {
  padding-left: 15px; }

.schedule_listings.aprvl_rcTable11 .ReactTable .rt-thead.-header .rt-tr .rt-th {
  background: var(--bg-color); }

.schedule_listings.aprvl_rcTable11 .ReactTable .rt-thead.-header {
  border-radius: var(--b-radius);
  overflow: hidden; }

.schedule_listings.aprvl_rcTable11 .ReactTable .rt-thead.-header .rt-tr {
  color: #fff !important; }

.schedule_listings i.icon.icon-views:before {
  font-size: 20px;
  display: inline-block;
  line-height: 21px;
  vertical-align: middle;
  color: #1ca177; }

.schedule_listings .ReactTable .rt-noData,
.schedule_listings .rt-tr-group,
.schedule_listings span.-pageInfo,
.schedule_listings .privious:before,
.schedule_listings .next:before {
  border-radius: var(--b-radius); }

.schedule_listings .next:before,
.schedule_listings .privious:before {
  background: var(--bg-color);
  background-color: var(--bg-color); }

.schedule_listings .ReactTable .rt-thead.-header .rt-tr .rt-th,
.schedule_listings .ReactTable .rt-thead.-header .rt-tr,
.schedule_listings .next:before,
.schedule_listings .privious:before {
  border-color: var(--b-color); }

.schedule_listings .ReactTable .rt-thead.-header .rt-tr {
  color: var(--txt-color); }

.schedule_listings .ReactTable .rt-noData {
  font-weight: 400 !important; }

.schedule_listings.p_left_table .ReactTable .rt-tbody .rt-td:first-child {
  padding-left: 13px !important; }

.schedule_listings.p_left_table .ReactTable .rt-tbody .rt-td:last-child {
  padding-right: 13px !important; }

.schedule_listings.PL_site1 .ReactTable .rt-tbody .rt-td:first-child {
  padding-left: 15px; }

.schedule_listings.th_padding-x .ReactTable .rt-thead .rt-th {
  padding: 0 15px; }

.schedule_listings.p_left_table .ReactTable .rt-tbody .rt-td:first-child {
  padding-left: 13px !important; }

.schedule_listings.p_left_table .ReactTable .rt-tbody .rt-td:last-child {
  padding-right: 13px !important; }

.schedule_listings .ReactTable .rt-noData {
  font-weight: 400 !important; }

#unfilled_on_app .schedule_listings .ReactTable .rt-tbody .rt-td:first-child {
  border-right: transparent; }

.px_text__ .ReactTable .rt-td {
  padding: 0 15px; }

div.ReactTable .rt-td,
div.ReactTable .rt-th {
  padding: 7px 15px; }

.th_txt_center__ .rt-resizable-header-content {
  text-align: center; }

.PL_site .ReactTable .rt-thead .rt-th:first-child,
.PL_site .ReactTable .rt-tbody .rt-td:first-child {
  padding-left: 15px; }

.PL_site .ReactTable .rt-thead .rt-th:first-child,
.PL_site .ReactTable .rt-tbody .rt-td:last-child {
  padding-right: 15px; }

.Table__itemCount {
  font-size: 14px; }

.Table__pagination {
  display: flex;
  justify-content: center;
  padding: 20px 10px; }

.Table__pageButton {
  font-size: 15px;
  outline: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--txt-color); }

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray; }

.Table__pageButton--active {
  color: #1e1e1e;
  font-weight: 700; }

.pagination-bottom .Table__pageButton span.icon.next,
.pagination-bottom .Table__pageButton span.icon.privious {
  background: 0 0;
  width: 30px;
  height: auto;
  margin-top: 2px; }

.pagination-bottom .Table__pageButton span.icon.next::before,
.pagination-bottom .Table__pageButton span.icon.privious::before {
  background: initial;
  background-color: initial; }

.listing_table .rt-td:last-child {
  border-right: 1px solid transparent !important; }

.th_txt_center__ .header_cnter_tabl._align_l__ .rt-resizable-header-content {
  justify-content: flex-start; }

.Req-Latest-Updates_tBL .line_space_tBL .rt-tr.-odd .rt-td.update_td_set,
.Req-Latest-Updates_tBL .line_space_tBL .rt-tr.-odd,
.Req-Latest-Updates_tBL .line_space_tBL .rt-tr.-even,
.Req-Latest-Updates_tBL .line_space_tBL .rt-tr.-even .rt-td.update_td_set,
.Req-Latest-Updates_tBL .line_space_tBL .rt-tr.-even,
.Req-Latest-Updates_tBL .line_space_tBL .rt-tr.-even {
  padding: 0px 0px !important; }

.Req-Latest-Updates_tBL .rt-td.update_td_set .stats_ups {
  margin-bottom: 0px; }

.content_main ul {
  list-style-type: disc; }

.Availability_tBL .ReactTable.-highlight .rt-tr.-odd,
.Availability_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub {
  background: #ebebeb; }
  .Availability_tBL .ReactTable.-highlight .rt-tr.-odd .calendar_me tr td,
  .Availability_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub .calendar_me tr td {
    background: #ebebeb;
    cursor: default; }
  .Availability_tBL .ReactTable.-highlight .rt-tr.-odd .calendar_me tr th,
  .Availability_tBL .ReactTable.-highlight .rt-tr.-odd + .tBL_Sub .calendar_me tr th {
    cursor: default; }

.Availability_tBL .ReactTable.-highlight .rt-tr.-even,
.Availability_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub {
  background: #fff; }
  .Availability_tBL .ReactTable.-highlight .rt-tr.-even .calendar_me tr td,
  .Availability_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub .calendar_me tr td {
    background: #fff;
    cursor: default; }
  .Availability_tBL .ReactTable.-highlight .rt-tr.-even .calendar_me tr th,
  .Availability_tBL .ReactTable.-highlight .rt-tr.-even + .tBL_Sub .calendar_me tr th {
    cursor: default; }

.Availability_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-odd:not(.-padRow):hover {
  background: #ebebeb; }

.Availability_tBL .ReactTable.-highlight .rt-tbody .rt-tr.-even:not(.-padRow):hover {
  background: #fff; }

.finance-statement_Table .rt-td {
  padding-left: 0px !important; }
  .finance-statement_Table .rt-td a u {
    text-decoration: underline !important;
    color: var(--txt-color); }

.finance-statement_Table .listing_table .rt-tr-group {
  margin: 0px; }

.Credit-notes_tBL .rt-expandable {
  display: none; }

.bonus-Training_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Add-Participant_tBL .line_space_tBL .rt-tr.-odd,
.Add-Participant_tBL .line_space_tBL .rt-tr.-even {
  padding: 3px 0px; }

.Add-Participant_tBL .Plus_button .create_add_but::before {
  font-size: 20px;
  padding: 2px 5px; }

.House-Detail_tBL .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.schedule_das_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.shedule_unconfirmed_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.shedule_cancele_and_reject_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.shedule_completed_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.Shedule_new_request_roster_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.schedule_active_roster_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.schedule_archived_dublicate_roster_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.schedule_filled_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_pending_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_sub_org_das_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_sub_org_site_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_contact_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_site_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_house_contact_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_sub_org_contact_table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.org_sub_site_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.participant_booking_list_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.participant_fms_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.participant_roster_requerst_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.participant_current_roster_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.participant_dashboard_Table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.imail_template_listing_table .tBL_Sub {
  padding: 15px;
  border-top: 1px solid #777; }

.imail_template_listing_table ul {
  list-style-type: initial;
  margin-left: 15px; }

@media (min-width: 1200px) {
  .w-10-lg {
    width: 10%; }
  .w-20-lg {
    width: 20%; }
  .w-30-lg {
    width: 30%; }
  .w-40-lg {
    width: 40%; }
  .w-50-lg {
    width: 50%; }
  .w-60-lg {
    width: 60%; }
  .w-70-lg {
    width: 70%; }
  .w-80-lg {
    width: 80%; }
  .w-90-lg {
    width: 90%; } }

@media (min-width: 2048px) {
  .fixed_size {
    width: 1750px; }
  .commmen_create_feedback_Modal .modal-dialog,
  .overlap_shift_popup_Modal .modal-dialog {
    width: 1300px; } }

@media (max-width: 1550px) {
  body {
    font-size: 14px; }
  ._Common_He_a h1,
  .main_heading_cmn- h1 {
    font-size: 36px; }
  .h-h1,
  h1.not_msg_v1,
  h1 {
    font-size: 36px; }
  h2 {
    font-size: 30px;
    line-height: 30px; }
  .Schedule_calendar .rbc-calendar {
    padding: 10px; }
  .landing_graph li {
    padding: 15px !important; }
    .landing_graph li .counter_div .counter_lines {
      background-size: 33px; }
    .landing_graph li .counter_div .counter_text {
      font-size: 28px;
      letter-spacing: 16px;
      padding: 0 5px 0 12px;
      width: calc(100% - 10px); }
    .landing_graph li h4.clr2,
    .landing_graph li h4.clr1,
    .landing_graph li .vw_bx12 h4,
    .landing_graph li .vw_bx12 h5 {
      font-size: 12px; }
    .landing_graph li h2.cl_black {
      font-size: 17px; }
  .Over_counter_ .Over_counter_div_ {
    padding: 30px;
    background: #fff;
    border-radius: var(--b-radius); }
    .Over_counter_ .Over_counter_div_ .Overview_conter {
      padding-left: 10px; }
      .Over_counter_ .Over_counter_div_ .Overview_conter .counter_div {
        margin: 0px auto; }
        .Over_counter_ .Over_counter_div_ .Overview_conter .counter_div .counter_lines {
          background-size: 33px; }
        .Over_counter_ .Over_counter_div_ .Overview_conter .counter_div .counter_text {
          font-size: 28px;
          letter-spacing: 16px;
          padding: 0 5px 0 12px;
          width: calc(100% - 10px); }
  .time_d_1:after {
    left: 31px; }
  .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div {
    width: 110px; }
    .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div > .time_no {
      left: 5px; }
  .but_around span.add_access {
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 51px; }
  .folder_tab .but_around_second span.add_access,
  span.circle_but {
    width: 85px;
    height: 85px;
    font-size: 50px; }
  .but_around_second li {
    margin: 3rem 0 0rem; }
  .listing_table .rt-thead .rt-tr .rt-th .rt-resizable-header-content {
    font-size: 14px; }
  .listing_table .rt-tbody .rt-tr .rt-td {
    font-size: 14px; }
  .row.your h4 {
    padding: 15px 10px;
    height: 70px;
    font-size: 20px;
    line-height: 21px; }
  .row.your [class*="col-"] {
    height: 160px; }
    .row.your [class*="col-"] a .Ext_1,
    .row.your [class*="col-"] a .Int_1 {
      margin: 0px auto;
      font-size: 65px; }
  .GroupMSG_team {
    font-size: 14px;
    padding: 5px 10px; }
  .member_shift #example-component,
  .member_shift .rbc-calendar {
    padding: 15px 30px 15px;
    width: 100%; }
  table.shift_table tr td {
    font-size: 10px; }
  .listing_table i.icon.icon-views:before,
  .icon_h-1:before,
  .schedule_listings i.icon.icon-views:before,
  .Edit_i1,
  .Arch_i1,
  .inactive_btn,
  .active_btn,
  i.update_button:before,
  .icon-pending-icons:before,
  span.booking_L_T1 i,
  .archive_button,
  .archive_Icon:before {
    font-size: 20px; }
  .Imail_btn_left_v1 i,
  .Imail_btn_right_v1 i {
    width: 35px;
    height: 35px;
    font-size: 15px; }
  .P_15_TB {
    padding-top: 10px;
    padding-bottom: 10px; }
  .P_25_T {
    padding-top: 20px; }
  .P_25_b {
    padding-bottom: 20px; }
  .P_25_TB {
    padding-top: 20px;
    padding-bottom: 20px; }
  .P_30_TB {
    padding-top: 25px;
    padding-bottom: 25px; }
  .P_20_TB {
    padding-top: 15px;
    padding-bottom: 15px; }
  .active_user {
    font-size: 14px; }
  .fund_duration_doc {
    margin-top: 10px; }
    .fund_duration_doc .Fil_ter_ToDo {
      display: block; }
  .boxi {
    padding: 30px 60px; }
  .L-I-P_Table .L-I-P_list li span {
    font-size: 13px; }
  .Fil_ter_ToDo label {
    font-size: 14px; }
  .Fil_ter_ToDo .react-datepicker__input-container input {
    font-size: 12px; } }

@media (max-width: 1199px) {
  body {
    font-size: 13px; }
  ._Common_He_a h1,
  .main_heading_cmn- h1 {
    font-size: 24px; }
  .h-h1,
  h1.not_msg_v1,
  h1 {
    font-size: 24px; }
  h2 {
    font-size: 20px;
    line-height: 20px; }
  .Schedule_calendar .rbc-calendar {
    padding: 10px; }
  .landing_graph li {
    padding: 15px !important; }
    .landing_graph li .counter_div .counter_lines {
      background-size: 33px; }
    .landing_graph li .counter_div .counter_text {
      font-size: 28px;
      letter-spacing: 16px;
      padding: 0 5px 0 12px;
      width: calc(100% - 10px); }
    .landing_graph li h4.clr2,
    .landing_graph li h4.clr1,
    .landing_graph li .vw_bx12 h4,
    .landing_graph li .vw_bx12 h5 {
      font-size: 12px; }
    .landing_graph li h2.cl_black {
      font-size: 17px; }
  .time_d_1:after {
    left: 31px; }
  .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div {
    width: 90px; }
    .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div > .time_no {
      left: 5px; }
  .Version_timeline_4.timeline_1 .time_d_1 {
    width: 660px; }
  .but_around span.add_access {
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 51px; }
  .folder_tab .but_around_second span.add_access,
  span.circle_but {
    width: 85px;
    height: 85px;
    font-size: 50px; }
  .but_around_second li {
    margin: 3rem 0 0rem; }
  .main_sidebar__ {
    width: 200px; }
    .main_sidebar__ .sideNavies__ li a,
    .main_sidebar__ .drpHdng {
      padding: 5px 10px;
      font-size: 11px; }
  .asideSect__ {
    padding-left: 215px; }
  .left_men_content_2v .nav_apps span.add_access {
    width: 40px;
    height: 40px;
    font-size: 25px;
    line-height: 40px; }
  .listing_table .rt-thead .rt-tr .rt-th .rt-resizable-header-content {
    font-size: 13px; }
  .listing_table .rt-tbody .rt-tr .rt-td {
    font-size: 13px; }
  .C_NeW_BtN {
    padding: 5px 10px; }
    .C_NeW_BtN i {
      font-size: 21px;
      padding-left: 7px; }
  .row.your h4 {
    padding: 15px 10px;
    height: 70px;
    font-size: 16px;
    line-height: 17px; }
  .row.your [class*="col-"] {
    height: 140px; }
    .row.your [class*="col-"] a .Ext_1,
    .row.your [class*="col-"] a .Int_1 {
      margin: 0px auto;
      font-size: 54px; }
  .GroupMSG_team {
    font-size: 13px;
    padding: 5px 10px; }
  .mess_V1_1 .mess_V_1 .nav_apps span.add_access {
    width: 30px;
    height: 30px;
    font-size: 20px; }
  .mess_V1_1 .mess_V_a1 {
    font-size: 12px; }
  .bl-sm-0 {
    border-left: 0px solid #1e1e1e !important; }
  .bt-sm-0 {
    border-top: 0px solid #1e1e1e !important; }
  .br-sm-0 {
    border-right: 0px solid #1e1e1e !important; }
  .bb-sm-0 {
    border-bottom: 0px solid #1e1e1e !important; }
  .bl-sm-1 {
    border-left: 1px solid #1e1e1e !important; }
  .bt-sm-1 {
    border-top: 1px solid #1e1e1e !important; }
  .br-sm-1 {
    border-right: 1px solid #1e1e1e !important; }
  .bb-sm-1 {
    border-bottom: 1px solid #1e1e1e !important; }
  .m-sm-0 {
    margin: 0 0; }
  .mt-sm-0 {
    margin-top: 0; }
  .mr-sm-0 {
    margin-right: 0; }
  .mb-sm-0 {
    margin-bottom: 0; }
  .ml-sm-0 {
    margin-left: 0; }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0; }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .m-sm-1 {
    margin: 0.25rem 0.25rem; }
  .mt-sm-1 {
    margin-top: 0.25rem; }
  .mr-sm-1 {
    margin-right: 0.25rem; }
  .mb-sm-1 {
    margin-bottom: 0.25rem; }
  .ml-sm-1 {
    margin-left: 0.25rem; }
  .mx-sm-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem; }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .m-sm-2 {
    margin: 0.5rem 0.5rem; }
  .mt-sm-2 {
    margin-top: 0.5rem; }
  .mr-sm-2 {
    margin-right: 0.5rem; }
  .mb-sm-2 {
    margin-bottom: 0.5rem; }
  .ml-sm-2 {
    margin-left: 0.5rem; }
  .mx-sm-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem; }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .m-sm-3 {
    margin: 1rem 1rem; }
  .mt-sm-3 {
    margin-top: 1rem; }
  .mr-sm-3 {
    margin-right: 1rem; }
  .mb-sm-3 {
    margin-bottom: 1rem; }
  .ml-sm-3 {
    margin-left: 1rem; }
  .mx-sm-3 {
    margin-right: 1rem;
    margin-left: 1rem; }
  .my-sm-3 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .m-sm-4 {
    margin: 1.5rem 1.5rem; }
  .mt-sm-4 {
    margin-top: 1.5rem; }
  .mr-sm-4 {
    margin-right: 1.5rem; }
  .mb-sm-4 {
    margin-bottom: 1.5rem; }
  .ml-sm-4 {
    margin-left: 1.5rem; }
  .mx-sm-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem; }
  .my-sm-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .m-sm-5 {
    margin: 3rem 3rem; }
  .mt-sm-5 {
    margin-top: 3rem; }
  .mr-sm-5 {
    margin-right: 3rem; }
  .mb-sm-5 {
    margin-bottom: 3rem; }
  .ml-sm-5 {
    margin-left: 3rem; }
  .mx-sm-5 {
    margin-right: 3rem;
    margin-left: 3rem; }
  .my-sm-5 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .p-sm-0 {
    padding: 0 0; }
  .pt-sm-0 {
    padding-top: 0; }
  .pr-sm-0 {
    padding-right: 0; }
  .pb-sm-0 {
    padding-bottom: 0; }
  .pl-sm-0 {
    padding-left: 0; }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0; }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .p-sm-1 {
    padding: 0.25rem 0.25rem; }
  .pt-sm-1 {
    padding-top: 0.25rem; }
  .pr-sm-1 {
    padding-right: 0.25rem; }
  .pb-sm-1 {
    padding-bottom: 0.25rem; }
  .pl-sm-1 {
    padding-left: 0.25rem; }
  .px-sm-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem; }
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .p-sm-2 {
    padding: 0.5rem 0.5rem; }
  .pt-sm-2 {
    padding-top: 0.5rem; }
  .pr-sm-2 {
    padding-right: 0.5rem; }
  .pb-sm-2 {
    padding-bottom: 0.5rem; }
  .pl-sm-2 {
    padding-left: 0.5rem; }
  .px-sm-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .p-sm-3 {
    padding: 1rem 1rem; }
  .pt-sm-3 {
    padding-top: 1rem; }
  .pr-sm-3 {
    padding-right: 1rem; }
  .pb-sm-3 {
    padding-bottom: 1rem; }
  .pl-sm-3 {
    padding-left: 1rem; }
  .px-sm-3 {
    padding-right: 1rem;
    padding-left: 1rem; }
  .py-sm-3 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .p-sm-4 {
    padding: 1.5rem 1.5rem; }
  .pt-sm-4 {
    padding-top: 1.5rem; }
  .pr-sm-4 {
    padding-right: 1.5rem; }
  .pb-sm-4 {
    padding-bottom: 1.5rem; }
  .pl-sm-4 {
    padding-left: 1.5rem; }
  .px-sm-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  .py-sm-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .p-sm-5 {
    padding: 3rem 3rem; }
  .pt-sm-5 {
    padding-top: 3rem; }
  .pr-sm-5 {
    padding-right: 3rem; }
  .pb-sm-5 {
    padding-bottom: 3rem; }
  .pl-sm-5 {
    padding-left: 3rem; }
  .px-sm-5 {
    padding-right: 3rem;
    padding-left: 3rem; }
  .py-sm-5 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .big_modal .modal-dialog,
  .customModal .Information_modal,
  .Modal_A.Modal_B.O_Modal_size .modal-dialog {
    width: 96%; }
  .listing_table i.icon.icon-views:before,
  .icon_h-1:before,
  .schedule_listings i.icon.icon-views:before,
  .Edit_i1,
  .Arch_i1,
  .inactive_btn,
  .active_btn,
  i.update_button:before,
  .icon-pending-icons:before,
  span.booking_L_T1 i,
  .archive_Icon:before {
    font-size: 17px; }
  .Imail_btn_left_v1 i,
  .Imail_btn_right_v1 i {
    width: 30px;
    height: 30px;
    font-size: 12px; }
  .P_25_TB {
    padding-top: 15px;
    padding-bottom: 15px; }
  .P_25_T {
    padding-top: 15px; }
  .P_25_b {
    padding-bottom: 15px; }
  .P_30_TB {
    padding-top: 20px;
    padding-bottom: 20px; }
  .P_15_TB {
    padding-top: 5px;
    padding-bottom: 5px; }
  .P_20_TB {
    padding-top: 10px;
    padding-bottom: 10px; }
  .active_user {
    font-size: 12px; }
  .gradient_color .but_login {
    font-size: 15px; }
  .gradient_color .Smiley h1 {
    text-align: center; }
  .boxi {
    padding: 30px 30px; } }

@media (max-width: 992px) {
  .bl-xs-0 {
    border-left: 0 solid #1e1e1e !important; }
  .bt-xs-0 {
    border-top: 0 solid #1e1e1e !important; }
  .br-xs-0 {
    border-right: 0 solid #1e1e1e !important; }
  .bb-xs-0 {
    border-bottom: 0 solid #1e1e1e !important; }
  .bl-xs-1 {
    border-left: 1px solid #1e1e1e !important; }
  .bt-xs-1 {
    border-top: 1px solid #1e1e1e !important; }
  .br-xs-1 {
    border-right: 1px solid #1e1e1e !important; }
  .bb-xs-1 {
    border-bottom: 1px solid #1e1e1e !important; }
  .m-xs-0 {
    margin: 0 0; }
  .mt-xs-0 {
    margin-top: 0; }
  .mr-xs-0 {
    margin-right: 0; }
  .mb-xs-0 {
    margin-bottom: 0; }
  .ml-xs-0 {
    margin-left: 0; }
  .mx-xs-0 {
    margin-right: 0;
    margin-left: 0; }
  .my-xs-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .m-xs-1 {
    margin: 0.25rem 0.25rem; }
  .mt-xs-1 {
    margin-top: 0.25rem; }
  .mr-xs-1 {
    margin-right: 0.25rem; }
  .mb-xs-1 {
    margin-bottom: 0.25rem; }
  .ml-xs-1 {
    margin-left: 0.25rem; }
  .mx-xs-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem; }
  .my-xs-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .m-xs-2 {
    margin: 0.5rem 0.5rem; }
  .mt-xs-2 {
    margin-top: 0.5rem; }
  .mr-xs-2 {
    margin-right: 0.5rem; }
  .mb-xs-2 {
    margin-bottom: 0.5rem; }
  .ml-xs-2 {
    margin-left: 0.5rem; }
  .mx-xs-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem; }
  .my-xs-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .m-xs-3 {
    margin: 1rem 1rem; }
  .mt-xs-3 {
    margin-top: 1rem; }
  .mr-xs-3 {
    margin-right: 1rem; }
  .mb-xs-3 {
    margin-bottom: 1rem; }
  .ml-xs-3 {
    margin-left: 1rem; }
  .mx-xs-3 {
    margin-right: 1rem;
    margin-left: 1rem; }
  .my-xs-3 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .m-xs-4 {
    margin: 1.5rem 1.5rem; }
  .mt-xs-4 {
    margin-top: 1.5rem; }
  .mr-xs-4 {
    margin-right: 1.5rem; }
  .mb-xs-4 {
    margin-bottom: 1.5rem; }
  .ml-xs-4 {
    margin-left: 1.5rem; }
  .mx-xs-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem; }
  .my-xs-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .m-xs-5 {
    margin: 3rem 3rem; }
  .mt-xs-5 {
    margin-top: 3rem; }
  .mr-xs-5 {
    margin-right: 3rem; }
  .mb-xs-5 {
    margin-bottom: 3rem; }
  .ml-xs-5 {
    margin-left: 3rem; }
  .mx-xs-5 {
    margin-right: 3rem;
    margin-left: 3rem; }
  .my-xs-5 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .p-xs-0 {
    padding: 0 0; }
  .pt-xs-0 {
    padding-top: 0; }
  .pr-xs-0 {
    padding-right: 0; }
  .pb-xs-0 {
    padding-bottom: 0; }
  .pl-xs-0 {
    padding-left: 0; }
  .px-xs-0 {
    padding-right: 0;
    padding-left: 0; }
  .py-xs-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .p-xs-1 {
    padding: 0.25rem 0.25rem; }
  .pt-xs-1 {
    padding-top: 0.25rem; }
  .pr-xs-1 {
    padding-right: 0.25rem; }
  .pb-xs-1 {
    padding-bottom: 0.25rem; }
  .pl-xs-1 {
    padding-left: 0.25rem; }
  .px-xs-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem; }
  .py-xs-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .p-xs-2 {
    padding: 0.5rem 0.5rem; }
  .pt-xs-2 {
    padding-top: 0.5rem; }
  .pr-xs-2 {
    padding-right: 0.5rem; }
  .pb-xs-2 {
    padding-bottom: 0.5rem; }
  .pl-xs-2 {
    padding-left: 0.5rem; }
  .px-xs-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .py-xs-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .p-xs-3 {
    padding: 1rem 1rem; }
  .pt-xs-3 {
    padding-top: 1rem; }
  .pr-xs-3 {
    padding-right: 1rem; }
  .pb-xs-3 {
    padding-bottom: 1rem; }
  .pl-xs-3 {
    padding-left: 1rem; }
  .px-xs-3 {
    padding-right: 1rem;
    padding-left: 1rem; }
  .py-xs-3 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .p-xs-4 {
    padding: 1.5rem 1.5rem; }
  .pt-xs-4 {
    padding-top: 1.5rem; }
  .pr-xs-4 {
    padding-right: 1.5rem; }
  .pb-xs-4 {
    padding-bottom: 1.5rem; }
  .pl-xs-4 {
    padding-left: 1.5rem; }
  .px-xs-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem; }
  .py-xs-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .p-xs-5 {
    padding: 3rem 3rem; }
  .pt-xs-5 {
    padding-top: 3rem; }
  .pr-xs-5 {
    padding-right: 3rem; }
  .pb-xs-5 {
    padding-bottom: 3rem; }
  .pl-xs-5 {
    padding-left: 3rem; }
  .px-xs-5 {
    padding-right: 3rem;
    padding-left: 3rem; }
  .py-xs-5 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .Modal_A .modal-dialog,
  .Modal_A.Modal_B.M_Modal_size .modal-dialog {
    width: 94%; }
  .AL_flex {
    flex-wrap: wrap; }
  .asideSect__ {
    padding-left: 15px; }
  .main_sidebar__ {
    display: none; }
  .landing_graph {
    flex-wrap: wrap; }
    .landing_graph li {
      width: 50%; }
      .landing_graph li:nth-child(2n) {
        margin: 0px; }
  .landing_graph {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1%; }
    .landing_graph li {
      width: 100%; }
  .landing_graph_item_2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1%; }
    .landing_graph_item_2 li {
      width: 100%; }
  .folder_tab .but_around_second span.add_access,
  span.circle_but {
    width: 50px;
    height: 50px;
    font-size: 25px; }
  .folder_tab .but_around_second {
    list-style: none;
    padding: 0px 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; }
    .folder_tab .but_around_second li {
      width: 100%;
      padding-top: 0px; }
  .Version_timeline_4.timeline_1 .time_d_1:after {
    left: 31px; }
  .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div {
    width: 70px; }
    .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div > .time_no {
      left: 5px;
      width: 40px;
      height: 40px;
      border: 5px solid;
      font-size: 15px; }
    .Version_timeline_4.timeline_1 > .time_l_1 > .time_no_div .line_h {
      height: 5px;
      width: 41%;
      margin-top: 45px; }
  .Version_timeline_4.timeline_1 .time_d_1::after {
    width: 5px;
    left: 23px;
    background-size: 5px !important; }
  .Version_timeline_4.timeline_1 .time_d_1 {
    width: 100%; }
  .Version_timeline_4.timeline_1 .time_d_style {
    border: 5px solid var(--bg-color); }
  .Version_timeline_4.timeline_1 .time_line_parent .time_no {
    width: 40px;
    height: 40px;
    border: 5px solid;
    font-size: 15px;
    margin: 18px -3px; }
  .Version_timeline_4.timeline_1 .time_line_parent .line_h {
    margin: 38px 0;
    height: 5px;
    width: 82%; }
  .Version_timeline_4.timeline_1 .time_line_parent .time_no_div {
    width: 65px; }
  .Version_timeline_4.timeline_1 .time_l_1:last-child > .time_d_1::after {
    height: 70px; }
  .Version_timeline_4.timeline_1 .t_t_v4-1 {
    flex-wrap: wrap;
    justify-content: center; }
  .Version_timeline_4.timeline_1 .Recruit_Time_header {
    flex-wrap: wrap; }
  .Version_timeline_4.timeline_1 .Recruit_Time_header.min-height {
    height: auto; }
  .Version_timeline_4.timeline_1 .limt_flex_set_0 {
    flex-wrap: wrap; }
  .Version_timeline_4.timeline_1 .Rec_Left_s_1 {
    flex: none;
    width: 100%; }
  .Version_timeline_4.timeline_1 .Time_subTasks_Action__ {
    text-align: center; }
  .Version_timeline_4.timeline_1 .t_t_v4-1a {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    display: block;
    width: 100%; }
  .Version_timeline_4.timeline_1 .t_t_v4-1b {
    width: 100%;
    text-align: center;
    justify-content: center; }
  .Version_timeline_4.timeline_1 .ci_date {
    width: 100%;
    justify-content: center; }
  .Version_timeline_4.timeline_1 .time_d_1::after {
    top: -20px; }
  .usrMngBox::before {
    display: none; }
  .prof_left small {
    text-align: center; }
  .toggle_list_set_ {
    display: block; }
  .open_left_menu .main_sidebar__ {
    display: block;
    z-index: 1;
    width: 210px; }
  .toggle_list_set_ {
    width: 49px;
    font-size: 23px;
    background: var(--bg-color);
    color: #fff;
    justify-content: center;
    padding: 7px;
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 0px; }
  .overlay_left_menu {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: none;
    z-index: 1; }
  .overlay_left_menu.show {
    display: block; }
  .open_left_menu {
    position: inherit; }
  .all_box {
    max-width: 100%; } }

@media (max-width: 767px) {
  .landing_graph {
    grid-template-columns: 1fr; }
    .landing_graph li:nth-child(2) {
      margin-top: 15px;
      margin-bottom: 15px; }
  .landing_graph_item_2 {
    grid-template-columns: 1fr; }
    .landing_graph_item_2 li {
      margin-bottom: 15px; }
  ._Common_He_a {
    flex-wrap: wrap; }
  ._Common_Search_a {
    flex-wrap: wrap; }
  .d_flex1 {
    flex-wrap: wrap; }
  .app_infoBox__ {
    padding: 15px; }
  .applicant_info_footer_1 {
    flex-wrap: wrap; }
  .FD_ul_ {
    flex-direction: column; }
    .FD_ul_ .FD_li_:nth-child(2) {
      margin-top: 15px;
      margin-bottom: 15px; }
  .user_profile tr {
    display: grid;
    text-align: center;
    margin: 0px auto;
    justify-content: center;
    grid-row-gap: 22px; }
    .user_profile tr .width_130 {
      display: block;
      margin: 0px auto; }
  ul.file_down li {
    width: 33.0%; } }

.slds_manage_contact-modal .slds-modal__content {
  height: 50% !important; }

.dataTable-quickfilter tr {
  height: 30px !important; }

.pg-head-cus-pad .slds-page-header__detail-row {
  flex-wrap: wrap; }

.pg-head-cus-pad .slds-page-header__detail-block {
  padding-left: 1rem; }
