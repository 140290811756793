$table-even-bg:#ebebeb;
$table-bg_bg:#e2e2e2;
$table-odd-bg:#fff;

$org-color: #1EB35A;
$member-color: #FF7043;
$participant-color: #B175FF;
$fms-color: #D32F2F;
$imail-color: #2E9CCA;
$finance-color: #464765;
$crm-color: #992BFF;
$recruitment-color: #ff7043;
$schedule-color: #E07196;
$admin-color: #242543;