.infographic-sidebar {
    color: white;
    height: 100%;

    // Needed so that content won't be overlapped by scrollbar
    // 666px sidebar width - 17px scrollbar width (15px safari, 12px edge)
    min-width: 649px; 

    * {
        color: white;
    }
    
    .no-infographics {
        padding: 24px;
        margin-top: 30px;
    }
    
    .infographic-sidebar-header {
        height: 55px;
        padding-left: 24px;
        padding-right: 24px;
        border-bottom: 1px solid white;
        
        h3 {
            color: white;
            margin: 0px;
            margin-top: 4px;
            font-size: 28px;
            vertical-align: middle;
            margin-left: 18px;
        }

        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }

        .icon-right-arrow {
            float: right;
            margin-top: 10px;
            cursor: pointer;
        }

    }

    .infographic-sidebar-list {
        .infographic-sidebar-block {
            padding: 24px;

            img {
                margin-bottom: 24px;
            }

            h4 {
                font-size: 20px;
                margin-bottom: 12px;
                line-height: 32px;
            }

            p {
                font-size: 16px;
                line-height: 1.5; // 24px = 16px * 1.5
            }
            
        }

        hr {
            background-color: white !important;
            border-color: white !important;
            border-top-width: 1px !important;
            margin: 0px 24px;
            width: auto !important;
        }
    }

    .infographic-sidebar-footer {
        margin-top: 196px;
        padding: 48px;
        border-top: 1px solid white;
        
        h4 {
            font-size: 20px;
            margin-bottom: 24px;
            color: white;
            line-height: 1;
        }
        
        .btn {
            color: white;
            background-color: #992BFF;
            border: none;
            font-size: 20px;
            padding-left: 44px;
            padding-right: 44px;
        }
    }
}