
.v4_pro_d1__{background:#fff;padding:0 15px}
.v4_pro_d1__ .progress{margin-bottom:0}
.progress-b1{
  position: relative;
}
.progress-b1 .progress-bar{background:var(--crm-Processing_color)}
.progress-b2.progress{height:25px}
.progress-b2.progress div{font-size:15px;margin-top:1px}
.progress-b3 .progress-b2.progress{height:17px;border-radius:var(--b-radius)}
.progress-b3 .progress{background:var(--crm-success_color)}
.progress-b3 .progress-bar{background:var(--crm-Processing_color)}
.progress-b3 .progress-b2.progress div{margin-top:0;font-weight:400}
.recruitment_module .progress-b1 .progress{
  background: var(--light-color-background);
}
.recruitment_module .progress-b1 .progress-bar {
  background:var(--bg-color);
}
.Crm .progress-b1 .progress-b2{
  background: #e0c0fe;
}
.progress_b4 .progress-b2.progress div{font-size:10px}
.progress_b4 .progress-b2.progress{margin-bottom:10px}
.sed_set_0_ .Partt_d1_txt_3{line-height:normal;margin-top:7px}
.progress-b5 .progress-b2.progress{height:55px;margin-bottom:0}