// make this styling scoped to avoid affecting other styles
.referencemodule {
    --bg-color: #05adee;
  --txt-color: #05adee;
  --b-color: #05adee;
  --light_color1: #7ddbff;
  --light-color2: #72d0f5;
  --light-color3: #36c7ff;
  --b-radius: 4px;
  --light-color-background: #05adee54;
}