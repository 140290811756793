// make this styling scoped to avoid affecting other styles
.slds-related-tab-details {
    font-family: "Salesforce Sans", Arial, Helvetica, sans-serif;
    font-size: 13;

    .slds-card-bor {
        border: 1px solid #dddbda !important;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.1) !important;
    }

    .slds-box-bg {
        background: #fff;
    }

    .sdls-timeline-subject {
        font-size: 13px;
    }

    .SLDSReactTable.ReactTable .rt-noData {
        vertical-align: middle;
        background-color: white;
        position: static;
        width: 100%;
        transform: none;
        margin: 0;
        line-height: 26px;
    }

    .radio-btn-display {
        display: inline-block !important;
    }

    .reset-col {
        color: #0070d2;
    }
}

.slds-tbl-roles .slds-more-action-dropdown.slds-dropdown,
 .slds-tbl-roles .slds-more-action-dropdown.slds-dropdown {
  display: block;
  visibility: visible;
  opacity: 1;
  left: 0 !important;
  top: 100% !important;
  max-width: 150px !important;
  width: 100%;
  overflow: auto;
  right: inherit !important;
  min-width: 150px !important;
  top: 100% !important;
}
.slds-tbl-roles .rt-tbody,
.slds-tbl-roles.rt-table{
  overflow:visible !important;
}
.slds-tbl-roles .rt-th, .slds-tbl-roles .rt-td{
  overflow:visible !important;
}

.slds-input-readonly {
    border: 1px solid #dddbda !important;
    cursor: not-allowed;
    padding-left: 10px !important;
}
.consent-form-card .slds-tile-lbl { width:40% !important}
.consent-form-card .slds-tile-val { width:60% !important}
.bg-yellow {background-color: #ffff00}
#notes-listing .text-wrap {white-space: pre-wrap; width: "100%";}