.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem !important;
}
.react-datepicker__year-dropdown {
  overflow-y: scroll !important;
  height: 150px !important;
}
.react-datepicker__year-read-view {
  border: 1px solid #fff !important;
  color: #fff !important;
}
.react-datepicker__year-read-view--down-arrow {
  top: 10px !important;
  margin-right: 5px;
}
.react-datepicker__year-read-view--down-arrow {
  top: 4px !important;
}
.react-datepicker__close-icon::after {
  background-color: #1e1e1e !important;
}

.react-datepicker__time-box ul {
  height: 150px !important;
}
.react-datepicker {
  font-size: 1.1rem !important;
  display: flex !important;
}
.react-datepicker__current-month {
  font-size: 1.3rem !important;
}
.react-datepicker__header {
  background-color: #1e1e1e;
}
.react-datepicker__day-name,
.react-datepicker__current-month {
  color: #fff !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #1e1e1e;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #1e1e1e;
}
// .react-datepicker-popper{z-index: 9999 !important;}
.react-datepicker-popper {
  z-index: 999999 !important;
}
.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__header {
  background: var(--bg-color);
  background-color: var(--bg-color);
}

.datepicker_my {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 11px;
    width: 30px !important;
    height: 30px;
    line-height: 30px;
  }
  .react-datepicker__day--keyboard-selected {
    background: var(--bg-color);
    color: #fff;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background: var(--bg-color);
  }
  .react-datepicker__navigation--previous {
    border-right-color: #fff;
  }
  .react-datepicker__navigation--next {
    border-left-color: #fff;
  }
  .react-datepicker__time-container {
    position: absolute;
    right: -70px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 0 5px;
    font-size: 11px;
    display: flex;
    align-items: center;
  }
  .react-datepicker__time-container .react-datepicker__time {
    border: solid 1px #aeaeae;
    overflow: hidden;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: var(--bg-color);
    color: #fff;
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: var(--bg-color);
  }
  .react-datepicker__time-list {
    height: 200px;
  }
  .csform-group
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 10px;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: 12px;
  }
}

.csform-group,
.cust_date_picker {
  & .react-datepicker__current-month,
  & .react-datepicker-time__header {
    color: #fff;
  }
  & .react-datepicker__day-name {
    color: #fff;
  }
  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    font-size: 11px;
    width: 30px !important;
    height: 30px;
    line-height: 30px;
  }
  & .react-datepicker__day--selected,
  & .react-datepicker__day--in-selecting-range,
  & .react-datepicker__day--in-range {
    background: var(--bg-color);
  }
  & .react-datepicker__navigation--previous {
    border-right-color: #fff;
  }
  & .react-datepicker__navigation--next {
    border-left-color: #fff;
  }
  & .react-datepicker__time-container {
    position: absolute;
    right: -70px;
  }
  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 0 5px;
    font-size: 11px;
    display: flex;
    align-items: center;
  }
  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover,
  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--bg-color) !important;
  }
  & .react-datepicker__time-container .react-datepicker__time {
    border: solid 1px #aeaeae;
    overflow: hidden;
  }
  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: var(--bg-color);
    color: #fff;
  }
  & .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: var(--bg-color);
  }
  & .react-datepicker__time-list {
    height: 200px !important;
  }
  & .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 10px;
  }
  & .react-datepicker__current-month,
  & .react-datepicker-time__header {
    font-size: 12px;
  }
  &.right_0_date_piker {
    .react-datepicker-popper {
      margin-left: -120px !important;
      .react-datepicker__triangle {
        left: 150px;
      }
    }
  }
  .react-datepicker__close-icon {
    margin-top: -15px;
    margin-right: -15px;
  }
}

.react-datepicker__input-container {
  .csForm_control {
    padding-left: 7px;
    padding-right: 7px;
  }
}
