div.SLDSReactSelect.Select {
    $SLDSSelectColor: #080707;
    $SLDSLineHeight: 17px;

    height: 32px;
    font-size: 13px;
    font-weight: normal;
    color: $SLDSSelectColor;
    text-align: left;
    font-family: 'Salesforce Sans', Arial, Helvetica, sans-serif;
    background-color: white;
    border: 1px solid #dddbda;
    border-radius: 0.25rem;
    width: 100%;
    transition: border 0.1s linear, background-color 0.1s linear;
    padding: 0;
    // line-height: 1.875rem;
    min-height: calc(30px + (1px * 2));
  
    input {
      height: inherit;
      border: none;
      line-height: 1;
      min-height: 30px;
      background: none;
    }
  
    .Select-placeholder, .Select-value-label {
      font-weight: normal !important;
      color: inherit !important;
      font-size: inherit;
    }
  
    .Select-control .Select-placeholder, 
    &.Select--single > .Select-control .Select-value {
      text-align: inherit !important;
    }
  
    .Select-control, 
    &.is-open > .Select-control {
      border-color: transparent;
    }
  
    .Select-input {
      height: auto;
    }
  
    .Select-control > *:last-child {
      padding-right: 0px;
    }
  
    &.is-searchable .Select-input input {
      padding-left: 0px !important;
    }
  
    .Select-clear-zone {
      width: 38px;
    }
  
    .Select-input {
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        vertical-align: middle;
    }  
  
    &.is-searchable .Select-input input {
      border-radius: 0px;
      padding-left: 7px !important;
    }
  
    .Select-placeholder {
      top: 2px !important;
      opacity: 0.5;
    }

  
    .Select-placeholder,
    .Select-value-label {
      font-size: inherit;
      font-weight: inherit !important;
      color: inherit !important;
      line-height: $SLDSLineHeight;
    }
  
    .Select-input {
      // background: none !important;
      overflow: hidden;
    }

    // &.Select--single .Select-control, 
    // &.is-focused > .Select-control, 
    // &.is-open > .Select-control, 
    // &.is-focused:not(.is-opne) > .Select-control
    .Select-control {
        background: inherit;
        background-color: inherit;
    }
  
    div.Select-control {
      height: auto;
      border: none;
      background: #1e1e1e;
      height: 100%;
      color: inherit;
    }
  
    .Select-arrow {
      border-color: #fff transparent transparent !important;
      border-style: solid;
      border-width: 12px 9px 2.5px !important;
      display: inline-block;
      height: 9px;
      width: 0;
      position: absolute !important;
      top: 14px;
      right: 13px;
    }
  
    &.is-open > .Select-control .Select-arrow {
      border-color: transparent transparent #fff !important;
      border-width: 0 9px 12px !important;
      position: absolute !important;
      top: 12px !important;
      right: 13px !important;
    }
  
    .Select-value-label {
      color: inherit !important;
    }
  
    .Select-menu-outer {
      border-radius: none !important;
      overflow: hidden !important;
      font-size: 12px !important;
      font-weight: inherit !important;
      margin-top: 3px;
      margin-bottom: 3px;
      color: inherit;

        .Select-option {
            color: inherit;
            cursor: pointer;
            display: block;
            padding: 8px 10px;

            &.is-focused {
                background-color: #ebf5ff;
                background-color: rgba(0, 126, 255, 0.08);
                color: inherit;
            }
        }
    }
  
    .Select-arrow,
    &.is-open > .Select-control .Select-arrow {
      border-width: 0px !important;
    }
  
    .Select-arrow::before,
    &.is-open > .Select-control .Select-arrow::before {
      content: "\68";
      font-family: "icons_me";
      border-width: 0px;
      top: -3px;
      position: absolute;
      margin-left: -15px;
      color: inherit;
      font-size: 14px;
    }
  
    &.Select--single .Select-control,
    &.is-focused > .Select-control,
    &.is-open > .Select-control,
    &.is-focused:not(.is-open) > .Select-control,
    .Select-control {
      background: none;
      background-color: transparent;
    }
  
    .Select-control .Select-placeholder,
    &.Select--single > .Select-control .Select-value {
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%);
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: auto;
      line-height: $SLDSLineHeight;
      position: absolute;
      text-align: inherit;
      width: 100%;
      padding-left: 5px;
    }
  
    .Select-placeholder,
    &.Select--single > .Select-control .Select-value {
      max-width: calc(100% - 10px);
      width: calc(100% - 10px);
      padding-right: 10px !important;
    }
  
    &.Select--single > .Select-control .Select-value .Select-value-label {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      padding-right: 7px;
      padding-left: 5px;
      align-content: center; /* display: flex;  */
      justify-content: center;
      align-items: center;
      height: $SLDSLineHeight;

    }
  
    &.Select--multi .Select-multi-value-wrapper {
      width: 100%;
    }

    
    .Select-input > input,
    .Select-input {
      width: 100% !important;
    }
    
    &.Select--single .Select-control,
    &.is-focused > .Select-control,
    &.is-open > .Select-control,
    &.is-focused:not(.is-opne) > .Select-control
    .Select-control {
      border-radius: 0;
    }
  
    &.is-focused,
    &.is-open {
      outline: 0;
      border-color: #1589ee;
      box-shadow: 0 0 3px #0070D2;
  
      .slds-input, .slds-input {
          outline: 0;
          border: none;
          background-color: transparent;
          box-shadow: none;
      }
    }

    &.has-value {
        box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 3px #dddbda;

        &.has-no-value {
          box-shadow: initial;
          
          .Select-clear-zone {
              display: none;
          }
        }
    }

    .Select-placeholder, 
    &.Select--single > .Select-control .Select-value {
        color: inherit;
    }

    &.is-loading {
        position: relative;
        
        .Select-loading-zone {
            position: absolute;
            top: 0;
            right: 35px;
            display: flex;
            height: 100%;
            align-items: center;
        }
    }

  
}

// Fixes the dropdown item inner background colored 
// as grey when integrating with slds-table
.slds-no-cell-focus .SLDSReactSelect .slds-has-focus {
  background: none;
}
  
.Select.default_validation.SLDSReactSelect.has-no-value.is-disabled {
  background: #f4f4f4;
}
  
  
  